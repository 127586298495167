@charset "UTF-8";
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #FFFFFF !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dddddd;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dddddd;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.428571;
  color: #555555;
  text-align: left;
  background-color: #f0f0f0;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.6923rem;
}

p {
  margin-top: 0;
  margin-bottom: 0.6923rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #ef6262;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #e71d1d;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.9231rem 0.7692rem;
  padding-bottom: 0.9231rem 0.7692rem;
  color: #777777;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.6923rem;
  font-family: inherit;
  font-weight: 300;
  line-height: 1.1;
  color: inherit;
}

h1, .h1 {
  font-size: 2.538rem;
}

h2, .h2 {
  font-size: 2.077rem;
}

h3, .h3 {
  font-size: 1.769rem;
}

h4, .h4 {
  font-size: 1.308rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.9231rem;
}

.lead {
  font-size: 1.077rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #FFC870;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #777777;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.3077rem;
  background-color: #f0f0f0;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #777777;
}

code {
  font-size: 90%;
  color: #c7254e;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 90%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.9231rem 0.7692rem;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dddddd;
}

.table tbody + tbody {
  border-top: 2px solid #dddddd;
}

.table .table {
  background-color: #f0f0f0;
}

.table-sm th,
.table-sm td {
  padding: 0.5385rem;
}

.table-bordered {
  border: 1px solid #dddddd;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dddddd;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover {
  background-color: #f5f5f5;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fad3d3;
}

.table-hover .table-primary:hover {
  background-color: #f7bcbc;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #f7bcbc;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d9d9d9;
}

.table-hover .table-secondary:hover {
  background-color: #cccccc;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cccccc;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #daf1ed;
}

.table-hover .table-success:hover {
  background-color: #c8eae4;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c8eae4;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dff0f6;
}

.table-hover .table-info:hover {
  background-color: #cbe7f0;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #cbe7f0;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff0d7;
}

.table-hover .table-warning:hover {
  background-color: #ffe6be;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe6be;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbd3d3;
}

.table-hover .table-danger:hover {
  background-color: #f9bcbc;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9bcbc;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: white;
}

.table-hover .table-light:hover {
  background-color: #f2f2f2;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f2f2f2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c9c8c8;
}

.table-hover .table-dark:hover {
  background-color: #bcbbbb;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #bcbbbb;
}

.table-grey,
.table-grey > th,
.table-grey > td {
  background-color: #f1f1f1;
}

.table-hover .table-grey:hover {
  background-color: #e4e4e4;
}

.table-hover .table-grey:hover > td,
.table-hover .table-grey:hover > th {
  background-color: #e4e4e4;
}

.table-alt-color1,
.table-alt-color1 > th,
.table-alt-color1 > td {
  background-color: #e1f4fb;
}

.table-hover .table-alt-color1:hover {
  background-color: #cbecf8;
}

.table-hover .table-alt-color1:hover > td,
.table-hover .table-alt-color1:hover > th {
  background-color: #cbecf8;
}

.table-alt-color2,
.table-alt-color2 > th,
.table-alt-color2 > td {
  background-color: #fff5da;
}

.table-hover .table-alt-color2:hover {
  background-color: #ffeec1;
}

.table-hover .table-alt-color2:hover > td,
.table-hover .table-alt-color2:hover > th {
  background-color: #ffeec1;
}

.table-alt-color3,
.table-alt-color3 > th,
.table-alt-color3 > td {
  background-color: #dae2ed;
}

.table-hover .table-alt-color3:hover {
  background-color: #c9d5e5;
}

.table-hover .table-alt-color3:hover > td,
.table-hover .table-alt-color3:hover > th {
  background-color: #c9d5e5;
}

.table-alt-color4,
.table-alt-color4 > th,
.table-alt-color4 > td {
  background-color: #f1f1f1;
}

.table-hover .table-alt-color4:hover {
  background-color: #e4e4e4;
}

.table-hover .table-alt-color4:hover > td,
.table-hover .table-alt-color4:hover > th {
  background-color: #e4e4e4;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f5f5f5;
}

.table-hover .table-active:hover {
  background-color: #e8e8e8;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e8e8e8;
}

.table .thead-dark th {
  color: #f0f0f0;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #636c72;
  background-color: #eceeef;
  border-color: #dddddd;
}

.table-dark {
  color: #f0f0f0;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 3.385rem;
  padding: 0.7692rem 0.9231rem;
  font-size: 1rem;
  line-height: 1.428571;
  color: #777777;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 2px solid #eaeaea;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #777777;
  background-color: #FFFFFF;
  border-color: #d1d1d1;
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder {
  color: #999999;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #eeeeee;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #777777;
  background-color: #FFFFFF;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.7692rem + 2px);
  padding-bottom: calc(0.7692rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.428571;
}

.col-form-label-lg {
  padding-top: calc(0.9231rem + 2px);
  padding-bottom: calc(0.9231rem + 2px);
  font-size: 1.154rem;
  line-height: 1.633333;
}

.col-form-label-sm {
  padding-top: calc(0.3077rem + 2px);
  padding-bottom: calc(0.3077rem + 2px);
  font-size: 0.9231rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.7692rem;
  padding-bottom: 0.7692rem;
  margin-bottom: 0;
  line-height: 1.428571;
  color: #555555;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: 2.53846rem;
  padding: 0.3077rem 0.9231rem;
  font-size: 0.9231rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: 3.84615rem;
  padding: 0.9231rem 1.077rem;
  font-size: 1.154rem;
  line-height: 1.633333;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.538rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.154rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.154rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #777777;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #7ACCBE;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.9231rem;
  line-height: 1.428571;
  color: #212529;
  background-color: rgba(122, 204, 190, 0.9);
  border-radius: 0;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #7ACCBE;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #7ACCBE;
  box-shadow: 0 0 0 0 rgba(122, 204, 190, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #7ACCBE;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #7ACCBE;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #d6efeb;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #9fdad0;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0 rgba(122, 204, 190, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #7ACCBE;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 rgba(122, 204, 190, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #EF6262;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.9231rem;
  line-height: 1.428571;
  color: #FFFFFF;
  background-color: rgba(239, 98, 98, 0.9);
  border-radius: 0;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #EF6262;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #EF6262;
  box-shadow: 0 0 0 0 rgba(239, 98, 98, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #EF6262;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #EF6262;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #fbd6d6;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #f49090;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0 rgba(239, 98, 98, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #EF6262;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 rgba(239, 98, 98, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.6154rem 0.9231rem;
  font-size: 1rem;
  line-height: 1.428571;
  border-radius: 0;
  transition: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #FFFFFF;
  background-color: #ef6262;
  border-color: #ef6262;
}

.btn-primary:hover {
  color: #FFFFFF;
  background-color: #eb4040;
  border-color: #ea3434;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0 rgba(239, 98, 98, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #FFFFFF;
  background-color: #ef6262;
  border-color: #ef6262;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ea3434;
  border-color: #e92929;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(239, 98, 98, 0.5);
}

.btn-secondary {
  color: #FFFFFF;
  background-color: #777777;
  border-color: #777777;
}

.btn-secondary:hover {
  color: #FFFFFF;
  background-color: #646464;
  border-color: #5e5e5e;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0 rgba(119, 119, 119, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #FFFFFF;
  background-color: #777777;
  border-color: #777777;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #5e5e5e;
  border-color: #575757;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(119, 119, 119, 0.5);
}

.btn-success {
  color: #212529;
  background-color: #7ACCBE;
  border-color: #7ACCBE;
}

.btn-success:hover {
  color: #212529;
  background-color: #5ec1b0;
  border-color: #55beac;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0 rgba(122, 204, 190, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #212529;
  background-color: #7ACCBE;
  border-color: #7ACCBE;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #212529;
  background-color: #55beac;
  border-color: #4cbaa7;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(122, 204, 190, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #8DCADF;
  border-color: #8DCADF;
}

.btn-info:hover {
  color: #212529;
  background-color: #6fbcd7;
  border-color: #65b7d4;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0 rgba(141, 202, 223, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #8DCADF;
  border-color: #8DCADF;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #65b7d4;
  border-color: #5bb3d1;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(141, 202, 223, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #FFC870;
  border-color: #FFC870;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffb94a;
  border-color: #ffb43d;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0 rgba(255, 200, 112, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #FFC870;
  border-color: #FFC870;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffb43d;
  border-color: #ffaf30;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 200, 112, 0.5);
}

.btn-danger {
  color: #FFFFFF;
  background-color: #EF6262;
  border-color: #EF6262;
}

.btn-danger:hover {
  color: #FFFFFF;
  background-color: #eb3f3f;
  border-color: #ea3434;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0 rgba(239, 98, 98, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #FFFFFF;
  background-color: #EF6262;
  border-color: #EF6262;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ea3434;
  border-color: #e92828;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(239, 98, 98, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-light:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-dark {
  color: #FFFFFF;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-dark:hover {
  color: #FFFFFF;
  background-color: #2a2929;
  border-color: #232323;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0 rgba(61, 60, 60, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #FFFFFF;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #232323;
  border-color: #1d1c1c;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(61, 60, 60, 0.5);
}

.btn-grey {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-grey:hover {
  color: #212529;
  background-color: #b9b9b9;
  border-color: #b3b3b3;
}

.btn-grey:focus, .btn-grey.focus {
  box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5);
}

.btn-grey.disabled, .btn-grey:disabled {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active,
.show > .btn-grey.dropdown-toggle {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #acacac;
}

.btn-grey:not(:disabled):not(.disabled):active:focus, .btn-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-grey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5);
}

.btn-alt-color1 {
  color: #212529;
  background-color: #95d9f0;
  border-color: #95d9f0;
}

.btn-alt-color1:hover {
  color: #212529;
  background-color: #73cdeb;
  border-color: #68c9ea;
}

.btn-alt-color1:focus, .btn-alt-color1.focus {
  box-shadow: 0 0 0 0 rgba(149, 217, 240, 0.5);
}

.btn-alt-color1.disabled, .btn-alt-color1:disabled {
  color: #212529;
  background-color: #95d9f0;
  border-color: #95d9f0;
}

.btn-alt-color1:not(:disabled):not(.disabled):active, .btn-alt-color1:not(:disabled):not(.disabled).active,
.show > .btn-alt-color1.dropdown-toggle {
  color: #212529;
  background-color: #68c9ea;
  border-color: #5dc5e8;
}

.btn-alt-color1:not(:disabled):not(.disabled):active:focus, .btn-alt-color1:not(:disabled):not(.disabled).active:focus,
.show > .btn-alt-color1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(149, 217, 240, 0.5);
}

.btn-alt-color2 {
  color: #212529;
  background-color: #ffdc7a;
  border-color: #ffdc7a;
}

.btn-alt-color2:hover {
  color: #212529;
  background-color: #ffd254;
  border-color: #ffcf47;
}

.btn-alt-color2:focus, .btn-alt-color2.focus {
  box-shadow: 0 0 0 0 rgba(255, 220, 122, 0.5);
}

.btn-alt-color2.disabled, .btn-alt-color2:disabled {
  color: #212529;
  background-color: #ffdc7a;
  border-color: #ffdc7a;
}

.btn-alt-color2:not(:disabled):not(.disabled):active, .btn-alt-color2:not(:disabled):not(.disabled).active,
.show > .btn-alt-color2.dropdown-toggle {
  color: #212529;
  background-color: #ffcf47;
  border-color: #ffcb3a;
}

.btn-alt-color2:not(:disabled):not(.disabled):active:focus, .btn-alt-color2:not(:disabled):not(.disabled).active:focus,
.show > .btn-alt-color2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 220, 122, 0.5);
}

.btn-alt-color3 {
  color: #FFFFFF;
  background-color: #7a98bf;
  border-color: #7a98bf;
}

.btn-alt-color3:hover {
  color: #FFFFFF;
  background-color: #6084b3;
  border-color: #587dae;
}

.btn-alt-color3:focus, .btn-alt-color3.focus {
  box-shadow: 0 0 0 0 rgba(122, 152, 191, 0.5);
}

.btn-alt-color3.disabled, .btn-alt-color3:disabled {
  color: #FFFFFF;
  background-color: #7a98bf;
  border-color: #7a98bf;
}

.btn-alt-color3:not(:disabled):not(.disabled):active, .btn-alt-color3:not(:disabled):not(.disabled).active,
.show > .btn-alt-color3.dropdown-toggle {
  color: #FFFFFF;
  background-color: #587dae;
  border-color: #5177a8;
}

.btn-alt-color3:not(:disabled):not(.disabled):active:focus, .btn-alt-color3:not(:disabled):not(.disabled).active:focus,
.show > .btn-alt-color3.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(122, 152, 191, 0.5);
}

.btn-alt-color4 {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-alt-color4:hover {
  color: #212529;
  background-color: #b9b9b9;
  border-color: #b3b3b3;
}

.btn-alt-color4:focus, .btn-alt-color4.focus {
  box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5);
}

.btn-alt-color4.disabled, .btn-alt-color4:disabled {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-alt-color4:not(:disabled):not(.disabled):active, .btn-alt-color4:not(:disabled):not(.disabled).active,
.show > .btn-alt-color4.dropdown-toggle {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #acacac;
}

.btn-alt-color4:not(:disabled):not(.disabled):active:focus, .btn-alt-color4:not(:disabled):not(.disabled).active:focus,
.show > .btn-alt-color4.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5);
}

.btn-outline-primary {
  color: #ef6262;
  background-color: transparent;
  background-image: none;
  border-color: #ef6262;
}

.btn-outline-primary:hover {
  color: #FFFFFF;
  background-color: #ef6262;
  border-color: #ef6262;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(239, 98, 98, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #ef6262;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ef6262;
  border-color: #ef6262;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(239, 98, 98, 0.5);
}

.btn-outline-secondary {
  color: #777777;
  background-color: transparent;
  background-image: none;
  border-color: #777777;
}

.btn-outline-secondary:hover {
  color: #FFFFFF;
  background-color: #777777;
  border-color: #777777;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0 rgba(119, 119, 119, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #777777;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #777777;
  border-color: #777777;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(119, 119, 119, 0.5);
}

.btn-outline-success {
  color: #7ACCBE;
  background-color: transparent;
  background-image: none;
  border-color: #7ACCBE;
}

.btn-outline-success:hover {
  color: #212529;
  background-color: #7ACCBE;
  border-color: #7ACCBE;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(122, 204, 190, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #7ACCBE;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #7ACCBE;
  border-color: #7ACCBE;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(122, 204, 190, 0.5);
}

.btn-outline-info {
  color: #8DCADF;
  background-color: transparent;
  background-image: none;
  border-color: #8DCADF;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #8DCADF;
  border-color: #8DCADF;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(141, 202, 223, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #8DCADF;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #8DCADF;
  border-color: #8DCADF;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(141, 202, 223, 0.5);
}

.btn-outline-warning {
  color: #FFC870;
  background-color: transparent;
  background-image: none;
  border-color: #FFC870;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #FFC870;
  border-color: #FFC870;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(255, 200, 112, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #FFC870;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #FFC870;
  border-color: #FFC870;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 200, 112, 0.5);
}

.btn-outline-danger {
  color: #EF6262;
  background-color: transparent;
  background-image: none;
  border-color: #EF6262;
}

.btn-outline-danger:hover {
  color: #FFFFFF;
  background-color: #EF6262;
  border-color: #EF6262;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(239, 98, 98, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #EF6262;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #EF6262;
  border-color: #EF6262;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(239, 98, 98, 0.5);
}

.btn-outline-light {
  color: #FFFFFF;
  background-color: transparent;
  background-image: none;
  border-color: #FFFFFF;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #FFFFFF;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
}

.btn-outline-dark {
  color: #3d3c3c;
  background-color: transparent;
  background-image: none;
  border-color: #3d3c3c;
}

.btn-outline-dark:hover {
  color: #FFFFFF;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(61, 60, 60, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #3d3c3c;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(61, 60, 60, 0.5);
}

.btn-outline-grey {
  color: #cccccc;
  background-color: transparent;
  background-image: none;
  border-color: #cccccc;
}

.btn-outline-grey:hover {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-outline-grey:focus, .btn-outline-grey.focus {
  box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5);
}

.btn-outline-grey.disabled, .btn-outline-grey:disabled {
  color: #cccccc;
  background-color: transparent;
}

.btn-outline-grey:not(:disabled):not(.disabled):active, .btn-outline-grey:not(:disabled):not(.disabled).active,
.show > .btn-outline-grey.dropdown-toggle {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-outline-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-grey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5);
}

.btn-outline-alt-color1 {
  color: #95d9f0;
  background-color: transparent;
  background-image: none;
  border-color: #95d9f0;
}

.btn-outline-alt-color1:hover {
  color: #212529;
  background-color: #95d9f0;
  border-color: #95d9f0;
}

.btn-outline-alt-color1:focus, .btn-outline-alt-color1.focus {
  box-shadow: 0 0 0 0 rgba(149, 217, 240, 0.5);
}

.btn-outline-alt-color1.disabled, .btn-outline-alt-color1:disabled {
  color: #95d9f0;
  background-color: transparent;
}

.btn-outline-alt-color1:not(:disabled):not(.disabled):active, .btn-outline-alt-color1:not(:disabled):not(.disabled).active,
.show > .btn-outline-alt-color1.dropdown-toggle {
  color: #212529;
  background-color: #95d9f0;
  border-color: #95d9f0;
}

.btn-outline-alt-color1:not(:disabled):not(.disabled):active:focus, .btn-outline-alt-color1:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-alt-color1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(149, 217, 240, 0.5);
}

.btn-outline-alt-color2 {
  color: #ffdc7a;
  background-color: transparent;
  background-image: none;
  border-color: #ffdc7a;
}

.btn-outline-alt-color2:hover {
  color: #212529;
  background-color: #ffdc7a;
  border-color: #ffdc7a;
}

.btn-outline-alt-color2:focus, .btn-outline-alt-color2.focus {
  box-shadow: 0 0 0 0 rgba(255, 220, 122, 0.5);
}

.btn-outline-alt-color2.disabled, .btn-outline-alt-color2:disabled {
  color: #ffdc7a;
  background-color: transparent;
}

.btn-outline-alt-color2:not(:disabled):not(.disabled):active, .btn-outline-alt-color2:not(:disabled):not(.disabled).active,
.show > .btn-outline-alt-color2.dropdown-toggle {
  color: #212529;
  background-color: #ffdc7a;
  border-color: #ffdc7a;
}

.btn-outline-alt-color2:not(:disabled):not(.disabled):active:focus, .btn-outline-alt-color2:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-alt-color2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 220, 122, 0.5);
}

.btn-outline-alt-color3 {
  color: #7a98bf;
  background-color: transparent;
  background-image: none;
  border-color: #7a98bf;
}

.btn-outline-alt-color3:hover {
  color: #FFFFFF;
  background-color: #7a98bf;
  border-color: #7a98bf;
}

.btn-outline-alt-color3:focus, .btn-outline-alt-color3.focus {
  box-shadow: 0 0 0 0 rgba(122, 152, 191, 0.5);
}

.btn-outline-alt-color3.disabled, .btn-outline-alt-color3:disabled {
  color: #7a98bf;
  background-color: transparent;
}

.btn-outline-alt-color3:not(:disabled):not(.disabled):active, .btn-outline-alt-color3:not(:disabled):not(.disabled).active,
.show > .btn-outline-alt-color3.dropdown-toggle {
  color: #FFFFFF;
  background-color: #7a98bf;
  border-color: #7a98bf;
}

.btn-outline-alt-color3:not(:disabled):not(.disabled):active:focus, .btn-outline-alt-color3:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-alt-color3.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(122, 152, 191, 0.5);
}

.btn-outline-alt-color4 {
  color: #cccccc;
  background-color: transparent;
  background-image: none;
  border-color: #cccccc;
}

.btn-outline-alt-color4:hover {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-outline-alt-color4:focus, .btn-outline-alt-color4.focus {
  box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5);
}

.btn-outline-alt-color4.disabled, .btn-outline-alt-color4:disabled {
  color: #cccccc;
  background-color: transparent;
}

.btn-outline-alt-color4:not(:disabled):not(.disabled):active, .btn-outline-alt-color4:not(:disabled):not(.disabled).active,
.show > .btn-outline-alt-color4.dropdown-toggle {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-outline-alt-color4:not(:disabled):not(.disabled):active:focus, .btn-outline-alt-color4:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-alt-color4.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #ef6262;
  background-color: transparent;
}

.btn-link:hover {
  color: #e71d1d;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #777777;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.9231rem 1.231rem;
  font-size: 1.154rem;
  line-height: 1.633333;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.3846rem 0.6923rem;
  font-size: 0.9231rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.26146em;
  vertical-align: 0.26146em;
  content: "";
  border-top: 0.3076em solid;
  border-right: 0.3076em solid transparent;
  border-bottom: 0;
  border-left: 0.3076em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 12.31rem;
  padding: 0.3846rem 0;
  margin: 0.1538rem 0 0;
  font-size: 1rem;
  color: #555555;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.1538rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.26146em;
  vertical-align: 0.26146em;
  content: "";
  border-top: 0;
  border-right: 0.3076em solid transparent;
  border-bottom: 0.3076em solid;
  border-left: 0.3076em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.1538rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.26146em;
  vertical-align: 0.26146em;
  content: "";
  border-top: 0.3076em solid transparent;
  border-right: 0;
  border-bottom: 0.3076em solid transparent;
  border-left: 0.3076em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.1538rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.26146em;
  vertical-align: 0.26146em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.26146em;
  vertical-align: 0.26146em;
  content: "";
  border-top: 0.3076em solid transparent;
  border-right: 0.3076em solid;
  border-bottom: 0.3076em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eceeef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #ef6262;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #777777;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.3846rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.9231rem;
  color: #777777;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.692325rem;
  padding-left: 0.692325rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.519225rem;
  padding-left: 0.519225rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.92325rem;
  padding-left: 0.92325rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.7692rem 0.9231rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.428571;
  color: #777777;
  text-align: center;
  white-space: nowrap;
  background-color: #eaeaea;
  border: 2px solid #eaeaea;
  border-radius: 0;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: 3.84615rem;
  padding: 0.9231rem 1.077rem;
  font-size: 1.154rem;
  line-height: 1.633333;
  border-radius: 0;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: 2.53846rem;
  padding: 0.3077rem 0.9231rem;
  font-size: 0.9231rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.428571rem;
  padding-left: 2.077rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1.385rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #ef6262;
  background-color: #FFFFFF;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #FFFFFF;
  background-color: #FFFFFF;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #777777;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #eceeef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: -0.131714rem;
  left: -2.077rem;
  display: block;
  width: 1.692rem;
  height: 1.692rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #FFFFFF;
}

.custom-control-label::after {
  position: absolute;
  top: -0.131714rem;
  left: -2.077rem;
  display: block;
  width: 1.692rem;
  height: 1.692rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFFFFF;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #ef6262;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23ef6262' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(239, 98, 98, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(239, 98, 98, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFFFFF;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(239, 98, 98, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3.385rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.428571;
  color: #777777;
  vertical-align: middle;
  background: #FFFFFF url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23323232' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #eaeaea;
  border-radius: 0.3077rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #d1d1d1;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(209, 209, 209, 0.5);
}

.custom-select:focus::-ms-value {
  color: #777777;
  background-color: #FFFFFF;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #777777;
  background-color: #eceeef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: 2.53846rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: 3.84615rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 3.385rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 3.385rem;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #d1d1d1;
  box-shadow: 0 0 0 0 transparent;
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #d1d1d1;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #eeeeee;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 3.385rem;
  padding: 0.6154rem 0.9231rem;
  line-height: 1.428571;
  color: #777777;
  background-color: #FFFFFF;
  border: 1px solid #eaeaea;
  border-radius: 0;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.659371rem;
  padding: 0.6154rem 0.9231rem;
  line-height: 1.428571;
  color: #777777;
  content: "Browse";
  background-color: #eaeaea;
  border-left: 1px solid #eaeaea;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0 transparent;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0 transparent;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f0f0f0, 0 0 0 0 transparent;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ef6262;
  border: 0;
  border-radius: 1rem;
  transition: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: white;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ef6262;
  border: 0;
  border-radius: 1rem;
  transition: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: white;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: #ef6262;
  border: 0;
  border-radius: 1rem;
  transition: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: white;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 1.385rem 1.538rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #777777;
}

.nav-tabs {
  border-bottom: 1px solid transparent;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent;
}

.nav-tabs .nav-link.disabled {
  color: #777777;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #555555;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #ef6262;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 1.275rem;
  padding-bottom: 1.275rem;
  margin-right: 1rem;
  font-size: 1.154rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 1.385rem;
  padding-bottom: 1.385rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.154rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #FFFFFF;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #FFFFFF;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #FFFFFF;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #FFFFFF;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 20px;
}

.card-title {
  margin-bottom: 1.538rem;
}

.card-subtitle {
  margin-top: -0.769rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 20px;
}

.card-header {
  padding: 1.538rem 20px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0 - 1px) calc(0 - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1.538rem 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0 - 1px) calc(0 - 1px);
}

.card-header-tabs {
  margin-right: -10px;
  margin-bottom: -1.538rem;
  margin-left: -10px;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -10px;
  margin-left: -10px;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0 - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1.538rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.6154rem 1.154rem;
  margin-bottom: 1.385rem;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 0.3077rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.6154rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.6154rem;
  color: #777777;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #787878;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3077rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.6154rem 0.9231rem;
  margin-left: -1px;
  line-height: 1.428571;
  color: #ef6262;
  background-color: #FFFFFF;
  border: 1px solid #dddddd;
}

.page-link:hover {
  z-index: 2;
  color: #e71d1d;
  text-decoration: none;
  background-color: #eeeeee;
  border-color: #dddddd;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0 transparent;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3077rem;
  border-bottom-left-radius: 0.3077rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.3077rem;
  border-bottom-right-radius: 0.3077rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #FFFFFF;
  background-color: #ef6262;
  border-color: #ef6262;
}

.page-item.disabled .page-link {
  color: #777777;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #dddddd;
}

.pagination-lg .page-link {
  padding: 0.9231rem 1.231rem;
  font-size: 1.154rem;
  line-height: 1.633333;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.3846rem 0.6923rem;
  font-size: 0.9231rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.4712rem 0.5385rem;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.5385rem;
  padding-left: 0.5385rem;
  border-radius: 0.7692rem;
}

.badge-primary {
  color: #FFFFFF;
  background-color: #ef6262;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #ea3434;
}

.badge-secondary {
  color: #FFFFFF;
  background-color: #777777;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #5e5e5e;
}

.badge-success {
  color: #212529;
  background-color: #7ACCBE;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #55beac;
}

.badge-info {
  color: #212529;
  background-color: #8DCADF;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #65b7d4;
}

.badge-warning {
  color: #212529;
  background-color: #FFC870;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #ffb43d;
}

.badge-danger {
  color: #FFFFFF;
  background-color: #EF6262;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #ea3434;
}

.badge-light {
  color: #212529;
  background-color: #FFFFFF;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #e6e6e6;
}

.badge-dark {
  color: #FFFFFF;
  background-color: #3d3c3c;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #232323;
}

.badge-grey {
  color: #212529;
  background-color: #cccccc;
}

.badge-grey[href]:hover, .badge-grey[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #b3b3b3;
}

.badge-alt-color1 {
  color: #212529;
  background-color: #95d9f0;
}

.badge-alt-color1[href]:hover, .badge-alt-color1[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #68c9ea;
}

.badge-alt-color2 {
  color: #212529;
  background-color: #ffdc7a;
}

.badge-alt-color2[href]:hover, .badge-alt-color2[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #ffcf47;
}

.badge-alt-color3 {
  color: #FFFFFF;
  background-color: #7a98bf;
}

.badge-alt-color3[href]:hover, .badge-alt-color3[href]:focus {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #587dae;
}

.badge-alt-color4 {
  color: #212529;
  background-color: #cccccc;
}

.badge-alt-color4[href]:hover, .badge-alt-color4[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #b3b3b3;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eceeef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 1.385rem 1.385rem;
  margin-bottom: 1.385rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.27rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.385rem 1.385rem;
  color: inherit;
}

.alert-primary {
  color: #7c3333;
  background-color: #fce0e0;
  border-color: #fad3d3;
}

.alert-primary hr {
  border-top-color: #f7bcbc;
}

.alert-primary .alert-link {
  color: #582424;
}

.alert-secondary {
  color: #3e3e3e;
  background-color: #e4e4e4;
  border-color: #d9d9d9;
}

.alert-secondary hr {
  border-top-color: #cccccc;
}

.alert-secondary .alert-link {
  color: #252525;
}

.alert-success {
  color: #3f6a63;
  background-color: #e4f5f2;
  border-color: #daf1ed;
}

.alert-success hr {
  border-top-color: #c8eae4;
}

.alert-success .alert-link {
  color: #2c4a45;
}

.alert-info {
  color: #496974;
  background-color: #e8f4f9;
  border-color: #dff0f6;
}

.alert-info hr {
  border-top-color: #cbe7f0;
}

.alert-info .alert-link {
  color: #354d55;
}

.alert-warning {
  color: #85683a;
  background-color: #fff4e2;
  border-color: #fff0d7;
}

.alert-warning hr {
  border-top-color: #ffe6be;
}

.alert-warning .alert-link {
  color: #614c2b;
}

.alert-danger {
  color: #7c3333;
  background-color: #fce0e0;
  border-color: #fbd3d3;
}

.alert-danger hr {
  border-top-color: #f9bcbc;
}

.alert-danger .alert-link {
  color: #582424;
}

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-light hr {
  border-top-color: #f2f2f2;
}

.alert-light .alert-link {
  color: #6c6c6c;
}

.alert-dark {
  color: #201f1f;
  background-color: #d8d8d8;
  border-color: #c9c8c8;
}

.alert-dark hr {
  border-top-color: #bcbbbb;
}

.alert-dark .alert-link {
  color: #060606;
}

.alert-grey {
  color: #6a6a6a;
  background-color: whitesmoke;
  border-color: #f1f1f1;
}

.alert-grey hr {
  border-top-color: #e4e4e4;
}

.alert-grey .alert-link {
  color: #515151;
}

.alert-alt-color1 {
  color: #4d717d;
  background-color: #eaf7fc;
  border-color: #e1f4fb;
}

.alert-alt-color1 hr {
  border-top-color: #cbecf8;
}

.alert-alt-color1 .alert-link {
  color: #3a545d;
}

.alert-alt-color2 {
  color: #85723f;
  background-color: #fff8e4;
  border-color: #fff5da;
}

.alert-alt-color2 hr {
  border-top-color: #ffeec1;
}

.alert-alt-color2 .alert-link {
  color: #62542f;
}

.alert-alt-color3 {
  color: #3f4f63;
  background-color: #e4eaf2;
  border-color: #dae2ed;
}

.alert-alt-color3 hr {
  border-top-color: #c9d5e5;
}

.alert-alt-color3 .alert-link {
  color: #2b3644;
}

.alert-alt-color4 {
  color: #6a6a6a;
  background-color: whitesmoke;
  border-color: #f1f1f1;
}

.alert-alt-color4 hr {
  border-top-color: #e4e4e4;
}

.alert-alt-color4 .alert-link {
  color: #515151;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 3.077rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 3.077rem;
  overflow: hidden;
  font-size: 0.9231rem;
  background-color: #f5f5f5;
  border-radius: 0;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #ef6262;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 3.077rem 3.077rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 2s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #555555;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #555555;
  text-decoration: none;
  background-color: #f5f5f5;
}

.list-group-item-action:active {
  color: #555555;
  background-color: #f5f5f5;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1.154rem;
  margin-bottom: -1px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #777777;
  background-color: #eeeeee;
}

.list-group-item.active {
  z-index: 2;
  color: #FFFFFF;
  background-color: #ef6262;
  border-color: #ef6262;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #7c3333;
  background-color: #fad3d3;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #7c3333;
  background-color: #f7bcbc;
}

.list-group-item-primary.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #7c3333;
  border-color: #7c3333;
}

.list-group-item-secondary {
  color: #3e3e3e;
  background-color: #d9d9d9;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #3e3e3e;
  background-color: #cccccc;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #3e3e3e;
  border-color: #3e3e3e;
}

.list-group-item-success {
  color: #3f6a63;
  background-color: #daf1ed;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #3f6a63;
  background-color: #c8eae4;
}

.list-group-item-success.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #3f6a63;
  border-color: #3f6a63;
}

.list-group-item-info {
  color: #496974;
  background-color: #dff0f6;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #496974;
  background-color: #cbe7f0;
}

.list-group-item-info.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #496974;
  border-color: #496974;
}

.list-group-item-warning {
  color: #85683a;
  background-color: #fff0d7;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #85683a;
  background-color: #ffe6be;
}

.list-group-item-warning.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #85683a;
  border-color: #85683a;
}

.list-group-item-danger {
  color: #7c3333;
  background-color: #fbd3d3;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7c3333;
  background-color: #f9bcbc;
}

.list-group-item-danger.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #7c3333;
  border-color: #7c3333;
}

.list-group-item-light {
  color: #858585;
  background-color: white;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-light.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-dark {
  color: #201f1f;
  background-color: #c9c8c8;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #201f1f;
  background-color: #bcbbbb;
}

.list-group-item-dark.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #201f1f;
  border-color: #201f1f;
}

.list-group-item-grey {
  color: #6a6a6a;
  background-color: #f1f1f1;
}

.list-group-item-grey.list-group-item-action:hover, .list-group-item-grey.list-group-item-action:focus {
  color: #6a6a6a;
  background-color: #e4e4e4;
}

.list-group-item-grey.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}

.list-group-item-alt-color1 {
  color: #4d717d;
  background-color: #e1f4fb;
}

.list-group-item-alt-color1.list-group-item-action:hover, .list-group-item-alt-color1.list-group-item-action:focus {
  color: #4d717d;
  background-color: #cbecf8;
}

.list-group-item-alt-color1.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #4d717d;
  border-color: #4d717d;
}

.list-group-item-alt-color2 {
  color: #85723f;
  background-color: #fff5da;
}

.list-group-item-alt-color2.list-group-item-action:hover, .list-group-item-alt-color2.list-group-item-action:focus {
  color: #85723f;
  background-color: #ffeec1;
}

.list-group-item-alt-color2.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #85723f;
  border-color: #85723f;
}

.list-group-item-alt-color3 {
  color: #3f4f63;
  background-color: #dae2ed;
}

.list-group-item-alt-color3.list-group-item-action:hover, .list-group-item-alt-color3.list-group-item-action:focus {
  color: #3f4f63;
  background-color: #c9d5e5;
}

.list-group-item-alt-color3.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #3f4f63;
  border-color: #3f4f63;
}

.list-group-item-alt-color4 {
  color: #6a6a6a;
  background-color: #f1f1f1;
}

.list-group-item-alt-color4.list-group-item-action:hover, .list-group-item-alt-color4.list-group-item-action:focus {
  color: #6a6a6a;
  background-color: #e4e4e4;
}

.list-group-item-alt-color4.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: inherit;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: inherit;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.154rem;
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1.154rem;
  margin: -1.154rem -1.154rem -1.154rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.428571;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.154rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.154rem;
  border-top: 1px solid #e5e5e5;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 900px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.428571;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.9231rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #000000;
  border-radius: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.428571;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 0.7692rem;
  height: 0.7692rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.7692rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.7692rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.7692rem 0.3846rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #FFFFFF;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.7692rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.7692rem + 1px) * -1);
  width: 0.7692rem;
  height: 0.7692rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.3846rem 0.7692rem 0.3846rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #FFFFFF;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.7692rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.7692rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.3846rem 0.7692rem 0.3846rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #FFFFFF;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 0.7692rem;
  margin-left: -0.3846rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.7692rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.7692rem + 1px) * -1);
  width: 0.7692rem;
  height: 0.7692rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.3846rem 0 0.3846rem 0.7692rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}


.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #FFFFFF;
}

.popover-header {
  padding: 0.6154rem 1.077rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.6923rem 1.077rem;
  color: #555555;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFFFFF;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #FFFFFF;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #ef6262 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ea3434 !important;
}

.bg-secondary {
  background-color: #777777 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #5e5e5e !important;
}

.bg-success {
  background-color: #7ACCBE !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #55beac !important;
}

.bg-info {
  background-color: #8DCADF !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #65b7d4 !important;
}

.bg-warning {
  background-color: #FFC870 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffb43d !important;
}

.bg-danger {
  background-color: #EF6262 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea3434 !important;
}

.bg-light {
  background-color: #FFFFFF !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark {
  background-color: #3d3c3c !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #232323 !important;
}

.bg-grey {
  background-color: #cccccc !important;
}

a.bg-grey:hover, a.bg-grey:focus,
button.bg-grey:hover,
button.bg-grey:focus {
  background-color: #b3b3b3 !important;
}

.bg-alt-color1 {
  background-color: #95d9f0 !important;
}

a.bg-alt-color1:hover, a.bg-alt-color1:focus,
button.bg-alt-color1:hover,
button.bg-alt-color1:focus {
  background-color: #68c9ea !important;
}

.bg-alt-color2 {
  background-color: #ffdc7a !important;
}

a.bg-alt-color2:hover, a.bg-alt-color2:focus,
button.bg-alt-color2:hover,
button.bg-alt-color2:focus {
  background-color: #ffcf47 !important;
}

.bg-alt-color3 {
  background-color: #7a98bf !important;
}

a.bg-alt-color3:hover, a.bg-alt-color3:focus,
button.bg-alt-color3:hover,
button.bg-alt-color3:focus {
  background-color: #587dae !important;
}

.bg-alt-color4 {
  background-color: #cccccc !important;
}

a.bg-alt-color4:hover, a.bg-alt-color4:focus,
button.bg-alt-color4:hover,
button.bg-alt-color4:focus {
  background-color: #b3b3b3 !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ef6262 !important;
}

.border-secondary {
  border-color: #777777 !important;
}

.border-success {
  border-color: #7ACCBE !important;
}

.border-info {
  border-color: #8DCADF !important;
}

.border-warning {
  border-color: #FFC870 !important;
}

.border-danger {
  border-color: #EF6262 !important;
}

.border-light {
  border-color: #FFFFFF !important;
}

.border-dark {
  border-color: #3d3c3c !important;
}

.border-grey {
  border-color: #cccccc !important;
}

.border-alt-color1 {
  border-color: #95d9f0 !important;
}

.border-alt-color2 {
  border-color: #ffdc7a !important;
}

.border-alt-color3 {
  border-color: #7a98bf !important;
}

.border-alt-color4 {
  border-color: #cccccc !important;
}

.border-white {
  border-color: #FFFFFF !important;
}

.rounded {
  border-radius: 0.3077rem !important;
}

.rounded-top {
  border-top-left-radius: 0.3077rem !important;
  border-top-right-radius: 0.3077rem !important;
}

.rounded-right {
  border-top-right-radius: 0.3077rem !important;
  border-bottom-right-radius: 0.3077rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.3077rem !important;
  border-bottom-left-radius: 0.3077rem !important;
}

.rounded-left {
  border-top-left-radius: 0.3077rem !important;
  border-bottom-left-radius: 0.3077rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after, .btn-group-vertical > .btn-group::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.384615rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.384615rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.384615rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.384615rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.384615rem !important;
}

.m-2 {
  margin: 0.769231rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.769231rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.769231rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.769231rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.769231rem !important;
}

.m-3 {
  margin: 1.154rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.154rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.154rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.154rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.154rem !important;
}

.m-4 {
  margin: 1.538rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.538rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.538rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.538rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.538rem !important;
}

.m-5 {
  margin: 1.923rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.923rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.923rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.923rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.923rem !important;
}

.m-6 {
  margin: 2.308rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.308rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.308rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.308rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.308rem !important;
}

.m-7 {
  margin: 3.077rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3.077rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3.077rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3.077rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3.077rem !important;
}

.m-8 {
  margin: 3.851rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.851rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.851rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.851rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.851rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.384615rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.384615rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.384615rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.384615rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.384615rem !important;
}

.p-2 {
  padding: 0.769231rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.769231rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.769231rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.769231rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.769231rem !important;
}

.p-3 {
  padding: 1.154rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.154rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.154rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.154rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.154rem !important;
}

.p-4 {
  padding: 1.538rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.538rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.538rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.538rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.538rem !important;
}

.p-5 {
  padding: 1.923rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.923rem !important;
}

.pr-5,
.px-5 {
  padding-right: 1.923rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.923rem !important;
}

.pl-5,
.px-5 {
  padding-left: 1.923rem !important;
}

.p-6 {
  padding: 2.308rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.308rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.308rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.308rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.308rem !important;
}

.p-7 {
  padding: 3.077rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3.077rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3.077rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3.077rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3.077rem !important;
}

.p-8 {
  padding: 3.851rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.851rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.851rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.851rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.851rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.384615rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.384615rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.384615rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.384615rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.384615rem !important;
  }
  .m-sm-2 {
    margin: 0.769231rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.769231rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.769231rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.769231rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.769231rem !important;
  }
  .m-sm-3 {
    margin: 1.154rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.154rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.154rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.154rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.154rem !important;
  }
  .m-sm-4 {
    margin: 1.538rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.538rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.538rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.538rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.538rem !important;
  }
  .m-sm-5 {
    margin: 1.923rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.923rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.923rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.923rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.923rem !important;
  }
  .m-sm-6 {
    margin: 2.308rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.308rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.308rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.308rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.308rem !important;
  }
  .m-sm-7 {
    margin: 3.077rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.077rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.077rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.077rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.077rem !important;
  }
  .m-sm-8 {
    margin: 3.851rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.851rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.851rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.851rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.851rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.384615rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.384615rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.384615rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.384615rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.384615rem !important;
  }
  .p-sm-2 {
    padding: 0.769231rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.769231rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.769231rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.769231rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.769231rem !important;
  }
  .p-sm-3 {
    padding: 1.154rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.154rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.154rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.154rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.154rem !important;
  }
  .p-sm-4 {
    padding: 1.538rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.538rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.538rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.538rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.538rem !important;
  }
  .p-sm-5 {
    padding: 1.923rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.923rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.923rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.923rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.923rem !important;
  }
  .p-sm-6 {
    padding: 2.308rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.308rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.308rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.308rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.308rem !important;
  }
  .p-sm-7 {
    padding: 3.077rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.077rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.077rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.077rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.077rem !important;
  }
  .p-sm-8 {
    padding: 3.851rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.851rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.851rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.851rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.851rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.384615rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.384615rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.384615rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.384615rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.384615rem !important;
  }
  .m-md-2 {
    margin: 0.769231rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.769231rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.769231rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.769231rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.769231rem !important;
  }
  .m-md-3 {
    margin: 1.154rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.154rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.154rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.154rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.154rem !important;
  }
  .m-md-4 {
    margin: 1.538rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.538rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.538rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.538rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.538rem !important;
  }
  .m-md-5 {
    margin: 1.923rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.923rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.923rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.923rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.923rem !important;
  }
  .m-md-6 {
    margin: 2.308rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.308rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.308rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.308rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.308rem !important;
  }
  .m-md-7 {
    margin: 3.077rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.077rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.077rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.077rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.077rem !important;
  }
  .m-md-8 {
    margin: 3.851rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.851rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.851rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.851rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.851rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.384615rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.384615rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.384615rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.384615rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.384615rem !important;
  }
  .p-md-2 {
    padding: 0.769231rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.769231rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.769231rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.769231rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.769231rem !important;
  }
  .p-md-3 {
    padding: 1.154rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.154rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.154rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.154rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.154rem !important;
  }
  .p-md-4 {
    padding: 1.538rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.538rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.538rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.538rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.538rem !important;
  }
  .p-md-5 {
    padding: 1.923rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.923rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.923rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.923rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.923rem !important;
  }
  .p-md-6 {
    padding: 2.308rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.308rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.308rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.308rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.308rem !important;
  }
  .p-md-7 {
    padding: 3.077rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.077rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.077rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.077rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.077rem !important;
  }
  .p-md-8 {
    padding: 3.851rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.851rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.851rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.851rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.851rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.384615rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.384615rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.384615rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.384615rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.384615rem !important;
  }
  .m-lg-2 {
    margin: 0.769231rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.769231rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.769231rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.769231rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.769231rem !important;
  }
  .m-lg-3 {
    margin: 1.154rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.154rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.154rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.154rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.154rem !important;
  }
  .m-lg-4 {
    margin: 1.538rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.538rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.538rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.538rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.538rem !important;
  }
  .m-lg-5 {
    margin: 1.923rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.923rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.923rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.923rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.923rem !important;
  }
  .m-lg-6 {
    margin: 2.308rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.308rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.308rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.308rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.308rem !important;
  }
  .m-lg-7 {
    margin: 3.077rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.077rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.077rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.077rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.077rem !important;
  }
  .m-lg-8 {
    margin: 3.851rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.851rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.851rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.851rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.851rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.384615rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.384615rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.384615rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.384615rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.384615rem !important;
  }
  .p-lg-2 {
    padding: 0.769231rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.769231rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.769231rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.769231rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.769231rem !important;
  }
  .p-lg-3 {
    padding: 1.154rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.154rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.154rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.154rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.154rem !important;
  }
  .p-lg-4 {
    padding: 1.538rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.538rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.538rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.538rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.538rem !important;
  }
  .p-lg-5 {
    padding: 1.923rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.923rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.923rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.923rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.923rem !important;
  }
  .p-lg-6 {
    padding: 2.308rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.308rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.308rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.308rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.308rem !important;
  }
  .p-lg-7 {
    padding: 3.077rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.077rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.077rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.077rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.077rem !important;
  }
  .p-lg-8 {
    padding: 3.851rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.851rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.851rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.851rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.851rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.384615rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.384615rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.384615rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.384615rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.384615rem !important;
  }
  .m-xl-2 {
    margin: 0.769231rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.769231rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.769231rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.769231rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.769231rem !important;
  }
  .m-xl-3 {
    margin: 1.154rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.154rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.154rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.154rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.154rem !important;
  }
  .m-xl-4 {
    margin: 1.538rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.538rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.538rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.538rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.538rem !important;
  }
  .m-xl-5 {
    margin: 1.923rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.923rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.923rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.923rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.923rem !important;
  }
  .m-xl-6 {
    margin: 2.308rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.308rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.308rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.308rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.308rem !important;
  }
  .m-xl-7 {
    margin: 3.077rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.077rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.077rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.077rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.077rem !important;
  }
  .m-xl-8 {
    margin: 3.851rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.851rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.851rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.851rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.851rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.384615rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.384615rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.384615rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.384615rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.384615rem !important;
  }
  .p-xl-2 {
    padding: 0.769231rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.769231rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.769231rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.769231rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.769231rem !important;
  }
  .p-xl-3 {
    padding: 1.154rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.154rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.154rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.154rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.154rem !important;
  }
  .p-xl-4 {
    padding: 1.538rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.538rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.538rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.538rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.538rem !important;
  }
  .p-xl-5 {
    padding: 1.923rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.923rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.923rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.923rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.923rem !important;
  }
  .p-xl-6 {
    padding: 2.308rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.308rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.308rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.308rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.308rem !important;
  }
  .p-xl-7 {
    padding: 3.077rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.077rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.077rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.077rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.077rem !important;
  }
  .p-xl-8 {
    padding: 3.851rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.851rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.851rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.851rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.851rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #FFFFFF !important;
}

.text-primary {
  color: #ef6262 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #ea3434 !important;
}

.text-secondary {
  color: #777777 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #5e5e5e !important;
}

.text-success {
  color: #7ACCBE !important;
}

a.text-success:hover, a.text-success:focus {
  color: #55beac !important;
}

.text-info {
  color: #8DCADF !important;
}

a.text-info:hover, a.text-info:focus {
  color: #65b7d4 !important;
}

.text-warning {
  color: #FFC870 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ffb43d !important;
}

.text-danger {
  color: #EF6262 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ea3434 !important;
}

.text-light {
  color: #FFFFFF !important;
}

a.text-light:hover, a.text-light:focus {
  color: #e6e6e6 !important;
}

.text-dark {
  color: #3d3c3c !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #232323 !important;
}

.text-grey {
  color: #cccccc !important;
}

a.text-grey:hover, a.text-grey:focus {
  color: #b3b3b3 !important;
}

.text-alt-color1 {
  color: #95d9f0 !important;
}

a.text-alt-color1:hover, a.text-alt-color1:focus {
  color: #68c9ea !important;
}

.text-alt-color2 {
  color: #ffdc7a !important;
}

a.text-alt-color2:hover, a.text-alt-color2:focus {
  color: #ffcf47 !important;
}

.text-alt-color3 {
  color: #7a98bf !important;
}

a.text-alt-color3:hover, a.text-alt-color3:focus {
  color: #587dae !important;
}

.text-alt-color4 {
  color: #cccccc !important;
}

a.text-alt-color4:hover, a.text-alt-color4:focus {
  color: #b3b3b3 !important;
}

.text-body {
  color: #555555 !important;
}

.text-muted {
  color: #777777 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/* General styles for the modal */
/* 
Styles for the html/body for special modal where we want 3d effects
Note that we need a container wrapping all content on the page for the 
perspective effects (not including the modals and the overlay).
*/
.modal-open {
  overflow: hidden;
}

.modal-perspective,
.modal-perspective body {
  height: 100%;
  overflow: hidden;
}

.modal-perspective body {
  background: #222;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.modal-show {
  visibility: visible;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1051;
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show ~ .modal-overlay {
  opacity: 1;
  visibility: visible;
}

/* Content styles */
.modal-content {
  position: relative;
  border-radius: 3px;
  margin: 30px auto;
  max-width: 630px;
  min-width: 320px;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

/* Effect 1: Fade in and scale up */
.modal-effect-1 .modal-content {
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-1 .modal-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 2: Slide from the right */
.modal-effect-2 .modal-content {
  -webkit-transform: translateX(20%);
  -moz-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -moz-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal-show.modal-effect-2 .modal-content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

/* Effect 3: Slide from the bottom */
.modal-effect-3 .modal-content {
  -webkit-transform: translateY(20%);
  -moz-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-3 .modal-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* Effect 4: Newspaper */
.modal-effect-4 .modal-content {
  -webkit-transform: scale(0) rotate(720deg);
  -moz-transform: scale(0) rotate(720deg);
  -ms-transform: scale(0) rotate(720deg);
  transform: scale(0) rotate(720deg);
  opacity: 0;
}

.modal-show.modal-effect-4 ~ .modal-overlay,
.modal-effect-4 .modal-content {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.modal-show.modal-effect-4 .modal-content {
  -webkit-transform: scale(1) rotate(0deg);
  -moz-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  opacity: 1;
}

/* Effect 5: fall */
.modal-effect-5.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-5 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(600px) rotateX(20deg);
  -moz-transform: translateZ(600px) rotateX(20deg);
  -ms-transform: translateZ(600px) rotateX(20deg);
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0;
}

.modal-show.modal-effect-5 .modal-content {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translateZ(0px) rotateX(0deg);
  -moz-transform: translateZ(0px) rotateX(0deg);
  -ms-transform: translateZ(0px) rotateX(0deg);
  transform: translateZ(0px) rotateX(0deg);
  opacity: 1;
}

/* Effect 6: side fall */
.modal-effect-6.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-6 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translate(30%) translateZ(600px) rotate(10deg);
  -moz-transform: translate(30%) translateZ(600px) rotate(10deg);
  -ms-transform: translate(30%) translateZ(600px) rotate(10deg);
  transform: translate(30%) translateZ(600px) rotate(10deg);
  opacity: 0;
}

.modal-show.modal-effect-6 .modal-content {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translate(0%) translateZ(0) rotate(0deg);
  -moz-transform: translate(0%) translateZ(0) rotate(0deg);
  -ms-transform: translate(0%) translateZ(0) rotate(0deg);
  transform: translate(0%) translateZ(0) rotate(0deg);
  opacity: 1;
}

/* Effect 7:  slide and stick to top */
.modal-effect-7 {
  top: 0;
}

.modal-effect-7 .modal-content {
  margin-top: 0;
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  opacity: 0;
}

.modal-show.modal-effect-7 .modal-content {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  border-radius: 0 0 3px 3px;
  opacity: 1;
}

/* Effect 8: 3D flip horizontal */
.modal-effect-8.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-8 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-70deg);
  -moz-transform: rotateY(-70deg);
  -ms-transform: rotateY(-70deg);
  transform: rotateY(-70deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.modal-show.modal-effect-8 .modal-content {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

/* Effect 9: 3D flip vertical */
.modal-effect-9.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-9 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-70deg);
  -moz-transform: rotateX(-70deg);
  -ms-transform: rotateX(-70deg);
  transform: rotateX(-70deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.modal-show.modal-effect-9 .modal-content {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

/* Effect 10: 3D sign */
.modal-effect-10.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-10 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-60deg);
  -moz-transform: rotateX(-60deg);
  -ms-transform: rotateX(-60deg);
  transform: rotateX(-60deg);
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-10 .modal-content {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

/* Effect 11: Super scaled */
.modal-effect-11 .modal-content {
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-11 .modal-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 12:  Just me */
.modal-effect-12 .modal-content {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-12 ~ .modal-overlay {
  background: #000;
}

.modal-show.modal-effect-12 .modal-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Effect 13: 3D slit */
.modal-effect-13.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-13 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-3000px) rotateY(90deg);
  -moz-transform: translateZ(-3000px) rotateY(90deg);
  -ms-transform: translateZ(-3000px) rotateY(90deg);
  transform: translateZ(-3000px) rotateY(90deg);
  opacity: 0;
}

.modal-show.modal-effect-13 .modal-content {
  -webkit-animation: slit .7s forwards ease-out;
  -moz-animation: slit .7s forwards ease-out;
  animation: slit .7s forwards ease-out;
}

@-webkit-keyframes slit {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(89deg);
    opacity: .5;
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0deg);
    opacity: 1;
  }
}

@-moz-keyframes slit {
  50% {
    -moz-transform: translateZ(-250px) rotateY(89deg);
    opacity: .5;
    -moz-animation-timing-function: ease-out;
  }
  100% {
    -moz-transform: translateZ(0) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes slit {
  50% {
    transform: translateZ(-250px) rotateY(89deg);
    opacity: 1;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateZ(0) rotateY(0deg);
    opacity: 1;
  }
}

/* Effect 14:  3D Rotate from bottom */
.modal-effect-14.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-14 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateY(100%) rotateX(90deg);
  -moz-transform: translateY(100%) rotateX(90deg);
  -ms-transform: translateY(100%) rotateX(90deg);
  transform: translateY(100%) rotateX(90deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.modal-show.modal-effect-14 .modal-content {
  -webkit-transform: translateY(0%) rotateX(0deg);
  -moz-transform: translateY(0%) rotateX(0deg);
  -ms-transform: translateY(0%) rotateX(0deg);
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
}

/* Effect 15:  3D Rotate in from left */
.modal-effect-15.modal-container {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal-effect-15 .modal-content {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -moz-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -ms-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  transform-origin: 0 100%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-15 .modal-content {
  -webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  -moz-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  -ms-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1;
}

/* Effect 16:  Blur */
.modal-show.modal-effect-16 ~ .modal-overlay {
  background: rgba(180, 46, 32, 0.5);
}

.modal-show.modal-effect-16 ~ .container {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  filter: blur(3px);
}

.modal-effect-16 .modal-content {
  -webkit-transform: translateY(-5%);
  -moz-transform: translateY(-5%);
  -ms-transform: translateY(-5%);
  transform: translateY(-5%);
  opacity: 0;
}

.modal-show.modal-effect-16 ~ .container,
.modal-effect-16 .modal-content {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal-show.modal-effect-16 .modal-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* Effect 17:  Slide in from bottom with perspective on container */
.modal-show.modal-effect-17 ~ .container {
  height: 100%;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.modal-show.modal-effect-17 ~ .container,
.modal-show.modal-effect-17 ~ .modal-overlay {
  -webkit-transform: rotateX(-2deg);
  -moz-transform: rotateX(-2deg);
  -ms-transform: rotateX(-2deg);
  transform: rotateX(-2deg);
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.modal-effect-17 .modal-content {
  opacity: 0;
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  -ms-transform: translateY(200%);
  transform: translateY(200%);
}

.modal-show.modal-effect-17 .modal-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 0.3s 0.2s;
  -moz-transition: all 0.3s 0.2s;
  transition: all 0.3s 0.2s;
}

/* Effect 18:  Slide from right with perspective on container */
.modal-show.modal-effect-18 ~ .container {
  height: 100%;
  overflow: hidden;
}

.modal-show.modal-effect-18 ~ .modal-overlay {
  background: rgba(143, 27, 15, 0.8);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.modal-show.modal-effect-18 ~ .container,
.modal-show.modal-effect-18 ~ .modal-overlay {
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 0% 50%;
  -webkit-animation: rotateRightSideFirst 0.5s forwards ease-in;
  -moz-transform-style: preserve-3d;
  -moz-transform-origin: 0% 50%;
  -moz-animation: rotateRightSideFirst 0.5s forwards ease-in;
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  animation: rotateRightSideFirst 0.5s forwards ease-in;
}

@-webkit-keyframes rotateRightSideFirst {
  50% {
    -webkit-transform: translateZ(-50px) rotateY(5deg);
    -webkit-animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateZ(-200px);
  }
}

@-moz-keyframes rotateRightSideFirst {
  50% {
    -moz-transform: translateZ(-50px) rotateY(5deg);
    -moz-animation-timing-function: ease-out;
  }
  100% {
    -moz-transform: translateZ(-200px);
  }
}

@keyframes rotateRightSideFirst {
  50% {
    transform: translateZ(-50px) rotateY(5deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateZ(-200px);
  }
}

.modal-effect-18 .modal-content {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  transform: translateX(200%);
  opacity: 0;
}

.modal-show.modal-effect-18 .modal-content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  -webkit-transition: all 0.5s 0.1s;
  -moz-transition: all 0.5s 0.1s;
  transition: all 0.5s 0.1s;
}

/* Open Sans Webfont Kit
 * -------------------------- */
/* Light 300
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/light/opensans-light-webfont.eot");
  src: url("../lib/open-sans/light/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/light/opensans-light-webfont.woff") format("woff"), url("../lib/open-sans/light/opensans-light-webfont.ttf") format("truetype"), url("../lib/open-sans/light/opensans-light-webfont.svg#OpenSansLight") format("svg");
  font-weight: 300;
  font-style: normal;
}

/* Light 300 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/light-italic/opensans-light-italic-webfont.eot");
  src: url("../lib/open-sans/light-italic/opensans-light-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/light-italic/opensans-light-italic-webfont.woff") format("woff"), url("../lib/open-sans/light-italic/opensans-light-italic-webfont.ttf") format("truetype"), url("../lib/open-sans/light-italic/opensans-light-italic-webfont.svg#OpenSansLightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}

/* Regular 400
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/regular/opensans-regular-webfont.eot");
  src: url("../lib/open-sans/regular/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/regular/opensans-regular-webfont.woff") format("woff"), url("../lib/open-sans/regular/opensans-regular-webfont.ttf") format("truetype"), url("../lib/open-sans/regular/opensans-regular-webfont.svg#OpenSansRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Regular 400 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/italic/opensans-italic-webfont.eot");
  src: url("../lib/open-sans/italic/opensans-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/italic/opensans-italic-webfont.woff") format("woff"), url("../lib/open-sans/italic/opensans-italic-webfont.ttf") format("truetype"), url("../lib/open-sans/italic/opensans-italic-webfont.svg#OpenSansItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}

/* Semibold 600
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/semibold/opensans-semibold-webfont.eot");
  src: url("../lib/open-sans/semibold/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/semibold/opensans-semibold-webfont.woff") format("woff"), url("../lib/open-sans/semibold/opensans-semibold-webfont.ttf") format("truetype"), url("../lib/open-sans/semibold/opensans-semibold-webfont.svg#OpenSansSemibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

/* Semi-Bold 600 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/semibold-italic/opensans-semibold-italic-webfont.eot");
  src: url("../lib/open-sans/semibold-italic/opensans-semibold-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/semibold-italic/opensans-semibold-italic-webfont.woff") format("woff"), url("../lib/open-sans/semibold-italic/opensans-semibold-italic-webfont.ttf") format("truetype"), url("../lib/open-sans/semibold-italic/opensans-semibold-italic-webfont.svg#OpenSansSemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
}

/* Bold 700
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/bold/opensans-bold-webfont.eot");
  src: url("../lib/open-sans/bold/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/bold/opensans-bold-webfont.woff") format("woff"), url("../lib/open-sans/bold/opensans-bold-webfont.ttf") format("truetype"), url("../lib/open-sans/bold/opensans-bold-webfont.svg#OpenSansBold") format("svg");
  font-weight: bold;
  font-style: normal;
}

/* Bold 700 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/bold-italic/opensans-bold-italic-webfont.eot");
  src: url("../lib/open-sans/bold-italic/opensans-bold-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/bold-italic/opensans-bold-italic-webfont.woff") format("woff"), url("../lib/open-sans/bold-italic/opensans-bold-italic-webfont.ttf") format("truetype"), url("../lib/open-sans/bold-italic/opensans-bold-italic-webfont.svg#OpenSansItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}

/* Extrabold 800
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/extrabold/opensans-extrabold-webfont.eot");
  src: url("../lib/open-sans/extrabold/opensans-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/extrabold/opensans-extrabold-webfont.woff") format("woff"), url("../lib/open-sans/extrabold/opensans-extrabold-webfont.ttf") format("truetype"), url("../lib/open-sans/extrabold/opensans-extrabold-webfont.svg#OpenSansExtrabold") format("svg");
  font-weight: 800;
  font-style: normal;
}

/* Extra-Bold 800 Italic
 * -------------------------- */
@font-face {
  font-family: 'Open Sans';
  src: url("../lib/open-sans/extrabold-italic/opensans-extrabold-italic-webfont.eot");
  src: url("../lib/open-sans/extrabold-italic/opensans-extrabold-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../lib/open-sans/extrabold-italic/opensans-extrabold-italic-webfont.woff") format("woff"), url("../lib/open-sans/extrabold-italic/opensans-extrabold-italic-webfont.ttf") format("truetype"), url("../lib/open-sans/extrabold-italic/opensans-extrabold-italic-webfont.svg#OpenSansExtraboldItalic") format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/thin/raleway-thin.eot");
  src: url("../lib/raleway/thin/raleway-thin.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/thin/raleway-thin.woff") format("woff"), url("../lib/raleway/thin/raleway-thin.ttf") format("truetype"), url("../lib/raleway/thin/raleway-thin.svg#ralewaythin") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/extralight/raleway-extralight.eot");
  src: url("../lib/raleway/extralight/raleway-extralight.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/extralight/raleway-extralight.woff") format("woff"), url("../lib/raleway/extralight/raleway-extralight.ttf") format("truetype"), url("../lib/raleway/extralight/raleway-extralight.svg#ralewayextralight") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/light/raleway-light.eot");
  src: url("../lib/raleway/light/raleway-light.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/light/raleway-light.woff") format("woff"), url("../lib/raleway/light/raleway-light.ttf") format("truetype"), url("../lib/raleway/light/raleway-light.svg#ralewaylight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/regular/raleway-regular.eot");
  src: url("../lib/raleway/regular/raleway-regular.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/regular/raleway-regular.woff") format("woff"), url("../lib/raleway/regular/raleway-regular.ttf") format("truetype"), url("../lib/raleway/regular/raleway-regular.svg#ralewayregular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/medium/raleway-medium.eot");
  src: url("../lib/raleway/medium/raleway-medium.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/medium/raleway-medium.woff") format("woff"), url("../lib/raleway/medium/raleway-medium.ttf") format("truetype"), url("../lib/raleway/medium/raleway-medium.svg#ralewaymedium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/semibold/raleway-semibold.eot");
  src: url("../lib/raleway/semibold/raleway-semibold.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/semibold/raleway-semibold.woff") format("woff"), url("../lib/raleway/semibold/raleway-semibold.ttf") format("truetype"), url("../lib/raleway/semibold/raleway-semibold.svg#ralewaysemibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/bold/raleway-bold.eot");
  src: url("../lib/raleway/bold/raleway-bold.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/bold/raleway-bold.woff") format("woff"), url("../lib/raleway/bold/raleway-bold.ttf") format("truetype"), url("../lib/raleway/bold/raleway-bold.svg#ralewaybold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/extrabold/raleway-extrabold.eot");
  src: url("../lib/raleway/extrabold/raleway-extrabold.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/extrabold/raleway-extrabold.woff") format("woff"), url("../lib/raleway/extrabold/raleway-extrabold.ttf") format("truetype"), url("../lib/raleway/extrabold/raleway-extrabold.svg#ralewayextrabold") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../lib/raleway/heavy/raleway-heavy.eot");
  src: url("../lib/raleway/heavy/raleway-heavy.eot?#iefix") format("embedded-opentype"), url("../lib/raleway/heavy/raleway-heavy.woff") format("woff"), url("../lib/raleway/heavy/raleway-heavy.ttf") format("truetype"), url("../lib/raleway/heavy/raleway-heavy.svg#ralewayheavy") format("svg");
  font-weight: 900;
  font-style: normal;
}

/*------------------------------------------------------------------
[General elements style]
*/
html {
  height: 100%;
  overflow-x: hidden;
  font-size: 13px;
}

body {
  padding: 0;
  background-color: #f0f0f0;
  height: 100%;
}

.rtl body {
  direction: rtl;
  text-align: unset;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
}

p {
  font-size: inherit;
  line-height: 1.615rem;
}

button:focus {
  outline: none !important;
}

/*------------------------------------------------------------------
	[Left sidebar]
*/
.am-left-sidebar {
  background-color: #3d3c3c;
  width: 100px;
  height: 100%;
  left: 0;
  position: absolute;
}

.am-left-sidebar .am-scroller-fixed-left-sidebar {
  position: relative;
  width: 100%;
  height: 100%;
}

.rtl .am-left-sidebar {
  left: auto;
  right: 0;
}

.am-left-sidebar .sidebar-elements {
  margin: 0;
  padding: 0;
}

.am-left-sidebar .sidebar-elements > li {
  border-bottom: 1px solid #474646;
  list-style: none;
}

.am-left-sidebar .sidebar-elements > li > a {
  display: block;
  padding: 15px;
  color: #e1e1e1;
  text-align: center;
  font-size: 12px;
}

.am-left-sidebar .sidebar-elements > li > a .icon {
  display: block;
  font-size: 33px;
}

.am-left-sidebar .sidebar-elements > li > a span {
  display: block;
  margin-top: 5px;
}

@media (min-width: 768px) {
  .am-left-sidebar .sidebar-elements > li:hover, .am-left-sidebar .sidebar-elements > li.open {
    background-color: #424141;
  }
}

.am-left-sidebar .sidebar-elements > li.active > a {
  border-left: 2px solid #ef6262;
  padding-left: 13px;
}

.rtl .am-left-sidebar .sidebar-elements > li.active > a {
  padding-left: 15px;
  padding-right: 13px;
  border-left-width: 0;
  border-right: 2px solid #ef6262;
}

.am-left-sidebar .sidebar-elements > li > ul {
  display: none;
  position: fixed;
  left: 100px;
  top: 0;
  height: 100%;
  background-color: #484747;
  z-index: 1031;
  padding: 0;
  table-layout: fixed;
  list-style: none;
  width: 280px;
}

.rtl .am-left-sidebar .sidebar-elements > li > ul {
  left: auto;
  right: 100px;
}

@media (max-width: 767px) {
  .rtl .am-left-sidebar .sidebar-elements > li > ul {
    right: 0;
  }
}

@media (min-width: 768px) {
  .am-left-sidebar .sidebar-elements > li > ul.visible {
    display: table;
  }
  .am-left-sidebar .sidebar-elements > li > ul.hide {
    display: none;
  }
}

.am-left-sidebar .sidebar-elements > li > ul > li.title {
  color: #FFFFFF;
  border-bottom: 1px solid #6d6b6b;
  font-size: 26px;
  font-weight: 300;
  margin: 0 25px 0;
  padding-bottom: 18px;
  padding-top: 25px;
}

.am-left-sidebar .sidebar-elements > li > ul > .nav-items {
  display: table-row;
  height: 100%;
}

.am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller {
  position: relative;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller {
    display: block;
  }
}

.am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller .content {
  height: 100%;
  width: 100%;
  position: absolute;
  padding-top: 15px;
  margin-right: 0 !important;
}

@media screen and (min-width: 0�) {
  .am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller .content {
    position: static;
    overflow: hidden;
  }
}

.am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller .content > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller .content > ul > li > a {
  padding: 11px 25px;
  color: #b3b3b3;
  font-size: 13px;
  display: block;
  font-weight: 300;
}

.am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller .content > ul > li > a .badge {
  float: right;
  border-radius: 2px;
  font-weight: 400;
  line-height: 10px;
  padding: 5px 10px;
}

.rtl .am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller .content > ul > li > a .badge {
  float: left;
}

.am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller .content > ul > li > a:hover, .am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller .content > ul > li > a:focus {
  background-color: #424141;
  color: #FFFFFF;
}

.am-left-sidebar .sidebar-elements > li > ul > .nav-items .am-scroller .content > ul li.active > a {
  color: #f17979;
}

.am-left-sidebar .am-logo {
  display: none;
}

.am-left-sidebar:before {
  content: "";
  position: fixed;
  z-index: -1;
  width: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: inherit;
}

.rtl .am-left-sidebar:before {
  left: auto;
  right: 0;
}

.am-animate .am-left-sidebar:before {
  transition: all 0.35s ease;
}

@media (max-width: 767px) {
  .am-left-sidebar:before {
    display: none;
  }
}

@media (max-width: 767px) {
  .am-left-sidebar {
    width: 85%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    transform: translate3d(-100%, 0, 0);
    z-index: 1031;
  }
  .rtl .am-left-sidebar {
    transform: translate3d(100%, 0, 0);
  }
  .am-left-sidebar .sidebar-elements > li > a {
    text-align: left;
    position: relative;
  }
  .rtl .am-left-sidebar .sidebar-elements > li > a {
    text-align: right;
  }
  .am-left-sidebar .sidebar-elements > li > a .icon {
    display: inline-block;
    font-size: 25px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .rtl .am-left-sidebar .sidebar-elements > li > a .icon {
    margin-right: 0;
    margin-left: 10px;
  }
  .am-left-sidebar .sidebar-elements > li > a span {
    display: inline-block;
  }
  .am-left-sidebar .sidebar-elements > li.parent > a:after {
    content: '\e688';
    float: right;
    font-family: "Stroke 7";
    font-size: 26px;
    line-height: 28px;
    color: #a1a1a1;
  }
  .rtl .am-left-sidebar .sidebar-elements > li.parent > a:after {
    float: left;
  }
  .am-left-sidebar .sidebar-elements > li > ul {
    position: relative;
    height: auto;
    width: 100%;
    left: 0;
  }
  .am-left-sidebar .sidebar-elements > li > ul > li.title {
    display: none;
  }
  .am-left-sidebar .sidebar-elements > li > ul > li.nav-items {
    display: block;
    height: auto;
  }
  .am-left-sidebar .sidebar-elements > li > ul > li.nav-items > .am-scroller {
    height: auto;
    overflow: visible;
  }
  .am-left-sidebar .sidebar-elements > li > ul > li.nav-items > .am-scroller > .content {
    padding-top: 0;
    overflow: visible;
    position: relative;
  }
  .am-left-sidebar .sidebar-elements > li:hover {
    background-color: inherit;
  }
  .am-left-sidebar .sidebar-elements > li:hover > ul {
    display: none;
  }
  .am-left-sidebar .sidebar-elements li.open > ul {
    display: block;
  }
  .am-left-sidebar .am-logo {
    background: url("../img/logo-full.png") no-repeat 15px center;
    height: 70px;
    background-color: #393838;
    border-bottom: 1px solid #353434;
    display: block;
  }
}

@media -webkit-min-device-pixel-ratio and (max-width: 767px) {
  .am-left-sidebar .am-logo {
    background-image: url("../img/logo-full-retina.png");
    background-size: 150px 39px;
  }
}

.am-animate .am-left-sidebar {
  transition: all 0.35s ease;
}

.am-fixed-sidebar .am-left-sidebar {
  margin-top: 80px;
  padding-bottom: 80px;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1031;
}

@media (max-width: 767px) {
  .am-fixed-sidebar .am-left-sidebar {
    margin-top: 0;
    padding-bottom: 0;
  }
}

.am-fixed-sidebar .am-left-sidebar:before {
  top: 80px;
}

.am-fixed-sidebar .am-left-sidebar .nano-pane {
  width: 4px;
}

.am-fixed-sidebar .am-left-sidebar .nano-pane > .nano-slider {
  background-color: rgba(0, 0, 0, 0.15);
}

@media (max-width: 767px) {
  body.open-left-sidebar .am-wrapper {
    overflow: hidden;
  }
  body.open-left-sidebar .am-content, body.open-left-sidebar .am-top-header {
    transform: translate3d(85%, 0, 0);
  }
  .rtl body.open-left-sidebar .am-content, .rtl body.open-left-sidebar .am-top-header {
    transform: translate3d(-85%, 0, 0);
  }
  body.open-left-sidebar .am-left-sidebar {
    transform: translate3d(0%, 0, 0);
  }
}

/*------------------------------------------------------------------
  [Main content]
*/
.am-content {
  position: relative;
  left: 0;
  margin-left: 100px;
  min-height: 100%;
}

.rtl .am-content {
  left: auto;
  right: 0;
  margin-right: 100px;
  margin-left: 0;
}

@media (max-width: 767px) {
  .rtl .am-content {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .am-content {
    margin: 0;
  }
}

.am-animate .am-content {
  transition: all 0.35s ease;
}

.am-nosidebar-left .am-content {
  margin-left: 0;
}

.main-content {
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 35px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-content {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .main-content {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
  }
}

.am-no-padding .main-content {
  padding: 0;
}

.am-wrapper {
  min-height: 100%;
  padding-top: 80px;
}

@media (max-width: 767px) {
  .am-wrapper {
    padding-top: 0;
    overflow-x: hidden;
  }
}

/*------------------------------------------------------------------
  [Page aside]
*/
.am-aside .main-content {
  margin-left: 280px;
}

.rtl .am-aside .main-content {
  margin-right: 280px;
  margin-left: 0;
}

@media (max-width: 767px) {
  .rtl .am-aside .main-content {
    margin-right: inherit;
  }
}

@media (max-width: 767px) {
  .am-aside .main-content {
    margin-left: inherit;
  }
}

.am-aside .content {
  height: 100%;
}

.page-aside {
  background: none repeat scroll 0 0 #FFFFFF;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 100px;
  border-right: 1px solid #e6e6e6;
  padding-top: 80px;
}

.rtl .page-aside {
  left: auto;
  right: 100px;
  border-left: 1px solid #e6e6e6;
  border-right: 0;
}

@media (max-width: 767px) {
  .rtl .page-aside {
    right: 0;
    border-left-width: 0;
  }
}

.am-animate .page-aside {
  transition: all 0.35s ease;
}

.page-aside .am-scroller-aside {
  position: relative;
  height: 100%;
  width: 100%;
}

.page-aside .aside-content .nav {
  display: block;
}

.page-aside .aside-content .nav li a {
  display: block;
  position: relative;
}

.page-aside .aside-content .nav li a:hover {
  text-decoration: none;
  background-color: #f0f0f0;
}

.page-aside .content {
  padding: 20px;
  position: relative;
}

@media (max-width: 767px) {
  .page-aside {
    width: 100%;
    display: block;
    height: auto;
    position: relative;
    padding-top: 0;
    left: 0;
    border-right-width: 0;
  }
  .page-aside .badge {
    position: static;
    overflow: visible;
    margin: 0 !important;
  }
}

.am-sticky-footer .page-aside {
  padding-bottom: 50px;
}

.aside-header {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .rtl .aside-header {
    padding-right: 54px;
  }
}

.aside-header .navbar-toggle {
  background: rgba(0, 0, 0, 0.015);
  padding: 5px 6px;
  display: none;
  outline: none;
  border: 1px solid transparent;
  margin-right: 15px;
  text-align: right;
  margin-top: 8px;
  margin-bottom: 8px;
  right: 20px;
  position: absolute;
  color: inherit;
  cursor: pointer;
}

.aside-header .navbar-toggle .icon {
  font-size: 1.923rem;
}

@media (max-width: 767px) {
  .aside-header .navbar-toggle {
    display: block;
  }
}

.aside-header .description {
  color: #6f6f6f;
}

.aside-nav {
  visibility: visible;
}

.aside-nav.collapse {
  display: block;
}

.rtl .aside-nav .nav {
  padding-right: 0;
}

.aside-nav .nav li a {
  color: #555555;
  padding: 12px 15px;
}

.aside-nav .nav li.active a {
  background-color: #f2f2f2;
}

.aside-nav .nav li .badge {
  float: right;
  font-size: .9231rem;
  font-weight: 300;
  padding: .3077rem .5385rem;
}

.rtl .aside-nav .nav li .badge {
  float: left;
}

.aside-nav .nav li .icon {
  font-size: 1.462rem;
  vertical-align: middle;
  margin-right: 3px;
  display: inline-block;
}

.aside-nav .title {
  color: #888888;
  margin: 5px 0 0 0;
  padding: 8px 13px 4px;
  border-bottom: 1px solid #e6e6e6;
}

@media (max-width: 767px) {
  .aside-nav {
    border-bottom: 1px solid #e6e6e6;
  }
  .aside-nav.collapse {
    display: none;
  }
  .aside-nav.show {
    display: block;
  }
}

/*------------------------------------------------------------------
  [Page head]
*/
/*Page Title*/
.page-head {
  background: none repeat scroll 0 0 #FFFFFF;
  padding: 20px 35px;
  position: relative;
}

.page-head h2 {
  margin-bottom: 5px;
  margin-top: 6px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  letter-spacing: -1px;
  font-size: 2.154rem;
  line-height: 36px;
}

.page-head .breadcrumb {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  margin-bottom: 0;
  padding: 4px;
}

.page-head .breadcrumb .breadcrumb-item {
  font-size: 0.9231rem;
}

.page-head .breadcrumb .breadcrumb-item:before {
  top: -.07692rem;
}

.page-head .breadcrumb-item {
  padding-left: 0;
}

.am-white-header .page-head {
  background-color: #f8f8f8;
}

.am-aside .page-head {
  margin-left: 280px;
}

.rtl .am-aside .page-head {
  margin-left: 0;
  margin-right: 280px;
}

@media (max-width: 767px) {
  .rtl .am-aside .page-head {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .am-aside .page-head {
    margin-left: 0;
    border-bottom: 1px solid #e6e6e6;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .page-head {
    padding: 20px 25px;
  }
}

@media (max-width: 767px) {
  .page-head {
    padding: 18px 15px 20px;
  }
  .page-head h2 {
    font-size: 1.846rem;
    line-height: 28px;
  }
}

/*------------------------------------------------------------------
  [Right sidebar]
*/
.am-right-sidebar {
  position: fixed;
  width: 255px;
  height: 100%;
  right: -255px;
  top: 0;
  bottom: 0;
  z-index: 1031;
}

.rtl .am-right-sidebar {
  right: auto;
  left: -255px;
}

.am-animate .am-right-sidebar {
  transition: all 0.35s ease;
}

@media (min-width: 768px) {
  body.open-right-sidebar .am-content {
    left: -255px;
  }
  .rtl body.open-right-sidebar .am-content {
    left: auto;
    right: -255px;
  }
  body.open-right-sidebar .page-aside {
    left: -155px;
  }
  body.open-right-sidebar .am-top-header {
    right: 255px;
    left: -255px;
  }
  .rtl body.open-right-sidebar .am-top-header {
    right: -255px;
    left: 255px;
  }
  body.open-right-sidebar .am-left-sidebar {
    left: -255px;
  }
  body.open-right-sidebar .am-left-sidebar:before {
    left: -255px;
  }
  .rtl body.open-right-sidebar .am-left-sidebar {
    left: auto;
    right: -255px;
  }
  .rtl body.open-right-sidebar .am-left-sidebar:before {
    left: auto;
    right: -255px;
  }
  body.open-right-sidebar .am-right-sidebar {
    right: 0;
  }
  .rtl body.open-right-sidebar .am-right-sidebar {
    right: auto;
    left: 0;
  }
}

@media (max-width: 767px) {
  body.open-right-sidebar .am-content, body.open-right-sidebar .am-top-header, body.open-right-sidebar .page-aside {
    transform: translate3d(-255px, 0, 0);
  }
}

@media (max-width: 767px) and (max-width: 767px) {
  body.open-right-sidebar .am-content, body.open-right-sidebar .am-top-header, body.open-right-sidebar .page-aside {
    transform: translate3d(-85%, 0, 0);
  }
  .rtl body.open-right-sidebar .am-content, .rtl body.open-right-sidebar .am-top-header, .rtl body.open-right-sidebar .page-aside {
    transform: translate3d(85%, 0, 0);
  }
}

@media (max-width: 767px) and (max-width: 767px) {
  body.open-right-sidebar .am-wrapper {
    overflow: hidden;
  }
  body.open-right-sidebar .am-right-sidebar {
    transform: translate3d(0, 0, 0);
  }
}

.am-right-sidebar {
  background-color: #484747;
}

.am-right-sidebar .sb-content {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}

.am-right-sidebar .user-info {
  text-align: center;
  padding: 30px 20px;
  border-bottom: 1px solid #525050;
  background-color: #424141;
}

.am-right-sidebar .user-info img {
  border-radius: 50%;
  width: 76px;
  height: 76px;
  margin-bottom: 20px;
}

.am-right-sidebar .user-info span {
  display: block;
  font-family: "Raleway", sans-serif;
}

.am-right-sidebar .user-info span.name {
  color: #e1e1e1;
  font-size: 14px;
}

.am-right-sidebar .user-info span.name .status {
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: #00b777;
  display: inline-block;
  margin-left: 8px;
  position: relative;
  top: -1px;
}

.rtl .am-right-sidebar .user-info span.name .status {
  margin-left: 0;
  margin-right: 8px;
}

.am-right-sidebar .user-info span.position {
  color: #aaaaaa;
  font-size: 13px;
}

@media (max-width: 767px) {
  .am-right-sidebar .user-info {
    background-color: #3b3a3a;
    border-bottom: 0 solid #353434;
    text-align: left;
    padding: 17px 22px 21px;
  }
  .rtl .am-right-sidebar .user-info {
    text-align: right;
  }
  .am-right-sidebar .user-info img {
    height: 36px;
    width: 36px;
    margin-bottom: 0;
    float: left;
  }
  .rtl .am-right-sidebar .user-info img {
    float: right;
  }
  .am-right-sidebar .user-info > span {
    padding-left: 46px;
  }
  .rtl .am-right-sidebar .user-info > span {
    padding-left: 0;
    padding-right: 46px;
  }
  .am-right-sidebar .user-info > span.position {
    font-size: 12px;
    line-height: 12px;
  }
}

.am-right-sidebar h2 {
  color: #e1e1e1;
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 15px;
  border-bottom: 1px solid #6d6b6b;
  padding-left: 5px;
  margin-bottom: 25px;
  margin-top: 18px;
}

.am-right-sidebar .search {
  position: relative;
  width: 100%;
}

.am-right-sidebar .search > input {
  border: 0;
  background-color: #424141;
  color: #b3b3b3;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  padding: 15px 40px 15px 20px;
  outline: none;
}

.rtl .am-right-sidebar .search > input {
  padding-right: 20px;
  padding-left: 40px;
}

.am-right-sidebar .search > span {
  position: absolute;
  color: #aaaaaa;
  right: 15px;
  top: 50%;
  font-size: 20px;
  margin-top: -10px;
  cursor: pointer;
}

.rtl .am-right-sidebar .search > span {
  right: auto;
  left: 15px;
}

.am-right-sidebar .search > span:hover {
  color: #d1d1d1;
}

.am-right-sidebar .tab-navigation .nav-tabs {
  background-color: #424141;
  border-bottom: 0;
  padding: 0 15px;
  justify-content: space-around;
}

.am-right-sidebar .tab-navigation .nav-tabs > li {
  margin-bottom: -1px;
}

.am-right-sidebar .tab-navigation .nav-tabs > li > a {
  display: block;
  position: relative;
  background-color: transparent;
  border-width: 0;
  padding: 14px 10px;
  min-width: 40px;
}

.am-right-sidebar .tab-navigation .nav-tabs > li > a .icon {
  font-size: 25px;
  color: #aaaaaa;
}

.am-right-sidebar .tab-navigation .nav-tabs > li > a:hover {
  border: 0;
  background-color: transparent;
}

.am-right-sidebar .tab-navigation .nav-tabs > li > a:hover .icon {
  color: #FFFFFF;
}

.am-right-sidebar .tab-navigation .nav-tabs > li > a:focus {
  border: 0;
  background-color: transparent;
}

.am-right-sidebar .tab-navigation .nav-tabs > li > a.active .icon {
  color: #ef6262;
}

.am-right-sidebar .tab-panel {
  height: 100%;
  display: table-row;
}

.am-right-sidebar .tab-panel .tab-content {
  height: 100%;
  position: relative;
  width: 100%;
  padding: 0;
  background: transparent;
  display: table-cell;
  overflow: hidden;
}

.am-right-sidebar .tab-panel .tab-content .tab-pane {
  height: 100%;
  position: relative;
  width: 100%;
}

.am-right-sidebar .tab-panel .tab-content .tab-pane > .content {
  overflow: auto;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.am-right-sidebar .tab-panel .tab-content .announcement .announcement-container {
  height: 100%;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.am-right-sidebar .tab-panel .tab-content .announcement .announcement-wrapper {
  display: table-row;
  height: 100%;
}

.am-right-sidebar .tab-panel .tab-content .announcement .am-scroller-announcement {
  position: relative;
  width: 100%;
  height: 100%;
}

.am-right-sidebar .tab-panel .tab-content .announcement .announcement-content {
  padding: 10px 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.am-right-sidebar .tab-panel .tab-content .announcement ul {
  list-style: none;
  padding: 0;
}

.am-right-sidebar .tab-panel .tab-content .announcement ul > li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 25px;
}

.rtl .am-right-sidebar .tab-panel .tab-content .announcement ul > li {
  padding-left: 0;
  padding-right: 30px;
}

.am-right-sidebar .tab-panel .tab-content .announcement ul > li > .icon {
  position: absolute;
  left: 0;
  font-size: 16px;
  background-color: #424141;
  color: #d6d6d6;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  text-align: center;
  line-height: 20px;
}

.rtl .am-right-sidebar .tab-panel .tab-content .announcement ul > li > .icon {
  left: auto;
  right: 0;
}

.am-right-sidebar .tab-panel .tab-content .announcement ul > li > .icon span.icon {
  vertical-align: middle;
}

.am-right-sidebar .tab-panel .tab-content .announcement ul > li .content {
  margin-left: 5px;
}

.rtl .am-right-sidebar .tab-panel .tab-content .announcement ul > li .content {
  margin-left: 0;
  margin-right: 5px;
}

.am-right-sidebar .tab-panel .tab-content .announcement ul > li .content a {
  display: block;
  color: #FFFFFF;
  padding-top: 3px;
  font-size: 12px;
}

.am-right-sidebar .tab-panel .tab-content .announcement ul > li .content a:hover {
  color: #f38787;
}

.am-right-sidebar .tab-panel .tab-content .announcement ul > li .content span {
  display: block;
  color: #b3b3b3;
  margin-top: 5px;
  font-size: 12px;
}

.am-right-sidebar .tab-panel .tab-content .faqs .faqs-container {
  height: 100%;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.am-right-sidebar .tab-panel .tab-content .faqs .faqs-wrapper {
  display: table-row;
  height: 100%;
}

.am-right-sidebar .tab-panel .tab-content .faqs .am-scroller-faqs {
  position: relative;
  width: 100%;
  height: 100%;
}

.am-right-sidebar .tab-panel .tab-content .faqs .faqs-content {
  padding: 10px 20px 1px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group {
  flex-flow: column wrap;
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group .card {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 20px;
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-header {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-header .card-title {
  margin-top: 0;
  margin-bottom: 0;
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-header .card-title a {
  color: #FFFFFF;
  position: relative;
  padding: 0;
  padding-left: 35px;
  line-height: 24px;
  display: block;
}

.rtl .am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-header .card-title a {
  padding-left: 0;
  padding-right: 35px;
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-header .card-title a .icon {
  background-color: #424141;
  height: 26px;
  width: 26px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
}

.rtl .am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-header .card-title a .icon {
  left: auto;
  right: 0;
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-header .card-title a .icon > span {
  font-size: 26px;
  color: #d6d6d6;
  position: relative;
  top: 1px;
  display: inline-block;
  transition: all 0.3s ease;
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-header .card-title a .title {
  display: block;
  font-size: 12px;
  font-weight: 400;
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-header .card-title a.collapsed .icon {
  transform: none;
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-header .card-title a.collapsed .icon > span {
  transform: rotate(-90deg);
}

.am-right-sidebar .tab-panel .tab-content .faqs .card-group .card .card-collapse .card-body {
  border-top: 0;
  color: #b3b3b3;
  padding-left: 35px;
  padding-top: 3px;
  padding-right: 0;
  padding-bottom: 0;
  font-size: 12px;
}

.am-right-sidebar .tab-panel .tab-content .ticket .ticket-container {
  height: 100%;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.am-right-sidebar .tab-panel .tab-content .ticket .ticket-wrapper {
  display: table-row;
  height: 100%;
}

.am-right-sidebar .tab-panel .tab-content .ticket .am-scroller-ticket {
  position: relative;
  width: 100%;
  height: 100%;
}

.am-right-sidebar .tab-panel .tab-content .ticket .ticket-content {
  padding: 10px 20px 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.am-right-sidebar .tab-panel .tab-content .ticket form {
  margin-top: 30px;
}

.am-right-sidebar .tab-panel .tab-content .ticket form .send-ticket {
  margin-bottom: 30px;
}

.am-right-sidebar .tab-panel .tab-content .ticket form .send-ticket label {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 13px;
}

.am-right-sidebar .tab-panel .tab-content .ticket form .send-ticket .form-control {
  background-color: transparent;
  border-radius: 2px;
  box-shadow: none;
  font-size: 13px;
  line-height: 1.428571;
  border: 1px solid #646262;
  color: #e1e1e1;
  height: auto;
  padding: 10px;
}

.am-right-sidebar .tab-panel .tab-content .ticket form .send-ticket .form-control::placeholder {
  color: #999999;
}

.am-right-sidebar .tab-panel .tab-content .ticket form .send-ticket textarea {
  min-height: 160px;
}

.am-right-sidebar .tab-panel .tab-content .ticket form .btn {
  width: 100%;
}

@media (max-width: 767px) {
  .am-right-sidebar {
    width: 85%;
    right: 0;
    transform: translate3d(100%, 0, 0);
  }
  .rtl .am-right-sidebar {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }
  .am-right-sidebar .tab-panel .tab-content > .chat {
    width: 100%;
  }
  .am-right-sidebar .tab-panel .tab-content > .chat .chat-contacts, .am-right-sidebar .tab-panel .tab-content > .chat .chat-window {
    width: 100%;
  }
  .am-right-sidebar .tab-panel .tab-content > .chat .chat-window {
    left: 100%;
  }
  .am-right-sidebar .tab-panel .tab-content > .chat.chat-opened .chat-window {
    left: 0;
  }
  .am-right-sidebar .tab-panel .tab-content > .chat.chat-opened .chat-contacts {
    left: -100%;
  }
}

.am-right-sidebar .chat {
  width: 255px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0;
}

.am-content .chat.chat-scroll {
  height: 350px;
  position: relative;
  width: 100%;
}

.chat .chat-category {
  margin: 30px 20px 15px;
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 15px;
  border-bottom: 1px solid #ededed;
  padding-left: 5px;
}

.am-right-sidebar .chat .chat-category {
  border-bottom-color: #6d6b6b;
  color: #e1e1e1;
}

.chat h2 {
  margin: 28px 20px 15px;
}

.chat .recent, .chat .contact, .chat .chat-contact-list {
  margin-bottom: 30px;
  font-size: 13px;
}

.am-right-sidebar .chat .recent, .am-right-sidebar .chat .contact, .am-right-sidebar .chat .chat-contact-list {
  font-size: 12px;
}

.chat .recent .user a, .chat .contact .user a, .chat .chat-contact-list .user a {
  display: block;
  padding: 14px 25px;
  overflow: hidden;
}

.am-right-sidebar .chat .recent .user a, .am-right-sidebar .chat .contact .user a, .am-right-sidebar .chat .chat-contact-list .user a {
  padding: 11px 25px;
}

.chat .recent .user a img, .chat .contact .user a img, .chat .chat-contact-list .user a img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  float: left;
}

.rtl .chat .recent .user a img, .rtl .chat .contact .user a img, .rtl .chat .chat-contact-list .user a img {
  float: right;
}

.chat .recent .user a .user-data, .chat .recent .user a .user-data2, .chat .contact .user a .user-data, .chat .contact .user a .user-data2, .chat .chat-contact-list .user a .user-data, .chat .chat-contact-list .user a .user-data2 {
  padding-left: 40px;
  line-height: 16px;
}

.rtl .chat .recent .user a .user-data, .rtl .chat .recent .user a .user-data2, .rtl .chat .contact .user a .user-data, .rtl .chat .contact .user a .user-data2, .rtl .chat .chat-contact-list .user a .user-data, .rtl .chat .chat-contact-list .user a .user-data2 {
  padding-left: 0;
  padding-right: 40px;
}

.chat .recent .user a .user-data .status, .chat .recent .user a .user-data2 .status, .chat .contact .user a .user-data .status, .chat .contact .user a .user-data2 .status, .chat .chat-contact-list .user a .user-data .status, .chat .chat-contact-list .user a .user-data2 .status {
  background-color: #00b777;
  border-radius: 50%;
  float: right;
  width: 7px;
  height: 7px;
  position: relative;
  top: 5px;
}

.rtl .chat .recent .user a .user-data .status, .rtl .chat .recent .user a .user-data2 .status, .rtl .chat .contact .user a .user-data .status, .rtl .chat .contact .user a .user-data2 .status, .rtl .chat .chat-contact-list .user a .user-data .status, .rtl .chat .chat-contact-list .user a .user-data2 .status {
  float: left;
}

.chat .recent .user a .user-data .status.offline, .chat .recent .user a .user-data2 .status.offline, .chat .contact .user a .user-data .status.offline, .chat .contact .user a .user-data2 .status.offline, .chat .chat-contact-list .user a .user-data .status.offline, .chat .chat-contact-list .user a .user-data2 .status.offline {
  background-color: #e65a5a;
}

.chat .recent .user a .user-data .status.away, .chat .recent .user a .user-data2 .status.away, .chat .contact .user a .user-data .status.away, .chat .contact .user a .user-data2 .status.away, .chat .chat-contact-list .user a .user-data .status.away, .chat .chat-contact-list .user a .user-data2 .status.away {
  background-color: #f2c329;
}

.chat .recent .user a .user-data .name, .chat .recent .user a .user-data2 .name, .chat .contact .user a .user-data .name, .chat .contact .user a .user-data2 .name, .chat .chat-contact-list .user a .user-data .name, .chat .chat-contact-list .user a .user-data2 .name {
  color: #555555;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}

.am-right-sidebar .chat .recent .user a .user-data .name, .am-right-sidebar .chat .recent .user a .user-data2 .name, .am-right-sidebar .chat .contact .user a .user-data .name, .am-right-sidebar .chat .contact .user a .user-data2 .name, .am-right-sidebar .chat .chat-contact-list .user a .user-data .name, .am-right-sidebar .chat .chat-contact-list .user a .user-data2 .name {
  color: #e1e1e1;
}

.chat .recent .user a .user-data .message, .chat .recent .user a .user-data2 .message, .chat .contact .user a .user-data .message, .chat .contact .user a .user-data2 .message, .chat .chat-contact-list .user a .user-data .message, .chat .chat-contact-list .user a .user-data2 .message {
  display: block;
  color: #999999;
  font-size: 11px;
}

.chat .recent .user a .user-data2, .chat .contact .user a .user-data2, .chat .chat-contact-list .user a .user-data2 {
  margin-top: 7px;
}

.chat .recent .user a:hover, .chat .contact .user a:hover, .chat .chat-contact-list .user a:hover {
  background-color: #f7f7f7;
}

.am-right-sidebar .chat .recent .user a:hover, .am-right-sidebar .chat .contact .user a:hover, .am-right-sidebar .chat .chat-contact-list .user a:hover {
  background-color: #424141;
}

.chat .chat-contacts, .chat .chat-window {
  width: 100%;
}

.am-right-sidebar .chat .chat-contacts, .am-right-sidebar .chat .chat-window {
  width: 255px;
  height: 100%;
  display: table;
  table-layout: fixed;
  position: absolute;
  left: 0;
  transition: left 0.3s ease;
}

.am-right-sidebar .chat .chat-contacts .chat-sections {
  height: 100%;
  display: table-row;
}

.am-right-sidebar .chat .chat-contacts .chat-sections .am-scroller-chat {
  position: relative;
  width: 100%;
  height: 100%;
}

.am-right-sidebar .chat .chat-contacts .chat-sections .content {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
}

.chat .chat-contacts .search {
  position: relative;
}

.am-right-sidebar .chat .chat-window {
  height: 100%;
  left: 255px;
  display: table;
  table-layout: fixed;
}

.chat .chat-window .title {
  padding: 20px 10px;
  padding-right: 20px;
  overflow: hidden;
}

.chat .chat-window .title .icon {
  font-size: 35px;
  color: #b3b3b3;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

.chat .chat-window .title .user-name {
  font-size: 19px;
  line-height: 23px;
}

.chat .chat-window .title .user {
  display: inline-block;
  float: right;
  text-align: right;
  line-height: 13px;
}

.chat .chat-window .title .user img {
  height: 35px;
  width: 35x;
  border-radius: 50%;
  float: right;
  margin-left: 10px;
}

.chat .chat-window .title .user h2 {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
}

.chat .chat-window .title .user span {
  display: block;
  font-size: 12px;
  color: #999999;
}

.chat .chat-window .chat-messages {
  font-size: 13px;
  height: 274px;
}

.chat .chat-window .chat-messages .am-scroller-messages {
  position: relative;
  width: 100%;
  height: 100%;
}

.am-right-sidebar .chat .chat-window .chat-messages {
  height: 100%;
  display: table-row;
}

.chat .chat-window .chat-messages .content {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  outline: none;
}

.chat .chat-window .chat-messages ul {
  outline: none;
  margin: 0;
  padding: 0;
}

.chat .chat-window .chat-messages ul > li {
  display: block;
  list-style: none;
  padding: 9px 16px;
}

.chat .chat-window .chat-messages ul > li .msg {
  position: relative;
  display: inline-block;
  background-color: #f06c6c;
  color: #FFFFFF;
  font-size: 12px;
  padding: 7px 8px;
  border-radius: 3px;
  white-space: normal;
  text-align: left;
  margin-left: 40px;
}

.rtl .chat .chat-window .chat-messages ul > li .msg {
  text-align: right;
  margin-left: 0;
  margin-right: 40px;
}

.chat .chat-window .chat-messages ul > li .msg:after {
  content: "";
  position: absolute;
  right: -5px;
  top: 7px;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #f06c6c;
  border-top: 6px solid transparent;
}

.rtl .chat .chat-window .chat-messages ul > li .msg:after {
  left: -5px;
  right: auto;
  border-right: 6px solid #f06c6c;
  border-left-width: 0;
}

.am-right-sidebar .chat .chat-window .chat-messages ul > li .msg {
  padding: 5px 8px;
}

.chat .chat-window .chat-messages ul > li.friend .msg {
  background-color: whitesmoke;
  color: #424141;
  margin-left: 0;
  margin-right: 40px;
}

.rtl .chat .chat-window .chat-messages ul > li.friend .msg {
  margin-right: 0;
  margin-left: 40px;
}

.chat .chat-window .chat-messages ul > li.friend .msg:after {
  right: auto;
  left: -5px;
  border-left-width: 0;
  border-right: 5px solid whitesmoke;
}

.rtl .chat .chat-window .chat-messages ul > li.friend .msg:after {
  right: -5px;
  left: auto;
  border-left: 5px solid white;
  border-right-width: 0;
}

.am-right-sidebar .chat .chat-window .chat-messages ul > li.friend .msg {
  background-color: #FFFFFF;
}

.am-right-sidebar .chat .chat-window .chat-messages ul > li.friend .msg:after {
  border-right-color: #FFFFFF;
}

.chat .chat-window .chat-messages ul > li.self {
  text-align: right;
}

.rtl .chat .chat-window .chat-messages ul > li.self {
  text-align: left;
}

.chat.chat-opened .chat-contacts {
  left: -255px;
}

.chat.chat-opened .chat-window {
  left: 0;
}

.chat.chat-scroll .chat-contacts, .chat.chat-scroll .chat-window {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
}

.chat-search {
  position: relative;
  border-top: 1px solid #ededed;
}

.chat-search > input {
  border: 0;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  padding: 20px;
  outline: none;
}

.chat-search > span {
  position: absolute;
  color: #aaaaaa;
  right: 15px;
  top: 50%;
  font-size: 20px;
  margin-top: -10px;
  cursor: pointer;
}

.rtl .chat-search > span {
  left: 15px;
  right: auto;
}

.chat-search > span:hover {
  color: #d1d1d1;
}

.chat-input {
  position: relative;
}

.chat-input .input-wrapper > input {
  border-width: 0;
  border-top: 1px solid #ededed;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  padding: 20px;
  padding-left: 42px;
  padding-right: 40px;
  outline: none;
}

.am-right-sidebar .chat-input .input-wrapper > input {
  background-color: #424141;
  color: #b3b3b3;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 0;
}

.chat-input .input-wrapper > span {
  position: absolute;
  color: #aaaaaa;
  right: 15px;
  top: 50%;
  font-size: 20px;
  margin-top: -10px;
  cursor: pointer;
}

.rtl .chat-input .input-wrapper > span {
  left: 15px;
  right: auto;
}

.chat-input .input-wrapper > span:hover {
  color: #d1d1d1;
}

.chat-input .input-wrapper .photo {
  left: 15px;
  font-size: 22px;
  top: 47%;
  right: auto;
}

.rtl .chat-input .input-wrapper .photo {
  right: 15px;
  left: auto;
}

/*------------------------------------------------------------------
	[Scroll to top button]
*/
.am-scroll-top {
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px 9px;
  display: none;
  transition: background-color .2s ease;
  border-radius: 4px;
}

.rtl .am-scroll-top {
  left: 10px;
  right: auto;
}

.am-scroll-top:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.am-scroll-top:hover, .am-scroll-top:focus {
  color: #FFFFFF;
}

.am-scroll-top:before {
  content: '\e682';
  font-family: "Stroke 7";
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
}

/*------------------------------------------------------------------
  [Splash screen]
*/
.am-splash-screen {
  background-color: #383737;
}

.am-splash-screen .am-wrapper {
  padding-top: 0;
}

.am-splash-screen .am-wrapper .am-content {
  margin: 0;
}

.am-splash-screen .am-wrapper .am-content a {
  color: #ababab;
}

.am-splash-screen .am-wrapper .am-content a:hover {
  color: #FFFFFF;
}

/*------------------------------------------------------------------
	[Sticky Footer]
*/
.am-sticky-footer {
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .am-sticky-footer {
    padding-bottom: 0;
  }
}

.am-sticky-footer footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 50px;
  background-color: #FFFFFF;
  margin-left: 100px;
  border-top: 1px solid #e6e6e6;
  padding: 15px 35px;
}

.rtl .am-sticky-footer footer {
  margin-left: 0;
  margin-right: 100px;
}

@media (max-width: 767px) {
  .am-sticky-footer footer {
    position: static;
    margin-left: 0;
  }
}

.am-sticky-footer.am-aside footer {
  padding: 15px 20px;
}

/*------------------------------------------------------------------
	[Top header]
*/
.am-top-header {
  background-color: #ef6262;
  border: 0;
  margin-bottom: 0;
}

.am-top-header.navbar {
  padding: 0;
}

.am-top-header.navbar-expand-md > .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 767px) {
  .am-top-header.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

.am-top-header .dropdown.show {
  background-color: #ed5454;
}

@media (max-width: 767px) {
  .am-top-header .dropdown.show {
    background-color: transparent;
  }
}

.am-top-header .dropdown > .dropdown-toggle:after {
  display: none;
}

.am-animate .am-top-header {
  transition: all 0.35s ease;
}

.rtl .am-top-header .am-navbar-header {
  float: right;
}

@media (max-width: 767px) {
  .rtl .am-top-header .am-navbar-header {
    float: none;
  }
}

.am-top-header .am-navbar-header .navbar-brand {
  float: left;
  background-color: #3d3c3c;
  color: #FFFFFF;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: -15px;
  line-height: 80px;
  height: 80px;
  width: 100px;
  background-image: url("../img/logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 20px;
  border-bottom: 1px solid #474646;
}

.rtl .am-top-header .am-navbar-header .navbar-brand {
  margin-right: -15px;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .am-top-header .am-navbar-header .navbar-brand {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .am-top-header .am-navbar-header .navbar-brand {
    margin-right: 10px;
  }
}

.am-nosidebar-left .am-top-header .am-navbar-header .navbar-brand {
  background-color: transparent;
  border-bottom-width: 0;
  margin-left: 0;
  margin-right: 10px;
  width: 180px;
  background-image: url("../img/logo-full.png");
}

@media (min-width: 768px) and (max-width: 991px) {
  .am-nosidebar-left .am-top-header .am-navbar-header .navbar-brand {
    margin-right: 0;
    padding-left: 0;
    width: 160px;
  }
}

.am-top-header .am-navbar-header .am-toggle-left-sidebar {
  border-radius: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  font-size: 0;
  text-align: center;
  display: none;
  padding: 0 18px;
  border-width: 0;
  line-height: 70px;
}

.rtl .am-top-header .am-navbar-header .am-toggle-left-sidebar {
  left: auto;
  right: 0;
}

.am-top-header .am-navbar-header .am-toggle-left-sidebar .icon-bar {
  display: inline-block;
  background-color: transparent;
  height: auto;
  width: auto;
  vertical-align: middle;
}

.am-top-header .am-navbar-header .am-toggle-left-sidebar .icon-bar span {
  display: block;
  width: 25px;
  height: 1px;
  margin-top: 6px;
  background-color: #FFFFFF;
}

.am-top-header .am-navbar-header .am-toggle-left-sidebar .icon-bar span:first-child {
  margin-top: 0;
}

.am-top-header .am-navbar-header .am-toggle-left-sidebar:hover, .am-top-header .am-navbar-header .am-toggle-left-sidebar:focus {
  background-color: transparent;
}

@media (max-width: 767px) {
  .am-top-header .am-navbar-header .am-toggle-left-sidebar {
    display: block;
  }
}

.am-top-header .am-navbar-header .page-title {
  display: none;
  color: #FFFFFF;
  font-family: "Raleway", sans-serif;
  font-size: 1.462rem;
  line-height: 70px;
}

@media (max-width: 767px) {
  .am-top-header .am-navbar-header .page-title {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .am-top-header .am-navbar-header {
    width: 100%;
    text-align: center;
  }
}

.am-top-header .am-search-container {
  margin-left: 17px;
  order: 1;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .am-top-header .am-search-container {
    display: none;
  }
}

.am-top-header .am-search-container .am-search {
  position: relative;
  width: 100%;
  max-width: 500px;
  border-bottom: 1px solid #f06b6b;
}

@media (min-width: 768px) and (max-width: 991px) {
  .am-top-header .am-search-container .am-search {
    max-width: 330px;
  }
}

.am-top-header .am-search-container .am-search > input {
  border: 1px solid transparent;
  background-color: #ed5757;
  color: #FFFFFF;
  width: 100%;
  font-family: "Raleway", sans-serif;
  font-size: 1.231rem;
  font-weight: 300;
  padding: 8px 10px;
  outline: none;
  transition: border-color 0.3s ease;
}

.am-top-header .am-search-container .am-search > input:focus {
  border-color: #ec4747;
}

.am-top-header .am-search-container .am-search > input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: rgba(255, 255, 255, 0.4);
}

.am-top-header .am-search-container .am-search > input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

.am-top-header .am-search-container .am-search > input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(255, 255, 255, 0.4);
}

.am-top-header .am-search-container .am-search > span {
  position: absolute;
  color: #FFFFFF;
  right: 8px;
  top: 50%;
  font-size: 1.846rem;
  margin-top: -12px;
  cursor: pointer;
}

.rtl .am-top-header .am-search-container .am-search > span {
  right: unset;
  left: 8px;
}

.am-top-header .navbar-collapse {
  padding-right: 20px;
}

.rtl .am-top-header .navbar-collapse {
  padding-right: 0;
  padding-left: 20px;
}

.rtl .am-top-header .navbar-collapse .navbar-nav {
  padding-right: 0;
}

.am-top-header .navbar-collapse .navbar-nav > li > a {
  line-height: 80px;
  color: #FFFFFF;
  font-family: "Raleway", sans-serif;
  font-size: 1.077rem;
  padding: 0 18px;
  outline: none;
}

@media (min-width: 768px) and (max-width: 991px) {
  .am-top-header .navbar-collapse .navbar-nav > li > a {
    padding: 0 12px;
  }
}

.am-top-header .navbar-collapse .navbar-nav > li.dropdown > a .angle-down {
  font-size: 2.077rem;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .rtl .am-top-header .navbar-collapse .navbar-nav > li.dropdown > a .angle-down {
    float: none;
    line-height: 1;
  }
}

.am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
  border: 0;
  border-radius: 3px;
  margin-top: 2px;
}

.rtl .am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
  left: auto;
  right: 0;
  float: right;
}

@media (max-width: 767px) {
  .rtl .am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
    float: none;
  }
}

.am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > li > a {
  display: block;
  font-size: .9231rem;
  font-weight: 300;
  color: #504e4e;
  padding: 10px 20px;
  min-width: 175px;
}

.am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > li > a:hover {
  background-color: #ef6262;
  color: #FFFFFF;
}

.am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > li > a:focus, .am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > li > a:active {
  background-color: transparent;
  color: #504e4e;
}

.am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu:after {
  border-bottom: 8px solid white;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top: -8px;
  content: "";
  display: inline-block;
  left: 42px;
  margin-left: -6px;
  position: absolute;
}

.rtl .am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu:after {
  left: auto;
  right: 42px;
}

@media (max-width: 767px) {
  .am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
    box-shadow: unset;
  }
}

.am-top-header .navbar-collapse .navbar-nav.am-top-menu {
  order: 1;
  flex-grow: 1;
}

.am-top-header .navbar-collapse .navbar-nav.am-top-menu > li:first-child > a {
  padding-left: 15px;
}

.rtl .am-top-header .navbar-collapse .navbar-nav.am-top-menu {
  float: right;
}

@media (max-width: 767px) {
  .rtl .am-top-header .navbar-collapse .navbar-nav.am-top-menu {
    float: none;
  }
}

.rtl .am-top-header .navbar-collapse .navbar-nav.am-top-menu > li:first-child > a {
  padding-left: 18px;
}

.rtl .am-top-header .navbar-collapse .navbar-nav.am-top-menu > li:last-child > a {
  padding-right: 15px;
}

.am-top-header .navbar-collapse .am-user-nav {
  order: 3;
  margin-right: 0;
}

.rtl .am-top-header .navbar-collapse .am-user-nav {
  float: left;
}

@media (max-width: 767px) {
  .rtl .am-top-header .navbar-collapse .am-user-nav {
    float: none;
  }
}

.am-top-header .navbar-collapse .am-user-nav > li.dropdown > a {
  padding-left: 16px;
  padding-right: 4px;
}

.rtl .am-top-header .navbar-collapse .am-user-nav > li.dropdown > a {
  padding-left: 0px;
  padding-right: 16px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .am-top-header .navbar-collapse .am-user-nav > li.dropdown > a {
    padding-left: 10px;
  }
}

.am-top-header .navbar-collapse .am-user-nav > li.dropdown > a img {
  max-width: 43px;
  max-height: 43px;
  border-radius: 50%;
}

.am-top-header .navbar-collapse .am-user-nav > li.dropdown > a .user-name {
  margin-left: 8px;
  display: none;
}

@media (max-width: 767px) {
  .rtl .am-top-header .navbar-collapse .am-user-nav > li.dropdown > a .user-name {
    margin-left: 0;
    margin-right: 8px;
  }
}

.am-top-header .navbar-collapse .am-user-nav > li.dropdown .dropdown-menu {
  right: 0;
  left: auto;
}

.rtl .am-top-header .navbar-collapse .am-user-nav > li.dropdown .dropdown-menu {
  left: 0;
  right: auto;
  float: left;
}

@media (max-width: 767px) {
  .rtl .am-top-header .navbar-collapse .am-user-nav > li.dropdown .dropdown-menu {
    position: static;
    float: none;
  }
}

.am-top-header .navbar-collapse .am-user-nav > li.dropdown .dropdown-menu li a .icon {
  font-size: 1.538rem;
  vertical-align: middle;
  margin-right: 6px;
}

.rtl .am-top-header .navbar-collapse .am-user-nav > li.dropdown .dropdown-menu li a .icon {
  float: right;
  margin-right: 0;
  margin-left: 6px;
}

.am-top-header .navbar-collapse .am-user-nav > li.dropdown .dropdown-menu:after {
  left: auto;
  right: 20px;
}

.rtl .am-top-header .navbar-collapse .am-user-nav > li.dropdown .dropdown-menu:after {
  right: auto;
  left: 20px;
}

.am-top-header .navbar-collapse .am-icons-nav {
  order: 2;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .am-top-header .navbar-collapse .am-icons-nav {
    float: none;
  }
}

.rtl .am-top-header .navbar-collapse .am-icons-nav {
  float: left;
  margin-right: 0;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .rtl .am-top-header .navbar-collapse .am-icons-nav {
    float: none;
    margin-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .am-top-header .navbar-collapse .am-icons-nav {
    margin-right: 10px;
  }
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown > a {
  padding: 0 9px;
  position: relative;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown > a .icon {
  font-size: 28px;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.8);
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown > a .indicator {
  background: #FFFFFF;
  border-radius: 50%;
  display: block;
  height: 6px;
  width: 6px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 9px;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections {
  width: 300px;
  border-radius: 5px;
  left: auto;
  margin-right: -150px;
  right: 50%;
  padding-bottom: 0;
}

.rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections {
  right: 50%;
}

@media (max-width: 767px) {
  .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections {
    right: 0;
    margin: 0;
  }
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .title, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .title, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .title {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  color: #8a8a8a;
  text-align: center;
  padding: 3px 0 7px;
  border-bottom: 1px solid #ebebeb;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .title .badge, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .title .badge, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .title .badge {
  font-weight: 600;
  font-size: .7692rem;
  line-height: 9px;
  vertical-align: middle;
  margin-left: 4px;
  background-color: #e4e4e4;
  color: #8a8a8a;
  min-width: 17px;
  height: 17px;
  text-align: center;
  padding: 3px 6px;
  border-radius: 10px;
}

.rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .title .badge, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .title .badge, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .title .badge {
  margin-left: 0;
  margin-right: 4px;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .footer a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .footer a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .footer a {
  font-size: 1rem;
  color: #8a8a8a;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #ebebeb;
  display: block;
  outline: none;
  font-family: "Raleway", sans-serif;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .footer a:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .footer a:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .footer a:hover {
  background-color: whitesmoke;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages:after, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications:after, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections:after {
  left: 50%;
  margin-left: -8px;
}

.rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages:after, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications:after, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections:after {
  left: auto;
  right: 50%;
  margin-left: 0;
  margin-right: -8px;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications {
  position: relative;
  width: 100%;
  height: 222px;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li {
  overflow: hidden;
  border-bottom: 1px solid #ebebeb;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a {
  padding: 15px;
  display: block;
  overflow: hidden;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .logo, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .logo, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .logo, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .logo {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  background-color: #e4e4e4;
  float: left;
  text-align: center;
}

.rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .logo, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .logo, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .logo, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .logo {
  float: right;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .logo .icon, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .logo .icon, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .logo .icon, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .logo .icon {
  font-size: 1.923rem;
  color: #979595;
  line-height: 36px;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .user-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .user-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .user-content {
  padding-left: 50px;
  line-height: 14px;
}

.rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .user-content, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .user-content, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .user-content {
  padding-left: 0;
  padding-right: 50px;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .user-content .circle, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .user-content .circle, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content .circle, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .user-content .circle {
  width: 6px;
  height: 6px;
  background: #ef6262;
  border-radius: 50%;
  float: right;
  position: relative;
  top: 6px;
}

.rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .user-content .circle, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .user-content .circle, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content .circle, .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .user-content .circle {
  float: left;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .user-content .name, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .user-content .name, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content .name, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .user-content .name, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .user-content .text-content {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .user-content .text-content {
  color: #8a8a8a;
  font-size: .9231rem;
  line-height: 16px;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li > a .user-content .date, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li > a .user-content .date, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content .date, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li > a .user-content .date {
  color: #8a8a8a;
  display: block;
  font-size: .6923rem;
  margin-top: 4px;
  text-transform: uppercase;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li.active, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li.active, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li.active, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li.active {
  background-color: rgba(239, 98, 98, 0.1);
  border-bottom: 1px solid rgba(239, 98, 98, 0.1);
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover {
  background-color: #ef6262;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a {
  color: #FFFFFF;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a .logo, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a .logo, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .logo, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a .logo {
  background-color: #eb4242;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a .logo .icon, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a .logo .icon, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .logo .icon, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a .logo .icon {
  color: #FFFFFF;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a .user-content .circle, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a .user-content .circle, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .user-content .circle, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a .user-content .circle {
  background: #FFFFFF;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a .user-content .date, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a .user-content .date, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .user-content .date, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a .user-content .date {
  color: #FFFFFF;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:last-child, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:last-child, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:last-child, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:last-child {
  border-bottom: 0;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .logo {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  overflow: hidden;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .logo img {
  max-width: 100%;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content .date {
  display: inline-block;
  text-transform: none;
  float: right;
  margin-top: 0;
  font-size: .7692rem;
}

.rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content .date {
  float: left;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li > a .user-content .text-content {
  display: block;
  margin-top: 4px;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .logo {
  background-color: transparent;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul {
  padding: 0;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li {
  display: block;
  overflow: hidden;
  list-style: none;
  padding: 10px 20px;
  line-height: 34px;
  border-bottom: 1px solid #ebebeb;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li .logo {
  width: 35px;
  height: 35px;
  float: left;
  text-align: center;
}

.rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li .logo {
  float: right;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li .logo img {
  max-height: 100%;
  max-width: 100%;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li .field {
  padding-left: 45px;
  vertical-align: middle;
}

.rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li .field {
  padding-left: 0;
  padding-right: 45px;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li .field > span {
  font-family: "Raleway", sans-serif;
  color: #8a8a8a;
  font-weight: 500;
  font-size: 1rem;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li .field .switch-container {
  float: right;
}

.rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li .field .switch-container {
  float: left;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li .field .switch-button {
  vertical-align: middle;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li:hover {
  background-color: whitesmoke;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-connections .content ul li:last-child {
  border-bottom: 0;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown.show {
  background-color: transparent;
}

.am-top-header .navbar-collapse .am-icons-nav > li.dropdown.show > a .icon {
  color: #FFFFFF;
}

@media (min-width: 768px) and (max-width: 991px) {
  .am-top-header .navbar-collapse {
    padding-right: 2px;
  }
}

.am-top-header .am-toggle-right-sidebar {
  display: block;
  padding: 0 18px;
  padding-left: 0;
  line-height: 80px;
  color: #FFFFFF;
  margin-right: 5px;
  margin-left: -2px;
}

.rtl .am-top-header .am-toggle-right-sidebar {
  float: left;
  padding-right: 0;
  padding-left: 18px;
  margin-right: 7px;
  margin-left: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  .am-top-header .am-toggle-right-sidebar {
    margin-right: 0;
    padding-left: 5px;
    padding-right: 9px;
  }
}

.am-top-header .am-toggle-right-sidebar .icon {
  font-size: 2rem;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

@media (max-width: 767px) {
  .am-top-header .am-toggle-right-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 70px;
    margin-right: 0;
  }
  .rtl .am-top-header .am-toggle-right-sidebar {
    right: auto;
    left: 0;
  }
}

.am-top-header .navbar-toggler {
  text-align: center;
  background-color: #393838;
  font-size: 2.385rem;
  line-height: 41px;
  width: 100%;
  padding: 0;
  border: 0;
  color: #e1e1e1;
  outline: none;
}

@media (min-width: 768px) {
  .am-white-header .am-top-header {
    background-color: #FFFFFF;
    border-bottom: 1px solid #e6e6e6;
  }
  .am-white-header .am-top-header .navbar-brand {
    background-color: #ef6262;
    border-bottom-color: #474646;
    position: relative;
    top: 1px;
  }
  .am-white-header .am-top-header .navbar-brand:after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: inherit;
    position: absolute;
    top: -1px;
    left: 0;
  }
  .am-white-header .am-top-header .navbar-collapse .navbar-nav > li > a {
    color: #555555;
  }
  .am-white-header .am-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
    margin-top: 10px;
  }
  .am-white-header .am-top-header .navbar-collapse .navbar-nav > li.dropdown.show {
    background-color: #f7f7f7;
  }
  .am-white-header .am-top-header .navbar-collapse .navbar-nav.am-icons-nav > li.dropdown > a .icon {
    color: rgba(85, 85, 85, 0.8);
  }
  .am-white-header .am-top-header .navbar-collapse .navbar-nav.am-icons-nav > li.dropdown > a .indicator {
    background-color: #ef6262;
  }
  .am-white-header .am-top-header .navbar-collapse .navbar-nav.am-icons-nav > li.dropdown .dropdown-menu {
    margin-top: 10px;
  }
  .am-white-header .am-top-header .navbar-collapse .navbar-nav.am-icons-nav > li.dropdown.show {
    background-color: transparent;
  }
  .am-white-header .am-top-header .navbar-collapse .navbar-nav.am-icons-nav > li.dropdown.show > a .icon {
    color: #ef6262;
  }
  .am-white-header .am-top-header .am-toggle-right-sidebar {
    color: rgba(85, 85, 85, 0.8);
  }
}

@media (max-width: 767px) {
  .am-top-header {
    position: relative;
  }
  .am-top-header .navbar-collapse {
    background-color: #3d3c3c;
    border-top: 1px solid #353434;
    overflow: hidden;
    padding: 0 !important;
    margin: 0;
    width: 100%;
    max-height: none;
    box-shadow: none;
    position: relative;
  }
  .am-top-header .navbar-collapse > .navbar-nav {
    margin: 0;
  }
  .am-top-header .navbar-collapse > .navbar-nav > li > a {
    padding: 0 20px;
    line-height: 50px;
    font-size: 1rem;
    position: relative;
  }
  .rtl .am-top-header .navbar-collapse > .navbar-nav > li > a {
    display: flex;
    align-items: center;
  }
  .am-top-header .navbar-collapse > .navbar-nav > li > a {
    background-color: transparent;
  }
  .am-top-header .navbar-collapse > .navbar-nav > li.dropdown .dropdown-menu {
    background-color: #484747;
  }
  .am-top-header .navbar-collapse > .navbar-nav > li.dropdown .dropdown-menu > li > a {
    color: #e1e1e1;
    padding-left: 26px;
    line-height: 1.385rem;
  }
  .am-top-header .navbar-collapse > .navbar-nav > li.dropdown .dropdown-menu > li > a:hover, .am-top-header .navbar-collapse > .navbar-nav > li.dropdown .dropdown-menu > li > a:active, .am-top-header .navbar-collapse > .navbar-nav > li.dropdown .dropdown-menu > li > a:focus {
    background-color: transparent;
    color: #e1e1e1;
  }
  .am-top-header .navbar-collapse > .navbar-nav > li.dropdown .dropdown-menu > li > a:active {
    color: #FFFFFF;
  }
  .am-top-header .navbar-collapse > .navbar-nav > li.dropdown .dropdown-menu:after {
    content: "";
    display: none;
  }
  .am-top-header .navbar-collapse > .navbar-nav.am-top-menu > li:first-child > a {
    padding-left: 20px;
  }
  .rtl .am-top-header .navbar-collapse > .navbar-nav.am-top-menu > li:last-child > a {
    padding-right: 20px;
  }
  .am-top-header .navbar-collapse .am-user-nav > li.dropdown > a {
    padding: 0 15px;
    line-height: 65px;
  }
  .rtl .am-top-header .navbar-collapse .am-user-nav > li.dropdown > a {
    display: flex;
    align-items: center;
  }
  .am-top-header .navbar-collapse .am-user-nav > li.dropdown > a > img {
    max-height: 32px;
    max-width: 32px;
  }
  .am-top-header .navbar-collapse .am-user-nav > li.dropdown > a .user-name {
    display: inline;
  }
  .am-top-header .navbar-collapse .am-icons-nav {
    border-top: 1px solid #353434;
    background-color: #393838;
    text-align: center;
    flex-direction: row;
    justify-content: center;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown {
    display: inline-block;
    position: static;
  }
  .rtl .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > a {
    height: 50px;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > a .icon {
    color: #e1e1e1;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > a .indicator {
    background-color: #f17979;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > ul.dropdown-menu {
    position: absolute;
    top: 0;
    bottom: 51px;
    left: 0;
    width: 100%;
    margin-top: 0;
    padding-top: 0;
    border-radius: 0;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > ul.dropdown-menu > li {
    height: 100%;
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > ul.dropdown-menu > li .title {
    display: none;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > ul.dropdown-menu > li > .list {
    background-color: #eeeeee;
    height: 100%;
    display: table-row;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > ul.dropdown-menu > li .content {
    background-color: #eeeeee;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > ul.dropdown-menu > li .content > ul > li {
    background-color: #eeeeee;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > ul.dropdown-menu > li .footer > a {
    border-top-color: #e0e0e0;
    background-color: #ebebeb;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > ul.dropdown-menu > li .footer > a:hover {
    background-color: #e6e6e6;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown > ul.dropdown-menu:after {
    display: none;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li {
    border-bottom-color: #e0e0e0;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover {
    background-color: #eeeeee;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a {
    color: #ef6262;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .user-content .date, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a .user-content .date, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a .user-content .date, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a .user-content .text-content, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a .user-content .date, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a .user-content .text-content {
    color: #8a8a8a;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .user-content .circle, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a .user-content .circle, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a .user-content .circle, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a .user-content .circle {
    background-color: #ef6262;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .logo, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a .logo, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a .logo, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a .logo {
    background-color: #e4e4e4;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li:hover > a .logo .icon, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li:hover > a .logo .icon, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li:hover > a .logo .icon, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li:hover > a .logo .icon {
    color: #979595;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li.active, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li.active, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li.active, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li.active {
    border-bottom-color: #e0e0e0;
  }
  .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-top-messages > .content > ul > li.active:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-messages .am-scroller-notifications > .content > ul > li.active:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-top-messages > .content > ul > li.active:hover, .am-top-header .navbar-collapse .am-icons-nav > li.dropdown .am-notifications .am-scroller-notifications > .content > ul > li.active:hover {
    background-color: #fdefef;
  }
  .am-top-header .container-fluid {
    padding: 0;
  }
}

/*------------------------------------------------------------------
  [Forms]
*/
/*------------------------------------------------------------------
  [Switch button]
*/
.switch-button {
  display: inline-block;
  border-radius: 50px;
  background-color: #b3b3b3;
  width: 60px;
  height: 27px;
  padding: 2px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}

.switch-button input[type="checkbox"] {
  display: none;
}

.switch-button label {
  border-radius: 50%;
  border: 1px solid #ababab;
  background-color: #FFFFFF;
  margin: 0;
  height: 23px;
  width: 23px;
  z-index: 1;
  display: inline-block;
  cursor: pointer;
  background-clip: padding-box;
}

.switch-button label:before {
  position: absolute;
  font-size: 11px;
  font-weight: 600;
  z-index: 0;
  content: "OFF";
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 26px;
  top: 0;
  text-align: right;
  padding-right: 10px;
  color: #FFFFFF;
}

.rtl .switch-button label:before {
  padding-right: 0;
  padding-left: 10px;
  text-align: left;
}

.switch-button input[type="checkbox"]:checked + span {
  background-color: #ef6262;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2px;
}

.switch-button input[type="checkbox"]:checked + span label {
  float: right;
  border-color: #ec4b4b;
}

.rtl .switch-button input[type="checkbox"]:checked + span label {
  float: left;
}

.switch-button input[type="checkbox"]:checked + span label:before {
  position: absolute;
  font-size: 11px;
  font-weight: 600;
  z-index: 0;
  content: "ON";
  color: #FFFFFF;
  left: 0;
  text-align: left;
  padding-left: 10px;
}

.rtl .switch-button input[type="checkbox"]:checked + span label:before {
  padding-right: 10px;
  padding-left: 0;
  text-align: right;
}

.switch-button.switch-button-yesno label:before {
  content: "NO";
}

.switch-button.switch-button-yesno input[type="checkbox"]:checked + span label:before {
  content: "YES";
}

.switch-button.switch-button-xs {
  height: 20px;
  width: 53px;
  line-height: 22px;
  width: 50px;
}

.switch-button.switch-button-xs label {
  height: 16px;
  width: 16px;
}

.switch-button.switch-button-xs label:before {
  line-height: 19px;
}

.switch-button.switch-button-sm {
  height: 24px;
  width: 57px;
  line-height: 26px;
}

.switch-button.switch-button-sm label {
  height: 20px;
  width: 20px;
}

.switch-button.switch-button-sm label:before {
  line-height: 23px;
}

.switch-button.switch-button-lg {
  height: 30px;
  width: 63px;
  line-height: 32px;
  width: 65px;
}

.switch-button.switch-button-lg label {
  height: 26px;
  width: 26px;
}

.switch-button.switch-button-lg label:before {
  line-height: 29px;
}

.switch-button.switch-button-success {
  background-color: #b3b3b3;
}

.switch-button.switch-button-success label {
  border-color: #ababab;
}

.switch-button.switch-button-success input[type="checkbox"]:checked + span {
  background-color: #7ACCBE;
}

.switch-button.switch-button-success input[type="checkbox"]:checked + span label {
  border-color: #68c5b5;
}

.switch-button.switch-button-info {
  background-color: #b3b3b3;
}

.switch-button.switch-button-info label {
  border-color: #ababab;
}

.switch-button.switch-button-info input[type="checkbox"]:checked + span {
  background-color: #8DCADF;
}

.switch-button.switch-button-info input[type="checkbox"]:checked + span label {
  border-color: #79c1d9;
}

.switch-button.switch-button-warning {
  background-color: #b3b3b3;
}

.switch-button.switch-button-warning label {
  border-color: #ababab;
}

.switch-button.switch-button-warning input[type="checkbox"]:checked + span {
  background-color: #FFC870;
}

.switch-button.switch-button-warning input[type="checkbox"]:checked + span label {
  border-color: #ffbe57;
}

.switch-button.switch-button-danger {
  background-color: #b3b3b3;
}

.switch-button.switch-button-danger label {
  border-color: #ababab;
}

.switch-button.switch-button-danger input[type="checkbox"]:checked + span {
  background-color: #EF6262;
}

.switch-button.switch-button-danger input[type="checkbox"]:checked + span label {
  border-color: #ed4b4b;
}

/*------------------------------------------------------------------
  [cards]
*/
.card {
  background-color: #FFFFFF;
  margin-bottom: 30px;
  box-shadow: none;
  border-width: 0;
}

.card-header {
  font-size: 1.385rem;
  font-weight: 300;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 15px;
  margin: 0 1.538rem;
  background-color: transparent;
  color: #323232;
}

.card-header.card-header-transparent-border-bottom {
  border-bottom-color: transparent;
}

.card-header .card-title, .card-header .title, .card-header > h1, .card-header > h2, .card-header > h3, .card-header > h4, .card-header > h5, .card-header > h6 {
  font-size: 1.385rem;
  font-weight: 300;
  display: block;
  margin: 0;
}

.card-header .tools {
  float: right;
  font-size: 0;
}

.rtl .card-header .tools {
  float: left;
}

.card-header .tools > a {
  display: inline-block;
  color: #323232;
}

.card-header .tools .icon {
  display: inline-block;
  margin-left: 5px;
  font-size: 21px;
  line-height: 25px;
  vertical-align: middle;
  cursor: pointer;
}

.rtl .card-header .tools .icon {
  margin-left: 0;
  margin-right: 5px;
}

.card-header .tools .icon:hover {
  color: #ef6262;
}

.card-header .tools .icon.s7-close {
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.rtl .card-header .tools .icon.s7-close {
  margin-left: 0;
  margin-right: 1px;
}

.card-header .tools .value {
  font-size: 24px;
  line-height: 22px;
}

.card-header.no-border {
  border-width: 0;
}

.card-body {
  padding-top: 15px;
}

.card-header-fullwidth .card-header,
.card-primary .card-header,
.card-success .card-header,
.card-info .card-header,
.card-warning .card-header,
.card-danger .card-header,
.card-alt1 .card-header,
.card-alt2 .card-header,
.card-alt3 .card-header,
.card-alt4 .card-header,
.card-dark .card-header {
  margin: 0;
  padding-left: 1.538rem;
  padding-right: 1.538rem;
}

.card-header-fullwidth .card-header .card-title, .card-header-fullwidth .card-header .title, .card-header-fullwidth .card-header h1, .card-header-fullwidth .card-header h2, .card-header-fullwidth .card-header h3, .card-header-fullwidth .card-header h4, .card-header-fullwidth .card-header h5, .card-header-fullwidth .card-header h6,
.card-primary .card-header .card-title,
.card-primary .card-header .title,
.card-primary .card-header h1,
.card-primary .card-header h2,
.card-primary .card-header h3,
.card-primary .card-header h4,
.card-primary .card-header h5,
.card-primary .card-header h6,
.card-success .card-header .card-title,
.card-success .card-header .title,
.card-success .card-header h1,
.card-success .card-header h2,
.card-success .card-header h3,
.card-success .card-header h4,
.card-success .card-header h5,
.card-success .card-header h6,
.card-info .card-header .card-title,
.card-info .card-header .title,
.card-info .card-header h1,
.card-info .card-header h2,
.card-info .card-header h3,
.card-info .card-header h4,
.card-info .card-header h5,
.card-info .card-header h6,
.card-warning .card-header .card-title,
.card-warning .card-header .title,
.card-warning .card-header h1,
.card-warning .card-header h2,
.card-warning .card-header h3,
.card-warning .card-header h4,
.card-warning .card-header h5,
.card-warning .card-header h6,
.card-danger .card-header .card-title,
.card-danger .card-header .title,
.card-danger .card-header h1,
.card-danger .card-header h2,
.card-danger .card-header h3,
.card-danger .card-header h4,
.card-danger .card-header h5,
.card-danger .card-header h6,
.card-alt1 .card-header .card-title,
.card-alt1 .card-header .title,
.card-alt1 .card-header h1,
.card-alt1 .card-header h2,
.card-alt1 .card-header h3,
.card-alt1 .card-header h4,
.card-alt1 .card-header h5,
.card-alt1 .card-header h6,
.card-alt2 .card-header .card-title,
.card-alt2 .card-header .title,
.card-alt2 .card-header h1,
.card-alt2 .card-header h2,
.card-alt2 .card-header h3,
.card-alt2 .card-header h4,
.card-alt2 .card-header h5,
.card-alt2 .card-header h6,
.card-alt3 .card-header .card-title,
.card-alt3 .card-header .title,
.card-alt3 .card-header h1,
.card-alt3 .card-header h2,
.card-alt3 .card-header h3,
.card-alt3 .card-header h4,
.card-alt3 .card-header h5,
.card-alt3 .card-header h6,
.card-alt4 .card-header .card-title,
.card-alt4 .card-header .title,
.card-alt4 .card-header h1,
.card-alt4 .card-header h2,
.card-alt4 .card-header h3,
.card-alt4 .card-header h4,
.card-alt4 .card-header h5,
.card-alt4 .card-header h6,
.card-dark .card-header .card-title,
.card-dark .card-header .title,
.card-dark .card-header h1,
.card-dark .card-header h2,
.card-dark .card-header h3,
.card-dark .card-header h4,
.card-dark .card-header h5,
.card-dark .card-header h6 {
  border-width: 0;
}

.card-borders {
  border-width: 1px;
}

.card-transparent {
  background-color: transparent;
}

.card-primary .card-header,
.card-success .card-header,
.card-info .card-header,
.card-warning .card-header,
.card-danger .card-header,
.card-alt1 .card-header,
.card-alt2 .card-header,
.card-alt3 .card-header,
.card-alt4 .card-header,
.card-dark .card-header,
.card-full-primary .card-header,
.card-full-success .card-header,
.card-full-info .card-header,
.card-full-warning .card-header,
.card-full-danger .card-header,
.card-full-alt1 .card-header,
.card-full-alt2 .card-header,
.card-full-alt3 .card-header,
.card-full-alt4 .card-header,
.card-full-dark .card-header {
  color: #FFFFFF;
}

.card-primary .card-header .tools > a,
.card-success .card-header .tools > a,
.card-info .card-header .tools > a,
.card-warning .card-header .tools > a,
.card-danger .card-header .tools > a,
.card-alt1 .card-header .tools > a,
.card-alt2 .card-header .tools > a,
.card-alt3 .card-header .tools > a,
.card-alt4 .card-header .tools > a,
.card-dark .card-header .tools > a,
.card-full-primary .card-header .tools > a,
.card-full-success .card-header .tools > a,
.card-full-info .card-header .tools > a,
.card-full-warning .card-header .tools > a,
.card-full-danger .card-header .tools > a,
.card-full-alt1 .card-header .tools > a,
.card-full-alt2 .card-header .tools > a,
.card-full-alt3 .card-header .tools > a,
.card-full-alt4 .card-header .tools > a,
.card-full-dark .card-header .tools > a {
  color: #FFFFFF;
}

.card-primary .card-header, .card-full-primary .card-header {
  background-color: #ef6262;
  border-color: transparent;
}

.card-primary .card-header .tools .icon:hover, .card-primary .card-header .tools > a:hover, .card-full-primary .card-header .tools .icon:hover, .card-full-primary .card-header .tools > a:hover {
  color: #cc3f3f;
}

.card-success .card-header .tools .icon:hover, .card-success .card-header .tools > a:hover, .card-full-success .card-header .tools .icon:hover, .card-full-success .card-header .tools > a:hover {
  color: #57a99b;
}

.card-info .card-header .tools .icon:hover, .card-info .card-header .tools > a:hover, .card-full-info .card-header .tools .icon:hover, .card-full-info .card-header .tools > a:hover {
  color: #6aa7bc;
}

.card-warning .card-header .tools .icon:hover, .card-warning .card-header .tools > a:hover, .card-full-warning .card-header .tools .icon:hover, .card-full-warning .card-header .tools > a:hover {
  color: #dca54d;
}

.card-danger .card-header .tools .icon:hover, .card-danger .card-header .tools > a:hover, .card-full-danger .card-header .tools .icon:hover, .card-full-danger .card-header .tools > a:hover {
  color: #cc3f3f;
}

.card-alt1 .card-header, .card-full-alt1 .card-header {
  background-color: #56c3e7;
  border-color: transparent;
}

.card-alt1 .card-header .tools .icon:hover, .card-alt1 .card-header .tools > a:hover, .card-full-alt1 .card-header .tools .icon:hover, .card-full-alt1 .card-header .tools > a:hover {
  color: #33a0c4;
}

.card-alt2 .card-header, .card-full-alt2 .card-header {
  background-color: #ffcf66;
  border-color: transparent;
}

.card-alt2 .card-header .tools .icon:hover, .card-alt2 .card-header .tools > a:hover, .card-full-alt2 .card-header .tools .icon:hover, .card-full-alt2 .card-header .tools > a:hover {
  color: #dcac43;
}

.card-alt3 .card-header, .card-full-alt3 .card-header {
  background-color: #7a98bf;
  border-color: transparent;
}

.card-alt3 .card-header .tools .icon:hover, .card-alt3 .card-header .tools > a:hover, .card-full-alt3 .card-header .tools .icon:hover, .card-full-alt3 .card-header .tools > a:hover {
  color: #57759c;
}

.card-alt4 .card-header, .card-full-alt4 .card-header {
  background-color: #bdbdbd;
  border-color: transparent;
}

.card-alt4 .card-header .tools .icon:hover, .card-alt4 .card-header .tools > a:hover, .card-full-alt4 .card-header .tools .icon:hover, .card-full-alt4 .card-header .tools > a:hover {
  color: #9a9a9a;
}

.card-dark .card-header, .card-full-dark .card-header {
  background-color: #3d3c3c;
  border-color: transparent;
}

.card-dark .card-header .tools .icon:hover, .card-dark .card-header .tools > a:hover, .card-full-dark .card-header .tools .icon:hover, .card-full-dark .card-header .tools > a:hover {
  color: #1a1919;
}

.card-full-primary {
  background-color: #ef6262;
  color: #FFFFFF;
}

.card-full-primary .card-header {
  border-color: #e55858;
}

.card-full-success {
  background-color: #7ACCBE;
  color: #FFFFFF;
}

.card-full-success .card-header {
  border-color: #70c2b4;
}

.card-full-info {
  background-color: #8DCADF;
  color: #FFFFFF;
}

.card-full-info .card-header {
  border-color: #83c0d5;
}

.card-full-warning {
  background-color: #FFC870;
  color: #FFFFFF;
}

.card-full-warning .card-header {
  border-color: #f5be66;
}

.card-full-danger {
  background-color: #EF6262;
  color: #FFFFFF;
}

.card-full-danger .card-header {
  border-color: #e55858;
}

.card-full-alt1 {
  background-color: #56c3e7;
  color: #FFFFFF;
}

.card-full-alt1 .card-header {
  border-color: #4cb9dd;
}

.card-full-alt2 {
  background-color: #ffcf66;
  color: #FFFFFF;
}

.card-full-alt2 .card-header {
  border-color: #f5c55c;
}

.card-full-alt3 {
  background-color: #7a98bf;
  color: #FFFFFF;
}

.card-full-alt3 .card-header {
  border-color: #708eb5;
}

.card-full-alt4 {
  background-color: #bdbdbd;
  color: #FFFFFF;
}

.card-full-alt4 .card-header {
  border-color: #b3b3b3;
}

.card-full-dark {
  background-color: #3d3c3c;
  color: #FFFFFF;
}

.card-full-dark .card-header {
  border-color: #333232;
}

.card-divider {
  margin: .7692rem 0;
  border-top: 1px solid #f2f2f2;
}

/*------------------------------------------------------------------
  [Code]
*/
/* Pretty printing styles. Used with prettify.js. */
.prettyprint {
  font-size: 0.9231rem;
  line-height: 1.5;
}

/* Light Theme*/
pre {
  color: #555555;
}

pre .pln {
  color: #777777;
}

pre .str {
  color: #DD1144;
}

pre .kwd {
  color: #445588;
}

pre .com {
  color: #b8b6b1;
}

pre .typ {
  color: #008080;
}

pre .lit {
  color: #DD1144;
}

pre .pun,
pre .opn,
pre .clo {
  color: #949494;
}

pre .tag {
  color: #698bb7;
}

pre .atn {
  color: #f17979;
}

pre .atv {
  color: #929292;
}

pre .dec,
pre .var {
  color: #DD1144;
}

pre .fun {
  color: #445588;
}

/*Dark Theme*/
pre.am-code-dark {
  background-color: #4d4342;
  color: #FFFFFF;
}

pre.am-code-dark .pln {
  color: #ffffff;
}

pre.am-code-dark .str {
  color: #b5db99;
}

pre.am-code-dark .kwd {
  color: #e9c865;
}

pre.am-code-dark .com {
  color: #c3b8b7;
}

pre.am-code-dark .typ {
  color: #FF9787;
}

pre.am-code-dark .lit {
  color: #b5db99;
}

pre.am-code-dark .pun,
pre.am-code-dark .opn,
pre.am-code-dark .clo {
  color: #ffffff;
}

pre.am-code-dark .tag {
  color: #e9c865;
}

pre.am-code-dark .atn {
  color: #FF9787;
}

pre.am-code-dark .atv {
  color: #b5db99;
}

pre.am-code-dark .dec,
pre.am-code-dark .var {
  color: #b5db99;
}

pre.am-code-dark .fun {
  color: #e9c865;
}

/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
}

/* IE indents via margin-left */
li.L0,
li.L1,
li.L2,
li.L3,
li.L5,
li.L6,
li.L7,
li.L8 {
  list-style-type: none;
}

/* Alternate shading for lines */
li.L1,
li.L3,
li.L5,
li.L7,
li.L9 {
  background: #eee;
}

/*------------------------------------------------------------------
  [Color swatch]
*/
.color-swatch {
  margin-top: 20px;
  display: inline-block;
  min-width: 230px;
  overflow: hidden;
  border-radius: 3px;
}

.color-swatch .colors {
  margin: 0;
}

.color-swatch .colors > div {
  min-height: 90px;
}

.color-swatch .desc {
  margin: 0;
}

.color-swatch .desc > div {
  text-align: center;
  background-color: #FFFFFF;
  line-height: 50px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: #686868;
}

/*------------------------------------------------------------------
  [Colors]
*/
.clr-primary {
  background-color: #ef6262;
}

.clr-success {
  background-color: #7ACCBE;
}

.clr-info {
  background-color: #8DCADF;
}

.clr-warning {
  background-color: #FFC870;
}

.clr-danger {
  background-color: #EF6262;
}

.clr-alt1 {
  background-color: #95d9f0;
}

.clr-alt2 {
  background-color: #ffdc7a;
}

.clr-alt3 {
  background-color: #7a98bf;
}

.clr-alt4 {
  background-color: #cccccc;
}

.clr-btn-secondary {
  background-color: #FFFFFF;
}

.clr-btn-secondary-hover {
  background-color: #f7f7f7;
}

.clr-btn-secondary-active {
  background-color: #ededed;
}

.clr-btn-primary {
  background-color: #ef6262;
}

.clr-btn-primary-hover {
  background-color: #f17979;
}

.clr-btn-primary-active {
  background-color: #ec4b4b;
}

.clr-btn-dark {
  background-color: #3d3c3c;
}

.clr-btn-dark-hover {
  background-color: #4a4949;
}

.clr-btn-dark-active {
  background-color: #302f2f;
}

.clr-btn-info {
  background-color: #8DCADF;
}

.clr-btn-info-hover {
  background-color: #a1d3e5;
}

.clr-btn-info-active {
  background-color: #79c1d9;
}

.clr-btn-warning {
  background-color: #FFC870;
}

.clr-btn-warning-hover {
  background-color: #ffd28a;
}

.clr-btn-warning-active {
  background-color: #ffbe57;
}

.clr-btn-danger {
  background-color: #EF6262;
}

.clr-btn-danger-hover {
  background-color: #f17979;
}

.clr-btn-danger-active {
  background-color: #ed4b4b;
}

.clr-btn-success {
  background-color: #7ACCBE;
}

.clr-btn-success-hover {
  background-color: #8cd3c7;
}

.clr-btn-success-active {
  background-color: #68c5b5;
}

.clr-btn-alt1 {
  background-color: #56c3e7;
}

.clr-btn-alt1-hover {
  background-color: #6dcbea;
}

.clr-btn-alt1-active {
  background-color: #40bbe4;
}

.clr-btn-alt2 {
  background-color: #ffcf66;
}

.clr-btn-alt2-hover {
  background-color: #ffd77f;
}

.clr-btn-alt2-active {
  background-color: #ffc74c;
}

.clr-btn-alt3 {
  background-color: #7a98bf;
}

.clr-btn-alt3-hover {
  background-color: #8ba5c7;
}

.clr-btn-alt3-active {
  background-color: #698bb7;
}

.clr-btn-alt4 {
  background-color: #bdbdbd;
}

.clr-btn-alt4-hover {
  background-color: #c9c9c9;
}

.clr-btn-alt4-active {
  background-color: #b0b0b0;
}

/*------------------------------------------------------------------
  [Grid]
*/
.bs-grid-block {
  border: 2px dashed #c5c5c5;
  font-weight: 300;
  font-size: 1.538rem;
  color: #807e7e;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 3.077rem;
}

.bs-grid-block .content {
  display: table-cell;
  height: 177px;
  text-align: center;
  vertical-align: middle;
}

.bs-grid-block .content .size {
  display: block;
}

.bs-grid-block .content .device {
  line-height: 0;
}

.bs-grid-block .content .device:after {
  font-size: 1.154rem;
  content: 'Large Desktop';
  color: #ef6262;
}

@media (max-width: 1199.98px) {
  .bs-grid-block .content .device:after {
    content: 'Desktop';
    color: #ef6262;
  }
}

@media (max-width: 991.98px) {
  .bs-grid-block .content .device:after {
    content: 'Laptop';
    color: #ef6262;
  }
}

@media (max-width: 767.98px) {
  .bs-grid-block .content .device:after {
    content: 'Tablet';
    color: #7a98bf;
  }
}

@media (max-width: 575.98px) {
  .bs-grid-block .content .device:after {
    content: 'Phone';
    color: #7ACCBE;
  }
}

/*------------------------------------------------------------------
  [Icons]
*/
.icon-container {
  background-color: #FFFFFF;
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.icon-container .icon {
  background-color: whitesmoke;
  border-radius: 50%;
  display: inline-block;
  height: 95px;
  width: 95px;
  margin-bottom: 15px;
  padding: 20px;
  text-align: center;
}

.icon-container .icon span {
  font-size: 55px;
  color: #646262;
}

.icon-container .class {
  display: block;
  font-size: 14px;
  font-weight: 300;
}

/*------------------------------------------------------------------
  [Pricing tables]
*/
.pricing-tables {
  margin: 30px 0 20px;
}

.pricing-tables > div {
  padding-left: 10px;
  padding-right: 10px;
}

.pricing-table {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0;
  border-top: 3px solid #c9c9c9;
  padding: 35px 20px;
  background: #FFFFFF;
  text-align: center;
  margin: 0 0 20px;
}

.pricing-table-title {
  font-size: 1.231rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.pricing-table-price {
  padding: 0 0 20px;
  color: #525252;
}

.pricing-table-price .currency {
  vertical-align: top;
  font-size: 1.385rem;
  line-height: 1;
}

.pricing-table-price .value {
  font-size: 60px;
  line-height: 40px;
  letter-spacing: -3px;
}

.pricing-table-price .frecuency {
  font-size: 20px;
  font-weight: 300;
  margin-left: 5px;
}

.pricing-table-description {
  color: #858585;
  margin-bottom: 10px;
}

.pricing-table-features {
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
}

.pricing-table-features > li {
  font-size: 1.077rem;
  line-height: 25px;
  font-weight: 300;
  line-height: 33px;
}

.pricing-table .btn {
  line-height: 23px;
  min-width: 120px;
  margin-bottom: 10px;
}

.pricing-table .card-divider {
  margin: 0;
  margin-top: 40px;
  padding-bottom: 30px;
}

.pricing-table-primary {
  border-color: #ef6262;
}

.pricing-table-warning {
  border-color: #FFC870;
}

.pricing-table-success {
  border-color: #7ACCBE;
}

.pricing-table-danger {
  border-color: #EF6262;
}

.pricing-table-alt1 {
  border-color: #95d9f0;
}

.pricing-table-alt2 {
  border-color: #ffdc7a;
}

.pricing-table-alt3 {
  border-color: #7a98bf;
}

.pricing-table-alt4 {
  border-color: #cccccc;
}

/*------------------------------------------------------------------
  [Timeline]
*/
.timeline {
  margin: 0 auto 40px;
  padding: 0;
  padding-bottom: 20px;
  max-width: 840px;
  position: relative;
}

@media (max-width: 767px) {
  .timeline {
    padding: 0;
  }
}

.timeline:before {
  content: '';
  position: absolute;
  top: 40px;
  bottom: 0;
  width: 2px;
  background: #dbdbdb;
  left: 170px;
  margin-left: -1px;
}

.rtl .timeline:before {
  left: auto;
  right: 170px;
}

@media (max-width: 767px) {
  .timeline:before {
    left: 50%;
    margin-left: -1px;
    top: -35px;
  }
}

.timeline-item {
  position: relative;
  padding-left: 210px;
  list-style: none;
  margin-bottom: 30px;
}

.rtl .timeline-item {
  padding-left: 0;
  padding-right: 210px;
}

@media (max-width: 767px) {
  .timeline-item {
    padding-left: 0;
    margin-top: 60px;
    margin-bottom: 0;
  }
}

.timeline-item:before {
  content: '';
  position: absolute;
  top: 34.5px;
  left: 162px;
  border: 2px solid #cccccc;
  width: 16px;
  height: 16px;
  background-color: #ededed;
  border-radius: 50px;
}

.rtl .timeline-item:before {
  left: auto;
  right: 162px;
}

@media (max-width: 767px) {
  .timeline-item:before {
    display: none;
  }
}

.timeline-item:nth-child(4n+1):before {
  border-color: #ef6262;
}

.timeline-item:nth-child(4n+2):before {
  border-color: #8DCADF;
}

.timeline-item:nth-child(4n+3):before {
  border-color: #FFC870;
}

.timeline-item:nth-child(4n+4):before {
  border-color: #7ACCBE;
}

.timeline-variant .timeline-item {
  padding-left: 0;
  margin: 30px 0 0;
}

@media (max-width: 767px) {
  .timeline-variant .timeline-item {
    margin-top: 60px;
  }
}

.timeline-variant .timeline-item.right {
  right: 0;
  padding-left: calc(50% + 45px);
}

@media (max-width: 767px) {
  .timeline-variant .timeline-item.right {
    padding: 0;
  }
}

.timeline-variant .timeline-item.left {
  left: 0;
  padding-right: calc(50% + 45px);
}

@media (max-width: 767px) {
  .timeline-variant .timeline-item.left {
    padding: 0;
  }
}

.timeline-variant .timeline-item.left .timeline-content:after {
  right: -5px;
  left: inherit;
}

.timeline-variant .timeline-item.left .timeline-content:before {
  right: -5px;
  left: inherit;
}

.timeline-variant .timeline-item.left .timeline-icon {
  left: initial;
  right: -67px;
}

@media (max-width: 767px) {
  .timeline-variant .timeline-item.left .timeline-icon {
    left: 50%;
    margin-left: -18px;
    top: -48px;
  }
}

.timeline-variant .timeline-item:before {
  display: none;
}

.timeline-date {
  position: absolute;
  display: block;
  left: 0;
  top: 34px;
  width: 145px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rtl .timeline-date {
  left: auto;
  right: 0;
}

@media (max-width: 767px) {
  .timeline-date {
    width: 100%;
    left: 0;
    top: -40px;
    text-align: center;
  }
  .timeline-date span {
    background-color: #e0e0e0;
    padding: 5px 8px;
    border-radius: 4px;
    line-height: 25px;
  }
}

.timeline-content {
  position: relative;
  background-color: #FFFFFF;
  padding: 25px 30px 25px 28px;
  border-radius: 3px;
  min-height: 85px;
}

.timeline-item-gallery .timeline-content {
  padding-bottom: 15px;
}

@media (max-width: 480px) {
  .timeline-content {
    padding: 15px 18px;
  }
}

.timeline-content:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  transform: rotate(45deg);
  top: 37.5px;
  left: -5px;
}

.rtl .timeline-content:before {
  left: auto;
  right: -5px;
}

@media (max-width: 767px) {
  .timeline-content:before {
    display: none;
  }
}

.timeline-avatar {
  width: 35px;
  height: 35px;
  display: block;
  border-radius: 50px;
  overflow: hidden;
  vertical-align: middle;
  float: left;
}

.rtl .timeline-avatar {
  float: right;
}

.timeline-avatar img {
  width: 100%;
  height: 100%;
}

.timeline-header {
  display: block;
  padding: 7px 0 0 46px;
}

.rtl .timeline-header {
  padding: 7px 46px 0 0;
}

@media (max-width: 767px) {
  .timeline-header {
    padding-top: 0;
  }
}

.timeline-variant .timeline-header {
  padding-top: 0;
  line-height: 16px;
}

.timeline-autor {
  display: inline-block;
  font-weight: 700;
}

.timeline-activity {
  display: inline-block;
  margin: 0;
}

.timeline-variant .timeline-activity {
  display: inline;
  line-height: 16px;
}

.timeline-time {
  float: right;
  color: #ababab;
}

.rtl .timeline-time {
  float: left;
}

.timeline-variant .timeline-time {
  float: none;
  display: block;
  line-height: 21px;
}

.timeline-summary {
  color: #8a8a8a;
  margin-top: 4px;
}

.timeline-summary p {
  margin-bottom: 0;
}

.timeline-variant .timeline-summary {
  margin-top: 20px;
}

.timeline-variant .timeline-summary p {
  line-height: 16px;
}

.timeline-gallery {
  margin-top: 12px;
}

.timeline-variant .timeline-gallery {
  margin-top: 20px;
}

.timeline-gallery .gallery-thumbnail {
  display: inline-block;
  max-width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.rtl .timeline-gallery .gallery-thumbnail {
  margin-right: 0;
  margin-left: 10px;
}

.timeline-blockquote {
  margin: 10px 0 0 5px;
  padding: 4px 10px;
  border-left: 4px solid #e0e0e0;
  color: #8a8a8a;
}

.rtl .timeline-blockquote {
  margin: 10px 5px 0 0;
}

.timeline-variant .timeline-blockquote {
  margin-top: 20px;
}

.timeline-blockquote p {
  font-size: 1rem;
}

.timeline-blockquote footer {
  color: #8a8a8a;
}

/*Load more button*/
@media (max-width: 767px) {
  .timeline-loadmore {
    text-align: center;
    padding: 0;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

.timeline-loadmore .load-more-btn {
  background-color: #dbdbdb;
  border-radius: 2px;
  padding: 5px 9px;
  color: #424242;
  outline: none;
  font-size: 0.92308rem;
  font-weight: 500;
  position: absolute;
  left: 131px;
}

.rtl .timeline-loadmore .load-more-btn {
  left: auto;
  right: 131px;
}

@media (max-width: 767px) {
  .timeline-loadmore .load-more-btn {
    position: relative;
    left: 0;
  }
}

.timeline-loadmore:before {
  display: none;
}

.timeline-variant .timeline-loadmore {
  text-align: center;
  margin-bottom: 40px;
}

.timeline-variant .timeline-loadmore .load-more-btn {
  position: relative;
  left: initial;
}

/*------------------------------------------------------------------
  [Detailed items content like gallery & blockquote]
*/
.timeline-item-detailed .timeline-time {
  line-height: 21px;
}

.timeline-item-detailed .timeline-header {
  line-height: 21px;
}

.timeline-variant .timeline-item-detailed .timeline-header {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
}

.timeline-item-detailed .timeline-avatar {
  vertical-align: top;
}

.timeline-variant {
  max-width: none;
  padding-bottom: 0;
}

.timeline-variant:before {
  left: 50%;
  z-index: -1;
  top: 70px;
}

.rtl .timeline-variant:before {
  left: 50%;
  right: auto;
}

.rtl .timeline-variant .timeline-content:before {
  left: -5px;
  right: auto;
}

.rtl .timeline-variant .timeline-loadmore {
  padding-right: 0;
}

.rtl .timeline-variant .timeline-loadmore .load-more-btn {
  right: auto;
}

.timeline-month {
  width: 100%;
  text-align: center;
  list-style: none;
  margin: 30px 0 15px;
  padding: 11px 0 12px;
  font-size: 1.385rem;
  background-color: #f0f0f0;
  font-weight: 300;
}

.timeline-month:first-child {
  margin-top: 0;
  padding-bottom: 5px;
}

.timeline-icon {
  position: absolute;
  color: #FFFFFF;
  top: 20px;
  left: -67px;
  width: 44px;
  height: 44px;
  font-size: 1.769rem;
  border: 2px solid #FFFFFF;
  text-align: center;
  background-color: #ededed;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .timeline-icon {
    left: 50%;
    margin-left: -18px;
    top: -48px;
    width: 38px;
    height: 38px;
    font-size: 1.385rem;
  }
}

.timeline-icon > .icon {
  line-height: 40px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .timeline-icon > .icon {
    line-height: 34px;
  }
}

.file .timeline-icon {
  background-color: #8DCADF;
}

.comment .timeline-icon {
  background-color: #FFC870;
}

.gallery .timeline-icon {
  background-color: #7ACCBE;
}

.quote .timeline-icon {
  background-color: #ef6262;
}

/*------------------------------------------------------------------
  [Elements]
*/
/*------------------------------------------------------------------
  [Widgets]
*/
.widget {
  background-color: #FFFFFF;
  padding: 20px;
  margin-bottom: 30px;
}

.widget > .widget-head {
  margin-bottom: 20px;
  display: block;
}

.widget > .widget-head .title {
  font-size: 1.231rem;
  font-weight: 300;
}

.widget > .widget-head .tools {
  float: right;
  font-size: 0;
}

.rtl .widget > .widget-head .tools {
  float: left;
}

.widget > .widget-head .tools > .icon, .widget > .widget-head .tools > .dropdown-toggle .icon {
  display: inline-block;
  margin-left: 5px;
  font-size: 1.615rem;
  line-height: 23px;
  vertical-align: middle;
  cursor: pointer;
  color: #555555;
}

.widget > .widget-head .tools > .icon:hover, .widget > .widget-head .tools > .dropdown-toggle .icon:hover {
  color: #ef6262;
}

.widget > .widget-head .tools > .icon.s7-close, .widget > .widget-head .tools > .dropdown-toggle .icon.s7-close {
  margin-left: 4px;
  position: relative;
  top: 1px;
}

.widget > .widget-head .tools .value {
  font-size: 1.846rem;
  line-height: 22px;
}

.widget .chart-container {
  position: relative;
}

.widget .chart-container > .counter {
  min-height: auto;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}

.widget .chart-container > .counter .value {
  font-size: 1.846rem;
}

.widget .chart-container > .counter .desc {
  text-transform: uppercase;
  font-size: 0.8462rem;
  color: #b2b2b2;
}

.widget.widget-fullwidth {
  padding: 0;
}

.widget.widget-fullwidth > .widget-head {
  padding: 20px 20px 10px;
  margin-bottom: 0;
}

.widget.widget-fullwidth .chart-container > .counter {
  min-height: auto;
  left: 20px;
}

.rtl .widget.widget-fullwidth .chart-container > .counter {
  right: 20px;
}

.widget.widget-small {
  margin-bottom: 20px;
}

.widget.widget-small.widget-fullwidth > .widget-head {
  padding: 20px 15px 10px;
}

/*------------------------------------------------------------------
  [Bootstrap elements]
*/
/*------------------------------------------------------------------
  [Alerts]
*/
.alert {
  line-height: 25px;
}

.alert .icon {
  font-size: 24px;
  color: #FFFFFF;
  vertical-align: middle;
  margin-right: 9px;
  line-height: 22px;
}

.rtl .alert .icon {
  float: right;
  margin-left: 9px;
  margin-right: 0;
}

.alert .close {
  opacity: .2;
}

.alert .close:hover, .alert .close:focus {
  opacity: .5;
}

.alert .close:hover:not(:disabled):not(.disabled), .alert .close:focus:not(:disabled):not(.disabled) {
  opacity: .5;
}

.alert .message > p,
.alert .message > ul {
  margin-bottom: 0;
}

.alert .message > p + p {
  margin-top: 5px;
}

.rtl .alert .message {
  border-left-width: 1px;
  border-right-width: 0;
}

.rtl .alert-dismissible {
  padding-left: 38px;
  padding-right: inherit;
}

.rtl .alert-dismissible .close {
  left: 0px;
  right: auto;
}

.alert-primary {
  color: #FFFFFF;
  background-color: #ef6262;
  border-color: #ef6262;
}

.alert-primary hr {
  border-top-color: #ec4b4b;
}

.alert-primary .alert-link {
  color: #e6e6e6;
}

.alert-success {
  color: #FFFFFF;
  background-color: #7ACCBE;
  border-color: #7ACCBE;
}

.alert-success hr {
  border-top-color: #68c5b5;
}

.alert-success .alert-link {
  color: #e6e6e6;
}

.alert-info {
  color: #FFFFFF;
  background-color: #8DCADF;
  border-color: #8DCADF;
}

.alert-info hr {
  border-top-color: #79c1d9;
}

.alert-info .alert-link {
  color: #e6e6e6;
}

.alert-warning {
  color: #FFFFFF;
  background-color: #FFC870;
  border-color: #FFC870;
}

.alert-warning hr {
  border-top-color: #ffbe57;
}

.alert-warning .alert-link {
  color: #e6e6e6;
}

.alert-danger {
  color: #FFFFFF;
  background-color: #EF6262;
  border-color: #EF6262;
}

.alert-danger hr {
  border-top-color: #ed4b4b;
}

.alert-danger .alert-link {
  color: #e6e6e6;
}

.alert-dark {
  color: #FFFFFF;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.alert-dark hr {
  border-top-color: #302f2f;
}

.alert-dark .alert-link {
  color: #e6e6e6;
}

.alert-alt1 {
  color: #FFFFFF;
  background-color: #95d9f0;
  border-color: #95d9f0;
}

.alert-alt1 hr {
  border-top-color: #7fd1ed;
}

.alert-alt1 .alert-link {
  color: #e6e6e6;
}

.alert-alt2 {
  color: #FFFFFF;
  background-color: #ffdc7a;
  border-color: #ffdc7a;
}

.alert-alt2 hr {
  border-top-color: #ffd561;
}

.alert-alt2 .alert-link {
  color: #e6e6e6;
}

.alert-alt3 {
  color: #FFFFFF;
  background-color: #7a98bf;
  border-color: #7a98bf;
}

.alert-alt3 hr {
  border-top-color: #698bb7;
}

.alert-alt3 .alert-link {
  color: #e6e6e6;
}

.alert-alt4 {
  color: #FFFFFF;
  background-color: #cccccc;
  border-color: #cccccc;
}

.alert-alt4 hr {
  border-top-color: #bfbfbf;
}

.alert-alt4 .alert-link {
  color: #e6e6e6;
}

.alert-dismissible.alert-primary .close, .alert-dismissible.alert-primary .close:hover, .alert-dismissible.alert-primary .close:focus {
  color: #a71111;
  text-shadow: 0 1px 0 #a71111;
}

.alert-dismissible.alert-success .close, .alert-dismissible.alert-success .close:hover, .alert-dismissible.alert-success .close:focus {
  color: #307d70;
  text-shadow: 0 1px 0 #307d70;
}

.alert-dismissible.alert-info .close, .alert-dismissible.alert-info .close:hover, .alert-dismissible.alert-info .close:focus {
  color: #2e86a5;
  text-shadow: 0 1px 0 #2e86a5;
}

.alert-dismissible.alert-warning .close, .alert-dismissible.alert-warning .close:hover, .alert-dismissible.alert-warning .close:focus {
  color: #d68400;
  text-shadow: 0 1px 0 #d68400;
}

.alert-dismissible.alert-danger .close, .alert-dismissible.alert-danger .close:hover, .alert-dismissible.alert-danger .close:focus {
  color: #a71111;
  text-shadow: 0 1px 0 #a71111;
}

.alert-dismissible.alert-dark .close, .alert-dismissible.alert-dark .close:hover, .alert-dismissible.alert-dark .close:focus {
  color: black;
  text-shadow: 0 1px 0 black;
}

.alert-dismissible.alert-alt1 .close, .alert-dismissible.alert-alt1 .close:hover, .alert-dismissible.alert-alt1 .close:focus {
  color: #1da2cf;
  text-shadow: 0 1px 0 #1da2cf;
}

.alert-dismissible.alert-alt2 .close, .alert-dismissible.alert-alt2 .close:hover, .alert-dismissible.alert-alt2 .close:focus {
  color: #e0a500;
  text-shadow: 0 1px 0 #e0a500;
}

.alert-dismissible.alert-alt3 .close, .alert-dismissible.alert-alt3 .close:hover, .alert-dismissible.alert-alt3 .close:focus {
  color: #344c6c;
  text-shadow: 0 1px 0 #344c6c;
}

.alert-dismissible.alert-alt4 .close, .alert-dismissible.alert-alt4 .close:hover, .alert-dismissible.alert-alt4 .close:focus {
  color: gray;
  text-shadow: 0 1px 0 gray;
}

.alert-icon {
  background-color: #FFFFFF;
  border: 0;
  padding: 0;
  position: relative;
  width: 100%;
  display: table;
  table-layout: fixed;
}

.rtl .alert-icon {
  padding: 0;
}

.alert-icon > .icon {
  position: relative;
  display: table-cell;
  width: 55px;
  text-align: center;
  vertical-align: middle;
  cursor: default;
}

.rtl .alert-icon > .icon {
  float: none;
}

.alert-icon > .icon:after {
  position: absolute;
  right: -10px;
  top: 50%;
  margin-top: -11px;
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-left: 11px solid #FFFFFF;
  -moz-transform: scale(0.9999);
  border-bottom: 11px solid transparent;
  border-top: 11px solid transparent;
}

.rtl .alert-icon > .icon:after {
  margin-left: 0;
  right: 54px;
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-right: 11px solid #FFFFFF;
  -moz-transform: scale(0.9999);
  border-bottom: 11px solid transparent;
  border-top: 11px solid transparent;
  border-left: 0;
}

.alert-icon > .message {
  display: table-cell;
  padding: 1.385rem;
  color: #555555;
  border: 1px solid #d4d4d4;
  border-left-width: 0;
}

.alert-icon.alert-dismissible .close, .alert-icon.alert-dismissible .close:hover, .alert-icon.alert-dismissible .close:focus {
  position: absolute;
  font-size: 1.538rem;
  right: 1.269rem;
  color: #555555;
  text-shadow: 0 1px 0 #555555;
  padding: 1.5388rem 0;
}

.rtl .alert-icon.alert-dismissible .close, .rtl .alert-icon.alert-dismissible .close:hover, .rtl .alert-icon.alert-dismissible .close:focus {
  left: 17px;
  right: auto;
}

.alert-icon.alert-primary > .icon {
  background-color: #ef6262;
}

.alert-icon.alert-primary > .icon:after {
  border-left-color: #ef6262;
}

.rtl .alert-icon.alert-primary > .icon:after {
  border-right-color: #ef6262;
}

.alert-icon.alert-success > .icon {
  background-color: #7ACCBE;
}

.alert-icon.alert-success > .icon:after {
  border-left-color: #7ACCBE;
}

.rtl .alert-icon.alert-success > .icon:after {
  border-right-color: #7ACCBE;
}

.alert-icon.alert-info > .icon {
  background-color: #8DCADF;
}

.alert-icon.alert-info > .icon:after {
  border-left-color: #8DCADF;
}

.rtl .alert-icon.alert-info > .icon:after {
  border-right-color: #8DCADF;
}

.alert-icon.alert-warning > .icon {
  background-color: #FFC870;
}

.alert-icon.alert-warning > .icon:after {
  border-left-color: #FFC870;
}

.rtl .alert-icon.alert-warning > .icon:after {
  border-right-color: #FFC870;
}

.alert-icon.alert-danger > .icon {
  background-color: #EF6262;
}

.alert-icon.alert-danger > .icon:after {
  border-left-color: #EF6262;
}

.rtl .alert-icon.alert-danger > .icon:after {
  border-right-color: #EF6262;
}

.alert-icon.alert-dark > .icon {
  background-color: #3d3c3c;
}

.alert-icon.alert-dark > .icon:after {
  border-left-color: #3d3c3c;
}

.rtl .alert-icon.alert-dark > .icon:after {
  border-right-color: #3d3c3c;
}

.alert-icon.alert-alt1 > .icon {
  background-color: #95d9f0;
}

.alert-icon.alert-alt1 > .icon:after {
  border-left-color: #95d9f0;
}

.rtl .alert-icon.alert-alt1 > .icon:after {
  border-right-color: #95d9f0;
}

.alert-icon.alert-alt2 > .icon {
  background-color: #ffdc7a;
}

.alert-icon.alert-alt2 > .icon:after {
  border-left-color: #ffdc7a;
}

.rtl .alert-icon.alert-alt2 > .icon:after {
  border-right-color: #ffdc7a;
}

.alert-icon.alert-alt3 > .icon {
  background-color: #7a98bf;
}

.alert-icon.alert-alt3 > .icon:after {
  border-left-color: #7a98bf;
}

.rtl .alert-icon.alert-alt3 > .icon:after {
  border-right-color: #7a98bf;
}

.alert-icon.alert-alt4 > .icon {
  background-color: #cccccc;
}

.alert-icon.alert-alt4 > .icon:after {
  border-left-color: #cccccc;
}

.rtl .alert-icon.alert-alt4 > .icon:after {
  border-right-color: #cccccc;
}

.alert-border-color.alert-primary .message {
  border-color: #ef6262;
}

.alert-border-color.alert-success .message {
  border-color: #7ACCBE;
}

.alert-border-color.alert-info .message {
  border-color: #8DCADF;
}

.alert-border-color.alert-warning .message {
  border-color: #FFC870;
}

.alert-border-color.alert-danger .message {
  border-color: #EF6262;
}

.alert-border-color.alert-dark .message {
  border-color: #3d3c3c;
}

.alert-border-color.alert-alt1 .message {
  border-color: #95d9f0;
}

.alert-border-color.alert-alt2 .message {
  border-color: #ffdc7a;
}

.alert-border-color.alert-alt3 .message {
  border-color: #7a98bf;
}

.alert-border-color.alert-alt4 .message {
  border-color: #cccccc;
}

.alert-icon-color {
  border: 1px solid #e6e6e6;
}

.alert-icon-color > .icon {
  background-color: #f8f8f8 !important;
  border-width: 0;
}

.alert-icon-color > .icon:after {
  border-left-color: #FFFFFF !important;
  display: none;
}

.rtl .alert-icon-color > .icon:after {
  border-right-color: #FFFFFF;
  display: none;
}

.alert-icon-color > .message {
  border-width: 0;
}

.rtl .alert-icon-color > .message {
  border-width: 0;
}

.alert-icon-color.alert-primary > .icon {
  background-color: transparent;
  color: #ef6262;
}

.alert-icon-color.alert-success > .icon {
  background-color: transparent;
  color: #7ACCBE;
}

.alert-icon-color.alert-info > .icon {
  background-color: transparent;
  color: #8DCADF;
}

.alert-icon-color.alert-warning > .icon {
  background-color: transparent;
  color: #FFC870;
}

.alert-icon-color.alert-danger > .icon {
  background-color: transparent;
  color: #EF6262;
}

.alert-icon-color.alert-dark > .icon {
  background-color: transparent;
  color: #3d3c3c;
}

.alert-icon-color.alert-alt1 > .icon {
  background-color: transparent;
  color: #95d9f0;
}

.alert-icon-color.alert-alt2 > .icon {
  background-color: transparent;
  color: #ffdc7a;
}

.alert-icon-color.alert-alt3 > .icon {
  background-color: transparent;
  color: #7a98bf;
}

.alert-icon-color.alert-alt4 > .icon {
  background-color: transparent;
  color: #cccccc;
}

/*------------------------------------------------------------------
  [Badges]
*/
.badge:empty {
  display: none;
}

.badge-pill {
  font-size: 0.8462rem;
  min-width: 0.7692rem;
  padding-top: 0.2308rem;
  padding-bottom: 0.2308rem;
}

.badge-primary {
  background-color: #ef6262;
  color: #FFFFFF;
  border-color: #ef6262;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  background-color: #ea3434;
}

.badge-success {
  background-color: #7ACCBE;
  color: #FFFFFF;
  border-color: #7ACCBE;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  background-color: #55beac;
}

.badge-info {
  background-color: #8DCADF;
  color: #FFFFFF;
  border-color: #8DCADF;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  background-color: #65b7d4;
}

.badge-warning {
  background-color: #FFC870;
  color: #FFFFFF;
  border-color: #FFC870;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  background-color: #ffb43d;
}

.badge-danger {
  background-color: #EF6262;
  color: #FFFFFF;
  border-color: #EF6262;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  background-color: #ea3434;
}

.badge-dark {
  background-color: #3d3c3c;
  color: #FFFFFF;
  border-color: #3d3c3c;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  background-color: #232323;
}

.badge-alt1 {
  background-color: #95d9f0;
  color: #FFFFFF;
  border-color: #95d9f0;
}

.badge-alt1[href]:hover, .badge-alt1[href]:focus {
  background-color: #68c9ea;
}

.badge-alt2 {
  background-color: #ffdc7a;
  color: #FFFFFF;
  border-color: #ffdc7a;
}

.badge-alt2[href]:hover, .badge-alt2[href]:focus {
  background-color: #ffcf47;
}

.badge-alt3 {
  background-color: #7a98bf;
  color: #FFFFFF;
  border-color: #7a98bf;
}

.badge-alt3[href]:hover, .badge-alt3[href]:focus {
  background-color: #587dae;
}

.badge-alt4 {
  background-color: #cccccc;
  color: #FFFFFF;
  border-color: #cccccc;
}

.badge-alt4[href]:hover, .badge-alt4[href]:focus {
  background-color: #b3b3b3;
}

.breadcrumb-item .icon {
  font-size: 1.4rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.breadcrumb-item + .breadcrumb-item:before {
  position: relative;
  top: -.1538rem;
  line-height: .4;
  vertical-align: middle;
  padding-right: 0.6154rem;
  padding-left: 0.6154rem;
  color: #cccccc;
}

/*------------------------------------------------------------------
  [Button groups]
*/
.btn + .dropdown-toggle-split {
  padding-left: 0.6154rem;
  padding-right: 0.6154rem;
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.btn-group-justified .btn,
.btn-group-justified .btn-group {
  float: none;
  display: table-cell;
  width: 1%;
}

.btn-group-justified .btn .btn,
.btn-group-justified .btn-group .btn {
  width: 100%;
}

.btn-group-justified .btn .dropdown-menu,
.btn-group-justified .btn-group .dropdown-menu {
  left: auto;
}

.btn-group-justified > .btn {
  border-right-width: 0;
}

.btn-group-justified > .btn:last-child {
  border-right-width: 1px;
}

/*------------------------------------------------------------------
  [Buttons]
*/
.btn {
  border-radius: 0;
}

.btn:active, .btn.active {
  box-shadow: none;
}

.btn.dropdown-toggle:after {
  margin-left: 0;
  vertical-align: middle;
}

.btn-hspace {
  margin-right: 5px;
}

.rtl .btn-hspace {
  margin-right: 0;
  margin-left: 5px;
}

.btn-vspace {
  margin-bottom: 5px;
}

.btn-space {
  margin-right: 5px;
  margin-bottom: 5px;
}

.rtl .btn-space {
  margin-right: 0;
  margin-left: 5px;
}

.btn-secondary, .btn-light {
  color: #555555;
  background-color: #FFFFFF;
  border-color: #dedede;
}

.btn-secondary:focus, .btn-secondary.focus, .btn-light:focus, .btn-light.focus {
  color: #555555;
  background-color: #FFFFFF;
  border-color: #dedede;
}

.btn-secondary:hover, .btn-secondary.hover, .btn-light:hover, .btn-light.hover {
  color: #555555;
  background-color: #f7f7f7;
  border-color: #d1d1d1;
}

.btn-secondary:active, .btn-secondary.active, .btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle, .btn-light:active, .btn-light.active, .btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #555555;
  background-color: #ededed;
  border-color: #c5c5c5;
}

.btn-secondary:active:hover, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:hover, .btn-secondary.active:focus, .btn-secondary.active.focus, .btn-secondary:not([disabled]):not(.disabled):active:hover, .btn-secondary:not([disabled]):not(.disabled):active:focus, .btn-secondary:not([disabled]):not(.disabled):active.focus, .btn-secondary:not([disabled]):not(.disabled).active:hover, .btn-secondary:not([disabled]):not(.disabled).active:focus, .btn-secondary:not([disabled]):not(.disabled).active.focus,
.show > .btn-secondary.dropdown-toggle:hover,
.show > .btn-secondary.dropdown-toggle:focus,
.show > .btn-secondary.dropdown-toggle.focus, .btn-light:active:hover, .btn-light:active:focus, .btn-light:active.focus, .btn-light.active:hover, .btn-light.active:focus, .btn-light.active.focus, .btn-light:not([disabled]):not(.disabled):active:hover, .btn-light:not([disabled]):not(.disabled):active:focus, .btn-light:not([disabled]):not(.disabled):active.focus, .btn-light:not([disabled]):not(.disabled).active:hover, .btn-light:not([disabled]):not(.disabled).active:focus, .btn-light:not([disabled]):not(.disabled).active.focus,
.show > .btn-light.dropdown-toggle:hover,
.show > .btn-light.dropdown-toggle:focus,
.show > .btn-light.dropdown-toggle.focus {
  color: #555555;
  background-color: #ededed;
  border-color: #c5c5c5;
}

.btn-secondary:active, .btn-secondary.active,
.show > .dropdown-toggle .btn-secondary, .btn-light:active, .btn-light.active,
.show > .dropdown-toggle .btn-light {
  background-image: none;
}

.btn-secondary.disabled, .btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary.disabled:active, .btn-secondary.disabled.active, .btn-secondary[disabled], .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled].focus, .btn-secondary[disabled]:active, .btn-secondary[disabled].active,
fieldset[disabled] .btn-secondary,
fieldset[disabled] .btn-secondary:hover,
fieldset[disabled] .btn-secondary:focus,
fieldset[disabled] .btn-secondary.focus,
fieldset[disabled] .btn-secondary:active,
fieldset[disabled] .btn-secondary.active, .btn-light.disabled, .btn-light.disabled:hover, .btn-light.disabled:focus, .btn-light.disabled.focus, .btn-light.disabled:active, .btn-light.disabled.active, .btn-light[disabled], .btn-light[disabled]:hover, .btn-light[disabled]:focus, .btn-light[disabled].focus, .btn-light[disabled]:active, .btn-light[disabled].active,
fieldset[disabled] .btn-light,
fieldset[disabled] .btn-light:hover,
fieldset[disabled] .btn-light:focus,
fieldset[disabled] .btn-light.focus,
fieldset[disabled] .btn-light:active,
fieldset[disabled] .btn-light.active {
  color: #555555;
  background-color: #FFFFFF;
  border-color: #dedede;
}

.btn-secondary .badge, .btn-light .badge {
  color: #FFFFFF;
  background-color: #555555;
}

.btn-primary {
  color: #FFFFFF;
  background-color: #ef6262;
  border-color: #ef6262;
}

.btn-primary:focus, .btn-primary.focus {
  color: #FFFFFF;
  background-color: #ef6262;
  border-color: #ef6262;
}

.btn-primary:hover, .btn-primary.hover {
  color: #FFFFFF;
  background-color: #f17979;
  border-color: #f17979;
}

.btn-primary:active, .btn-primary.active, .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ec4b4b;
  border-color: #ec4b4b;
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .btn-primary:not([disabled]):not(.disabled):active:hover, .btn-primary:not([disabled]):not(.disabled):active:focus, .btn-primary:not([disabled]):not(.disabled):active.focus, .btn-primary:not([disabled]):not(.disabled).active:hover, .btn-primary:not([disabled]):not(.disabled).active:focus, .btn-primary:not([disabled]):not(.disabled).active.focus,
.show > .btn-primary.dropdown-toggle:hover,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #ec4b4b;
  border-color: #ec4b4b;
}

.btn-primary:active, .btn-primary.active, .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  color: #FFFFFF;
  background-color: #ef6262;
  border-color: #ef6262;
}

.btn-primary .badge {
  color: #ef6262;
  background-color: #FFFFFF;
}

.btn-dark {
  color: #FFFFFF;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-dark:focus, .btn-dark.focus {
  color: #FFFFFF;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-dark:hover, .btn-dark.hover {
  color: #FFFFFF;
  background-color: #4a4949;
  border-color: #4a4949;
}

.btn-dark:active, .btn-dark.active, .btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #302f2f;
  border-color: #302f2f;
}

.btn-dark:active:hover, .btn-dark:active:focus, .btn-dark:active.focus, .btn-dark.active:hover, .btn-dark.active:focus, .btn-dark.active.focus, .btn-dark:not([disabled]):not(.disabled):active:hover, .btn-dark:not([disabled]):not(.disabled):active:focus, .btn-dark:not([disabled]):not(.disabled):active.focus, .btn-dark:not([disabled]):not(.disabled).active:hover, .btn-dark:not([disabled]):not(.disabled).active:focus, .btn-dark:not([disabled]):not(.disabled).active.focus,
.show > .btn-dark.dropdown-toggle:hover,
.show > .btn-dark.dropdown-toggle:focus,
.show > .btn-dark.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #302f2f;
  border-color: #302f2f;
}

.btn-dark:active, .btn-dark.active, .btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  background-image: none;
}

.btn-dark.disabled, .btn-dark.disabled:hover, .btn-dark.disabled:focus, .btn-dark.disabled.focus, .btn-dark.disabled:active, .btn-dark.disabled.active, .btn-dark[disabled], .btn-dark[disabled]:hover, .btn-dark[disabled]:focus, .btn-dark[disabled].focus, .btn-dark[disabled]:active, .btn-dark[disabled].active,
fieldset[disabled] .btn-dark,
fieldset[disabled] .btn-dark:hover,
fieldset[disabled] .btn-dark:focus,
fieldset[disabled] .btn-dark.focus,
fieldset[disabled] .btn-dark:active,
fieldset[disabled] .btn-dark.active {
  color: #FFFFFF;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-dark .badge {
  color: #3d3c3c;
  background-color: #FFFFFF;
}

.btn-success {
  color: #FFFFFF;
  background-color: #7ACCBE;
  border-color: #7ACCBE;
}

.btn-success:focus, .btn-success.focus {
  color: #FFFFFF;
  background-color: #7ACCBE;
  border-color: #7ACCBE;
}

.btn-success:hover, .btn-success.hover {
  color: #FFFFFF;
  background-color: #8cd3c7;
  border-color: #8cd3c7;
}

.btn-success:active, .btn-success.active, .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #FFFFFF;
  background-color: #68c5b5;
  border-color: #68c5b5;
}

.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .btn-success:not([disabled]):not(.disabled):active:hover, .btn-success:not([disabled]):not(.disabled):active:focus, .btn-success:not([disabled]):not(.disabled):active.focus, .btn-success:not([disabled]):not(.disabled).active:hover, .btn-success:not([disabled]):not(.disabled).active:focus, .btn-success:not([disabled]):not(.disabled).active.focus,
.show > .btn-success.dropdown-toggle:hover,
.show > .btn-success.dropdown-toggle:focus,
.show > .btn-success.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #68c5b5;
  border-color: #68c5b5;
}

.btn-success:active, .btn-success.active, .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  background-image: none;
}

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  color: #FFFFFF;
  background-color: #7ACCBE;
  border-color: #7ACCBE;
}

.btn-success .badge {
  color: #7ACCBE;
  background-color: #FFFFFF;
}

.btn-info {
  color: #FFFFFF;
  background-color: #8DCADF;
  border-color: #8DCADF;
}

.btn-info:focus, .btn-info.focus {
  color: #FFFFFF;
  background-color: #8DCADF;
  border-color: #8DCADF;
}

.btn-info:hover, .btn-info.hover {
  color: #FFFFFF;
  background-color: #a1d3e5;
  border-color: #a1d3e5;
}

.btn-info:active, .btn-info.active, .btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #FFFFFF;
  background-color: #79c1d9;
  border-color: #79c1d9;
}

.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus, .btn-info:not([disabled]):not(.disabled):active:hover, .btn-info:not([disabled]):not(.disabled):active:focus, .btn-info:not([disabled]):not(.disabled):active.focus, .btn-info:not([disabled]):not(.disabled).active:hover, .btn-info:not([disabled]):not(.disabled).active:focus, .btn-info:not([disabled]):not(.disabled).active.focus,
.show > .btn-info.dropdown-toggle:hover,
.show > .btn-info.dropdown-toggle:focus,
.show > .btn-info.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #79c1d9;
  border-color: #79c1d9;
}

.btn-info:active, .btn-info.active, .btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  background-image: none;
}

.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  color: #FFFFFF;
  background-color: #8DCADF;
  border-color: #8DCADF;
}

.btn-info .badge {
  color: #8DCADF;
  background-color: #FFFFFF;
}

.btn-warning {
  color: #FFFFFF;
  background-color: #FFC870;
  border-color: #FFC870;
}

.btn-warning:focus, .btn-warning.focus {
  color: #FFFFFF;
  background-color: #FFC870;
  border-color: #FFC870;
}

.btn-warning:hover, .btn-warning.hover {
  color: #FFFFFF;
  background-color: #ffd28a;
  border-color: #ffd28a;
}

.btn-warning:active, .btn-warning.active, .btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ffbe57;
  border-color: #ffbe57;
}

.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus, .btn-warning:not([disabled]):not(.disabled):active:hover, .btn-warning:not([disabled]):not(.disabled):active:focus, .btn-warning:not([disabled]):not(.disabled):active.focus, .btn-warning:not([disabled]):not(.disabled).active:hover, .btn-warning:not([disabled]):not(.disabled).active:focus, .btn-warning:not([disabled]):not(.disabled).active.focus,
.show > .btn-warning.dropdown-toggle:hover,
.show > .btn-warning.dropdown-toggle:focus,
.show > .btn-warning.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #ffbe57;
  border-color: #ffbe57;
}

.btn-warning:active, .btn-warning.active, .btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  background-image: none;
}

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  color: #FFFFFF;
  background-color: #FFC870;
  border-color: #FFC870;
}

.btn-warning .badge {
  color: #FFC870;
  background-color: #FFFFFF;
}

.btn-danger {
  color: #FFFFFF;
  background-color: #EF6262;
  border-color: #EF6262;
}

.btn-danger:focus, .btn-danger.focus {
  color: #FFFFFF;
  background-color: #EF6262;
  border-color: #EF6262;
}

.btn-danger:hover, .btn-danger.hover {
  color: #FFFFFF;
  background-color: #f17979;
  border-color: #f17979;
}

.btn-danger:active, .btn-danger.active, .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ed4b4b;
  border-color: #ed4b4b;
}

.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus, .btn-danger:not([disabled]):not(.disabled):active:hover, .btn-danger:not([disabled]):not(.disabled):active:focus, .btn-danger:not([disabled]):not(.disabled):active.focus, .btn-danger:not([disabled]):not(.disabled).active:hover, .btn-danger:not([disabled]):not(.disabled).active:focus, .btn-danger:not([disabled]):not(.disabled).active.focus,
.show > .btn-danger.dropdown-toggle:hover,
.show > .btn-danger.dropdown-toggle:focus,
.show > .btn-danger.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #ed4b4b;
  border-color: #ed4b4b;
}

.btn-danger:active, .btn-danger.active, .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  background-image: none;
}

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  color: #FFFFFF;
  background-color: #EF6262;
  border-color: #EF6262;
}

.btn-danger .badge {
  color: #EF6262;
  background-color: #FFFFFF;
}

.btn-alt1 {
  color: #FFFFFF;
  background-color: #56c3e7;
  border-color: #56c3e7;
}

.btn-alt1:focus, .btn-alt1.focus {
  color: #FFFFFF;
  background-color: #56c3e7;
  border-color: #56c3e7;
}

.btn-alt1:hover, .btn-alt1.hover {
  color: #FFFFFF;
  background-color: #6dcbea;
  border-color: #6dcbea;
}

.btn-alt1:active, .btn-alt1.active, .btn-alt1:not([disabled]):not(.disabled):active, .btn-alt1:not([disabled]):not(.disabled).active,
.show > .btn-alt1.dropdown-toggle {
  color: #FFFFFF;
  background-color: #40bbe4;
  border-color: #40bbe4;
}

.btn-alt1:active:hover, .btn-alt1:active:focus, .btn-alt1:active.focus, .btn-alt1.active:hover, .btn-alt1.active:focus, .btn-alt1.active.focus, .btn-alt1:not([disabled]):not(.disabled):active:hover, .btn-alt1:not([disabled]):not(.disabled):active:focus, .btn-alt1:not([disabled]):not(.disabled):active.focus, .btn-alt1:not([disabled]):not(.disabled).active:hover, .btn-alt1:not([disabled]):not(.disabled).active:focus, .btn-alt1:not([disabled]):not(.disabled).active.focus,
.show > .btn-alt1.dropdown-toggle:hover,
.show > .btn-alt1.dropdown-toggle:focus,
.show > .btn-alt1.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #40bbe4;
  border-color: #40bbe4;
}

.btn-alt1:active, .btn-alt1.active, .btn-alt1:not([disabled]):not(.disabled):active, .btn-alt1:not([disabled]):not(.disabled).active,
.show > .btn-alt1.dropdown-toggle {
  background-image: none;
}

.btn-alt1.disabled, .btn-alt1.disabled:hover, .btn-alt1.disabled:focus, .btn-alt1.disabled.focus, .btn-alt1.disabled:active, .btn-alt1.disabled.active, .btn-alt1[disabled], .btn-alt1[disabled]:hover, .btn-alt1[disabled]:focus, .btn-alt1[disabled].focus, .btn-alt1[disabled]:active, .btn-alt1[disabled].active,
fieldset[disabled] .btn-alt1,
fieldset[disabled] .btn-alt1:hover,
fieldset[disabled] .btn-alt1:focus,
fieldset[disabled] .btn-alt1.focus,
fieldset[disabled] .btn-alt1:active,
fieldset[disabled] .btn-alt1.active {
  color: #FFFFFF;
  background-color: #56c3e7;
  border-color: #56c3e7;
}

.btn-alt1 .badge {
  color: #56c3e7;
  background-color: #FFFFFF;
}

.btn-alt2 {
  color: #FFFFFF;
  background-color: #ffcf66;
  border-color: #ffcf66;
}

.btn-alt2:focus, .btn-alt2.focus {
  color: #FFFFFF;
  background-color: #ffcf66;
  border-color: #ffcf66;
}

.btn-alt2:hover, .btn-alt2.hover {
  color: #FFFFFF;
  background-color: #ffd77f;
  border-color: #ffd77f;
}

.btn-alt2:active, .btn-alt2.active, .btn-alt2:not([disabled]):not(.disabled):active, .btn-alt2:not([disabled]):not(.disabled).active,
.show > .btn-alt2.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ffc74c;
  border-color: #ffc74c;
}

.btn-alt2:active:hover, .btn-alt2:active:focus, .btn-alt2:active.focus, .btn-alt2.active:hover, .btn-alt2.active:focus, .btn-alt2.active.focus, .btn-alt2:not([disabled]):not(.disabled):active:hover, .btn-alt2:not([disabled]):not(.disabled):active:focus, .btn-alt2:not([disabled]):not(.disabled):active.focus, .btn-alt2:not([disabled]):not(.disabled).active:hover, .btn-alt2:not([disabled]):not(.disabled).active:focus, .btn-alt2:not([disabled]):not(.disabled).active.focus,
.show > .btn-alt2.dropdown-toggle:hover,
.show > .btn-alt2.dropdown-toggle:focus,
.show > .btn-alt2.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #ffc74c;
  border-color: #ffc74c;
}

.btn-alt2:active, .btn-alt2.active, .btn-alt2:not([disabled]):not(.disabled):active, .btn-alt2:not([disabled]):not(.disabled).active,
.show > .btn-alt2.dropdown-toggle {
  background-image: none;
}

.btn-alt2.disabled, .btn-alt2.disabled:hover, .btn-alt2.disabled:focus, .btn-alt2.disabled.focus, .btn-alt2.disabled:active, .btn-alt2.disabled.active, .btn-alt2[disabled], .btn-alt2[disabled]:hover, .btn-alt2[disabled]:focus, .btn-alt2[disabled].focus, .btn-alt2[disabled]:active, .btn-alt2[disabled].active,
fieldset[disabled] .btn-alt2,
fieldset[disabled] .btn-alt2:hover,
fieldset[disabled] .btn-alt2:focus,
fieldset[disabled] .btn-alt2.focus,
fieldset[disabled] .btn-alt2:active,
fieldset[disabled] .btn-alt2.active {
  color: #FFFFFF;
  background-color: #ffcf66;
  border-color: #ffcf66;
}

.btn-alt2 .badge {
  color: #ffcf66;
  background-color: #FFFFFF;
}

.btn-alt3 {
  color: #FFFFFF;
  background-color: #7a98bf;
  border-color: #7a98bf;
}

.btn-alt3:focus, .btn-alt3.focus {
  color: #FFFFFF;
  background-color: #7a98bf;
  border-color: #7a98bf;
}

.btn-alt3:hover, .btn-alt3.hover {
  color: #FFFFFF;
  background-color: #8ba5c7;
  border-color: #8ba5c7;
}

.btn-alt3:active, .btn-alt3.active, .btn-alt3:not([disabled]):not(.disabled):active, .btn-alt3:not([disabled]):not(.disabled).active,
.show > .btn-alt3.dropdown-toggle {
  color: #FFFFFF;
  background-color: #698bb7;
  border-color: #698bb7;
}

.btn-alt3:active:hover, .btn-alt3:active:focus, .btn-alt3:active.focus, .btn-alt3.active:hover, .btn-alt3.active:focus, .btn-alt3.active.focus, .btn-alt3:not([disabled]):not(.disabled):active:hover, .btn-alt3:not([disabled]):not(.disabled):active:focus, .btn-alt3:not([disabled]):not(.disabled):active.focus, .btn-alt3:not([disabled]):not(.disabled).active:hover, .btn-alt3:not([disabled]):not(.disabled).active:focus, .btn-alt3:not([disabled]):not(.disabled).active.focus,
.show > .btn-alt3.dropdown-toggle:hover,
.show > .btn-alt3.dropdown-toggle:focus,
.show > .btn-alt3.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #698bb7;
  border-color: #698bb7;
}

.btn-alt3:active, .btn-alt3.active, .btn-alt3:not([disabled]):not(.disabled):active, .btn-alt3:not([disabled]):not(.disabled).active,
.show > .btn-alt3.dropdown-toggle {
  background-image: none;
}

.btn-alt3.disabled, .btn-alt3.disabled:hover, .btn-alt3.disabled:focus, .btn-alt3.disabled.focus, .btn-alt3.disabled:active, .btn-alt3.disabled.active, .btn-alt3[disabled], .btn-alt3[disabled]:hover, .btn-alt3[disabled]:focus, .btn-alt3[disabled].focus, .btn-alt3[disabled]:active, .btn-alt3[disabled].active,
fieldset[disabled] .btn-alt3,
fieldset[disabled] .btn-alt3:hover,
fieldset[disabled] .btn-alt3:focus,
fieldset[disabled] .btn-alt3.focus,
fieldset[disabled] .btn-alt3:active,
fieldset[disabled] .btn-alt3.active {
  color: #FFFFFF;
  background-color: #7a98bf;
  border-color: #7a98bf;
}

.btn-alt3 .badge {
  color: #7a98bf;
  background-color: #FFFFFF;
}

.btn-alt4 {
  color: #FFFFFF;
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

.btn-alt4:focus, .btn-alt4.focus {
  color: #FFFFFF;
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

.btn-alt4:hover, .btn-alt4.hover {
  color: #FFFFFF;
  background-color: #c9c9c9;
  border-color: #c9c9c9;
}

.btn-alt4:active, .btn-alt4.active, .btn-alt4:not([disabled]):not(.disabled):active, .btn-alt4:not([disabled]):not(.disabled).active,
.show > .btn-alt4.dropdown-toggle {
  color: #FFFFFF;
  background-color: #b0b0b0;
  border-color: #b0b0b0;
}

.btn-alt4:active:hover, .btn-alt4:active:focus, .btn-alt4:active.focus, .btn-alt4.active:hover, .btn-alt4.active:focus, .btn-alt4.active.focus, .btn-alt4:not([disabled]):not(.disabled):active:hover, .btn-alt4:not([disabled]):not(.disabled):active:focus, .btn-alt4:not([disabled]):not(.disabled):active.focus, .btn-alt4:not([disabled]):not(.disabled).active:hover, .btn-alt4:not([disabled]):not(.disabled).active:focus, .btn-alt4:not([disabled]):not(.disabled).active.focus,
.show > .btn-alt4.dropdown-toggle:hover,
.show > .btn-alt4.dropdown-toggle:focus,
.show > .btn-alt4.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #b0b0b0;
  border-color: #b0b0b0;
}

.btn-alt4:active, .btn-alt4.active, .btn-alt4:not([disabled]):not(.disabled):active, .btn-alt4:not([disabled]):not(.disabled).active,
.show > .btn-alt4.dropdown-toggle {
  background-image: none;
}

.btn-alt4.disabled, .btn-alt4.disabled:hover, .btn-alt4.disabled:focus, .btn-alt4.disabled.focus, .btn-alt4.disabled:active, .btn-alt4.disabled.active, .btn-alt4[disabled], .btn-alt4[disabled]:hover, .btn-alt4[disabled]:focus, .btn-alt4[disabled].focus, .btn-alt4[disabled]:active, .btn-alt4[disabled].active,
fieldset[disabled] .btn-alt4,
fieldset[disabled] .btn-alt4:hover,
fieldset[disabled] .btn-alt4:focus,
fieldset[disabled] .btn-alt4.focus,
fieldset[disabled] .btn-alt4:active,
fieldset[disabled] .btn-alt4.active {
  color: #FFFFFF;
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

.btn-alt4 .badge {
  color: #bdbdbd;
  background-color: #FFFFFF;
}

.btn-xs {
  padding: 0.1538rem 0.3846rem;
  font-size: 0.8462rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  font-size: 0.92308rem;
}

.btn-lg, .btn-group-lg > .btn {
  font-size: 1.1538rem;
  font-weight: 500;
}

.btn-rounded {
  border-radius: 3px;
}

.btn-rounded.btn-xs {
  border-radius: 3px;
}

.btn-rounded.btn-lg, .btn-group-lg > .btn-rounded.btn {
  border-radius: 5px;
}

.btn-primary.btn-shade1 {
  background-color: #ed5454;
  border-color: #ed5454;
}

.btn-primary.btn-shade1:focus, .btn-primary.btn-shade1.focus {
  background-color: #ed5454;
  border-color: #ed5454;
}

.btn-primary.btn-shade1:hover {
  background-color: #f06c6c;
  border-color: #f06c6c;
}

.btn-primary.btn-shade1:active, .btn-primary.btn-shade1.active,
.show > .btn-primary.btn-shade1.dropdown-toggle {
  background-color: #ec4747;
  border-color: #ec4747;
}

.btn-primary.btn-shade1:active:hover, .btn-primary.btn-shade1:active:focus, .btn-primary.btn-shade1:active.focus, .btn-primary.btn-shade1.active:hover, .btn-primary.btn-shade1.active:focus, .btn-primary.btn-shade1.active.focus,
.show > .btn-primary.btn-shade1.dropdown-toggle:hover,
.show > .btn-primary.btn-shade1.dropdown-toggle:focus,
.show > .btn-primary.btn-shade1.dropdown-toggle.focus {
  background-color: #ec4747;
  border-color: #ec4747;
}

.btn-primary.btn-shade1.disabled, .btn-primary.btn-shade1.disabled:hover, .btn-primary.btn-shade1.disabled:focus, .btn-primary.btn-shade1.disabled.focus, .btn-primary.btn-shade1.disabled:active, .btn-primary.btn-shade1.disabled.active, .btn-primary.btn-shade1[disabled], .btn-primary.btn-shade1[disabled]:hover, .btn-primary.btn-shade1[disabled]:focus, .btn-primary.btn-shade1[disabled].focus, .btn-primary.btn-shade1[disabled]:active, .btn-primary.btn-shade1[disabled].active,
fieldset[disabled] .btn-primary.btn-shade1,
fieldset[disabled] .btn-primary.btn-shade1:hover,
fieldset[disabled] .btn-primary.btn-shade1:focus,
fieldset[disabled] .btn-primary.btn-shade1.focus,
fieldset[disabled] .btn-primary.btn-shade1:active,
fieldset[disabled] .btn-primary.btn-shade1.active {
  background-color: #ed5454;
  border-color: #ed5454;
}

.btn-primary.btn-shade1 .badge {
  color: #ed5454;
}

.btn-primary.btn-shade2 {
  background-color: #ec4b4b;
  border-color: #ec4b4b;
}

.btn-primary.btn-shade2:focus, .btn-primary.btn-shade2.focus {
  background-color: #ec4b4b;
  border-color: #ec4b4b;
}

.btn-primary.btn-shade2:hover {
  background-color: #ef6262;
  border-color: #ef6262;
}

.btn-primary.btn-shade2:active, .btn-primary.btn-shade2.active,
.show > .btn-primary.btn-shade2.dropdown-toggle {
  background-color: #eb3d3d;
  border-color: #eb3d3d;
}

.btn-primary.btn-shade2:active:hover, .btn-primary.btn-shade2:active:focus, .btn-primary.btn-shade2:active.focus, .btn-primary.btn-shade2.active:hover, .btn-primary.btn-shade2.active:focus, .btn-primary.btn-shade2.active.focus,
.show > .btn-primary.btn-shade2.dropdown-toggle:hover,
.show > .btn-primary.btn-shade2.dropdown-toggle:focus,
.show > .btn-primary.btn-shade2.dropdown-toggle.focus {
  background-color: #eb3d3d;
  border-color: #eb3d3d;
}

.btn-primary.btn-shade2.disabled, .btn-primary.btn-shade2.disabled:hover, .btn-primary.btn-shade2.disabled:focus, .btn-primary.btn-shade2.disabled.focus, .btn-primary.btn-shade2.disabled:active, .btn-primary.btn-shade2.disabled.active, .btn-primary.btn-shade2[disabled], .btn-primary.btn-shade2[disabled]:hover, .btn-primary.btn-shade2[disabled]:focus, .btn-primary.btn-shade2[disabled].focus, .btn-primary.btn-shade2[disabled]:active, .btn-primary.btn-shade2[disabled].active,
fieldset[disabled] .btn-primary.btn-shade2,
fieldset[disabled] .btn-primary.btn-shade2:hover,
fieldset[disabled] .btn-primary.btn-shade2:focus,
fieldset[disabled] .btn-primary.btn-shade2.focus,
fieldset[disabled] .btn-primary.btn-shade2:active,
fieldset[disabled] .btn-primary.btn-shade2.active {
  background-color: #ec4b4b;
  border-color: #ec4b4b;
}

.btn-primary.btn-shade2 .badge {
  color: #ec4b4b;
}

.btn-primary.btn-shade3 {
  background-color: #eb4242;
  border-color: #eb4242;
}

.btn-primary.btn-shade3:focus, .btn-primary.btn-shade3.focus {
  background-color: #eb4242;
  border-color: #eb4242;
}

.btn-primary.btn-shade3:hover {
  background-color: #ee5959;
  border-color: #ee5959;
}

.btn-primary.btn-shade3:active, .btn-primary.btn-shade3.active,
.show > .btn-primary.btn-shade3.dropdown-toggle {
  background-color: #ea3434;
  border-color: #ea3434;
}

.btn-primary.btn-shade3:active:hover, .btn-primary.btn-shade3:active:focus, .btn-primary.btn-shade3:active.focus, .btn-primary.btn-shade3.active:hover, .btn-primary.btn-shade3.active:focus, .btn-primary.btn-shade3.active.focus,
.show > .btn-primary.btn-shade3.dropdown-toggle:hover,
.show > .btn-primary.btn-shade3.dropdown-toggle:focus,
.show > .btn-primary.btn-shade3.dropdown-toggle.focus {
  background-color: #ea3434;
  border-color: #ea3434;
}

.btn-primary.btn-shade3.disabled, .btn-primary.btn-shade3.disabled:hover, .btn-primary.btn-shade3.disabled:focus, .btn-primary.btn-shade3.disabled.focus, .btn-primary.btn-shade3.disabled:active, .btn-primary.btn-shade3.disabled.active, .btn-primary.btn-shade3[disabled], .btn-primary.btn-shade3[disabled]:hover, .btn-primary.btn-shade3[disabled]:focus, .btn-primary.btn-shade3[disabled].focus, .btn-primary.btn-shade3[disabled]:active, .btn-primary.btn-shade3[disabled].active,
fieldset[disabled] .btn-primary.btn-shade3,
fieldset[disabled] .btn-primary.btn-shade3:hover,
fieldset[disabled] .btn-primary.btn-shade3:focus,
fieldset[disabled] .btn-primary.btn-shade3.focus,
fieldset[disabled] .btn-primary.btn-shade3:active,
fieldset[disabled] .btn-primary.btn-shade3.active {
  background-color: #eb4242;
  border-color: #eb4242;
}

.btn-primary.btn-shade3 .badge {
  color: #eb4242;
}

.btn-primary.btn-shade4 {
  background-color: #ea3939;
  border-color: #ea3939;
}

.btn-primary.btn-shade4:focus, .btn-primary.btn-shade4.focus {
  background-color: #ea3939;
  border-color: #ea3939;
}

.btn-primary.btn-shade4:hover {
  background-color: #ed5050;
  border-color: #ed5050;
}

.btn-primary.btn-shade4:active, .btn-primary.btn-shade4.active,
.show > .btn-primary.btn-shade4.dropdown-toggle {
  background-color: #e92b2b;
  border-color: #e92b2b;
}

.btn-primary.btn-shade4:active:hover, .btn-primary.btn-shade4:active:focus, .btn-primary.btn-shade4:active.focus, .btn-primary.btn-shade4.active:hover, .btn-primary.btn-shade4.active:focus, .btn-primary.btn-shade4.active.focus,
.show > .btn-primary.btn-shade4.dropdown-toggle:hover,
.show > .btn-primary.btn-shade4.dropdown-toggle:focus,
.show > .btn-primary.btn-shade4.dropdown-toggle.focus {
  background-color: #e92b2b;
  border-color: #e92b2b;
}

.btn-primary.btn-shade4.disabled, .btn-primary.btn-shade4.disabled:hover, .btn-primary.btn-shade4.disabled:focus, .btn-primary.btn-shade4.disabled.focus, .btn-primary.btn-shade4.disabled:active, .btn-primary.btn-shade4.disabled.active, .btn-primary.btn-shade4[disabled], .btn-primary.btn-shade4[disabled]:hover, .btn-primary.btn-shade4[disabled]:focus, .btn-primary.btn-shade4[disabled].focus, .btn-primary.btn-shade4[disabled]:active, .btn-primary.btn-shade4[disabled].active,
fieldset[disabled] .btn-primary.btn-shade4,
fieldset[disabled] .btn-primary.btn-shade4:hover,
fieldset[disabled] .btn-primary.btn-shade4:focus,
fieldset[disabled] .btn-primary.btn-shade4.focus,
fieldset[disabled] .btn-primary.btn-shade4:active,
fieldset[disabled] .btn-primary.btn-shade4.active {
  background-color: #ea3939;
  border-color: #ea3939;
}

.btn-primary.btn-shade4 .badge {
  color: #ea3939;
}

.btn-success.btn-shade1 {
  background-color: #6fc8b9;
  border-color: #6fc8b9;
}

.btn-success.btn-shade1:focus, .btn-success.btn-shade1.focus {
  background-color: #6fc8b9;
  border-color: #6fc8b9;
}

.btn-success.btn-shade1:hover {
  background-color: #81cfc2;
  border-color: #81cfc2;
}

.btn-success.btn-shade1:active, .btn-success.btn-shade1.active,
.show > .btn-success.btn-shade1.dropdown-toggle {
  background-color: #64c4b3;
  border-color: #64c4b3;
}

.btn-success.btn-shade1:active:hover, .btn-success.btn-shade1:active:focus, .btn-success.btn-shade1:active.focus, .btn-success.btn-shade1.active:hover, .btn-success.btn-shade1.active:focus, .btn-success.btn-shade1.active.focus,
.show > .btn-success.btn-shade1.dropdown-toggle:hover,
.show > .btn-success.btn-shade1.dropdown-toggle:focus,
.show > .btn-success.btn-shade1.dropdown-toggle.focus {
  background-color: #64c4b3;
  border-color: #64c4b3;
}

.btn-success.btn-shade1.disabled, .btn-success.btn-shade1.disabled:hover, .btn-success.btn-shade1.disabled:focus, .btn-success.btn-shade1.disabled.focus, .btn-success.btn-shade1.disabled:active, .btn-success.btn-shade1.disabled.active, .btn-success.btn-shade1[disabled], .btn-success.btn-shade1[disabled]:hover, .btn-success.btn-shade1[disabled]:focus, .btn-success.btn-shade1[disabled].focus, .btn-success.btn-shade1[disabled]:active, .btn-success.btn-shade1[disabled].active,
fieldset[disabled] .btn-success.btn-shade1,
fieldset[disabled] .btn-success.btn-shade1:hover,
fieldset[disabled] .btn-success.btn-shade1:focus,
fieldset[disabled] .btn-success.btn-shade1.focus,
fieldset[disabled] .btn-success.btn-shade1:active,
fieldset[disabled] .btn-success.btn-shade1.active {
  background-color: #6fc8b9;
  border-color: #6fc8b9;
}

.btn-success.btn-shade1 .badge {
  color: #6fc8b9;
}

.btn-success.btn-shade2 {
  background-color: #68c5b5;
  border-color: #68c5b5;
}

.btn-success.btn-shade2:focus, .btn-success.btn-shade2.focus {
  background-color: #68c5b5;
  border-color: #68c5b5;
}

.btn-success.btn-shade2:hover {
  background-color: #7accbe;
  border-color: #7accbe;
}

.btn-success.btn-shade2:active, .btn-success.btn-shade2.active,
.show > .btn-success.btn-shade2.dropdown-toggle {
  background-color: #5dc1b0;
  border-color: #5dc1b0;
}

.btn-success.btn-shade2:active:hover, .btn-success.btn-shade2:active:focus, .btn-success.btn-shade2:active.focus, .btn-success.btn-shade2.active:hover, .btn-success.btn-shade2.active:focus, .btn-success.btn-shade2.active.focus,
.show > .btn-success.btn-shade2.dropdown-toggle:hover,
.show > .btn-success.btn-shade2.dropdown-toggle:focus,
.show > .btn-success.btn-shade2.dropdown-toggle.focus {
  background-color: #5dc1b0;
  border-color: #5dc1b0;
}

.btn-success.btn-shade2.disabled, .btn-success.btn-shade2.disabled:hover, .btn-success.btn-shade2.disabled:focus, .btn-success.btn-shade2.disabled.focus, .btn-success.btn-shade2.disabled:active, .btn-success.btn-shade2.disabled.active, .btn-success.btn-shade2[disabled], .btn-success.btn-shade2[disabled]:hover, .btn-success.btn-shade2[disabled]:focus, .btn-success.btn-shade2[disabled].focus, .btn-success.btn-shade2[disabled]:active, .btn-success.btn-shade2[disabled].active,
fieldset[disabled] .btn-success.btn-shade2,
fieldset[disabled] .btn-success.btn-shade2:hover,
fieldset[disabled] .btn-success.btn-shade2:focus,
fieldset[disabled] .btn-success.btn-shade2.focus,
fieldset[disabled] .btn-success.btn-shade2:active,
fieldset[disabled] .btn-success.btn-shade2.active {
  background-color: #68c5b5;
  border-color: #68c5b5;
}

.btn-success.btn-shade2 .badge {
  color: #68c5b5;
}

.btn-success.btn-shade3 {
  background-color: #60c2b1;
  border-color: #60c2b1;
}

.btn-success.btn-shade3:focus, .btn-success.btn-shade3.focus {
  background-color: #60c2b1;
  border-color: #60c2b1;
}

.btn-success.btn-shade3:hover {
  background-color: #73c9ba;
  border-color: #73c9ba;
}

.btn-success.btn-shade3:active, .btn-success.btn-shade3.active,
.show > .btn-success.btn-shade3.dropdown-toggle {
  background-color: #55beac;
  border-color: #55beac;
}

.btn-success.btn-shade3:active:hover, .btn-success.btn-shade3:active:focus, .btn-success.btn-shade3:active.focus, .btn-success.btn-shade3.active:hover, .btn-success.btn-shade3.active:focus, .btn-success.btn-shade3.active.focus,
.show > .btn-success.btn-shade3.dropdown-toggle:hover,
.show > .btn-success.btn-shade3.dropdown-toggle:focus,
.show > .btn-success.btn-shade3.dropdown-toggle.focus {
  background-color: #55beac;
  border-color: #55beac;
}

.btn-success.btn-shade3.disabled, .btn-success.btn-shade3.disabled:hover, .btn-success.btn-shade3.disabled:focus, .btn-success.btn-shade3.disabled.focus, .btn-success.btn-shade3.disabled:active, .btn-success.btn-shade3.disabled.active, .btn-success.btn-shade3[disabled], .btn-success.btn-shade3[disabled]:hover, .btn-success.btn-shade3[disabled]:focus, .btn-success.btn-shade3[disabled].focus, .btn-success.btn-shade3[disabled]:active, .btn-success.btn-shade3[disabled].active,
fieldset[disabled] .btn-success.btn-shade3,
fieldset[disabled] .btn-success.btn-shade3:hover,
fieldset[disabled] .btn-success.btn-shade3:focus,
fieldset[disabled] .btn-success.btn-shade3.focus,
fieldset[disabled] .btn-success.btn-shade3:active,
fieldset[disabled] .btn-success.btn-shade3.active {
  background-color: #60c2b1;
  border-color: #60c2b1;
}

.btn-success.btn-shade3 .badge {
  color: #60c2b1;
}

.btn-success.btn-shade4 {
  background-color: #59bfae;
  border-color: #59bfae;
}

.btn-success.btn-shade4:focus, .btn-success.btn-shade4.focus {
  background-color: #59bfae;
  border-color: #59bfae;
}

.btn-success.btn-shade4:hover {
  background-color: #6bc6b7;
  border-color: #6bc6b7;
}

.btn-success.btn-shade4:active, .btn-success.btn-shade4.active,
.show > .btn-success.btn-shade4.dropdown-toggle {
  background-color: #4ebba8;
  border-color: #4ebba8;
}

.btn-success.btn-shade4:active:hover, .btn-success.btn-shade4:active:focus, .btn-success.btn-shade4:active.focus, .btn-success.btn-shade4.active:hover, .btn-success.btn-shade4.active:focus, .btn-success.btn-shade4.active.focus,
.show > .btn-success.btn-shade4.dropdown-toggle:hover,
.show > .btn-success.btn-shade4.dropdown-toggle:focus,
.show > .btn-success.btn-shade4.dropdown-toggle.focus {
  background-color: #4ebba8;
  border-color: #4ebba8;
}

.btn-success.btn-shade4.disabled, .btn-success.btn-shade4.disabled:hover, .btn-success.btn-shade4.disabled:focus, .btn-success.btn-shade4.disabled.focus, .btn-success.btn-shade4.disabled:active, .btn-success.btn-shade4.disabled.active, .btn-success.btn-shade4[disabled], .btn-success.btn-shade4[disabled]:hover, .btn-success.btn-shade4[disabled]:focus, .btn-success.btn-shade4[disabled].focus, .btn-success.btn-shade4[disabled]:active, .btn-success.btn-shade4[disabled].active,
fieldset[disabled] .btn-success.btn-shade4,
fieldset[disabled] .btn-success.btn-shade4:hover,
fieldset[disabled] .btn-success.btn-shade4:focus,
fieldset[disabled] .btn-success.btn-shade4.focus,
fieldset[disabled] .btn-success.btn-shade4:active,
fieldset[disabled] .btn-success.btn-shade4.active {
  background-color: #59bfae;
  border-color: #59bfae;
}

.btn-success.btn-shade4 .badge {
  color: #59bfae;
}

.btn-info.btn-shade1 {
  background-color: #81c4dc;
  border-color: #81c4dc;
}

.btn-info.btn-shade1:focus, .btn-info.btn-shade1.focus {
  background-color: #81c4dc;
  border-color: #81c4dc;
}

.btn-info.btn-shade1:hover {
  background-color: #95cee1;
  border-color: #95cee1;
}

.btn-info.btn-shade1:active, .btn-info.btn-shade1.active,
.show > .btn-info.btn-shade1.dropdown-toggle {
  background-color: #75bfd8;
  border-color: #75bfd8;
}

.btn-info.btn-shade1:active:hover, .btn-info.btn-shade1:active:focus, .btn-info.btn-shade1:active.focus, .btn-info.btn-shade1.active:hover, .btn-info.btn-shade1.active:focus, .btn-info.btn-shade1.active.focus,
.show > .btn-info.btn-shade1.dropdown-toggle:hover,
.show > .btn-info.btn-shade1.dropdown-toggle:focus,
.show > .btn-info.btn-shade1.dropdown-toggle.focus {
  background-color: #75bfd8;
  border-color: #75bfd8;
}

.btn-info.btn-shade1.disabled, .btn-info.btn-shade1.disabled:hover, .btn-info.btn-shade1.disabled:focus, .btn-info.btn-shade1.disabled.focus, .btn-info.btn-shade1.disabled:active, .btn-info.btn-shade1.disabled.active, .btn-info.btn-shade1[disabled], .btn-info.btn-shade1[disabled]:hover, .btn-info.btn-shade1[disabled]:focus, .btn-info.btn-shade1[disabled].focus, .btn-info.btn-shade1[disabled]:active, .btn-info.btn-shade1[disabled].active,
fieldset[disabled] .btn-info.btn-shade1,
fieldset[disabled] .btn-info.btn-shade1:hover,
fieldset[disabled] .btn-info.btn-shade1:focus,
fieldset[disabled] .btn-info.btn-shade1.focus,
fieldset[disabled] .btn-info.btn-shade1:active,
fieldset[disabled] .btn-info.btn-shade1.active {
  background-color: #81c4dc;
  border-color: #81c4dc;
}

.btn-info.btn-shade1 .badge {
  color: #81c4dc;
}

.btn-info.btn-shade2 {
  background-color: #79c1d9;
  border-color: #79c1d9;
}

.btn-info.btn-shade2:focus, .btn-info.btn-shade2.focus {
  background-color: #79c1d9;
  border-color: #79c1d9;
}

.btn-info.btn-shade2:hover {
  background-color: #8dcadf;
  border-color: #8dcadf;
}

.btn-info.btn-shade2:active, .btn-info.btn-shade2.active,
.show > .btn-info.btn-shade2.dropdown-toggle {
  background-color: #6dbbd6;
  border-color: #6dbbd6;
}

.btn-info.btn-shade2:active:hover, .btn-info.btn-shade2:active:focus, .btn-info.btn-shade2:active.focus, .btn-info.btn-shade2.active:hover, .btn-info.btn-shade2.active:focus, .btn-info.btn-shade2.active.focus,
.show > .btn-info.btn-shade2.dropdown-toggle:hover,
.show > .btn-info.btn-shade2.dropdown-toggle:focus,
.show > .btn-info.btn-shade2.dropdown-toggle.focus {
  background-color: #6dbbd6;
  border-color: #6dbbd6;
}

.btn-info.btn-shade2.disabled, .btn-info.btn-shade2.disabled:hover, .btn-info.btn-shade2.disabled:focus, .btn-info.btn-shade2.disabled.focus, .btn-info.btn-shade2.disabled:active, .btn-info.btn-shade2.disabled.active, .btn-info.btn-shade2[disabled], .btn-info.btn-shade2[disabled]:hover, .btn-info.btn-shade2[disabled]:focus, .btn-info.btn-shade2[disabled].focus, .btn-info.btn-shade2[disabled]:active, .btn-info.btn-shade2[disabled].active,
fieldset[disabled] .btn-info.btn-shade2,
fieldset[disabled] .btn-info.btn-shade2:hover,
fieldset[disabled] .btn-info.btn-shade2:focus,
fieldset[disabled] .btn-info.btn-shade2.focus,
fieldset[disabled] .btn-info.btn-shade2:active,
fieldset[disabled] .btn-info.btn-shade2.active {
  background-color: #79c1d9;
  border-color: #79c1d9;
}

.btn-info.btn-shade2 .badge {
  color: #79c1d9;
}

.btn-info.btn-shade3 {
  background-color: #71bdd7;
  border-color: #71bdd7;
}

.btn-info.btn-shade3:focus, .btn-info.btn-shade3.focus {
  background-color: #71bdd7;
  border-color: #71bdd7;
}

.btn-info.btn-shade3:hover {
  background-color: #85c6dd;
  border-color: #85c6dd;
}

.btn-info.btn-shade3:active, .btn-info.btn-shade3.active,
.show > .btn-info.btn-shade3.dropdown-toggle {
  background-color: #65b7d4;
  border-color: #65b7d4;
}

.btn-info.btn-shade3:active:hover, .btn-info.btn-shade3:active:focus, .btn-info.btn-shade3:active.focus, .btn-info.btn-shade3.active:hover, .btn-info.btn-shade3.active:focus, .btn-info.btn-shade3.active.focus,
.show > .btn-info.btn-shade3.dropdown-toggle:hover,
.show > .btn-info.btn-shade3.dropdown-toggle:focus,
.show > .btn-info.btn-shade3.dropdown-toggle.focus {
  background-color: #65b7d4;
  border-color: #65b7d4;
}

.btn-info.btn-shade3.disabled, .btn-info.btn-shade3.disabled:hover, .btn-info.btn-shade3.disabled:focus, .btn-info.btn-shade3.disabled.focus, .btn-info.btn-shade3.disabled:active, .btn-info.btn-shade3.disabled.active, .btn-info.btn-shade3[disabled], .btn-info.btn-shade3[disabled]:hover, .btn-info.btn-shade3[disabled]:focus, .btn-info.btn-shade3[disabled].focus, .btn-info.btn-shade3[disabled]:active, .btn-info.btn-shade3[disabled].active,
fieldset[disabled] .btn-info.btn-shade3,
fieldset[disabled] .btn-info.btn-shade3:hover,
fieldset[disabled] .btn-info.btn-shade3:focus,
fieldset[disabled] .btn-info.btn-shade3.focus,
fieldset[disabled] .btn-info.btn-shade3:active,
fieldset[disabled] .btn-info.btn-shade3.active {
  background-color: #71bdd7;
  border-color: #71bdd7;
}

.btn-info.btn-shade3 .badge {
  color: #71bdd7;
}

.btn-info.btn-shade4 {
  background-color: #69b9d5;
  border-color: #69b9d5;
}

.btn-info.btn-shade4:focus, .btn-info.btn-shade4.focus {
  background-color: #69b9d5;
  border-color: #69b9d5;
}

.btn-info.btn-shade4:hover {
  background-color: #7dc3db;
  border-color: #7dc3db;
}

.btn-info.btn-shade4:active, .btn-info.btn-shade4.active,
.show > .btn-info.btn-shade4.dropdown-toggle {
  background-color: #5db4d2;
  border-color: #5db4d2;
}

.btn-info.btn-shade4:active:hover, .btn-info.btn-shade4:active:focus, .btn-info.btn-shade4:active.focus, .btn-info.btn-shade4.active:hover, .btn-info.btn-shade4.active:focus, .btn-info.btn-shade4.active.focus,
.show > .btn-info.btn-shade4.dropdown-toggle:hover,
.show > .btn-info.btn-shade4.dropdown-toggle:focus,
.show > .btn-info.btn-shade4.dropdown-toggle.focus {
  background-color: #5db4d2;
  border-color: #5db4d2;
}

.btn-info.btn-shade4.disabled, .btn-info.btn-shade4.disabled:hover, .btn-info.btn-shade4.disabled:focus, .btn-info.btn-shade4.disabled.focus, .btn-info.btn-shade4.disabled:active, .btn-info.btn-shade4.disabled.active, .btn-info.btn-shade4[disabled], .btn-info.btn-shade4[disabled]:hover, .btn-info.btn-shade4[disabled]:focus, .btn-info.btn-shade4[disabled].focus, .btn-info.btn-shade4[disabled]:active, .btn-info.btn-shade4[disabled].active,
fieldset[disabled] .btn-info.btn-shade4,
fieldset[disabled] .btn-info.btn-shade4:hover,
fieldset[disabled] .btn-info.btn-shade4:focus,
fieldset[disabled] .btn-info.btn-shade4.focus,
fieldset[disabled] .btn-info.btn-shade4:active,
fieldset[disabled] .btn-info.btn-shade4.active {
  background-color: #69b9d5;
  border-color: #69b9d5;
}

.btn-info.btn-shade4 .badge {
  color: #69b9d5;
}

.btn-warning.btn-shade1 {
  background-color: #ffc261;
  border-color: #ffc261;
}

.btn-warning.btn-shade1:focus, .btn-warning.btn-shade1.focus {
  background-color: #ffc261;
  border-color: #ffc261;
}

.btn-warning.btn-shade1:hover {
  background-color: #ffcc7a;
  border-color: #ffcc7a;
}

.btn-warning.btn-shade1:active, .btn-warning.btn-shade1.active,
.show > .btn-warning.btn-shade1.dropdown-toggle {
  background-color: #ffbc51;
  border-color: #ffbc51;
}

.btn-warning.btn-shade1:active:hover, .btn-warning.btn-shade1:active:focus, .btn-warning.btn-shade1:active.focus, .btn-warning.btn-shade1.active:hover, .btn-warning.btn-shade1.active:focus, .btn-warning.btn-shade1.active.focus,
.show > .btn-warning.btn-shade1.dropdown-toggle:hover,
.show > .btn-warning.btn-shade1.dropdown-toggle:focus,
.show > .btn-warning.btn-shade1.dropdown-toggle.focus {
  background-color: #ffbc51;
  border-color: #ffbc51;
}

.btn-warning.btn-shade1.disabled, .btn-warning.btn-shade1.disabled:hover, .btn-warning.btn-shade1.disabled:focus, .btn-warning.btn-shade1.disabled.focus, .btn-warning.btn-shade1.disabled:active, .btn-warning.btn-shade1.disabled.active, .btn-warning.btn-shade1[disabled], .btn-warning.btn-shade1[disabled]:hover, .btn-warning.btn-shade1[disabled]:focus, .btn-warning.btn-shade1[disabled].focus, .btn-warning.btn-shade1[disabled]:active, .btn-warning.btn-shade1[disabled].active,
fieldset[disabled] .btn-warning.btn-shade1,
fieldset[disabled] .btn-warning.btn-shade1:hover,
fieldset[disabled] .btn-warning.btn-shade1:focus,
fieldset[disabled] .btn-warning.btn-shade1.focus,
fieldset[disabled] .btn-warning.btn-shade1:active,
fieldset[disabled] .btn-warning.btn-shade1.active {
  background-color: #ffc261;
  border-color: #ffc261;
}

.btn-warning.btn-shade1 .badge {
  color: #ffc261;
}

.btn-warning.btn-shade2 {
  background-color: #ffbe57;
  border-color: #ffbe57;
}

.btn-warning.btn-shade2:focus, .btn-warning.btn-shade2.focus {
  background-color: #ffbe57;
  border-color: #ffbe57;
}

.btn-warning.btn-shade2:hover {
  background-color: #ffc870;
  border-color: #ffc870;
}

.btn-warning.btn-shade2:active, .btn-warning.btn-shade2.active,
.show > .btn-warning.btn-shade2.dropdown-toggle {
  background-color: #ffb847;
  border-color: #ffb847;
}

.btn-warning.btn-shade2:active:hover, .btn-warning.btn-shade2:active:focus, .btn-warning.btn-shade2:active.focus, .btn-warning.btn-shade2.active:hover, .btn-warning.btn-shade2.active:focus, .btn-warning.btn-shade2.active.focus,
.show > .btn-warning.btn-shade2.dropdown-toggle:hover,
.show > .btn-warning.btn-shade2.dropdown-toggle:focus,
.show > .btn-warning.btn-shade2.dropdown-toggle.focus {
  background-color: #ffb847;
  border-color: #ffb847;
}

.btn-warning.btn-shade2.disabled, .btn-warning.btn-shade2.disabled:hover, .btn-warning.btn-shade2.disabled:focus, .btn-warning.btn-shade2.disabled.focus, .btn-warning.btn-shade2.disabled:active, .btn-warning.btn-shade2.disabled.active, .btn-warning.btn-shade2[disabled], .btn-warning.btn-shade2[disabled]:hover, .btn-warning.btn-shade2[disabled]:focus, .btn-warning.btn-shade2[disabled].focus, .btn-warning.btn-shade2[disabled]:active, .btn-warning.btn-shade2[disabled].active,
fieldset[disabled] .btn-warning.btn-shade2,
fieldset[disabled] .btn-warning.btn-shade2:hover,
fieldset[disabled] .btn-warning.btn-shade2:focus,
fieldset[disabled] .btn-warning.btn-shade2.focus,
fieldset[disabled] .btn-warning.btn-shade2:active,
fieldset[disabled] .btn-warning.btn-shade2.active {
  background-color: #ffbe57;
  border-color: #ffbe57;
}

.btn-warning.btn-shade2 .badge {
  color: #ffbe57;
}

.btn-warning.btn-shade3 {
  background-color: #ffba4c;
  border-color: #ffba4c;
}

.btn-warning.btn-shade3:focus, .btn-warning.btn-shade3.focus {
  background-color: #ffba4c;
  border-color: #ffba4c;
}

.btn-warning.btn-shade3:hover {
  background-color: #ffc466;
  border-color: #ffc466;
}

.btn-warning.btn-shade3:active, .btn-warning.btn-shade3.active,
.show > .btn-warning.btn-shade3.dropdown-toggle {
  background-color: #ffb43d;
  border-color: #ffb43d;
}

.btn-warning.btn-shade3:active:hover, .btn-warning.btn-shade3:active:focus, .btn-warning.btn-shade3:active.focus, .btn-warning.btn-shade3.active:hover, .btn-warning.btn-shade3.active:focus, .btn-warning.btn-shade3.active.focus,
.show > .btn-warning.btn-shade3.dropdown-toggle:hover,
.show > .btn-warning.btn-shade3.dropdown-toggle:focus,
.show > .btn-warning.btn-shade3.dropdown-toggle.focus {
  background-color: #ffb43d;
  border-color: #ffb43d;
}

.btn-warning.btn-shade3.disabled, .btn-warning.btn-shade3.disabled:hover, .btn-warning.btn-shade3.disabled:focus, .btn-warning.btn-shade3.disabled.focus, .btn-warning.btn-shade3.disabled:active, .btn-warning.btn-shade3.disabled.active, .btn-warning.btn-shade3[disabled], .btn-warning.btn-shade3[disabled]:hover, .btn-warning.btn-shade3[disabled]:focus, .btn-warning.btn-shade3[disabled].focus, .btn-warning.btn-shade3[disabled]:active, .btn-warning.btn-shade3[disabled].active,
fieldset[disabled] .btn-warning.btn-shade3,
fieldset[disabled] .btn-warning.btn-shade3:hover,
fieldset[disabled] .btn-warning.btn-shade3:focus,
fieldset[disabled] .btn-warning.btn-shade3.focus,
fieldset[disabled] .btn-warning.btn-shade3:active,
fieldset[disabled] .btn-warning.btn-shade3.active {
  background-color: #ffba4c;
  border-color: #ffba4c;
}

.btn-warning.btn-shade3 .badge {
  color: #ffba4c;
}

.btn-warning.btn-shade4 {
  background-color: #ffb642;
  border-color: #ffb642;
}

.btn-warning.btn-shade4:focus, .btn-warning.btn-shade4.focus {
  background-color: #ffb642;
  border-color: #ffb642;
}

.btn-warning.btn-shade4:hover {
  background-color: #ffc05c;
  border-color: #ffc05c;
}

.btn-warning.btn-shade4:active, .btn-warning.btn-shade4.active,
.show > .btn-warning.btn-shade4.dropdown-toggle {
  background-color: #ffb033;
  border-color: #ffb033;
}

.btn-warning.btn-shade4:active:hover, .btn-warning.btn-shade4:active:focus, .btn-warning.btn-shade4:active.focus, .btn-warning.btn-shade4.active:hover, .btn-warning.btn-shade4.active:focus, .btn-warning.btn-shade4.active.focus,
.show > .btn-warning.btn-shade4.dropdown-toggle:hover,
.show > .btn-warning.btn-shade4.dropdown-toggle:focus,
.show > .btn-warning.btn-shade4.dropdown-toggle.focus {
  background-color: #ffb033;
  border-color: #ffb033;
}

.btn-warning.btn-shade4.disabled, .btn-warning.btn-shade4.disabled:hover, .btn-warning.btn-shade4.disabled:focus, .btn-warning.btn-shade4.disabled.focus, .btn-warning.btn-shade4.disabled:active, .btn-warning.btn-shade4.disabled.active, .btn-warning.btn-shade4[disabled], .btn-warning.btn-shade4[disabled]:hover, .btn-warning.btn-shade4[disabled]:focus, .btn-warning.btn-shade4[disabled].focus, .btn-warning.btn-shade4[disabled]:active, .btn-warning.btn-shade4[disabled].active,
fieldset[disabled] .btn-warning.btn-shade4,
fieldset[disabled] .btn-warning.btn-shade4:hover,
fieldset[disabled] .btn-warning.btn-shade4:focus,
fieldset[disabled] .btn-warning.btn-shade4.focus,
fieldset[disabled] .btn-warning.btn-shade4:active,
fieldset[disabled] .btn-warning.btn-shade4.active {
  background-color: #ffb642;
  border-color: #ffb642;
}

.btn-warning.btn-shade4 .badge {
  color: #ffb642;
}

.btn-danger.btn-shade1 {
  background-color: #ee5454;
  border-color: #ee5454;
}

.btn-danger.btn-shade1:focus, .btn-danger.btn-shade1.focus {
  background-color: #ee5454;
  border-color: #ee5454;
}

.btn-danger.btn-shade1:hover {
  background-color: #f06b6b;
  border-color: #f06b6b;
}

.btn-danger.btn-shade1:active, .btn-danger.btn-shade1.active,
.show > .btn-danger.btn-shade1.dropdown-toggle {
  background-color: #ec4646;
  border-color: #ec4646;
}

.btn-danger.btn-shade1:active:hover, .btn-danger.btn-shade1:active:focus, .btn-danger.btn-shade1:active.focus, .btn-danger.btn-shade1.active:hover, .btn-danger.btn-shade1.active:focus, .btn-danger.btn-shade1.active.focus,
.show > .btn-danger.btn-shade1.dropdown-toggle:hover,
.show > .btn-danger.btn-shade1.dropdown-toggle:focus,
.show > .btn-danger.btn-shade1.dropdown-toggle.focus {
  background-color: #ec4646;
  border-color: #ec4646;
}

.btn-danger.btn-shade1.disabled, .btn-danger.btn-shade1.disabled:hover, .btn-danger.btn-shade1.disabled:focus, .btn-danger.btn-shade1.disabled.focus, .btn-danger.btn-shade1.disabled:active, .btn-danger.btn-shade1.disabled.active, .btn-danger.btn-shade1[disabled], .btn-danger.btn-shade1[disabled]:hover, .btn-danger.btn-shade1[disabled]:focus, .btn-danger.btn-shade1[disabled].focus, .btn-danger.btn-shade1[disabled]:active, .btn-danger.btn-shade1[disabled].active,
fieldset[disabled] .btn-danger.btn-shade1,
fieldset[disabled] .btn-danger.btn-shade1:hover,
fieldset[disabled] .btn-danger.btn-shade1:focus,
fieldset[disabled] .btn-danger.btn-shade1.focus,
fieldset[disabled] .btn-danger.btn-shade1:active,
fieldset[disabled] .btn-danger.btn-shade1.active {
  background-color: #ee5454;
  border-color: #ee5454;
}

.btn-danger.btn-shade1 .badge {
  color: #ee5454;
}

.btn-danger.btn-shade2 {
  background-color: #ed4b4b;
  border-color: #ed4b4b;
}

.btn-danger.btn-shade2:focus, .btn-danger.btn-shade2.focus {
  background-color: #ed4b4b;
  border-color: #ed4b4b;
}

.btn-danger.btn-shade2:hover {
  background-color: #ef6262;
  border-color: #ef6262;
}

.btn-danger.btn-shade2:active, .btn-danger.btn-shade2.active,
.show > .btn-danger.btn-shade2.dropdown-toggle {
  background-color: #eb3d3d;
  border-color: #eb3d3d;
}

.btn-danger.btn-shade2:active:hover, .btn-danger.btn-shade2:active:focus, .btn-danger.btn-shade2:active.focus, .btn-danger.btn-shade2.active:hover, .btn-danger.btn-shade2.active:focus, .btn-danger.btn-shade2.active.focus,
.show > .btn-danger.btn-shade2.dropdown-toggle:hover,
.show > .btn-danger.btn-shade2.dropdown-toggle:focus,
.show > .btn-danger.btn-shade2.dropdown-toggle.focus {
  background-color: #eb3d3d;
  border-color: #eb3d3d;
}

.btn-danger.btn-shade2.disabled, .btn-danger.btn-shade2.disabled:hover, .btn-danger.btn-shade2.disabled:focus, .btn-danger.btn-shade2.disabled.focus, .btn-danger.btn-shade2.disabled:active, .btn-danger.btn-shade2.disabled.active, .btn-danger.btn-shade2[disabled], .btn-danger.btn-shade2[disabled]:hover, .btn-danger.btn-shade2[disabled]:focus, .btn-danger.btn-shade2[disabled].focus, .btn-danger.btn-shade2[disabled]:active, .btn-danger.btn-shade2[disabled].active,
fieldset[disabled] .btn-danger.btn-shade2,
fieldset[disabled] .btn-danger.btn-shade2:hover,
fieldset[disabled] .btn-danger.btn-shade2:focus,
fieldset[disabled] .btn-danger.btn-shade2.focus,
fieldset[disabled] .btn-danger.btn-shade2:active,
fieldset[disabled] .btn-danger.btn-shade2.active {
  background-color: #ed4b4b;
  border-color: #ed4b4b;
}

.btn-danger.btn-shade2 .badge {
  color: #ed4b4b;
}

.btn-danger.btn-shade3 {
  background-color: #ec4242;
  border-color: #ec4242;
}

.btn-danger.btn-shade3:focus, .btn-danger.btn-shade3.focus {
  background-color: #ec4242;
  border-color: #ec4242;
}

.btn-danger.btn-shade3:hover {
  background-color: #ee5959;
  border-color: #ee5959;
}

.btn-danger.btn-shade3:active, .btn-danger.btn-shade3.active,
.show > .btn-danger.btn-shade3.dropdown-toggle {
  background-color: #ea3434;
  border-color: #ea3434;
}

.btn-danger.btn-shade3:active:hover, .btn-danger.btn-shade3:active:focus, .btn-danger.btn-shade3:active.focus, .btn-danger.btn-shade3.active:hover, .btn-danger.btn-shade3.active:focus, .btn-danger.btn-shade3.active.focus,
.show > .btn-danger.btn-shade3.dropdown-toggle:hover,
.show > .btn-danger.btn-shade3.dropdown-toggle:focus,
.show > .btn-danger.btn-shade3.dropdown-toggle.focus {
  background-color: #ea3434;
  border-color: #ea3434;
}

.btn-danger.btn-shade3.disabled, .btn-danger.btn-shade3.disabled:hover, .btn-danger.btn-shade3.disabled:focus, .btn-danger.btn-shade3.disabled.focus, .btn-danger.btn-shade3.disabled:active, .btn-danger.btn-shade3.disabled.active, .btn-danger.btn-shade3[disabled], .btn-danger.btn-shade3[disabled]:hover, .btn-danger.btn-shade3[disabled]:focus, .btn-danger.btn-shade3[disabled].focus, .btn-danger.btn-shade3[disabled]:active, .btn-danger.btn-shade3[disabled].active,
fieldset[disabled] .btn-danger.btn-shade3,
fieldset[disabled] .btn-danger.btn-shade3:hover,
fieldset[disabled] .btn-danger.btn-shade3:focus,
fieldset[disabled] .btn-danger.btn-shade3.focus,
fieldset[disabled] .btn-danger.btn-shade3:active,
fieldset[disabled] .btn-danger.btn-shade3.active {
  background-color: #ec4242;
  border-color: #ec4242;
}

.btn-danger.btn-shade3 .badge {
  color: #ec4242;
}

.btn-danger.btn-shade4 {
  background-color: #eb3838;
  border-color: #eb3838;
}

.btn-danger.btn-shade4:focus, .btn-danger.btn-shade4.focus {
  background-color: #eb3838;
  border-color: #eb3838;
}

.btn-danger.btn-shade4:hover {
  background-color: #ed4f4f;
  border-color: #ed4f4f;
}

.btn-danger.btn-shade4:active, .btn-danger.btn-shade4.active,
.show > .btn-danger.btn-shade4.dropdown-toggle {
  background-color: #e92a2a;
  border-color: #e92a2a;
}

.btn-danger.btn-shade4:active:hover, .btn-danger.btn-shade4:active:focus, .btn-danger.btn-shade4:active.focus, .btn-danger.btn-shade4.active:hover, .btn-danger.btn-shade4.active:focus, .btn-danger.btn-shade4.active.focus,
.show > .btn-danger.btn-shade4.dropdown-toggle:hover,
.show > .btn-danger.btn-shade4.dropdown-toggle:focus,
.show > .btn-danger.btn-shade4.dropdown-toggle.focus {
  background-color: #e92a2a;
  border-color: #e92a2a;
}

.btn-danger.btn-shade4.disabled, .btn-danger.btn-shade4.disabled:hover, .btn-danger.btn-shade4.disabled:focus, .btn-danger.btn-shade4.disabled.focus, .btn-danger.btn-shade4.disabled:active, .btn-danger.btn-shade4.disabled.active, .btn-danger.btn-shade4[disabled], .btn-danger.btn-shade4[disabled]:hover, .btn-danger.btn-shade4[disabled]:focus, .btn-danger.btn-shade4[disabled].focus, .btn-danger.btn-shade4[disabled]:active, .btn-danger.btn-shade4[disabled].active,
fieldset[disabled] .btn-danger.btn-shade4,
fieldset[disabled] .btn-danger.btn-shade4:hover,
fieldset[disabled] .btn-danger.btn-shade4:focus,
fieldset[disabled] .btn-danger.btn-shade4.focus,
fieldset[disabled] .btn-danger.btn-shade4:active,
fieldset[disabled] .btn-danger.btn-shade4.active {
  background-color: #eb3838;
  border-color: #eb3838;
}

.btn-danger.btn-shade4 .badge {
  color: #eb3838;
}

.btn-dark.btn-shade1 {
  background-color: #353434;
  border-color: #353434;
}

.btn-dark.btn-shade1:focus, .btn-dark.btn-shade1.focus {
  background-color: #353434;
  border-color: #353434;
}

.btn-dark.btn-shade1:hover {
  background-color: #424141;
  border-color: #424141;
}

.btn-dark.btn-shade1:active, .btn-dark.btn-shade1.active,
.show > .btn-dark.btn-shade1.dropdown-toggle {
  background-color: #2e2d2d;
  border-color: #2e2d2d;
}

.btn-dark.btn-shade1:active:hover, .btn-dark.btn-shade1:active:focus, .btn-dark.btn-shade1:active.focus, .btn-dark.btn-shade1.active:hover, .btn-dark.btn-shade1.active:focus, .btn-dark.btn-shade1.active.focus,
.show > .btn-dark.btn-shade1.dropdown-toggle:hover,
.show > .btn-dark.btn-shade1.dropdown-toggle:focus,
.show > .btn-dark.btn-shade1.dropdown-toggle.focus {
  background-color: #2e2d2d;
  border-color: #2e2d2d;
}

.btn-dark.btn-shade1.disabled, .btn-dark.btn-shade1.disabled:hover, .btn-dark.btn-shade1.disabled:focus, .btn-dark.btn-shade1.disabled.focus, .btn-dark.btn-shade1.disabled:active, .btn-dark.btn-shade1.disabled.active, .btn-dark.btn-shade1[disabled], .btn-dark.btn-shade1[disabled]:hover, .btn-dark.btn-shade1[disabled]:focus, .btn-dark.btn-shade1[disabled].focus, .btn-dark.btn-shade1[disabled]:active, .btn-dark.btn-shade1[disabled].active,
fieldset[disabled] .btn-dark.btn-shade1,
fieldset[disabled] .btn-dark.btn-shade1:hover,
fieldset[disabled] .btn-dark.btn-shade1:focus,
fieldset[disabled] .btn-dark.btn-shade1.focus,
fieldset[disabled] .btn-dark.btn-shade1:active,
fieldset[disabled] .btn-dark.btn-shade1.active {
  background-color: #353434;
  border-color: #353434;
}

.btn-dark.btn-shade1 .badge {
  color: #353434;
}

.btn-dark.btn-shade2 {
  background-color: #302f2f;
  border-color: #302f2f;
}

.btn-dark.btn-shade2:focus, .btn-dark.btn-shade2.focus {
  background-color: #302f2f;
  border-color: #302f2f;
}

.btn-dark.btn-shade2:hover {
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-dark.btn-shade2:active, .btn-dark.btn-shade2.active,
.show > .btn-dark.btn-shade2.dropdown-toggle {
  background-color: #282828;
  border-color: #282828;
}

.btn-dark.btn-shade2:active:hover, .btn-dark.btn-shade2:active:focus, .btn-dark.btn-shade2:active.focus, .btn-dark.btn-shade2.active:hover, .btn-dark.btn-shade2.active:focus, .btn-dark.btn-shade2.active.focus,
.show > .btn-dark.btn-shade2.dropdown-toggle:hover,
.show > .btn-dark.btn-shade2.dropdown-toggle:focus,
.show > .btn-dark.btn-shade2.dropdown-toggle.focus {
  background-color: #282828;
  border-color: #282828;
}

.btn-dark.btn-shade2.disabled, .btn-dark.btn-shade2.disabled:hover, .btn-dark.btn-shade2.disabled:focus, .btn-dark.btn-shade2.disabled.focus, .btn-dark.btn-shade2.disabled:active, .btn-dark.btn-shade2.disabled.active, .btn-dark.btn-shade2[disabled], .btn-dark.btn-shade2[disabled]:hover, .btn-dark.btn-shade2[disabled]:focus, .btn-dark.btn-shade2[disabled].focus, .btn-dark.btn-shade2[disabled]:active, .btn-dark.btn-shade2[disabled].active,
fieldset[disabled] .btn-dark.btn-shade2,
fieldset[disabled] .btn-dark.btn-shade2:hover,
fieldset[disabled] .btn-dark.btn-shade2:focus,
fieldset[disabled] .btn-dark.btn-shade2.focus,
fieldset[disabled] .btn-dark.btn-shade2:active,
fieldset[disabled] .btn-dark.btn-shade2.active {
  background-color: #302f2f;
  border-color: #302f2f;
}

.btn-dark.btn-shade2 .badge {
  color: #302f2f;
}

.btn-dark.btn-shade3 {
  background-color: #2b2a2a;
  border-color: #2b2a2a;
}

.btn-dark.btn-shade3:focus, .btn-dark.btn-shade3.focus {
  background-color: #2b2a2a;
  border-color: #2b2a2a;
}

.btn-dark.btn-shade3:hover {
  background-color: #383737;
  border-color: #383737;
}

.btn-dark.btn-shade3:active, .btn-dark.btn-shade3.active,
.show > .btn-dark.btn-shade3.dropdown-toggle {
  background-color: #232323;
  border-color: #232323;
}

.btn-dark.btn-shade3:active:hover, .btn-dark.btn-shade3:active:focus, .btn-dark.btn-shade3:active.focus, .btn-dark.btn-shade3.active:hover, .btn-dark.btn-shade3.active:focus, .btn-dark.btn-shade3.active.focus,
.show > .btn-dark.btn-shade3.dropdown-toggle:hover,
.show > .btn-dark.btn-shade3.dropdown-toggle:focus,
.show > .btn-dark.btn-shade3.dropdown-toggle.focus {
  background-color: #232323;
  border-color: #232323;
}

.btn-dark.btn-shade3.disabled, .btn-dark.btn-shade3.disabled:hover, .btn-dark.btn-shade3.disabled:focus, .btn-dark.btn-shade3.disabled.focus, .btn-dark.btn-shade3.disabled:active, .btn-dark.btn-shade3.disabled.active, .btn-dark.btn-shade3[disabled], .btn-dark.btn-shade3[disabled]:hover, .btn-dark.btn-shade3[disabled]:focus, .btn-dark.btn-shade3[disabled].focus, .btn-dark.btn-shade3[disabled]:active, .btn-dark.btn-shade3[disabled].active,
fieldset[disabled] .btn-dark.btn-shade3,
fieldset[disabled] .btn-dark.btn-shade3:hover,
fieldset[disabled] .btn-dark.btn-shade3:focus,
fieldset[disabled] .btn-dark.btn-shade3.focus,
fieldset[disabled] .btn-dark.btn-shade3:active,
fieldset[disabled] .btn-dark.btn-shade3.active {
  background-color: #2b2a2a;
  border-color: #2b2a2a;
}

.btn-dark.btn-shade3 .badge {
  color: #2b2a2a;
}

.btn-dark.btn-shade4 {
  background-color: #262525;
  border-color: #262525;
}

.btn-dark.btn-shade4:focus, .btn-dark.btn-shade4.focus {
  background-color: #262525;
  border-color: #262525;
}

.btn-dark.btn-shade4:hover {
  background-color: #333232;
  border-color: #333232;
}

.btn-dark.btn-shade4:active, .btn-dark.btn-shade4.active,
.show > .btn-dark.btn-shade4.dropdown-toggle {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
}

.btn-dark.btn-shade4:active:hover, .btn-dark.btn-shade4:active:focus, .btn-dark.btn-shade4:active.focus, .btn-dark.btn-shade4.active:hover, .btn-dark.btn-shade4.active:focus, .btn-dark.btn-shade4.active.focus,
.show > .btn-dark.btn-shade4.dropdown-toggle:hover,
.show > .btn-dark.btn-shade4.dropdown-toggle:focus,
.show > .btn-dark.btn-shade4.dropdown-toggle.focus {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
}

.btn-dark.btn-shade4.disabled, .btn-dark.btn-shade4.disabled:hover, .btn-dark.btn-shade4.disabled:focus, .btn-dark.btn-shade4.disabled.focus, .btn-dark.btn-shade4.disabled:active, .btn-dark.btn-shade4.disabled.active, .btn-dark.btn-shade4[disabled], .btn-dark.btn-shade4[disabled]:hover, .btn-dark.btn-shade4[disabled]:focus, .btn-dark.btn-shade4[disabled].focus, .btn-dark.btn-shade4[disabled]:active, .btn-dark.btn-shade4[disabled].active,
fieldset[disabled] .btn-dark.btn-shade4,
fieldset[disabled] .btn-dark.btn-shade4:hover,
fieldset[disabled] .btn-dark.btn-shade4:focus,
fieldset[disabled] .btn-dark.btn-shade4.focus,
fieldset[disabled] .btn-dark.btn-shade4:active,
fieldset[disabled] .btn-dark.btn-shade4.active {
  background-color: #262525;
  border-color: #262525;
}

.btn-dark.btn-shade4 .badge {
  color: #262525;
}

.btn-alt1.btn-shade1 {
  background-color: #49bee5;
  border-color: #49bee5;
}

.btn-alt1.btn-shade1:focus, .btn-alt1.btn-shade1.focus {
  background-color: #49bee5;
  border-color: #49bee5;
}

.btn-alt1.btn-shade1:hover {
  background-color: #5fc6e8;
  border-color: #5fc6e8;
}

.btn-alt1.btn-shade1:active, .btn-alt1.btn-shade1.active,
.show > .btn-alt1.btn-shade1.dropdown-toggle {
  background-color: #3cb9e3;
  border-color: #3cb9e3;
}

.btn-alt1.btn-shade1:active:hover, .btn-alt1.btn-shade1:active:focus, .btn-alt1.btn-shade1:active.focus, .btn-alt1.btn-shade1.active:hover, .btn-alt1.btn-shade1.active:focus, .btn-alt1.btn-shade1.active.focus,
.show > .btn-alt1.btn-shade1.dropdown-toggle:hover,
.show > .btn-alt1.btn-shade1.dropdown-toggle:focus,
.show > .btn-alt1.btn-shade1.dropdown-toggle.focus {
  background-color: #3cb9e3;
  border-color: #3cb9e3;
}

.btn-alt1.btn-shade1.disabled, .btn-alt1.btn-shade1.disabled:hover, .btn-alt1.btn-shade1.disabled:focus, .btn-alt1.btn-shade1.disabled.focus, .btn-alt1.btn-shade1.disabled:active, .btn-alt1.btn-shade1.disabled.active, .btn-alt1.btn-shade1[disabled], .btn-alt1.btn-shade1[disabled]:hover, .btn-alt1.btn-shade1[disabled]:focus, .btn-alt1.btn-shade1[disabled].focus, .btn-alt1.btn-shade1[disabled]:active, .btn-alt1.btn-shade1[disabled].active,
fieldset[disabled] .btn-alt1.btn-shade1,
fieldset[disabled] .btn-alt1.btn-shade1:hover,
fieldset[disabled] .btn-alt1.btn-shade1:focus,
fieldset[disabled] .btn-alt1.btn-shade1.focus,
fieldset[disabled] .btn-alt1.btn-shade1:active,
fieldset[disabled] .btn-alt1.btn-shade1.active {
  background-color: #49bee5;
  border-color: #49bee5;
}

.btn-alt1.btn-shade1 .badge {
  color: #49bee5;
}

.btn-alt1.btn-shade2 {
  background-color: #40bbe4;
  border-color: #40bbe4;
}

.btn-alt1.btn-shade2:focus, .btn-alt1.btn-shade2.focus {
  background-color: #40bbe4;
  border-color: #40bbe4;
}

.btn-alt1.btn-shade2:hover {
  background-color: #56c3e7;
  border-color: #56c3e7;
}

.btn-alt1.btn-shade2:active, .btn-alt1.btn-shade2.active,
.show > .btn-alt1.btn-shade2.dropdown-toggle {
  background-color: #33b6e2;
  border-color: #33b6e2;
}

.btn-alt1.btn-shade2:active:hover, .btn-alt1.btn-shade2:active:focus, .btn-alt1.btn-shade2:active.focus, .btn-alt1.btn-shade2.active:hover, .btn-alt1.btn-shade2.active:focus, .btn-alt1.btn-shade2.active.focus,
.show > .btn-alt1.btn-shade2.dropdown-toggle:hover,
.show > .btn-alt1.btn-shade2.dropdown-toggle:focus,
.show > .btn-alt1.btn-shade2.dropdown-toggle.focus {
  background-color: #33b6e2;
  border-color: #33b6e2;
}

.btn-alt1.btn-shade2.disabled, .btn-alt1.btn-shade2.disabled:hover, .btn-alt1.btn-shade2.disabled:focus, .btn-alt1.btn-shade2.disabled.focus, .btn-alt1.btn-shade2.disabled:active, .btn-alt1.btn-shade2.disabled.active, .btn-alt1.btn-shade2[disabled], .btn-alt1.btn-shade2[disabled]:hover, .btn-alt1.btn-shade2[disabled]:focus, .btn-alt1.btn-shade2[disabled].focus, .btn-alt1.btn-shade2[disabled]:active, .btn-alt1.btn-shade2[disabled].active,
fieldset[disabled] .btn-alt1.btn-shade2,
fieldset[disabled] .btn-alt1.btn-shade2:hover,
fieldset[disabled] .btn-alt1.btn-shade2:focus,
fieldset[disabled] .btn-alt1.btn-shade2.focus,
fieldset[disabled] .btn-alt1.btn-shade2:active,
fieldset[disabled] .btn-alt1.btn-shade2.active {
  background-color: #40bbe4;
  border-color: #40bbe4;
}

.btn-alt1.btn-shade2 .badge {
  color: #40bbe4;
}

.btn-alt1.btn-shade3 {
  background-color: #37b7e3;
  border-color: #37b7e3;
}

.btn-alt1.btn-shade3:focus, .btn-alt1.btn-shade3.focus {
  background-color: #37b7e3;
  border-color: #37b7e3;
}

.btn-alt1.btn-shade3:hover {
  background-color: #4ebfe6;
  border-color: #4ebfe6;
}

.btn-alt1.btn-shade3:active, .btn-alt1.btn-shade3.active,
.show > .btn-alt1.btn-shade3.dropdown-toggle {
  background-color: #2ab3e1;
  border-color: #2ab3e1;
}

.btn-alt1.btn-shade3:active:hover, .btn-alt1.btn-shade3:active:focus, .btn-alt1.btn-shade3:active.focus, .btn-alt1.btn-shade3.active:hover, .btn-alt1.btn-shade3.active:focus, .btn-alt1.btn-shade3.active.focus,
.show > .btn-alt1.btn-shade3.dropdown-toggle:hover,
.show > .btn-alt1.btn-shade3.dropdown-toggle:focus,
.show > .btn-alt1.btn-shade3.dropdown-toggle.focus {
  background-color: #2ab3e1;
  border-color: #2ab3e1;
}

.btn-alt1.btn-shade3.disabled, .btn-alt1.btn-shade3.disabled:hover, .btn-alt1.btn-shade3.disabled:focus, .btn-alt1.btn-shade3.disabled.focus, .btn-alt1.btn-shade3.disabled:active, .btn-alt1.btn-shade3.disabled.active, .btn-alt1.btn-shade3[disabled], .btn-alt1.btn-shade3[disabled]:hover, .btn-alt1.btn-shade3[disabled]:focus, .btn-alt1.btn-shade3[disabled].focus, .btn-alt1.btn-shade3[disabled]:active, .btn-alt1.btn-shade3[disabled].active,
fieldset[disabled] .btn-alt1.btn-shade3,
fieldset[disabled] .btn-alt1.btn-shade3:hover,
fieldset[disabled] .btn-alt1.btn-shade3:focus,
fieldset[disabled] .btn-alt1.btn-shade3.focus,
fieldset[disabled] .btn-alt1.btn-shade3:active,
fieldset[disabled] .btn-alt1.btn-shade3.active {
  background-color: #37b7e3;
  border-color: #37b7e3;
}

.btn-alt1.btn-shade3 .badge {
  color: #37b7e3;
}

.btn-alt1.btn-shade4 {
  background-color: #2eb4e1;
  border-color: #2eb4e1;
}

.btn-alt1.btn-shade4:focus, .btn-alt1.btn-shade4.focus {
  background-color: #2eb4e1;
  border-color: #2eb4e1;
}

.btn-alt1.btn-shade4:hover {
  background-color: #45bce5;
  border-color: #45bce5;
}

.btn-alt1.btn-shade4:active, .btn-alt1.btn-shade4.active,
.show > .btn-alt1.btn-shade4.dropdown-toggle {
  background-color: #21afe0;
  border-color: #21afe0;
}

.btn-alt1.btn-shade4:active:hover, .btn-alt1.btn-shade4:active:focus, .btn-alt1.btn-shade4:active.focus, .btn-alt1.btn-shade4.active:hover, .btn-alt1.btn-shade4.active:focus, .btn-alt1.btn-shade4.active.focus,
.show > .btn-alt1.btn-shade4.dropdown-toggle:hover,
.show > .btn-alt1.btn-shade4.dropdown-toggle:focus,
.show > .btn-alt1.btn-shade4.dropdown-toggle.focus {
  background-color: #21afe0;
  border-color: #21afe0;
}

.btn-alt1.btn-shade4.disabled, .btn-alt1.btn-shade4.disabled:hover, .btn-alt1.btn-shade4.disabled:focus, .btn-alt1.btn-shade4.disabled.focus, .btn-alt1.btn-shade4.disabled:active, .btn-alt1.btn-shade4.disabled.active, .btn-alt1.btn-shade4[disabled], .btn-alt1.btn-shade4[disabled]:hover, .btn-alt1.btn-shade4[disabled]:focus, .btn-alt1.btn-shade4[disabled].focus, .btn-alt1.btn-shade4[disabled]:active, .btn-alt1.btn-shade4[disabled].active,
fieldset[disabled] .btn-alt1.btn-shade4,
fieldset[disabled] .btn-alt1.btn-shade4:hover,
fieldset[disabled] .btn-alt1.btn-shade4:focus,
fieldset[disabled] .btn-alt1.btn-shade4.focus,
fieldset[disabled] .btn-alt1.btn-shade4:active,
fieldset[disabled] .btn-alt1.btn-shade4.active {
  background-color: #2eb4e1;
  border-color: #2eb4e1;
}

.btn-alt1.btn-shade4 .badge {
  color: #2eb4e1;
}

.btn-alt2.btn-shade1 {
  background-color: #ffca56;
  border-color: #ffca56;
}

.btn-alt2.btn-shade1:focus, .btn-alt2.btn-shade1.focus {
  background-color: #ffca56;
  border-color: #ffca56;
}

.btn-alt2.btn-shade1:hover {
  background-color: #ffd270;
  border-color: #ffd270;
}

.btn-alt2.btn-shade1:active, .btn-alt2.btn-shade1.active,
.show > .btn-alt2.btn-shade1.dropdown-toggle {
  background-color: #ffc547;
  border-color: #ffc547;
}

.btn-alt2.btn-shade1:active:hover, .btn-alt2.btn-shade1:active:focus, .btn-alt2.btn-shade1:active.focus, .btn-alt2.btn-shade1.active:hover, .btn-alt2.btn-shade1.active:focus, .btn-alt2.btn-shade1.active.focus,
.show > .btn-alt2.btn-shade1.dropdown-toggle:hover,
.show > .btn-alt2.btn-shade1.dropdown-toggle:focus,
.show > .btn-alt2.btn-shade1.dropdown-toggle.focus {
  background-color: #ffc547;
  border-color: #ffc547;
}

.btn-alt2.btn-shade1.disabled, .btn-alt2.btn-shade1.disabled:hover, .btn-alt2.btn-shade1.disabled:focus, .btn-alt2.btn-shade1.disabled.focus, .btn-alt2.btn-shade1.disabled:active, .btn-alt2.btn-shade1.disabled.active, .btn-alt2.btn-shade1[disabled], .btn-alt2.btn-shade1[disabled]:hover, .btn-alt2.btn-shade1[disabled]:focus, .btn-alt2.btn-shade1[disabled].focus, .btn-alt2.btn-shade1[disabled]:active, .btn-alt2.btn-shade1[disabled].active,
fieldset[disabled] .btn-alt2.btn-shade1,
fieldset[disabled] .btn-alt2.btn-shade1:hover,
fieldset[disabled] .btn-alt2.btn-shade1:focus,
fieldset[disabled] .btn-alt2.btn-shade1.focus,
fieldset[disabled] .btn-alt2.btn-shade1:active,
fieldset[disabled] .btn-alt2.btn-shade1.active {
  background-color: #ffca56;
  border-color: #ffca56;
}

.btn-alt2.btn-shade1 .badge {
  color: #ffca56;
}

.btn-alt2.btn-shade2 {
  background-color: #ffc74c;
  border-color: #ffc74c;
}

.btn-alt2.btn-shade2:focus, .btn-alt2.btn-shade2.focus {
  background-color: #ffc74c;
  border-color: #ffc74c;
}

.btn-alt2.btn-shade2:hover {
  background-color: #ffcf66;
  border-color: #ffcf66;
}

.btn-alt2.btn-shade2:active, .btn-alt2.btn-shade2.active,
.show > .btn-alt2.btn-shade2.dropdown-toggle {
  background-color: #ffc23d;
  border-color: #ffc23d;
}

.btn-alt2.btn-shade2:active:hover, .btn-alt2.btn-shade2:active:focus, .btn-alt2.btn-shade2:active.focus, .btn-alt2.btn-shade2.active:hover, .btn-alt2.btn-shade2.active:focus, .btn-alt2.btn-shade2.active.focus,
.show > .btn-alt2.btn-shade2.dropdown-toggle:hover,
.show > .btn-alt2.btn-shade2.dropdown-toggle:focus,
.show > .btn-alt2.btn-shade2.dropdown-toggle.focus {
  background-color: #ffc23d;
  border-color: #ffc23d;
}

.btn-alt2.btn-shade2.disabled, .btn-alt2.btn-shade2.disabled:hover, .btn-alt2.btn-shade2.disabled:focus, .btn-alt2.btn-shade2.disabled.focus, .btn-alt2.btn-shade2.disabled:active, .btn-alt2.btn-shade2.disabled.active, .btn-alt2.btn-shade2[disabled], .btn-alt2.btn-shade2[disabled]:hover, .btn-alt2.btn-shade2[disabled]:focus, .btn-alt2.btn-shade2[disabled].focus, .btn-alt2.btn-shade2[disabled]:active, .btn-alt2.btn-shade2[disabled].active,
fieldset[disabled] .btn-alt2.btn-shade2,
fieldset[disabled] .btn-alt2.btn-shade2:hover,
fieldset[disabled] .btn-alt2.btn-shade2:focus,
fieldset[disabled] .btn-alt2.btn-shade2.focus,
fieldset[disabled] .btn-alt2.btn-shade2:active,
fieldset[disabled] .btn-alt2.btn-shade2.active {
  background-color: #ffc74c;
  border-color: #ffc74c;
}

.btn-alt2.btn-shade2 .badge {
  color: #ffc74c;
}

.btn-alt2.btn-shade3 {
  background-color: #ffc442;
  border-color: #ffc442;
}

.btn-alt2.btn-shade3:focus, .btn-alt2.btn-shade3.focus {
  background-color: #ffc442;
  border-color: #ffc442;
}

.btn-alt2.btn-shade3:hover {
  background-color: #ffcc5b;
  border-color: #ffcc5b;
}

.btn-alt2.btn-shade3:active, .btn-alt2.btn-shade3.active,
.show > .btn-alt2.btn-shade3.dropdown-toggle {
  background-color: #ffbf33;
  border-color: #ffbf33;
}

.btn-alt2.btn-shade3:active:hover, .btn-alt2.btn-shade3:active:focus, .btn-alt2.btn-shade3:active.focus, .btn-alt2.btn-shade3.active:hover, .btn-alt2.btn-shade3.active:focus, .btn-alt2.btn-shade3.active.focus,
.show > .btn-alt2.btn-shade3.dropdown-toggle:hover,
.show > .btn-alt2.btn-shade3.dropdown-toggle:focus,
.show > .btn-alt2.btn-shade3.dropdown-toggle.focus {
  background-color: #ffbf33;
  border-color: #ffbf33;
}

.btn-alt2.btn-shade3.disabled, .btn-alt2.btn-shade3.disabled:hover, .btn-alt2.btn-shade3.disabled:focus, .btn-alt2.btn-shade3.disabled.focus, .btn-alt2.btn-shade3.disabled:active, .btn-alt2.btn-shade3.disabled.active, .btn-alt2.btn-shade3[disabled], .btn-alt2.btn-shade3[disabled]:hover, .btn-alt2.btn-shade3[disabled]:focus, .btn-alt2.btn-shade3[disabled].focus, .btn-alt2.btn-shade3[disabled]:active, .btn-alt2.btn-shade3[disabled].active,
fieldset[disabled] .btn-alt2.btn-shade3,
fieldset[disabled] .btn-alt2.btn-shade3:hover,
fieldset[disabled] .btn-alt2.btn-shade3:focus,
fieldset[disabled] .btn-alt2.btn-shade3.focus,
fieldset[disabled] .btn-alt2.btn-shade3:active,
fieldset[disabled] .btn-alt2.btn-shade3.active {
  background-color: #ffc442;
  border-color: #ffc442;
}

.btn-alt2.btn-shade3 .badge {
  color: #ffc442;
}

.btn-alt2.btn-shade4 {
  background-color: #ffc138;
  border-color: #ffc138;
}

.btn-alt2.btn-shade4:focus, .btn-alt2.btn-shade4.focus {
  background-color: #ffc138;
  border-color: #ffc138;
}

.btn-alt2.btn-shade4:hover {
  background-color: #ffc951;
  border-color: #ffc951;
}

.btn-alt2.btn-shade4:active, .btn-alt2.btn-shade4.active,
.show > .btn-alt2.btn-shade4.dropdown-toggle {
  background-color: #ffbc28;
  border-color: #ffbc28;
}

.btn-alt2.btn-shade4:active:hover, .btn-alt2.btn-shade4:active:focus, .btn-alt2.btn-shade4:active.focus, .btn-alt2.btn-shade4.active:hover, .btn-alt2.btn-shade4.active:focus, .btn-alt2.btn-shade4.active.focus,
.show > .btn-alt2.btn-shade4.dropdown-toggle:hover,
.show > .btn-alt2.btn-shade4.dropdown-toggle:focus,
.show > .btn-alt2.btn-shade4.dropdown-toggle.focus {
  background-color: #ffbc28;
  border-color: #ffbc28;
}

.btn-alt2.btn-shade4.disabled, .btn-alt2.btn-shade4.disabled:hover, .btn-alt2.btn-shade4.disabled:focus, .btn-alt2.btn-shade4.disabled.focus, .btn-alt2.btn-shade4.disabled:active, .btn-alt2.btn-shade4.disabled.active, .btn-alt2.btn-shade4[disabled], .btn-alt2.btn-shade4[disabled]:hover, .btn-alt2.btn-shade4[disabled]:focus, .btn-alt2.btn-shade4[disabled].focus, .btn-alt2.btn-shade4[disabled]:active, .btn-alt2.btn-shade4[disabled].active,
fieldset[disabled] .btn-alt2.btn-shade4,
fieldset[disabled] .btn-alt2.btn-shade4:hover,
fieldset[disabled] .btn-alt2.btn-shade4:focus,
fieldset[disabled] .btn-alt2.btn-shade4.focus,
fieldset[disabled] .btn-alt2.btn-shade4:active,
fieldset[disabled] .btn-alt2.btn-shade4.active {
  background-color: #ffc138;
  border-color: #ffc138;
}

.btn-alt2.btn-shade4 .badge {
  color: #ffc138;
}

.btn-alt3.btn-shade1 {
  background-color: #7090ba;
  border-color: #7090ba;
}

.btn-alt3.btn-shade1:focus, .btn-alt3.btn-shade1.focus {
  background-color: #7090ba;
  border-color: #7090ba;
}

.btn-alt3.btn-shade1:hover {
  background-color: #819dc2;
  border-color: #819dc2;
}

.btn-alt3.btn-shade1:active, .btn-alt3.btn-shade1.active,
.show > .btn-alt3.btn-shade1.dropdown-toggle {
  background-color: #6588b5;
  border-color: #6588b5;
}

.btn-alt3.btn-shade1:active:hover, .btn-alt3.btn-shade1:active:focus, .btn-alt3.btn-shade1:active.focus, .btn-alt3.btn-shade1.active:hover, .btn-alt3.btn-shade1.active:focus, .btn-alt3.btn-shade1.active.focus,
.show > .btn-alt3.btn-shade1.dropdown-toggle:hover,
.show > .btn-alt3.btn-shade1.dropdown-toggle:focus,
.show > .btn-alt3.btn-shade1.dropdown-toggle.focus {
  background-color: #6588b5;
  border-color: #6588b5;
}

.btn-alt3.btn-shade1.disabled, .btn-alt3.btn-shade1.disabled:hover, .btn-alt3.btn-shade1.disabled:focus, .btn-alt3.btn-shade1.disabled.focus, .btn-alt3.btn-shade1.disabled:active, .btn-alt3.btn-shade1.disabled.active, .btn-alt3.btn-shade1[disabled], .btn-alt3.btn-shade1[disabled]:hover, .btn-alt3.btn-shade1[disabled]:focus, .btn-alt3.btn-shade1[disabled].focus, .btn-alt3.btn-shade1[disabled]:active, .btn-alt3.btn-shade1[disabled].active,
fieldset[disabled] .btn-alt3.btn-shade1,
fieldset[disabled] .btn-alt3.btn-shade1:hover,
fieldset[disabled] .btn-alt3.btn-shade1:focus,
fieldset[disabled] .btn-alt3.btn-shade1.focus,
fieldset[disabled] .btn-alt3.btn-shade1:active,
fieldset[disabled] .btn-alt3.btn-shade1.active {
  background-color: #7090ba;
  border-color: #7090ba;
}

.btn-alt3.btn-shade1 .badge {
  color: #7090ba;
}

.btn-alt3.btn-shade2 {
  background-color: #698bb7;
  border-color: #698bb7;
}

.btn-alt3.btn-shade2:focus, .btn-alt3.btn-shade2.focus {
  background-color: #698bb7;
  border-color: #698bb7;
}

.btn-alt3.btn-shade2:hover {
  background-color: #7a98bf;
  border-color: #7a98bf;
}

.btn-alt3.btn-shade2:active, .btn-alt3.btn-shade2.active,
.show > .btn-alt3.btn-shade2.dropdown-toggle {
  background-color: #5e83b2;
  border-color: #5e83b2;
}

.btn-alt3.btn-shade2:active:hover, .btn-alt3.btn-shade2:active:focus, .btn-alt3.btn-shade2:active.focus, .btn-alt3.btn-shade2.active:hover, .btn-alt3.btn-shade2.active:focus, .btn-alt3.btn-shade2.active.focus,
.show > .btn-alt3.btn-shade2.dropdown-toggle:hover,
.show > .btn-alt3.btn-shade2.dropdown-toggle:focus,
.show > .btn-alt3.btn-shade2.dropdown-toggle.focus {
  background-color: #5e83b2;
  border-color: #5e83b2;
}

.btn-alt3.btn-shade2.disabled, .btn-alt3.btn-shade2.disabled:hover, .btn-alt3.btn-shade2.disabled:focus, .btn-alt3.btn-shade2.disabled.focus, .btn-alt3.btn-shade2.disabled:active, .btn-alt3.btn-shade2.disabled.active, .btn-alt3.btn-shade2[disabled], .btn-alt3.btn-shade2[disabled]:hover, .btn-alt3.btn-shade2[disabled]:focus, .btn-alt3.btn-shade2[disabled].focus, .btn-alt3.btn-shade2[disabled]:active, .btn-alt3.btn-shade2[disabled].active,
fieldset[disabled] .btn-alt3.btn-shade2,
fieldset[disabled] .btn-alt3.btn-shade2:hover,
fieldset[disabled] .btn-alt3.btn-shade2:focus,
fieldset[disabled] .btn-alt3.btn-shade2.focus,
fieldset[disabled] .btn-alt3.btn-shade2:active,
fieldset[disabled] .btn-alt3.btn-shade2.active {
  background-color: #698bb7;
  border-color: #698bb7;
}

.btn-alt3.btn-shade2 .badge {
  color: #698bb7;
}

.btn-alt3.btn-shade3 {
  background-color: #6285b3;
  border-color: #6285b3;
}

.btn-alt3.btn-shade3:focus, .btn-alt3.btn-shade3.focus {
  background-color: #6285b3;
  border-color: #6285b3;
}

.btn-alt3.btn-shade3:hover {
  background-color: #7393bc;
  border-color: #7393bc;
}

.btn-alt3.btn-shade3:active, .btn-alt3.btn-shade3.active,
.show > .btn-alt3.btn-shade3.dropdown-toggle {
  background-color: #587dae;
  border-color: #587dae;
}

.btn-alt3.btn-shade3:active:hover, .btn-alt3.btn-shade3:active:focus, .btn-alt3.btn-shade3:active.focus, .btn-alt3.btn-shade3.active:hover, .btn-alt3.btn-shade3.active:focus, .btn-alt3.btn-shade3.active.focus,
.show > .btn-alt3.btn-shade3.dropdown-toggle:hover,
.show > .btn-alt3.btn-shade3.dropdown-toggle:focus,
.show > .btn-alt3.btn-shade3.dropdown-toggle.focus {
  background-color: #587dae;
  border-color: #587dae;
}

.btn-alt3.btn-shade3.disabled, .btn-alt3.btn-shade3.disabled:hover, .btn-alt3.btn-shade3.disabled:focus, .btn-alt3.btn-shade3.disabled.focus, .btn-alt3.btn-shade3.disabled:active, .btn-alt3.btn-shade3.disabled.active, .btn-alt3.btn-shade3[disabled], .btn-alt3.btn-shade3[disabled]:hover, .btn-alt3.btn-shade3[disabled]:focus, .btn-alt3.btn-shade3[disabled].focus, .btn-alt3.btn-shade3[disabled]:active, .btn-alt3.btn-shade3[disabled].active,
fieldset[disabled] .btn-alt3.btn-shade3,
fieldset[disabled] .btn-alt3.btn-shade3:hover,
fieldset[disabled] .btn-alt3.btn-shade3:focus,
fieldset[disabled] .btn-alt3.btn-shade3.focus,
fieldset[disabled] .btn-alt3.btn-shade3:active,
fieldset[disabled] .btn-alt3.btn-shade3.active {
  background-color: #6285b3;
  border-color: #6285b3;
}

.btn-alt3.btn-shade3 .badge {
  color: #6285b3;
}

.btn-alt3.btn-shade4 {
  background-color: #5b80b0;
  border-color: #5b80b0;
}

.btn-alt3.btn-shade4:focus, .btn-alt3.btn-shade4.focus {
  background-color: #5b80b0;
  border-color: #5b80b0;
}

.btn-alt3.btn-shade4:hover {
  background-color: #6c8db8;
  border-color: #6c8db8;
}

.btn-alt3.btn-shade4:active, .btn-alt3.btn-shade4.active,
.show > .btn-alt3.btn-shade4.dropdown-toggle {
  background-color: #5278aa;
  border-color: #5278aa;
}

.btn-alt3.btn-shade4:active:hover, .btn-alt3.btn-shade4:active:focus, .btn-alt3.btn-shade4:active.focus, .btn-alt3.btn-shade4.active:hover, .btn-alt3.btn-shade4.active:focus, .btn-alt3.btn-shade4.active.focus,
.show > .btn-alt3.btn-shade4.dropdown-toggle:hover,
.show > .btn-alt3.btn-shade4.dropdown-toggle:focus,
.show > .btn-alt3.btn-shade4.dropdown-toggle.focus {
  background-color: #5278aa;
  border-color: #5278aa;
}

.btn-alt3.btn-shade4.disabled, .btn-alt3.btn-shade4.disabled:hover, .btn-alt3.btn-shade4.disabled:focus, .btn-alt3.btn-shade4.disabled.focus, .btn-alt3.btn-shade4.disabled:active, .btn-alt3.btn-shade4.disabled.active, .btn-alt3.btn-shade4[disabled], .btn-alt3.btn-shade4[disabled]:hover, .btn-alt3.btn-shade4[disabled]:focus, .btn-alt3.btn-shade4[disabled].focus, .btn-alt3.btn-shade4[disabled]:active, .btn-alt3.btn-shade4[disabled].active,
fieldset[disabled] .btn-alt3.btn-shade4,
fieldset[disabled] .btn-alt3.btn-shade4:hover,
fieldset[disabled] .btn-alt3.btn-shade4:focus,
fieldset[disabled] .btn-alt3.btn-shade4.focus,
fieldset[disabled] .btn-alt3.btn-shade4:active,
fieldset[disabled] .btn-alt3.btn-shade4.active {
  background-color: #5b80b0;
  border-color: #5b80b0;
}

.btn-alt3.btn-shade4 .badge {
  color: #5b80b0;
}

.btn-alt4.btn-shade1 {
  background-color: #b5b5b5;
  border-color: #b5b5b5;
}

.btn-alt4.btn-shade1:focus, .btn-alt4.btn-shade1.focus {
  background-color: #b5b5b5;
  border-color: #b5b5b5;
}

.btn-alt4.btn-shade1:hover {
  background-color: #c2c2c2;
  border-color: #c2c2c2;
}

.btn-alt4.btn-shade1:active, .btn-alt4.btn-shade1.active,
.show > .btn-alt4.btn-shade1.dropdown-toggle {
  background-color: #adadad;
  border-color: #adadad;
}

.btn-alt4.btn-shade1:active:hover, .btn-alt4.btn-shade1:active:focus, .btn-alt4.btn-shade1:active.focus, .btn-alt4.btn-shade1.active:hover, .btn-alt4.btn-shade1.active:focus, .btn-alt4.btn-shade1.active.focus,
.show > .btn-alt4.btn-shade1.dropdown-toggle:hover,
.show > .btn-alt4.btn-shade1.dropdown-toggle:focus,
.show > .btn-alt4.btn-shade1.dropdown-toggle.focus {
  background-color: #adadad;
  border-color: #adadad;
}

.btn-alt4.btn-shade1.disabled, .btn-alt4.btn-shade1.disabled:hover, .btn-alt4.btn-shade1.disabled:focus, .btn-alt4.btn-shade1.disabled.focus, .btn-alt4.btn-shade1.disabled:active, .btn-alt4.btn-shade1.disabled.active, .btn-alt4.btn-shade1[disabled], .btn-alt4.btn-shade1[disabled]:hover, .btn-alt4.btn-shade1[disabled]:focus, .btn-alt4.btn-shade1[disabled].focus, .btn-alt4.btn-shade1[disabled]:active, .btn-alt4.btn-shade1[disabled].active,
fieldset[disabled] .btn-alt4.btn-shade1,
fieldset[disabled] .btn-alt4.btn-shade1:hover,
fieldset[disabled] .btn-alt4.btn-shade1:focus,
fieldset[disabled] .btn-alt4.btn-shade1.focus,
fieldset[disabled] .btn-alt4.btn-shade1:active,
fieldset[disabled] .btn-alt4.btn-shade1.active {
  background-color: #b5b5b5;
  border-color: #b5b5b5;
}

.btn-alt4.btn-shade1 .badge {
  color: #b5b5b5;
}

.btn-alt4.btn-shade2 {
  background-color: #b0b0b0;
  border-color: #b0b0b0;
}

.btn-alt4.btn-shade2:focus, .btn-alt4.btn-shade2.focus {
  background-color: #b0b0b0;
  border-color: #b0b0b0;
}

.btn-alt4.btn-shade2:hover {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

.btn-alt4.btn-shade2:active, .btn-alt4.btn-shade2.active,
.show > .btn-alt4.btn-shade2.dropdown-toggle {
  background-color: #a8a8a8;
  border-color: #a8a8a8;
}

.btn-alt4.btn-shade2:active:hover, .btn-alt4.btn-shade2:active:focus, .btn-alt4.btn-shade2:active.focus, .btn-alt4.btn-shade2.active:hover, .btn-alt4.btn-shade2.active:focus, .btn-alt4.btn-shade2.active.focus,
.show > .btn-alt4.btn-shade2.dropdown-toggle:hover,
.show > .btn-alt4.btn-shade2.dropdown-toggle:focus,
.show > .btn-alt4.btn-shade2.dropdown-toggle.focus {
  background-color: #a8a8a8;
  border-color: #a8a8a8;
}

.btn-alt4.btn-shade2.disabled, .btn-alt4.btn-shade2.disabled:hover, .btn-alt4.btn-shade2.disabled:focus, .btn-alt4.btn-shade2.disabled.focus, .btn-alt4.btn-shade2.disabled:active, .btn-alt4.btn-shade2.disabled.active, .btn-alt4.btn-shade2[disabled], .btn-alt4.btn-shade2[disabled]:hover, .btn-alt4.btn-shade2[disabled]:focus, .btn-alt4.btn-shade2[disabled].focus, .btn-alt4.btn-shade2[disabled]:active, .btn-alt4.btn-shade2[disabled].active,
fieldset[disabled] .btn-alt4.btn-shade2,
fieldset[disabled] .btn-alt4.btn-shade2:hover,
fieldset[disabled] .btn-alt4.btn-shade2:focus,
fieldset[disabled] .btn-alt4.btn-shade2.focus,
fieldset[disabled] .btn-alt4.btn-shade2:active,
fieldset[disabled] .btn-alt4.btn-shade2.active {
  background-color: #b0b0b0;
  border-color: #b0b0b0;
}

.btn-alt4.btn-shade2 .badge {
  color: #b0b0b0;
}

.btn-alt4.btn-shade3 {
  background-color: #ababab;
  border-color: #ababab;
}

.btn-alt4.btn-shade3:focus, .btn-alt4.btn-shade3.focus {
  background-color: #ababab;
  border-color: #ababab;
}

.btn-alt4.btn-shade3:hover {
  background-color: #b8b8b8;
  border-color: #b8b8b8;
}

.btn-alt4.btn-shade3:active, .btn-alt4.btn-shade3.active,
.show > .btn-alt4.btn-shade3.dropdown-toggle {
  background-color: #a3a3a3;
  border-color: #a3a3a3;
}

.btn-alt4.btn-shade3:active:hover, .btn-alt4.btn-shade3:active:focus, .btn-alt4.btn-shade3:active.focus, .btn-alt4.btn-shade3.active:hover, .btn-alt4.btn-shade3.active:focus, .btn-alt4.btn-shade3.active.focus,
.show > .btn-alt4.btn-shade3.dropdown-toggle:hover,
.show > .btn-alt4.btn-shade3.dropdown-toggle:focus,
.show > .btn-alt4.btn-shade3.dropdown-toggle.focus {
  background-color: #a3a3a3;
  border-color: #a3a3a3;
}

.btn-alt4.btn-shade3.disabled, .btn-alt4.btn-shade3.disabled:hover, .btn-alt4.btn-shade3.disabled:focus, .btn-alt4.btn-shade3.disabled.focus, .btn-alt4.btn-shade3.disabled:active, .btn-alt4.btn-shade3.disabled.active, .btn-alt4.btn-shade3[disabled], .btn-alt4.btn-shade3[disabled]:hover, .btn-alt4.btn-shade3[disabled]:focus, .btn-alt4.btn-shade3[disabled].focus, .btn-alt4.btn-shade3[disabled]:active, .btn-alt4.btn-shade3[disabled].active,
fieldset[disabled] .btn-alt4.btn-shade3,
fieldset[disabled] .btn-alt4.btn-shade3:hover,
fieldset[disabled] .btn-alt4.btn-shade3:focus,
fieldset[disabled] .btn-alt4.btn-shade3.focus,
fieldset[disabled] .btn-alt4.btn-shade3:active,
fieldset[disabled] .btn-alt4.btn-shade3.active {
  background-color: #ababab;
  border-color: #ababab;
}

.btn-alt4.btn-shade3 .badge {
  color: #ababab;
}

.btn-alt4.btn-shade4 {
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}

.btn-alt4.btn-shade4:focus, .btn-alt4.btn-shade4.focus {
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}

.btn-alt4.btn-shade4:hover {
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}

.btn-alt4.btn-shade4:active, .btn-alt4.btn-shade4.active,
.show > .btn-alt4.btn-shade4.dropdown-toggle {
  background-color: #9e9e9e;
  border-color: #9e9e9e;
}

.btn-alt4.btn-shade4:active:hover, .btn-alt4.btn-shade4:active:focus, .btn-alt4.btn-shade4:active.focus, .btn-alt4.btn-shade4.active:hover, .btn-alt4.btn-shade4.active:focus, .btn-alt4.btn-shade4.active.focus,
.show > .btn-alt4.btn-shade4.dropdown-toggle:hover,
.show > .btn-alt4.btn-shade4.dropdown-toggle:focus,
.show > .btn-alt4.btn-shade4.dropdown-toggle.focus {
  background-color: #9e9e9e;
  border-color: #9e9e9e;
}

.btn-alt4.btn-shade4.disabled, .btn-alt4.btn-shade4.disabled:hover, .btn-alt4.btn-shade4.disabled:focus, .btn-alt4.btn-shade4.disabled.focus, .btn-alt4.btn-shade4.disabled:active, .btn-alt4.btn-shade4.disabled.active, .btn-alt4.btn-shade4[disabled], .btn-alt4.btn-shade4[disabled]:hover, .btn-alt4.btn-shade4[disabled]:focus, .btn-alt4.btn-shade4[disabled].focus, .btn-alt4.btn-shade4[disabled]:active, .btn-alt4.btn-shade4[disabled].active,
fieldset[disabled] .btn-alt4.btn-shade4,
fieldset[disabled] .btn-alt4.btn-shade4:hover,
fieldset[disabled] .btn-alt4.btn-shade4:focus,
fieldset[disabled] .btn-alt4.btn-shade4.focus,
fieldset[disabled] .btn-alt4.btn-shade4:active,
fieldset[disabled] .btn-alt4.btn-shade4.active {
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}

.btn-alt4.btn-shade4 .badge {
  color: #a6a6a6;
}

.btn-secondary.btn-facebook {
  color: #5d82d1;
}

.btn-secondary.btn-facebook:active, .btn-secondary.btn-facebook.active,
.show > .btn-secondary.btn-facebook.dropdown-toggle {
  color: #5d82d1;
}

.btn-secondary.btn-facebook:active:hover, .btn-secondary.btn-facebook:active:focus, .btn-secondary.btn-facebook:active.focus, .btn-secondary.btn-facebook.active:hover, .btn-secondary.btn-facebook.active:focus, .btn-secondary.btn-facebook.active.focus,
.show > .btn-secondary.btn-facebook.dropdown-toggle:hover,
.show > .btn-secondary.btn-facebook.dropdown-toggle:focus,
.show > .btn-secondary.btn-facebook.dropdown-toggle.focus {
  color: #5d82d1;
}

.btn-secondary.btn-twitter {
  color: #50bff5;
}

.btn-secondary.btn-twitter:active, .btn-secondary.btn-twitter.active,
.show > .btn-secondary.btn-twitter.dropdown-toggle {
  color: #50bff5;
}

.btn-secondary.btn-twitter:active:hover, .btn-secondary.btn-twitter:active:focus, .btn-secondary.btn-twitter:active.focus, .btn-secondary.btn-twitter.active:hover, .btn-secondary.btn-twitter.active:focus, .btn-secondary.btn-twitter.active.focus,
.show > .btn-secondary.btn-twitter.dropdown-toggle:hover,
.show > .btn-secondary.btn-twitter.dropdown-toggle:focus,
.show > .btn-secondary.btn-twitter.dropdown-toggle.focus {
  color: #50bff5;
}

.btn-secondary.btn-google-plus {
  color: #eb5e4c;
}

.btn-secondary.btn-google-plus:active, .btn-secondary.btn-google-plus.active,
.show > .btn-secondary.btn-google-plus.dropdown-toggle {
  color: #eb5e4c;
}

.btn-secondary.btn-google-plus:active:hover, .btn-secondary.btn-google-plus:active:focus, .btn-secondary.btn-google-plus:active.focus, .btn-secondary.btn-google-plus.active:hover, .btn-secondary.btn-google-plus.active:focus, .btn-secondary.btn-google-plus.active.focus,
.show > .btn-secondary.btn-google-plus.dropdown-toggle:hover,
.show > .btn-secondary.btn-google-plus.dropdown-toggle:focus,
.show > .btn-secondary.btn-google-plus.dropdown-toggle.focus {
  color: #eb5e4c;
}

.btn-secondary.btn-dribbble {
  color: #f7659c;
}

.btn-secondary.btn-dribbble:active, .btn-secondary.btn-dribbble.active,
.show > .btn-secondary.btn-dribbble.dropdown-toggle {
  color: #f7659c;
}

.btn-secondary.btn-dribbble:active:hover, .btn-secondary.btn-dribbble:active:focus, .btn-secondary.btn-dribbble:active.focus, .btn-secondary.btn-dribbble.active:hover, .btn-secondary.btn-dribbble.active:focus, .btn-secondary.btn-dribbble.active.focus,
.show > .btn-secondary.btn-dribbble.dropdown-toggle:hover,
.show > .btn-secondary.btn-dribbble.dropdown-toggle:focus,
.show > .btn-secondary.btn-dribbble.dropdown-toggle.focus {
  color: #f7659c;
}

.btn-secondary.btn-flickr {
  color: #0063dc;
}

.btn-secondary.btn-flickr:active, .btn-secondary.btn-flickr.active,
.show > .btn-secondary.btn-flickr.dropdown-toggle {
  color: #0063dc;
}

.btn-secondary.btn-flickr:active:hover, .btn-secondary.btn-flickr:active:focus, .btn-secondary.btn-flickr:active.focus, .btn-secondary.btn-flickr.active:hover, .btn-secondary.btn-flickr.active:focus, .btn-secondary.btn-flickr.active.focus,
.show > .btn-secondary.btn-flickr.dropdown-toggle:hover,
.show > .btn-secondary.btn-flickr.dropdown-toggle:focus,
.show > .btn-secondary.btn-flickr.dropdown-toggle.focus {
  color: #0063dc;
}

.btn-secondary.btn-linkedin {
  color: #238cc8;
}

.btn-secondary.btn-linkedin:active, .btn-secondary.btn-linkedin.active,
.show > .btn-secondary.btn-linkedin.dropdown-toggle {
  color: #238cc8;
}

.btn-secondary.btn-linkedin:active:hover, .btn-secondary.btn-linkedin:active:focus, .btn-secondary.btn-linkedin:active.focus, .btn-secondary.btn-linkedin.active:hover, .btn-secondary.btn-linkedin.active:focus, .btn-secondary.btn-linkedin.active.focus,
.show > .btn-secondary.btn-linkedin.dropdown-toggle:hover,
.show > .btn-secondary.btn-linkedin.dropdown-toggle:focus,
.show > .btn-secondary.btn-linkedin.dropdown-toggle.focus {
  color: #238cc8;
}

.btn-secondary.btn-youtube {
  color: #ef4e41;
}

.btn-secondary.btn-youtube:active, .btn-secondary.btn-youtube.active,
.show > .btn-secondary.btn-youtube.dropdown-toggle {
  color: #ef4e41;
}

.btn-secondary.btn-youtube:active:hover, .btn-secondary.btn-youtube:active:focus, .btn-secondary.btn-youtube:active.focus, .btn-secondary.btn-youtube.active:hover, .btn-secondary.btn-youtube.active:focus, .btn-secondary.btn-youtube.active.focus,
.show > .btn-secondary.btn-youtube.dropdown-toggle:hover,
.show > .btn-secondary.btn-youtube.dropdown-toggle:focus,
.show > .btn-secondary.btn-youtube.dropdown-toggle.focus {
  color: #ef4e41;
}

.btn-secondary.btn-pinterest {
  color: #e13138;
}

.btn-secondary.btn-pinterest:active, .btn-secondary.btn-pinterest.active,
.show > .btn-secondary.btn-pinterest.dropdown-toggle {
  color: #e13138;
}

.btn-secondary.btn-pinterest:active:hover, .btn-secondary.btn-pinterest:active:focus, .btn-secondary.btn-pinterest:active.focus, .btn-secondary.btn-pinterest.active:hover, .btn-secondary.btn-pinterest.active:focus, .btn-secondary.btn-pinterest.active.focus,
.show > .btn-secondary.btn-pinterest.dropdown-toggle:hover,
.show > .btn-secondary.btn-pinterest.dropdown-toggle:focus,
.show > .btn-secondary.btn-pinterest.dropdown-toggle.focus {
  color: #e13138;
}

.btn-secondary.btn-github {
  color: #333;
}

.btn-secondary.btn-github:active, .btn-secondary.btn-github.active,
.show > .btn-secondary.btn-github.dropdown-toggle {
  color: #333;
}

.btn-secondary.btn-github:active:hover, .btn-secondary.btn-github:active:focus, .btn-secondary.btn-github:active.focus, .btn-secondary.btn-github.active:hover, .btn-secondary.btn-github.active:focus, .btn-secondary.btn-github.active.focus,
.show > .btn-secondary.btn-github.dropdown-toggle:hover,
.show > .btn-secondary.btn-github.dropdown-toggle:focus,
.show > .btn-secondary.btn-github.dropdown-toggle.focus {
  color: #333;
}

.btn-secondary.btn-tumblr {
  color: #426d9b;
}

.btn-secondary.btn-tumblr:active, .btn-secondary.btn-tumblr.active,
.show > .btn-secondary.btn-tumblr.dropdown-toggle {
  color: #426d9b;
}

.btn-secondary.btn-tumblr:active:hover, .btn-secondary.btn-tumblr:active:focus, .btn-secondary.btn-tumblr:active.focus, .btn-secondary.btn-tumblr.active:hover, .btn-secondary.btn-tumblr.active:focus, .btn-secondary.btn-tumblr.active.focus,
.show > .btn-secondary.btn-tumblr.dropdown-toggle:hover,
.show > .btn-secondary.btn-tumblr.dropdown-toggle:focus,
.show > .btn-secondary.btn-tumblr.dropdown-toggle.focus {
  color: #426d9b;
}

.btn-social.btn-facebook {
  color: #FFFFFF;
  background-color: #5d82d1;
  border-color: #5d82d1;
}

.btn-social.btn-facebook:focus, .btn-social.btn-facebook.focus {
  color: #FFFFFF;
  background-color: #5d82d1;
  border-color: #5d82d1;
}

.btn-social.btn-facebook:hover, .btn-social.btn-facebook.hover {
  color: #FFFFFF;
  background-color: #7191d7;
  border-color: #7191d7;
}

.btn-social.btn-facebook:active, .btn-social.btn-facebook.active, .btn-social.btn-facebook:not([disabled]):not(.disabled):active, .btn-social.btn-facebook:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-facebook.dropdown-toggle {
  color: #FFFFFF;
  background-color: #4973cb;
  border-color: #4973cb;
}

.btn-social.btn-facebook:active:hover, .btn-social.btn-facebook:active:focus, .btn-social.btn-facebook:active.focus, .btn-social.btn-facebook.active:hover, .btn-social.btn-facebook.active:focus, .btn-social.btn-facebook.active.focus, .btn-social.btn-facebook:not([disabled]):not(.disabled):active:hover, .btn-social.btn-facebook:not([disabled]):not(.disabled):active:focus, .btn-social.btn-facebook:not([disabled]):not(.disabled):active.focus, .btn-social.btn-facebook:not([disabled]):not(.disabled).active:hover, .btn-social.btn-facebook:not([disabled]):not(.disabled).active:focus, .btn-social.btn-facebook:not([disabled]):not(.disabled).active.focus,
.show > .btn-social.btn-facebook.dropdown-toggle:hover,
.show > .btn-social.btn-facebook.dropdown-toggle:focus,
.show > .btn-social.btn-facebook.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #4973cb;
  border-color: #4973cb;
}

.btn-social.btn-facebook:active, .btn-social.btn-facebook.active, .btn-social.btn-facebook:not([disabled]):not(.disabled):active, .btn-social.btn-facebook:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-facebook.dropdown-toggle {
  background-image: none;
}

.btn-social.btn-facebook.disabled, .btn-social.btn-facebook.disabled:hover, .btn-social.btn-facebook.disabled:focus, .btn-social.btn-facebook.disabled.focus, .btn-social.btn-facebook.disabled:active, .btn-social.btn-facebook.disabled.active, .btn-social.btn-facebook[disabled], .btn-social.btn-facebook[disabled]:hover, .btn-social.btn-facebook[disabled]:focus, .btn-social.btn-facebook[disabled].focus, .btn-social.btn-facebook[disabled]:active, .btn-social.btn-facebook[disabled].active,
fieldset[disabled] .btn-social.btn-facebook,
fieldset[disabled] .btn-social.btn-facebook:hover,
fieldset[disabled] .btn-social.btn-facebook:focus,
fieldset[disabled] .btn-social.btn-facebook.focus,
fieldset[disabled] .btn-social.btn-facebook:active,
fieldset[disabled] .btn-social.btn-facebook.active {
  color: #FFFFFF;
  background-color: #5d82d1;
  border-color: #5d82d1;
}

.btn-social.btn-facebook .badge {
  color: #5d82d1;
  background-color: #FFFFFF;
}

.btn-social.btn-twitter {
  color: #FFFFFF;
  background-color: #50bff5;
  border-color: #50bff5;
}

.btn-social.btn-twitter:focus, .btn-social.btn-twitter.focus {
  color: #FFFFFF;
  background-color: #50bff5;
  border-color: #50bff5;
}

.btn-social.btn-twitter:hover, .btn-social.btn-twitter.hover {
  color: #FFFFFF;
  background-color: #68c8f6;
  border-color: #68c8f6;
}

.btn-social.btn-twitter:active, .btn-social.btn-twitter.active, .btn-social.btn-twitter:not([disabled]):not(.disabled):active, .btn-social.btn-twitter:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-twitter.dropdown-toggle {
  color: #FFFFFF;
  background-color: #38b6f4;
  border-color: #38b6f4;
}

.btn-social.btn-twitter:active:hover, .btn-social.btn-twitter:active:focus, .btn-social.btn-twitter:active.focus, .btn-social.btn-twitter.active:hover, .btn-social.btn-twitter.active:focus, .btn-social.btn-twitter.active.focus, .btn-social.btn-twitter:not([disabled]):not(.disabled):active:hover, .btn-social.btn-twitter:not([disabled]):not(.disabled):active:focus, .btn-social.btn-twitter:not([disabled]):not(.disabled):active.focus, .btn-social.btn-twitter:not([disabled]):not(.disabled).active:hover, .btn-social.btn-twitter:not([disabled]):not(.disabled).active:focus, .btn-social.btn-twitter:not([disabled]):not(.disabled).active.focus,
.show > .btn-social.btn-twitter.dropdown-toggle:hover,
.show > .btn-social.btn-twitter.dropdown-toggle:focus,
.show > .btn-social.btn-twitter.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #38b6f4;
  border-color: #38b6f4;
}

.btn-social.btn-twitter:active, .btn-social.btn-twitter.active, .btn-social.btn-twitter:not([disabled]):not(.disabled):active, .btn-social.btn-twitter:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-twitter.dropdown-toggle {
  background-image: none;
}

.btn-social.btn-twitter.disabled, .btn-social.btn-twitter.disabled:hover, .btn-social.btn-twitter.disabled:focus, .btn-social.btn-twitter.disabled.focus, .btn-social.btn-twitter.disabled:active, .btn-social.btn-twitter.disabled.active, .btn-social.btn-twitter[disabled], .btn-social.btn-twitter[disabled]:hover, .btn-social.btn-twitter[disabled]:focus, .btn-social.btn-twitter[disabled].focus, .btn-social.btn-twitter[disabled]:active, .btn-social.btn-twitter[disabled].active,
fieldset[disabled] .btn-social.btn-twitter,
fieldset[disabled] .btn-social.btn-twitter:hover,
fieldset[disabled] .btn-social.btn-twitter:focus,
fieldset[disabled] .btn-social.btn-twitter.focus,
fieldset[disabled] .btn-social.btn-twitter:active,
fieldset[disabled] .btn-social.btn-twitter.active {
  color: #FFFFFF;
  background-color: #50bff5;
  border-color: #50bff5;
}

.btn-social.btn-twitter .badge {
  color: #50bff5;
  background-color: #FFFFFF;
}

.btn-social.btn-google-plus {
  color: #FFFFFF;
  background-color: #eb5e4c;
  border-color: #eb5e4c;
}

.btn-social.btn-google-plus:focus, .btn-social.btn-google-plus.focus {
  color: #FFFFFF;
  background-color: #eb5e4c;
  border-color: #eb5e4c;
}

.btn-social.btn-google-plus:hover, .btn-social.btn-google-plus.hover {
  color: #FFFFFF;
  background-color: #ee7363;
  border-color: #ee7363;
}

.btn-social.btn-google-plus:active, .btn-social.btn-google-plus.active, .btn-social.btn-google-plus:not([disabled]):not(.disabled):active, .btn-social.btn-google-plus:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-google-plus.dropdown-toggle {
  color: #FFFFFF;
  background-color: #e84935;
  border-color: #e84935;
}

.btn-social.btn-google-plus:active:hover, .btn-social.btn-google-plus:active:focus, .btn-social.btn-google-plus:active.focus, .btn-social.btn-google-plus.active:hover, .btn-social.btn-google-plus.active:focus, .btn-social.btn-google-plus.active.focus, .btn-social.btn-google-plus:not([disabled]):not(.disabled):active:hover, .btn-social.btn-google-plus:not([disabled]):not(.disabled):active:focus, .btn-social.btn-google-plus:not([disabled]):not(.disabled):active.focus, .btn-social.btn-google-plus:not([disabled]):not(.disabled).active:hover, .btn-social.btn-google-plus:not([disabled]):not(.disabled).active:focus, .btn-social.btn-google-plus:not([disabled]):not(.disabled).active.focus,
.show > .btn-social.btn-google-plus.dropdown-toggle:hover,
.show > .btn-social.btn-google-plus.dropdown-toggle:focus,
.show > .btn-social.btn-google-plus.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #e84935;
  border-color: #e84935;
}

.btn-social.btn-google-plus:active, .btn-social.btn-google-plus.active, .btn-social.btn-google-plus:not([disabled]):not(.disabled):active, .btn-social.btn-google-plus:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-google-plus.dropdown-toggle {
  background-image: none;
}

.btn-social.btn-google-plus.disabled, .btn-social.btn-google-plus.disabled:hover, .btn-social.btn-google-plus.disabled:focus, .btn-social.btn-google-plus.disabled.focus, .btn-social.btn-google-plus.disabled:active, .btn-social.btn-google-plus.disabled.active, .btn-social.btn-google-plus[disabled], .btn-social.btn-google-plus[disabled]:hover, .btn-social.btn-google-plus[disabled]:focus, .btn-social.btn-google-plus[disabled].focus, .btn-social.btn-google-plus[disabled]:active, .btn-social.btn-google-plus[disabled].active,
fieldset[disabled] .btn-social.btn-google-plus,
fieldset[disabled] .btn-social.btn-google-plus:hover,
fieldset[disabled] .btn-social.btn-google-plus:focus,
fieldset[disabled] .btn-social.btn-google-plus.focus,
fieldset[disabled] .btn-social.btn-google-plus:active,
fieldset[disabled] .btn-social.btn-google-plus.active {
  color: #FFFFFF;
  background-color: #eb5e4c;
  border-color: #eb5e4c;
}

.btn-social.btn-google-plus .badge {
  color: #eb5e4c;
  background-color: #FFFFFF;
}

.btn-social.btn-dribbble {
  color: #FFFFFF;
  background-color: #f7659c;
  border-color: #f7659c;
}

.btn-social.btn-dribbble:focus, .btn-social.btn-dribbble.focus {
  color: #FFFFFF;
  background-color: #f7659c;
  border-color: #f7659c;
}

.btn-social.btn-dribbble:hover, .btn-social.btn-dribbble.hover {
  color: #FFFFFF;
  background-color: #f87dac;
  border-color: #f87dac;
}

.btn-social.btn-dribbble:active, .btn-social.btn-dribbble.active, .btn-social.btn-dribbble:not([disabled]):not(.disabled):active, .btn-social.btn-dribbble:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-dribbble.dropdown-toggle {
  color: #FFFFFF;
  background-color: #f64d8c;
  border-color: #f64d8c;
}

.btn-social.btn-dribbble:active:hover, .btn-social.btn-dribbble:active:focus, .btn-social.btn-dribbble:active.focus, .btn-social.btn-dribbble.active:hover, .btn-social.btn-dribbble.active:focus, .btn-social.btn-dribbble.active.focus, .btn-social.btn-dribbble:not([disabled]):not(.disabled):active:hover, .btn-social.btn-dribbble:not([disabled]):not(.disabled):active:focus, .btn-social.btn-dribbble:not([disabled]):not(.disabled):active.focus, .btn-social.btn-dribbble:not([disabled]):not(.disabled).active:hover, .btn-social.btn-dribbble:not([disabled]):not(.disabled).active:focus, .btn-social.btn-dribbble:not([disabled]):not(.disabled).active.focus,
.show > .btn-social.btn-dribbble.dropdown-toggle:hover,
.show > .btn-social.btn-dribbble.dropdown-toggle:focus,
.show > .btn-social.btn-dribbble.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #f64d8c;
  border-color: #f64d8c;
}

.btn-social.btn-dribbble:active, .btn-social.btn-dribbble.active, .btn-social.btn-dribbble:not([disabled]):not(.disabled):active, .btn-social.btn-dribbble:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-dribbble.dropdown-toggle {
  background-image: none;
}

.btn-social.btn-dribbble.disabled, .btn-social.btn-dribbble.disabled:hover, .btn-social.btn-dribbble.disabled:focus, .btn-social.btn-dribbble.disabled.focus, .btn-social.btn-dribbble.disabled:active, .btn-social.btn-dribbble.disabled.active, .btn-social.btn-dribbble[disabled], .btn-social.btn-dribbble[disabled]:hover, .btn-social.btn-dribbble[disabled]:focus, .btn-social.btn-dribbble[disabled].focus, .btn-social.btn-dribbble[disabled]:active, .btn-social.btn-dribbble[disabled].active,
fieldset[disabled] .btn-social.btn-dribbble,
fieldset[disabled] .btn-social.btn-dribbble:hover,
fieldset[disabled] .btn-social.btn-dribbble:focus,
fieldset[disabled] .btn-social.btn-dribbble.focus,
fieldset[disabled] .btn-social.btn-dribbble:active,
fieldset[disabled] .btn-social.btn-dribbble.active {
  color: #FFFFFF;
  background-color: #f7659c;
  border-color: #f7659c;
}

.btn-social.btn-dribbble .badge {
  color: #f7659c;
  background-color: #FFFFFF;
}

.btn-social.btn-flickr {
  color: #FFFFFF;
  background-color: #0063dc;
  border-color: #0063dc;
}

.btn-social.btn-flickr:focus, .btn-social.btn-flickr.focus {
  color: #FFFFFF;
  background-color: #0063dc;
  border-color: #0063dc;
}

.btn-social.btn-flickr:hover, .btn-social.btn-flickr.hover {
  color: #FFFFFF;
  background-color: #006ef6;
  border-color: #006ef6;
}

.btn-social.btn-flickr:active, .btn-social.btn-flickr.active, .btn-social.btn-flickr:not([disabled]):not(.disabled):active, .btn-social.btn-flickr:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-flickr.dropdown-toggle {
  color: #FFFFFF;
  background-color: #0058c3;
  border-color: #0058c3;
}

.btn-social.btn-flickr:active:hover, .btn-social.btn-flickr:active:focus, .btn-social.btn-flickr:active.focus, .btn-social.btn-flickr.active:hover, .btn-social.btn-flickr.active:focus, .btn-social.btn-flickr.active.focus, .btn-social.btn-flickr:not([disabled]):not(.disabled):active:hover, .btn-social.btn-flickr:not([disabled]):not(.disabled):active:focus, .btn-social.btn-flickr:not([disabled]):not(.disabled):active.focus, .btn-social.btn-flickr:not([disabled]):not(.disabled).active:hover, .btn-social.btn-flickr:not([disabled]):not(.disabled).active:focus, .btn-social.btn-flickr:not([disabled]):not(.disabled).active.focus,
.show > .btn-social.btn-flickr.dropdown-toggle:hover,
.show > .btn-social.btn-flickr.dropdown-toggle:focus,
.show > .btn-social.btn-flickr.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #0058c3;
  border-color: #0058c3;
}

.btn-social.btn-flickr:active, .btn-social.btn-flickr.active, .btn-social.btn-flickr:not([disabled]):not(.disabled):active, .btn-social.btn-flickr:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-flickr.dropdown-toggle {
  background-image: none;
}

.btn-social.btn-flickr.disabled, .btn-social.btn-flickr.disabled:hover, .btn-social.btn-flickr.disabled:focus, .btn-social.btn-flickr.disabled.focus, .btn-social.btn-flickr.disabled:active, .btn-social.btn-flickr.disabled.active, .btn-social.btn-flickr[disabled], .btn-social.btn-flickr[disabled]:hover, .btn-social.btn-flickr[disabled]:focus, .btn-social.btn-flickr[disabled].focus, .btn-social.btn-flickr[disabled]:active, .btn-social.btn-flickr[disabled].active,
fieldset[disabled] .btn-social.btn-flickr,
fieldset[disabled] .btn-social.btn-flickr:hover,
fieldset[disabled] .btn-social.btn-flickr:focus,
fieldset[disabled] .btn-social.btn-flickr.focus,
fieldset[disabled] .btn-social.btn-flickr:active,
fieldset[disabled] .btn-social.btn-flickr.active {
  color: #FFFFFF;
  background-color: #0063dc;
  border-color: #0063dc;
}

.btn-social.btn-flickr .badge {
  color: #0063dc;
  background-color: #FFFFFF;
}

.btn-social.btn-linkedin {
  color: #FFFFFF;
  background-color: #238cc8;
  border-color: #238cc8;
}

.btn-social.btn-linkedin:focus, .btn-social.btn-linkedin.focus {
  color: #FFFFFF;
  background-color: #238cc8;
  border-color: #238cc8;
}

.btn-social.btn-linkedin:hover, .btn-social.btn-linkedin.hover {
  color: #FFFFFF;
  background-color: #2b9ada;
  border-color: #2b9ada;
}

.btn-social.btn-linkedin:active, .btn-social.btn-linkedin.active, .btn-social.btn-linkedin:not([disabled]):not(.disabled):active, .btn-social.btn-linkedin:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-linkedin.dropdown-toggle {
  color: #FFFFFF;
  background-color: #1f7db2;
  border-color: #1f7db2;
}

.btn-social.btn-linkedin:active:hover, .btn-social.btn-linkedin:active:focus, .btn-social.btn-linkedin:active.focus, .btn-social.btn-linkedin.active:hover, .btn-social.btn-linkedin.active:focus, .btn-social.btn-linkedin.active.focus, .btn-social.btn-linkedin:not([disabled]):not(.disabled):active:hover, .btn-social.btn-linkedin:not([disabled]):not(.disabled):active:focus, .btn-social.btn-linkedin:not([disabled]):not(.disabled):active.focus, .btn-social.btn-linkedin:not([disabled]):not(.disabled).active:hover, .btn-social.btn-linkedin:not([disabled]):not(.disabled).active:focus, .btn-social.btn-linkedin:not([disabled]):not(.disabled).active.focus,
.show > .btn-social.btn-linkedin.dropdown-toggle:hover,
.show > .btn-social.btn-linkedin.dropdown-toggle:focus,
.show > .btn-social.btn-linkedin.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #1f7db2;
  border-color: #1f7db2;
}

.btn-social.btn-linkedin:active, .btn-social.btn-linkedin.active, .btn-social.btn-linkedin:not([disabled]):not(.disabled):active, .btn-social.btn-linkedin:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-linkedin.dropdown-toggle {
  background-image: none;
}

.btn-social.btn-linkedin.disabled, .btn-social.btn-linkedin.disabled:hover, .btn-social.btn-linkedin.disabled:focus, .btn-social.btn-linkedin.disabled.focus, .btn-social.btn-linkedin.disabled:active, .btn-social.btn-linkedin.disabled.active, .btn-social.btn-linkedin[disabled], .btn-social.btn-linkedin[disabled]:hover, .btn-social.btn-linkedin[disabled]:focus, .btn-social.btn-linkedin[disabled].focus, .btn-social.btn-linkedin[disabled]:active, .btn-social.btn-linkedin[disabled].active,
fieldset[disabled] .btn-social.btn-linkedin,
fieldset[disabled] .btn-social.btn-linkedin:hover,
fieldset[disabled] .btn-social.btn-linkedin:focus,
fieldset[disabled] .btn-social.btn-linkedin.focus,
fieldset[disabled] .btn-social.btn-linkedin:active,
fieldset[disabled] .btn-social.btn-linkedin.active {
  color: #FFFFFF;
  background-color: #238cc8;
  border-color: #238cc8;
}

.btn-social.btn-linkedin .badge {
  color: #238cc8;
  background-color: #FFFFFF;
}

.btn-social.btn-youtube {
  color: #FFFFFF;
  background-color: #ef4e41;
  border-color: #ef4e41;
}

.btn-social.btn-youtube:focus, .btn-social.btn-youtube.focus {
  color: #FFFFFF;
  background-color: #ef4e41;
  border-color: #ef4e41;
}

.btn-social.btn-youtube:hover, .btn-social.btn-youtube.hover {
  color: #FFFFFF;
  background-color: #f16459;
  border-color: #f16459;
}

.btn-social.btn-youtube:active, .btn-social.btn-youtube.active, .btn-social.btn-youtube:not([disabled]):not(.disabled):active, .btn-social.btn-youtube:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-youtube.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ed3829;
  border-color: #ed3829;
}

.btn-social.btn-youtube:active:hover, .btn-social.btn-youtube:active:focus, .btn-social.btn-youtube:active.focus, .btn-social.btn-youtube.active:hover, .btn-social.btn-youtube.active:focus, .btn-social.btn-youtube.active.focus, .btn-social.btn-youtube:not([disabled]):not(.disabled):active:hover, .btn-social.btn-youtube:not([disabled]):not(.disabled):active:focus, .btn-social.btn-youtube:not([disabled]):not(.disabled):active.focus, .btn-social.btn-youtube:not([disabled]):not(.disabled).active:hover, .btn-social.btn-youtube:not([disabled]):not(.disabled).active:focus, .btn-social.btn-youtube:not([disabled]):not(.disabled).active.focus,
.show > .btn-social.btn-youtube.dropdown-toggle:hover,
.show > .btn-social.btn-youtube.dropdown-toggle:focus,
.show > .btn-social.btn-youtube.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #ed3829;
  border-color: #ed3829;
}

.btn-social.btn-youtube:active, .btn-social.btn-youtube.active, .btn-social.btn-youtube:not([disabled]):not(.disabled):active, .btn-social.btn-youtube:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-youtube.dropdown-toggle {
  background-image: none;
}

.btn-social.btn-youtube.disabled, .btn-social.btn-youtube.disabled:hover, .btn-social.btn-youtube.disabled:focus, .btn-social.btn-youtube.disabled.focus, .btn-social.btn-youtube.disabled:active, .btn-social.btn-youtube.disabled.active, .btn-social.btn-youtube[disabled], .btn-social.btn-youtube[disabled]:hover, .btn-social.btn-youtube[disabled]:focus, .btn-social.btn-youtube[disabled].focus, .btn-social.btn-youtube[disabled]:active, .btn-social.btn-youtube[disabled].active,
fieldset[disabled] .btn-social.btn-youtube,
fieldset[disabled] .btn-social.btn-youtube:hover,
fieldset[disabled] .btn-social.btn-youtube:focus,
fieldset[disabled] .btn-social.btn-youtube.focus,
fieldset[disabled] .btn-social.btn-youtube:active,
fieldset[disabled] .btn-social.btn-youtube.active {
  color: #FFFFFF;
  background-color: #ef4e41;
  border-color: #ef4e41;
}

.btn-social.btn-youtube .badge {
  color: #ef4e41;
  background-color: #FFFFFF;
}

.btn-social.btn-pinterest {
  color: #FFFFFF;
  background-color: #e13138;
  border-color: #e13138;
}

.btn-social.btn-pinterest:focus, .btn-social.btn-pinterest.focus {
  color: #FFFFFF;
  background-color: #e13138;
  border-color: #e13138;
}

.btn-social.btn-pinterest:hover, .btn-social.btn-pinterest.hover {
  color: #FFFFFF;
  background-color: #e4474e;
  border-color: #e4474e;
}

.btn-social.btn-pinterest:active, .btn-social.btn-pinterest.active, .btn-social.btn-pinterest:not([disabled]):not(.disabled):active, .btn-social.btn-pinterest:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-pinterest.dropdown-toggle {
  color: #FFFFFF;
  background-color: #d92027;
  border-color: #d92027;
}

.btn-social.btn-pinterest:active:hover, .btn-social.btn-pinterest:active:focus, .btn-social.btn-pinterest:active.focus, .btn-social.btn-pinterest.active:hover, .btn-social.btn-pinterest.active:focus, .btn-social.btn-pinterest.active.focus, .btn-social.btn-pinterest:not([disabled]):not(.disabled):active:hover, .btn-social.btn-pinterest:not([disabled]):not(.disabled):active:focus, .btn-social.btn-pinterest:not([disabled]):not(.disabled):active.focus, .btn-social.btn-pinterest:not([disabled]):not(.disabled).active:hover, .btn-social.btn-pinterest:not([disabled]):not(.disabled).active:focus, .btn-social.btn-pinterest:not([disabled]):not(.disabled).active.focus,
.show > .btn-social.btn-pinterest.dropdown-toggle:hover,
.show > .btn-social.btn-pinterest.dropdown-toggle:focus,
.show > .btn-social.btn-pinterest.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #d92027;
  border-color: #d92027;
}

.btn-social.btn-pinterest:active, .btn-social.btn-pinterest.active, .btn-social.btn-pinterest:not([disabled]):not(.disabled):active, .btn-social.btn-pinterest:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-pinterest.dropdown-toggle {
  background-image: none;
}

.btn-social.btn-pinterest.disabled, .btn-social.btn-pinterest.disabled:hover, .btn-social.btn-pinterest.disabled:focus, .btn-social.btn-pinterest.disabled.focus, .btn-social.btn-pinterest.disabled:active, .btn-social.btn-pinterest.disabled.active, .btn-social.btn-pinterest[disabled], .btn-social.btn-pinterest[disabled]:hover, .btn-social.btn-pinterest[disabled]:focus, .btn-social.btn-pinterest[disabled].focus, .btn-social.btn-pinterest[disabled]:active, .btn-social.btn-pinterest[disabled].active,
fieldset[disabled] .btn-social.btn-pinterest,
fieldset[disabled] .btn-social.btn-pinterest:hover,
fieldset[disabled] .btn-social.btn-pinterest:focus,
fieldset[disabled] .btn-social.btn-pinterest.focus,
fieldset[disabled] .btn-social.btn-pinterest:active,
fieldset[disabled] .btn-social.btn-pinterest.active {
  color: #FFFFFF;
  background-color: #e13138;
  border-color: #e13138;
}

.btn-social.btn-pinterest .badge {
  color: #e13138;
  background-color: #FFFFFF;
}

.btn-social.btn-github {
  color: #FFFFFF;
  background-color: #333;
  border-color: #333;
}

.btn-social.btn-github:focus, .btn-social.btn-github.focus {
  color: #FFFFFF;
  background-color: #333;
  border-color: #333;
}

.btn-social.btn-github:hover, .btn-social.btn-github.hover {
  color: #FFFFFF;
  background-color: #404040;
  border-color: #404040;
}

.btn-social.btn-github:active, .btn-social.btn-github.active, .btn-social.btn-github:not([disabled]):not(.disabled):active, .btn-social.btn-github:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-github.dropdown-toggle {
  color: #FFFFFF;
  background-color: #262626;
  border-color: #262626;
}

.btn-social.btn-github:active:hover, .btn-social.btn-github:active:focus, .btn-social.btn-github:active.focus, .btn-social.btn-github.active:hover, .btn-social.btn-github.active:focus, .btn-social.btn-github.active.focus, .btn-social.btn-github:not([disabled]):not(.disabled):active:hover, .btn-social.btn-github:not([disabled]):not(.disabled):active:focus, .btn-social.btn-github:not([disabled]):not(.disabled):active.focus, .btn-social.btn-github:not([disabled]):not(.disabled).active:hover, .btn-social.btn-github:not([disabled]):not(.disabled).active:focus, .btn-social.btn-github:not([disabled]):not(.disabled).active.focus,
.show > .btn-social.btn-github.dropdown-toggle:hover,
.show > .btn-social.btn-github.dropdown-toggle:focus,
.show > .btn-social.btn-github.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #262626;
  border-color: #262626;
}

.btn-social.btn-github:active, .btn-social.btn-github.active, .btn-social.btn-github:not([disabled]):not(.disabled):active, .btn-social.btn-github:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-github.dropdown-toggle {
  background-image: none;
}

.btn-social.btn-github.disabled, .btn-social.btn-github.disabled:hover, .btn-social.btn-github.disabled:focus, .btn-social.btn-github.disabled.focus, .btn-social.btn-github.disabled:active, .btn-social.btn-github.disabled.active, .btn-social.btn-github[disabled], .btn-social.btn-github[disabled]:hover, .btn-social.btn-github[disabled]:focus, .btn-social.btn-github[disabled].focus, .btn-social.btn-github[disabled]:active, .btn-social.btn-github[disabled].active,
fieldset[disabled] .btn-social.btn-github,
fieldset[disabled] .btn-social.btn-github:hover,
fieldset[disabled] .btn-social.btn-github:focus,
fieldset[disabled] .btn-social.btn-github.focus,
fieldset[disabled] .btn-social.btn-github:active,
fieldset[disabled] .btn-social.btn-github.active {
  color: #FFFFFF;
  background-color: #333;
  border-color: #333;
}

.btn-social.btn-github .badge {
  color: #333;
  background-color: #FFFFFF;
}

.btn-social.btn-tumblr {
  color: #FFFFFF;
  background-color: #426d9b;
  border-color: #426d9b;
}

.btn-social.btn-tumblr:focus, .btn-social.btn-tumblr.focus {
  color: #FFFFFF;
  background-color: #426d9b;
  border-color: #426d9b;
}

.btn-social.btn-tumblr:hover, .btn-social.btn-tumblr.hover {
  color: #FFFFFF;
  background-color: #4a7aad;
  border-color: #4a7aad;
}

.btn-social.btn-tumblr:active, .btn-social.btn-tumblr.active, .btn-social.btn-tumblr:not([disabled]):not(.disabled):active, .btn-social.btn-tumblr:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-tumblr.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3a6089;
  border-color: #3a6089;
}

.btn-social.btn-tumblr:active:hover, .btn-social.btn-tumblr:active:focus, .btn-social.btn-tumblr:active.focus, .btn-social.btn-tumblr.active:hover, .btn-social.btn-tumblr.active:focus, .btn-social.btn-tumblr.active.focus, .btn-social.btn-tumblr:not([disabled]):not(.disabled):active:hover, .btn-social.btn-tumblr:not([disabled]):not(.disabled):active:focus, .btn-social.btn-tumblr:not([disabled]):not(.disabled):active.focus, .btn-social.btn-tumblr:not([disabled]):not(.disabled).active:hover, .btn-social.btn-tumblr:not([disabled]):not(.disabled).active:focus, .btn-social.btn-tumblr:not([disabled]):not(.disabled).active.focus,
.show > .btn-social.btn-tumblr.dropdown-toggle:hover,
.show > .btn-social.btn-tumblr.dropdown-toggle:focus,
.show > .btn-social.btn-tumblr.dropdown-toggle.focus {
  color: #FFFFFF;
  background-color: #3a6089;
  border-color: #3a6089;
}

.btn-social.btn-tumblr:active, .btn-social.btn-tumblr.active, .btn-social.btn-tumblr:not([disabled]):not(.disabled):active, .btn-social.btn-tumblr:not([disabled]):not(.disabled).active,
.show > .btn-social.btn-tumblr.dropdown-toggle {
  background-image: none;
}

.btn-social.btn-tumblr.disabled, .btn-social.btn-tumblr.disabled:hover, .btn-social.btn-tumblr.disabled:focus, .btn-social.btn-tumblr.disabled.focus, .btn-social.btn-tumblr.disabled:active, .btn-social.btn-tumblr.disabled.active, .btn-social.btn-tumblr[disabled], .btn-social.btn-tumblr[disabled]:hover, .btn-social.btn-tumblr[disabled]:focus, .btn-social.btn-tumblr[disabled].focus, .btn-social.btn-tumblr[disabled]:active, .btn-social.btn-tumblr[disabled].active,
fieldset[disabled] .btn-social.btn-tumblr,
fieldset[disabled] .btn-social.btn-tumblr:hover,
fieldset[disabled] .btn-social.btn-tumblr:focus,
fieldset[disabled] .btn-social.btn-tumblr.focus,
fieldset[disabled] .btn-social.btn-tumblr:active,
fieldset[disabled] .btn-social.btn-tumblr.active {
  color: #FFFFFF;
  background-color: #426d9b;
  border-color: #426d9b;
}

.btn-social.btn-tumblr .badge {
  color: #426d9b;
  background-color: #FFFFFF;
}

.btn .icon {
  font-size: 1.462rem;
  vertical-align: middle;
}

.btn .icon.icon-left {
  margin-right: 0.2308rem;
}

.btn .icon.icon-right {
  margin-left: 0.2308rem;
}

.btn .icon.fa {
  font-size: 1.154rem;
}

.btn.btn-lg .icon, .btn-group-lg > .btn .icon {
  font-size: 1.769rem;
  line-height: 25px;
}

.btn.btn-sm .icon, .btn-group-sm > .btn .icon {
  font-size: 1.231rem;
  line-height: 16px;
}

.btn.btn-xs .icon {
  font-size: 1.077rem;
  line-height: 16px;
}

.btn-group.show .dropdown-toggle {
  box-shadow: none;
}

.rtl .btn-group > .btn, .rtl
.btn-group-vertical > .btn {
  float: right;
}

.rtl .btn-group .btn + .btn, .rtl
.btn-group .btn + .btn-group, .rtl
.btn-group .btn-group + .btn, .rtl
.btn-group .btn-group + .btn-group {
  margin-right: -1px;
}

.rtl .btn-toolbar .btn, .rtl
.btn-toolbar .btn-group, .rtl
.btn-toolbar .input-group {
  float: right;
}

.rtl .btn-toolbar > .btn, .rtl
.btn-toolbar > .btn-group, .rtl
.btn-toolbar > .input-group {
  margin-right: 5px;
}

.rtl .btn-group-justified > .btn, .rtl
.btn-group-justified > .btn-group {
  float: none;
}

.rtl .btn-group-justified > .btn-group .dropdown-menu {
  right: auto;
}

.rtl .btn-group-vertical > .btn, .rtl
.btn-group-vertical > .btn-group, .rtl
.btn-group-vertical > .btn-group > .btn {
  float: none;
}

.rtl .btn-group-vertical > .btn-group > .btn {
  float: none;
}

.rtl .btn-group-vertical > .btn + .btn, .rtl
.btn-group-vertical > .btn + .btn-group, .rtl
.btn-group-vertical > .btn-group + .btn, .rtl
.btn-group-vertical > .btn-group + .btn-group {
  margin-right: 0;
}

/*------------------------------------------------------------------
  [Code]
*/
code {
  padding: 0.1538rem 0.3077rem;
  background-color: #f9f2f4;
}

pre {
  padding: 1.538rem;
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
  background-color: whitesmoke;
}

pre code {
  white-space: pre-wrap;
  padding: 0;
  background-color: transparent;
}

.accordion {
  padding: 0;
}

.accordion .card {
  background-color: trn;
  margin-bottom: 0;
}

.accordion .card + .card {
  margin-top: .384rem;
}

.accordion .card-header {
  background: #FFFFFF;
  padding: 0;
  margin: 0;
  border-bottom: 0;
}

.accordion .card-header button {
  display: block;
  width: 100%;
  font-size: 1.385rem;
  text-align: left;
  padding: 1.308rem 0.7692rem;
  color: #555555;
  font-weight: 300;
  line-height: 1.1;
  background-color: transparent;
  border: 0;
}

.rtl .accordion .card-header button {
  text-align: right;
}

.accordion .card-header button .icon {
  transition: transform 200ms ease-in-out;
  font-size: 1.923rem;
  vertical-align: middle;
  position: relative;
  top: -1px;
  display: inline-block;
}

.accordion .card-header button[aria-expanded='true'] {
  color: #ef6262;
}

.accordion .card-header button[aria-expanded='true'] .icon {
  transform: rotate(90deg);
}

.accordion .card-block {
  padding: 0 2.308rem 1.538rem 3.077rem;
}

.accordion .card-block p {
  margin: 0;
  line-height: 1.48;
}

.accordion-color .card {
  background-color: #7a98bf;
}

.accordion-color .card .card-header {
  background-color: #FFFFFF;
}

.accordion-color .card .card-header button {
  color: #555555;
  transition: background-color 200ms ease-in-out;
}

.accordion-color .card .card-header button[aria-expanded='true'] {
  background-color: #7a98bf;
  color: #FFFFFF;
}

.accordion-color .card .card-block {
  color: #FFFFFF;
}

.accordion-color .card.primary {
  background-color: #ef6262;
}

.accordion-color .card.primary .card-header button[aria-expanded='true'] {
  background-color: #ef6262;
}

.accordion-color .card.secondary {
  background-color: #777777;
}

.accordion-color .card.secondary .card-header button[aria-expanded='true'] {
  background-color: #777777;
}

.accordion-color .card.success {
  background-color: #7ACCBE;
}

.accordion-color .card.success .card-header button[aria-expanded='true'] {
  background-color: #7ACCBE;
}

.accordion-color .card.info {
  background-color: #8DCADF;
}

.accordion-color .card.info .card-header button[aria-expanded='true'] {
  background-color: #8DCADF;
}

.accordion-color .card.warning {
  background-color: #FFC870;
}

.accordion-color .card.warning .card-header button[aria-expanded='true'] {
  background-color: #FFC870;
}

.accordion-color .card.danger {
  background-color: #EF6262;
}

.accordion-color .card.danger .card-header button[aria-expanded='true'] {
  background-color: #EF6262;
}

.accordion-color .card.light {
  background-color: #FFFFFF;
}

.accordion-color .card.light .card-header button[aria-expanded='true'] {
  background-color: #FFFFFF;
}

.accordion-color .card.dark {
  background-color: #3d3c3c;
}

.accordion-color .card.dark .card-header button[aria-expanded='true'] {
  background-color: #3d3c3c;
}

.accordion-color .card.grey {
  background-color: #cccccc;
}

.accordion-color .card.grey .card-header button[aria-expanded='true'] {
  background-color: #cccccc;
}

.accordion-color .card.alt-color1 {
  background-color: #95d9f0;
}

.accordion-color .card.alt-color1 .card-header button[aria-expanded='true'] {
  background-color: #95d9f0;
}

.accordion-color .card.alt-color2 {
  background-color: #ffdc7a;
}

.accordion-color .card.alt-color2 .card-header button[aria-expanded='true'] {
  background-color: #ffdc7a;
}

.accordion-color .card.alt-color3 {
  background-color: #7a98bf;
}

.accordion-color .card.alt-color3 .card-header button[aria-expanded='true'] {
  background-color: #7a98bf;
}

.accordion-color .card.alt-color4 {
  background-color: #cccccc;
}

.accordion-color .card.alt-color4 .card-header button[aria-expanded='true'] {
  background-color: #cccccc;
}

.accordion-semi .card .card-header {
  background-color: #FFFFFF;
}

.accordion-semi .card .card-header button {
  color: #555555;
  transition: background-color 200ms ease-in-out;
}

.accordion-semi .card .card-header button[aria-expanded='true'] {
  color: #FFFFFF;
  background-color: #ef6262;
}

.accordion-semi .card .card-block {
  padding-top: 1.154rem;
}

.accordion-semi .card.primary .card-header button[aria-expanded='true'] {
  background-color: #ef6262;
}

.accordion-semi .card.secondary .card-header button[aria-expanded='true'] {
  background-color: #777777;
}

.accordion-semi .card.success .card-header button[aria-expanded='true'] {
  background-color: #7ACCBE;
}

.accordion-semi .card.info .card-header button[aria-expanded='true'] {
  background-color: #8DCADF;
}

.accordion-semi .card.warning .card-header button[aria-expanded='true'] {
  background-color: #FFC870;
}

.accordion-semi .card.danger .card-header button[aria-expanded='true'] {
  background-color: #EF6262;
}

.accordion-semi .card.light .card-header button[aria-expanded='true'] {
  background-color: #FFFFFF;
}

.accordion-semi .card.dark .card-header button[aria-expanded='true'] {
  background-color: #3d3c3c;
}

.accordion-semi .card.grey .card-header button[aria-expanded='true'] {
  background-color: #cccccc;
}

.accordion-semi .card.alt-color1 .card-header button[aria-expanded='true'] {
  background-color: #95d9f0;
}

.accordion-semi .card.alt-color2 .card-header button[aria-expanded='true'] {
  background-color: #ffdc7a;
}

.accordion-semi .card.alt-color3 .card-header button[aria-expanded='true'] {
  background-color: #7a98bf;
}

.accordion-semi .card.alt-color4 .card-header button[aria-expanded='true'] {
  background-color: #cccccc;
}

.custom-control {
  min-height: 1.692rem;
  min-width: 1.692rem;
  margin-bottom: 1.077rem;
  margin-top: .5385rem;
}

.rtl .custom-control {
  padding-left: 0;
  padding-right: 2.077rem;
}

.custom-control .custom-control-label {
  position: unset;
  line-height: 22px;
}

.custom-control .custom-control-label:empty {
  line-height: 21px;
}

.custom-control .custom-control-label:after, .custom-control .custom-control-label:before {
  left: 0;
  top: 0;
}

.rtl .custom-control .custom-control-label:after, .rtl .custom-control .custom-control-label:before {
  right: 0;
}

.custom-control .custom-control-label:hover:before {
  border-color: #d1d1d1;
}

.custom-control.custom-control-inverse {
  padding-left: 0;
  padding-right: 2.077rem;
}

.rtl .custom-control.custom-control-inverse {
  padding-left: 2.077rem;
  padding-right: 0;
}

.custom-control.custom-control-inverse .custom-control-label:after, .custom-control.custom-control-inverse .custom-control-label:before {
  right: 0;
  left: unset;
}

.rtl .custom-control.custom-control-inverse .custom-control-label:after, .rtl .custom-control.custom-control-inverse .custom-control-label:before {
  right: unset;
  left: 0;
}

.rtl .custom-control-inline {
  margin-right: 0;
  margin-left: 1.385rem;
}

.custom-control-input .custom-control-label {
  line-height: 1.6;
}

.custom-control-input:checked ~ .custom-control-label::after {
  color: #ef6262;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ef6262;
}

.custom-control-input:checked ~ .custom-control-color::after {
  background-color: #ef6262;
  color: #FFFFFF;
}

.custom-radio .custom-control-input:checked ~ .custom-control-color::after {
  border-radius: 50%;
}

.custom-checkbox .custom-control-label::before {
  border: 2px solid #eaeaea;
  content: "";
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "\e6ca";
  font-family: "Stroke 7";
  font-size: 1.3843rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.3;
}

.custom-radio .custom-control-label::before {
  border: 2px solid #eaeaea;
  content: "";
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  content: "\e6ce";
  font-family: "Stroke 7";
  font-size: 1.077rem;
  font-weight: normal;
  text-align: center;
}

.custom-radio-icon {
  margin: 0;
  padding: 0;
  width: 52px;
  height: 52px;
}

.custom-radio-icon .custom-control-label::after, .custom-radio-icon .custom-control-label::before {
  display: none;
}

.custom-radio-icon .custom-control-label {
  cursor: pointer;
  width: 52px;
  height: 52px;
  line-height: 1;
  border: 2px solid #d5d8de;
  border-radius: 50%;
  font-size: 2.847rem;
  color: #bdbdbd;
  background-color: #FFFFFF;
  text-align: center;
}

.custom-radio-icon .custom-control-label:hover {
  border-color: #b8bdc8;
}

.custom-radio-icon .custom-control-label i {
  vertical-align: middle;
}

.custom-radio-icon input[type="radio"]:checked + .custom-control-label {
  border-color: #ef6262;
  color: #ef6262;
  background-color: #FFFFFF;
}

.custom-radio-icon input[type="radio"]:checked + .custom-control-label i {
  color: #ef6262;
}

.custom-radio-icon input[type="radio"]:focus + .custom-control-label {
  box-shadow: none;
}

.custom-radio-icon.custom-control-inline + .custom-radio-icon.custom-control-inline {
  margin-left: 18px;
}

.was-validated .form-control-feedback:valid, .form-control-feedback.is-valid, .was-validated
.col-form-label:valid,
.col-form-label.is-valid, .was-validated
.form-check-label:valid,
.form-check-label.is-valid, .was-validated
.custom-control:valid,
.custom-control.is-valid {
  color: #555555;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #7ACCBE;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: none;
}

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #7ACCBE;
}

.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: none;
}

/*.custom-control-indicator {
    .was-validated &:valid,
    &.is-valid {
      border-color: $color;
    }
  }*/
.was-validated .custom-control-input:valid ~ .custom-control-label::after, .custom-control-input.is-valid ~ .custom-control-label::after {
  color: #7ACCBE;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #FFFFFF;
  border-color: #7ACCBE;
  color: #7ACCBE;
}

.was-validated .custom-control-input:checked:valid ~ .custom-control-color::after, .custom-control-input:checked.is-valid ~ .custom-control-color::after {
  background-color: #7ACCBE;
  color: #FFFFFF;
}

.was-validated .form-control-feedback:invalid, .form-control-feedback.is-invalid, .was-validated
.col-form-label:invalid,
.col-form-label.is-invalid, .was-validated
.form-check-label:invalid,
.form-check-label.is-invalid, .was-validated
.custom-control:invalid,
.custom-control.is-invalid {
  color: #555555;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #EF6262;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: none;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #EF6262;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: none;
}

/*.custom-control-indicator {
    .was-validated &:invalid,
    &.is-invalid {
      border-color: $color;
    }
  }*/
.was-validated .custom-control-input:invalid ~ .custom-control-label::after, .custom-control-input.is-invalid ~ .custom-control-label::after {
  color: #EF6262;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #FFFFFF;
  border-color: #EF6262;
  color: #EF6262;
}

.was-validated .custom-control-input:checked:invalid ~ .custom-control-color::after, .custom-control-input:checked.is-invalid ~ .custom-control-color::after {
  background-color: #EF6262;
  color: #FFFFFF;
}

.custom-control-sm {
  min-width: 1.38462rem;
  min-height: 1.38462rem;
}

.custom-control-sm.custom-control {
  top: 2px;
}

.custom-control-sm .custom-control-input ~ .custom-control-label::before {
  width: 1.38462rem;
  height: 1.38462rem;
}

.custom-control-sm .custom-control-input:checked ~ .custom-control-label::after {
  width: 1.38462rem;
  height: 1.38462rem;
  font-size: 15px;
  right: 0;
  line-height: 1.3;
  vertical-align: top;
}

.input-group-text .custom-control-input:not(:checked) ~ .custom-control-label::before {
  border-color: #dddddd;
}

.input-group-text .custom-control-input:not(:checked) ~ .custom-control-label:hover::before {
  border-color: #d1d1d1;
}

.custom-select {
  border-radius: 0;
}

.custom-select:focus {
  box-shadow: 0 0 0 transparent;
}

/*------------------------------------------------------------------
  [Dropdown menu]
*/
.dropdown-menu {
  box-shadow: 0 0.1538rem 0.3077rem rgba(0, 0, 0, 0.08);
}

.rtl .dropdown-menu {
  text-align: right;
  float: right;
  right: 0;
}

.rtl .dropdown-menu.dropdown-menu-right {
  left: 0;
  right: auto !important;
}

.dropdown-menu > .dropdown-item {
  font-weight: 300;
  color: #504e4e;
  padding: 9px 20px;
}

.dropdown-menu > .dropdown-item .icon {
  font-size: 17px;
  line-height: 15px;
  vertical-align: middle;
  margin-right: 4px;
}

.dropdown-menu > .dropdown-item:hover {
  background-color: #ef6262;
  color: #FFFFFF;
}

.dropdown-menu > .dropdown-item:focus, .dropdown-menu > .dropdown-item:active {
  background-color: transparent;
  color: #504e4e;
}

.dropdown-menu .dropdown-divider {
  margin: 6px 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown-header {
  padding: 5px 20px 1px;
  font-weight: 600;
  color: #c2c1c1;
  cursor: default;
}

/*------------------------------------------------------------------
  [Float]
*/
.rtl .float-right {
  float: left !important;
}

.rtl .float-left {
  float: right !important;
}

/*------------------------------------------------------------------
  [Forms]
*/
.form-control {
  height: 3.385rem;
  font-size: 1.07692rem;
}

.form-control:disabled {
  cursor: not-allowed;
}

.form-control[multiple] {
  padding: 0;
}

.form-control[multiple] option {
  padding: 8px 12px;
}

textarea.form-control, select[multiple], select[size] {
  height: auto;
}

input.form-control, textarea.form-control {
  -webkit-appearance: none;
}

label {
  font-weight: 400;
  font-size: 1.07692rem;
  margin-bottom: 8px;
}

.form-group.row {
  margin-bottom: 0;
  padding: 12px 0;
}

.form-group.row .col-form-label {
  padding-bottom: 8px;
  font-size: 1.07692rem;
  line-height: 1;
  word-wrap: normal;
}

@media (min-width: 768px) {
  .rtl .form-group.row .control-label {
    text-align: left;
  }
}

.input-group-btn .btn {
  height: 3.385rem;
}

.form-control-lg {
  height: 54px;
  padding: 0.9231rem 1.077rem;
  font-size: 1.231rem;
  font-weight: 300;
}

.form-control-sm {
  height: 33px;
  font-size: .9231rem;
  padding: 0.3077rem 0.9231rem;
}

.form-control-xs {
  height: 25px;
  font-size: .9231rem;
  padding: 0.07692rem 0.5385rem;
}

.form-control-success,
.form-control-danger {
  padding: 10px 45px 10px 12px;
}

.form-control-success + span,
.form-control-danger + span {
  padding-right: 2rem;
  background-repeat: no-repeat;
  background-position: center right 0.84625rem;
  background-size: 1.6925rem 1.6925rem;
  font-size: 1.615rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 48px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  pointer-events: none;
}

.form-control-success.is-valid + span,
.form-control-danger.is-valid + span {
  color: #7ACCBE;
}

.form-control-success.is-invalid + span,
.form-control-danger.is-invalid + span {
  color: #EF6262;
}

.form-control-feedback {
  font-size: 1.615rem;
}

@media (max-width: 767.98px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}

.rtl .col-1, .rtl .col-2, .rtl .col-3, .rtl .col-4, .rtl .col-5, .rtl .col-6, .rtl .col-7, .rtl .col-8, .rtl .col-9, .rtl .col-10, .rtl .col-11 {
  float: right;
}

.rtl .offset-1 {
  margin-right: 8.333333%;
  margin-left: 0;
}

.rtl .offset-2 {
  margin-right: 16.666667%;
  margin-left: 0;
}

.rtl .offset-3 {
  margin-right: 25%;
  margin-left: 0;
}

.rtl .offset-4 {
  margin-right: 33.333333%;
  margin-left: 0;
}

.rtl .offset-5 {
  margin-right: 41.666667%;
  margin-left: 0;
}

.rtl .offset-6 {
  margin-right: 50%;
  margin-left: 0;
}

.rtl .offset-7 {
  margin-right: 58.333333%;
  margin-left: 0;
}

.rtl .offset-8 {
  margin-right: 66.666667%;
  margin-left: 0;
}

.rtl .offset-9 {
  margin-right: 75%;
  margin-left: 0;
}

.rtl .offset-10 {
  margin-right: 83.333333%;
  margin-left: 0;
}

.rtl .offset-11 {
  margin-right: 91.666667%;
  margin-left: 0;
}

.rtl .offset-12 {
  margin-right: 100%;
  margin-left: 0;
}

.rtl .text-right {
  text-align: left !important;
}

.rtl .text-left {
  text-align: right !important;
}

@media (min-width: 576px) {
  .rtl .col-sm-1, .rtl .col-sm-2, .rtl .col-sm-3, .rtl .col-sm-4, .rtl .col-sm-5, .rtl .col-sm-6, .rtl .col-sm-7, .rtl .col-sm-8, .rtl .col-sm-9, .rtl .col-sm-10, .rtl .col-sm-11 {
    float: right;
  }
  .rtl .offset-sm-1 {
    margin-right: 8.333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-2 {
    margin-right: 16.666667%;
    margin-left: 0;
  }
  .rtl .offset-sm-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-sm-4 {
    margin-right: 33.333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-5 {
    margin-right: 41.666667%;
    margin-left: 0;
  }
  .rtl .offset-sm-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-sm-7 {
    margin-right: 58.333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-8 {
    margin-right: 66.666667%;
    margin-left: 0;
  }
  .rtl .offset-sm-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-sm-10 {
    margin-right: 83.333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-11 {
    margin-right: 91.666667%;
    margin-left: 0;
  }
  .rtl .offset-sm-12 {
    margin-right: 100%;
    margin-left: 0;
  }
  .rtl .text-sm-right {
    text-align: left !important;
  }
  .rtl .text-sm-left {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .rtl .col-md-1, .rtl .col-md-2, .rtl .col-md-3, .rtl .col-md-4, .rtl .col-md-5, .rtl .col-md-6, .rtl .col-md-7, .rtl .col-md-8, .rtl .col-md-9, .rtl .col-md-10, .rtl .col-md-11 {
    float: right;
  }
  .rtl .offset-md-1 {
    margin-right: 8.333333%;
    margin-left: 0;
  }
  .rtl .offset-md-2 {
    margin-right: 16.666667%;
    margin-left: 0;
  }
  .rtl .offset-md-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-md-4 {
    margin-right: 33.333333%;
    margin-left: 0;
  }
  .rtl .offset-md-5 {
    margin-right: 41.666667%;
    margin-left: 0;
  }
  .rtl .offset-md-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-md-7 {
    margin-right: 58.333333%;
    margin-left: 0;
  }
  .rtl .offset-md-8 {
    margin-right: 66.666667%;
    margin-left: 0;
  }
  .rtl .offset-md-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-md-10 {
    margin-right: 83.333333%;
    margin-left: 0;
  }
  .rtl .offset-md-11 {
    margin-right: 91.666667%;
    margin-left: 0;
  }
  .rtl .offset-md-12 {
    margin-right: 100%;
    margin-left: 0;
  }
  .rtl .text-md-right {
    text-align: left !important;
  }
  .rtl .text-md-left {
    text-align: right !important;
  }
}

@media (min-width: 992px) {
  .rtl .col-lg-1, .rtl .col-lg-2, .rtl .col-lg-3, .rtl .col-lg-4, .rtl .col-lg-5, .rtl .col-lg-6, .rtl .col-lg-7, .rtl .col-lg-8, .rtl .col-lg-9, .rtl .col-lg-10, .rtl .col-lg-11 {
    float: right;
  }
  .rtl .offset-lg-1 {
    margin-right: 8.333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-2 {
    margin-right: 16.666667%;
    margin-left: 0;
  }
  .rtl .offset-lg-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-lg-4 {
    margin-right: 33.333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-5 {
    margin-right: 41.666667%;
    margin-left: 0;
  }
  .rtl .offset-lg-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-lg-7 {
    margin-right: 58.333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-8 {
    margin-right: 66.666667%;
    margin-left: 0;
  }
  .rtl .offset-lg-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-lg-10 {
    margin-right: 83.333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-11 {
    margin-right: 91.666667%;
    margin-left: 0;
  }
  .rtl .offset-lg-12 {
    margin-right: 100%;
    margin-left: 0;
  }
  .rtl .text-lg-right {
    text-align: left !important;
  }
  .rtl .text-lg-left {
    text-align: right !important;
  }
}

@media (min-width: 1200px) {
  .rtl .col-xl-1, .rtl .col-xl-2, .rtl .col-xl-3, .rtl .col-xl-4, .rtl .col-xl-5, .rtl .col-xl-6, .rtl .col-xl-7, .rtl .col-xl-8, .rtl .col-xl-9, .rtl .col-xl-10, .rtl .col-xl-11 {
    float: right;
  }
  .rtl .offset-xl-1 {
    margin-right: 8.333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-2 {
    margin-right: 16.666667%;
    margin-left: 0;
  }
  .rtl .offset-xl-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-xl-4 {
    margin-right: 33.333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-5 {
    margin-right: 41.666667%;
    margin-left: 0;
  }
  .rtl .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-xl-7 {
    margin-right: 58.333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-8 {
    margin-right: 66.666667%;
    margin-left: 0;
  }
  .rtl .offset-xl-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-xl-10 {
    margin-right: 83.333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-11 {
    margin-right: 91.666667%;
    margin-left: 0;
  }
  .rtl .offset-xl-12 {
    margin-right: 100%;
    margin-left: 0;
  }
  .rtl .text-xl-right {
    text-align: left !important;
  }
  .rtl .text-xl-left {
    text-align: right !important;
  }
}

.input-group .form-control:hover, .input-group .form-control:focus, .input-group .form-control:active {
  z-index: 0;
}

.input-group-prepend .input-group-text, .input-group-append .input-group-text {
  padding: 0.6154rem 0.9231rem;
  font-size: 1.154rem;
  border-width: 1px;
}

.input-group-prepend .dropdown-toggle-split, .input-group-append .dropdown-toggle-split {
  padding-left: .9231rem;
  padding-right: .9231rem;
}

.input-group-lg > .input-group-prepend, .input-group-lg > .input-group-append {
  font-size: 1.231rem;
}

.input-group-prepend .custom-control, .input-group-append .custom-control {
  margin: 0;
  padding: 0;
}

.input-group-prepend input[type="radio"], .input-group-append input[type="radio"], .input-group-prepend
input[type="checkbox"], .input-group-append
input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 0;
}

.input-group-btn {
  flex-direction: row;
}

.input-search input {
  border: 1px solid #eaeaea;
  border-radius: 0;
  display: block;
  font-size: 1rem;
}

.input-search input:focus {
  background-color: transparent;
}

.input-search .input-group-btn {
  position: absolute;
  right: 0;
  display: block;
  width: auto;
  top: 1px;
  right: 1px;
  z-index: 3;
}

.input-search .input-group-btn button {
  box-shadow: none;
  border: none;
  height: 3.225rem;
  cursor: pointer;
}

.input-search .input-group-btn button:active {
  background-color: transparent;
  box-shadow: none;
}

.input-search .input-group-btn button .icon {
  font-size: 20px;
  color: #888888;
}

.input-search .input-group-btn button:hover {
  background-color: transparent;
}

.input-search.input-group-lg .input-group-btn button {
  height: 3.68615rem;
}

.input-search.input-group-sm .input-group-btn button {
  height: 2.37846rem;
}

.input-search.input-group-sm .input-group-btn button .icon {
  font-size: 22px;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: 54px;
  padding: 12px 14px;
  font-size: 16px;
  font-weight: 300;
}

/*------------------------------------------------------------------
  [List group]
*/
.list-group-item.active .badge {
  background: #FFFFFF;
  color: #ef6262;
}

.list-group-item .icon {
  flex: 0 0 auto;
  margin-right: .7rem;
  font-size: 1.385rem;
  vertical-align: middle;
}

.list-group-item.active .icon {
  color: #FFFFFF;
}

.list-group-item.disabled, .list-group-item:disabled {
  border-color: #dddddd;
}

.list-group-item .text {
  flex: 1 1 auto;
}

.list-group-item .badge {
  flex: 0 0 auto;
}

.active .list-group-item .badge {
  background: #FFFFFF;
  color: #ef6262;
}

.list-group-item .badge.badge-pill {
  display: inline-table;
}

.rtl .list-group-item .badge {
  float: left;
}

/*------------------------------------------------------------------
  [Modals]
*/
.modal-container {
  right: 0;
}

@media (max-width: 767px) {
  .modal-container {
    padding: 0 15px;
  }
}

.modal-content {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  overflow: hidden;
  background-color: #FFFFFF;
  color: #555555;
  border-width: 0;
}

.modal-header {
  border: 0;
}

.modal-header h1, .modal-header h2, .modal-header h3, .modal-header h4, .modal-header h5, .modal-header h6 {
  display: block;
  margin: 0;
}

.modal-header .close {
  font-size: 1.308rem;
  padding: 1.385rem;
  margin: -1.1rem -1.05rem -1rem auto;
  opacity: 0.4;
  text-shadow: none;
}

.rtl .modal-header .close {
  float: left;
  margin: -1.1rem auto -1rem -1.05rem;
}

.modal-header .close:hover {
  opacity: 0.8;
}

.modal-body {
  padding: 30px 20px;
}

.modal-body .i-circle {
  border: 2px solid #f1f1f1;
  border-radius: 50%;
  display: inline-block;
  height: 80px;
  width: 80px;
  margin-bottom: 15px;
  line-height: 105px;
}

.modal-body .i-circle .icon {
  font-size: 45px;
}

.modal-footer {
  background-color: #fcfcfc;
}

.modal-footer .btn .btn + .btn {
  margin-left: 0;
  margin-right: 5px;
}

.rtl .modal-footer {
  text-align: left;
}

.rtl .modal-footer > :not(:first-child) {
  margin-right: .25rem;
}

.modal-full-color .modal-content {
  color: #FFFFFF;
  background-color: transparent;
}

.modal-full-color .modal-header {
  background-color: #ed5050;
}

.modal-full-color .modal-header .close {
  color: #FFFFFF;
  line-height: 1.7;
  opacity: 0.6;
}

.modal-full-color .modal-header .close:hover {
  opacity: 1;
}

.modal-full-color .modal-body, .modal-full-color .modal-footer {
  background-color: #ef6262;
}

.modal-full-color .modal-footer {
  border: 0;
}

.modal-full-color-success .modal-header {
  background-color: #6bc6b7;
}

.modal-full-color-success .modal-body, .modal-full-color-success .modal-footer {
  background-color: #7ACCBE;
}

.modal-full-color-info .modal-header {
  background-color: #7dc3db;
}

.modal-full-color-info .modal-body, .modal-full-color-info .modal-footer {
  background-color: #8DCADF;
}

.modal-full-color-warning .modal-header {
  background-color: #ffc05c;
}

.modal-full-color-warning .modal-body, .modal-full-color-warning .modal-footer {
  background-color: #FFC870;
}

.modal-full-color-danger .modal-header {
  background-color: #ed4f4f;
}

.modal-full-color-danger .modal-body, .modal-full-color-danger .modal-footer {
  background-color: #EF6262;
}

.modal-full-color-dark .modal-header {
  background-color: #383737;
}

.modal-full-color-dark .modal-body, .modal-full-color-dark .modal-footer {
  background-color: #3d3c3c;
}

.modal-full-color-alt1 .modal-header {
  background-color: #8cd6ef;
}

.modal-full-color-alt1 .modal-body, .modal-full-color-alt1 .modal-footer {
  background-color: #95d9f0;
}

.modal-full-color-alt2 .modal-header {
  background-color: #ffd970;
}

.modal-full-color-alt2 .modal-body, .modal-full-color-alt2 .modal-footer {
  background-color: #ffdc7a;
}

.modal-full-color-alt3 .modal-header {
  background-color: #7393bc;
}

.modal-full-color-alt3 .modal-body, .modal-full-color-alt3 .modal-footer {
  background-color: #7a98bf;
}

.modal-full-color-alt4 .modal-header {
  background-color: #c7c7c7;
}

.modal-full-color-alt4 .modal-body, .modal-full-color-alt4 .modal-footer {
  background-color: #cccccc;
}

.full-width {
  width: 95%;
  max-width: none;
  margin-left: auto;
  margin-right: auto;
}

.full-width .modal-content {
  max-width: 100%;
}

@media (max-width: 480px) {
  .full-width {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
}

.custom-width .modal-dialog {
  width: 700px;
  max-width: none;
}

.custom-width .modal-content {
  width: 700px;
  max-width: none;
}

@media (max-width: 767.98px) {
  .custom-width .modal-content, .custom-width .modal-dialog, .custom-width .modal-header {
    width: 100% !important;
  }
}

.modal-colored-header .modal-content {
  background-color: transparent;
}

.modal-colored-header .modal-header {
  background-color: #ef6262;
  color: #FFFFFF;
  padding: 20px 15px;
}

.modal-colored-header .modal-header .close {
  color: #FFFFFF;
  margin-top: -0.8462rem;
}

.modal-colored-header .modal-body {
  background-color: #FFFFFF;
}

.modal-colored-header-success .modal-content .modal-header {
  background-color: #7ACCBE;
}

.modal-colored-header-info .modal-content .modal-header {
  background-color: #8DCADF;
}

.modal-colored-header-warning .modal-content .modal-header {
  background-color: #FFC870;
}

.modal-colored-header-danger .modal-content .modal-header {
  background-color: #EF6262;
}

.modal-colored-header-dark .modal-content .modal-header {
  background-color: #3d3c3c;
}

.modal-colored-header-alt1 .modal-content .modal-header {
  background-color: #95d9f0;
}

.modal-colored-header-alt2 .modal-content .modal-header {
  background-color: #ffdc7a;
}

.modal-colored-header-alt3 .modal-content .modal-header {
  background-color: #7a98bf;
}

.modal-colored-header-alt4 .modal-content .modal-header {
  background-color: #cccccc;
}

.modal-dark .modal-content {
  color: #FFFFFF;
  background-color: transparent;
}

.modal-dark .modal-header {
  background-color: #ef6262;
  padding: 20px 15px;
}

.modal-dark .modal-header .close {
  color: #FFFFFF;
  margin-top: -0.8462rem;
}

.modal-dark .modal-body.form .form-control {
  background-color: #3d3c3c;
}

.modal-dark .modal-body, .modal-dark .modal-footer {
  background-color: #3d3c3c;
}

.modal-dark .modal-footer {
  border: 0;
}

.modal-dark-success .modal-header {
  background-color: #7ACCBE;
}

.modal-dark-info .modal-header {
  background-color: #8DCADF;
}

.modal-dark-warning .modal-header {
  background-color: #FFC870;
}

.modal-dark-danger .modal-header {
  background-color: #EF6262;
}

.modal-dark-dark .modal-header {
  background-color: #383737;
}

.modal-dark-alt1 .modal-header {
  background-color: #8cd6ef;
}

.modal-dark-alt2 .modal-header {
  background-color: #ffd970;
}

.modal-dark-alt3 .modal-header {
  background-color: #7393bc;
}

.modal-dark-alt4 .modal-header {
  background-color: #c7c7c7;
}

@media (max-width: 767px) {
  .modal-dialog {
    min-width: 100%;
    padding: 0 15px;
    margin: 0;
  }
}

.modal-overlay {
  z-index: 1050;
}

.am-modal-open {
  overflow-x: visible;
}

.nav-tabs {
  background-color: whitesmoke;
  border-bottom-width: 0;
}

.rtl .nav-tabs {
  padding: 0;
}

.nav-tabs .nav-item {
  margin-right: 2px;
}

.rtl .nav-tabs .nav-item {
  float: right;
  margin-right: 0;
  margin-left: 2px;
}

.nav-tabs .nav-link {
  min-width: 80px;
  margin-right: 0;
  text-align: center;
  border-radius: 0;
  color: #555555;
}

.nav-tabs .nav-link:hover {
  color: #ef6262;
}

.nav-tabs .nav-link:active, .nav-tabs .nav-link.active {
  cursor: default;
}

.nav-tabs .nav-link:active:hover, .nav-tabs .nav-link.active:hover {
  color: #555555;
}

.tab-content {
  background: #FFFFFF;
  padding: 1.538rem;
}

.main-content .tab-content .tab-pane h1:first-child, .main-content .tab-content .tab-pane h2:first-child, .main-content .tab-content .tab-pane h3:first-child, .main-content .tab-content .tab-pane h4:first-child, .main-content .tab-content .tab-pane h5:first-child, .main-content .tab-content .tab-pane h6:first-child {
  margin-top: 0.3846rem;
}

.tab-bottom .tab-content {
  margin-bottom: 0;
}

.tab-left .nav-tabs, .tab-right .nav-tabs {
  float: left;
  flex-direction: column;
}

.tab-left .nav-tabs .nav-item, .tab-right .nav-tabs .nav-item {
  float: none;
  margin-right: 0;
  margin-bottom: 0;
}

.rtl .tab-left .nav-tabs .nav-item, .rtl .tab-right .nav-tabs .nav-item {
  margin-left: 0;
}

.tab-left .nav-tabs .nav-item .nav-link, .tab-right .nav-tabs .nav-item .nav-link {
  text-align: left;
  min-width: 2.923rem;
  min-height: 4.231rem;
  padding: 1.154rem 1.308rem;
  border-radius: 0;
}

.tab-left .nav-tabs .nav-item .nav-link .icon, .tab-right .nav-tabs .nav-item .nav-link .icon {
  font-size: 1.538rem;
}

.tab-left .tab-content, .tab-right .tab-content {
  overflow: auto;
}

.tab-right .nav-tabs {
  float: right;
}

.rtl .nav-justified .nav-item {
  float: none;
}

.pagination {
  margin: 1.385rem 0;
}

.rtl .pagination {
  padding-right: 0;
}

nav .pagination {
  display: flex;
}

.rtl .pagination .page-item .page-link, .rtl
.pagination .page-item > span {
  float: right;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:first-child > span {
  margin-left: -1px;
}

.pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
  border-radius: 0;
}

.page-item.active .page-link {
  z-index: 3;
}

.popover {
  box-shadow: 0 0.3846rem 0.7692rem rgba(0, 0, 0, 0.2);
}

.popover .popover-header {
  margin: 0;
}

.popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow, .popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow, .popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow, .popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
  margin: 0;
}

.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.7692rem 0.7692rem 0;
}

.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.7692rem 0.7692rem 0.7692rem;
}

.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.7692rem 0 0.7692rem 0.7692rem;
}

.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.7692rem 0.7692rem 0.7692rem 0;
}

.rtl .popover.bs-popover-top .arrow::before, .rtl .popover.bs-popover-auto[x-placement^="top"] .arrow::before, .rtl .popover.bs-popover-top .arrow::after, .rtl .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .rtl .popover.bs-popover-bottom .arrow::before, .rtl .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before, .rtl .popover.bs-popover-bottom .arrow::after, .rtl .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  margin-right: -0.7692rem;
  margin-left: 0;
}

/*------------------------------------------------------------------
  [Progress bars]
*/
.progress {
  height: 1.692rem;
  margin-bottom: 1.385rem;
  box-shadow: none;
}

.progress-bar {
  box-shadow: none;
}

.rtl .progress-bar {
  float: right;
}

/*------------------------------------------------------------------
  [Tables]
*/
.rtl th {
  text-align: inherit;
}

.rtl th.text-center {
  text-align: center;
}

.table > thead > tr > th {
  border-bottom-width: 1px;
  border-top-width: 0;
  font-weight: 400;
  font-size: 1rem;
}

.table > thead.primary > tr > th {
  color: #ef6262;
}

.table > thead.alt3 > tr > th {
  color: #7a98bf;
}

.table > tbody > tr > td {
  font-weight: 300;
  vertical-align: middle;
}

.table > tbody.no-border-x > tr > td {
  border-top-width: 0;
}

.table-sm > thead > tr > th {
  padding-top: 10px;
  padding-bottom: 8px;
}

.table-responsive {
  border: 0;
}

.table tbody tr td.user-avatar img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.rtl .table tbody tr td.user-avatar img {
  margin-right: 0;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .table tbody tr td.user-avatar img {
    display: none;
  }
}

.table tbody tr.primary td:first-child {
  border-left: 3px solid #ef6262;
  padding-left: 17px;
}

.table tbody tr.info td:first-child {
  border-left: 3px solid #8DCADF;
  padding-left: 17px;
}

.table tbody tr.success td:first-child {
  border-left: 3px solid #7ACCBE;
  padding-left: 17px;
}

.table tbody tr.warning td:first-child {
  border-left: 3px solid #FFC870;
  padding-left: 17px;
}

.table tbody tr.danger td:first-child {
  border-left: 3px solid #EF6262;
  padding-left: 17px;
}

.table tbody tr.dark td:first-child {
  border-left: 3px solid #3d3c3c;
  padding-left: 17px;
}

.table tbody tr.alt-color1 td:first-child {
  border-left: 3px solid #95d9f0;
  padding-left: 17px;
}

.table tbody tr.alt-color2 td:first-child {
  border-left: 3px solid #ffdc7a;
  padding-left: 17px;
}

.table tbody tr.alt-color3 td:first-child {
  border-left: 3px solid #7a98bf;
  padding-left: 17px;
}

.table tbody tr.alt-color4 td:first-child {
  border-left: 3px solid #cccccc;
  padding-left: 17px;
}

.table .number {
  text-align: right;
}

.table-fw-widget {
  margin-bottom: 0;
}

.table-fw-widget tr th:first-child, .table-fw-widget tr td:first-child {
  padding-left: 15px;
}

.table-fw-widget tr th:last-child, .table-fw-widget tr td:last-child {
  padding-right: 15px;
}

.table-fw-widget thead tr th {
  padding-top: 15px;
  padding-bottom: 10px;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ef6262;
  color: #FFFFFF;
}

.table-hover .table-primary:hover {
  background-color: #ec4b4b;
  color: #FFFFFF;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ec4b4b;
  color: #FFFFFF;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #777777;
  color: #FFFFFF;
}

.table-hover .table-secondary:hover {
  background-color: #6a6a6a;
  color: #FFFFFF;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #6a6a6a;
  color: #FFFFFF;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #7ACCBE;
  color: #FFFFFF;
}

.table-hover .table-success:hover {
  background-color: #68c5b5;
  color: #FFFFFF;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #68c5b5;
  color: #FFFFFF;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #8DCADF;
  color: #FFFFFF;
}

.table-hover .table-info:hover {
  background-color: #79c1d9;
  color: #FFFFFF;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #79c1d9;
  color: #FFFFFF;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #FFC870;
  color: #FFFFFF;
}

.table-hover .table-warning:hover {
  background-color: #ffbe57;
  color: #FFFFFF;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffbe57;
  color: #FFFFFF;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #EF6262;
  color: #FFFFFF;
}

.table-hover .table-danger:hover {
  background-color: #ed4b4b;
  color: #FFFFFF;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ed4b4b;
  color: #FFFFFF;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #FFFFFF;
  color: #FFFFFF;
}

.table-hover .table-light:hover {
  background-color: #f2f2f2;
  color: #FFFFFF;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f2f2f2;
  color: #FFFFFF;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #3d3c3c;
  color: #FFFFFF;
}

.table-hover .table-dark:hover {
  background-color: #302f2f;
  color: #FFFFFF;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #302f2f;
  color: #FFFFFF;
}

.table-grey,
.table-grey > th,
.table-grey > td {
  background-color: #cccccc;
  color: #FFFFFF;
}

.table-hover .table-grey:hover {
  background-color: #bfbfbf;
  color: #FFFFFF;
}

.table-hover .table-grey:hover > td,
.table-hover .table-grey:hover > th {
  background-color: #bfbfbf;
  color: #FFFFFF;
}

.table-alt-color1,
.table-alt-color1 > th,
.table-alt-color1 > td {
  background-color: #95d9f0;
  color: #FFFFFF;
}

.table-hover .table-alt-color1:hover {
  background-color: #7fd1ed;
  color: #FFFFFF;
}

.table-hover .table-alt-color1:hover > td,
.table-hover .table-alt-color1:hover > th {
  background-color: #7fd1ed;
  color: #FFFFFF;
}

.table-alt-color2,
.table-alt-color2 > th,
.table-alt-color2 > td {
  background-color: #ffdc7a;
  color: #FFFFFF;
}

.table-hover .table-alt-color2:hover {
  background-color: #ffd561;
  color: #FFFFFF;
}

.table-hover .table-alt-color2:hover > td,
.table-hover .table-alt-color2:hover > th {
  background-color: #ffd561;
  color: #FFFFFF;
}

.table-alt-color3,
.table-alt-color3 > th,
.table-alt-color3 > td {
  background-color: #7a98bf;
  color: #FFFFFF;
}

.table-hover .table-alt-color3:hover {
  background-color: #698bb7;
  color: #FFFFFF;
}

.table-hover .table-alt-color3:hover > td,
.table-hover .table-alt-color3:hover > th {
  background-color: #698bb7;
  color: #FFFFFF;
}

.table-alt-color4,
.table-alt-color4 > th,
.table-alt-color4 > td {
  background-color: #cccccc;
  color: #FFFFFF;
}

.table-hover .table-alt-color4:hover {
  background-color: #bfbfbf;
  color: #FFFFFF;
}

.table-hover .table-alt-color4:hover > td,
.table-hover .table-alt-color4:hover > th {
  background-color: #bfbfbf;
  color: #FFFFFF;
}

h4, h5, h6, .h4, .h5, .h6 {
  margin-top: 0.6923rem;
}

h4 small, h4 .small, h5 small, h5 .small, h6 small, h6 .small, .h4 small, .h4 .small, .h5 small, .h5 .small, .h6 small, .h6 .small {
  font-size: 75%;
}

h1, h2, h3, .h1, .h2, h3 {
  margin-top: 1.385rem;
}

h1 small, h1 .small, h2 small, h2 .small, h3 small, h3 .small, .h1 small, .h1 .small, .h2 small, .h2 .small, h3 small, h3 .small {
  font-size: 65%;
}

h1 small,
h1 .small, h2 small,
h2 .small, h3 small,
h3 .small, .h1 small,
.h1 .small, .h2 small,
.h2 .small, h3 small,
h3 .small,
h4 small,
h4 .small, h5 small,
h5 .small, h6 small,
h6 .small, .h4 small,
.h4 .small, .h5 small,
.h5 .small, .h6 small,
.h6 .small {
  color: #777777;
}

/*------------------------------------------------------------------
  [List]
*/
ol,
ul,
dl {
  margin-bottom: .6923rem;
}

.rtl .list-inline, .rtl
.list-unstyled {
  padding-right: 0;
}

.lead {
  line-height: 1.4;
  margin-bottom: 1.385rem;
}

@media (min-width: 768px) {
  .lead {
    font-size: 1.5rem;
  }
}

p small,
p .small {
  font-size: 92%;
}

/*------------------------------------------------------------------
  [Blockquotes]
*/
blockquote {
  padding: .6923rem 1.385rem;
  margin: 0 0 1.385rem;
  font-size: 1.25rem;
  border-left: 5px solid #eeeeee;
}

blockquote ol:last-child,
blockquote ul:last-child,
blockquote dl:last-child,
blockquote p:last-child {
  margin-bottom: 0;
}

blockquote footer {
  display: block;
  font-size: 80%;
  color: #777777;
}

blockquote footer:before {
  content: '\2014 \00A0';
}

blockquote.blockquote-reverse {
  padding-right: 1.154rem;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right;
}

blockquote.blockquote-reverse footer:before {
  content: '';
}

blockquote.blockquote-reverse footer:after {
  content: '\00A0 \2014';
}

.rtl blockquote {
  border-left-width: 0;
  border-right: 5px solid #eeeeee;
}

.rtl blockquote.blockquote-reverse {
  text-align: left;
  padding-right: 0;
  padding-left: 15px;
  border-right-width: 0;
  border-left: 5px solid #eeeeee;
}

.text-inverse {
  text-align: right;
}

.rtl .text-inverse {
  text-align: left;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 1px dotted #777777;
}

address {
  margin-bottom: 1.385rem;
}

.chat .chat-contacts .am-scroller-page-chat, .chat .chat-contacts .am-scroller-page-messages, .chat .chat-messages .am-scroller-page-chat, .chat .chat-messages .am-scroller-page-messages {
  position: relative;
  width: 100%;
  height: 100%;
}

.chat .chat-contacts .content, .chat .chat-messages .content {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
}

.docs {
  /* Pretty printing styles. Used with prettify.js. */
  /* Specify class=linenums on a pre to get line numbering */
  /* IE indents via margin-left */
  /* Alternate shading for lines */
}

.docs .page-aside .aside-content .aside-nav {
  padding: 0;
}

.docs .page-aside .aside-content .aside-nav .navbar {
  padding: 0;
  font-size: 1rem;
  line-height: 20px;
}

.docs .page-aside .aside-content .aside-nav .navbar > .nav-item > .nav-link:hover {
  background-color: #e8e8e8;
}

.docs .page-aside .aside-content .aside-nav .navbar > .nav-item > .nav-link.active {
  color: #ef6262;
}

.docs .page-aside .aside-content .aside-nav .navbar > .nav-item > .nav-link.active + ul {
  display: block;
}

.docs .page-aside .aside-content .aside-nav .navbar > .nav-item > ul {
  padding: 10px 0;
  display: none;
  background-color: whitesmoke;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  font-size: 1rem;
  line-height: 20px;
}

.docs .page-aside .aside-content .aside-nav .navbar > .nav-item > ul .nav-link {
  padding: 8px 20px;
}

.docs .page-aside .aside-content .aside-nav .navbar > .nav-item > ul .nav-link.active {
  color: #ef6262;
}

.docs .main-content {
  background-color: #fafafa;
}

.docs section > h1, .docs section > h2 {
  margin-top: 60px;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ededed;
}

.docs section > h2, .docs section > .h2 {
  font-size: 30px;
}

.docs section > h3, .docs section > .h3 {
  margin-top: 45px;
  margin-bottom: 10px;
  font-size: 27px;
}

.docs section > h4, .docs section > .h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 23px;
}

.docs section h1, .docs section h2, .docs section h3, .docs section h4, .docs section h5, .docs section h6, .docs section .h1, .docs section .h2, .docs section .h3, .docs section .h4, .docs section .h5, .docs section .h6 {
  color: #2a2a2a;
}

.docs section > p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 300;
  margin-bottom: 25px;
}

.docs section > p b, .docs section > p strong {
  font-weight: 400;
}

.docs section > ul, .docs section > ol {
  font-size: 17px;
  line-height: 33px;
}

.docs section > img {
  max-width: 100%;
}

.docs .gallery-container .item {
  width: 50%;
}

.docs .prettyprint {
  font-size: 15px;
  line-height: 1.5;
}

.docs pre {
  border: 0;
  padding: 20px;
  margin-bottom: 0;
}

.docs .code-panel {
  margin-bottom: 30px;
}

.docs .code-panel__heading {
  background: #5A5775;
  color: #cecece;
  font-size: 15px;
  padding: 11px 16px;
}

.docs pre {
  background-color: #4F4A68;
  color: #EDEBE6;
}

.docs pre .pln {
  color: #eeeeee;
}

.docs pre .str {
  color: #92CF78;
}

.docs pre .kwd {
  color: #f386ac;
}

.docs pre .com {
  color: #8275E7;
}

.docs pre .typ {
  color: #97f6fb;
}

.docs pre .lit {
  color: #dcc175;
}

.docs pre .pun,
.docs pre .opn,
.docs pre .clo {
  color: #e3e2e0;
}

.docs pre .tag {
  color: #f386ac;
}

.docs pre .atn {
  color: #1DFDC0;
}

.docs pre .atv {
  color: #F2C770;
}

.docs pre .dec,
.docs pre .var {
  color: #E86493;
}

.docs pre .fun {
  color: #445588;
}

.docs ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
}

.docs li.L0,
.docs li.L1,
.docs li.L2,
.docs li.L3,
.docs li.L5,
.docs li.L6,
.docs li.L7,
.docs li.L8 {
  list-style-type: none;
}

.docs li.L1,
.docs li.L3,
.docs li.L5,
.docs li.L7,
.docs li.L9 {
  background: #eee;
}

.aside-compose {
  text-align: center;
  padding: 20px 25px;
  margin-top: 10px;
}

.email-search {
  background-color: #FFFFFF;
  padding: 20px 25px;
}

.email-search h3 {
  margin: 9px 0 15px 0;
}

.email-search span {
  color: #888;
  font-weight: 300;
  font-size: 1.231rem;
  display: inline-block;
}

.email-filters {
  padding: 9px 20px 10px 20px;
  border-bottom: 1px solid #e6e6e6;
  background-color: #FFFFFF;
  display: table;
  width: 100%;
}

.email-filters > div {
  display: table-cell;
  vertical-align: middle;
}

.email-filters .am-select-all.custom-checkbox .custom-control-label {
  margin-left: 13px;
}

.email-filters .email-filters-right {
  text-align: right;
}

.email-filters input {
  margin-right: 8px;
}

.email-filters .custom-checkbox, .email-list .custom-checkbox {
  margin-bottom: .5385rem;
}

.email-list {
  background: #FFFFFF;
}

.email-list .item {
  border-bottom: 1px solid #e6e6e6;
  padding: 17px 20px;
  display: table;
  width: 100%;
  cursor: pointer;
  background-color: #fafafa;
  position: relative;
}

.email-list .item .date {
  float: right;
}

.rtl .email-list .item .date {
  float: left;
}

.email-list .item.unread {
  background-color: #FFFFFF;
}

.email-list .item.unread:after {
  content: '';
  display: block;
  position: absolute;
  width: 3px;
  top: -1px;
  bottom: -1px;
  left: 0;
  background-color: #ef6262;
}

.email-list .item.unread .from, .email-list .item.unread .date {
  font-weight: 600;
}

.email-list .item > div {
  vertical-align: top;
  display: table-cell;
}

.email-list .item > div:first-child {
  width: 40px;
}

.email-list .item .icon {
  margin-right: 7px;
  font-size: 1.077rem;
  display: inline-block;
}

.email-list .item .from {
  font-weight: 400;
  font-size: 1rem;
  margin: 0 0 3px 0;
}

.email-list .item .msg {
  margin: 0;
  color: #888888;
}

.email-list .item:hover {
  background-color: #f7f7f7;
}

.email-head {
  background-color: #FFFFFF;
  padding: 20px 25px 24px;
}

.email-head .icons {
  font-size: 1.923rem;
  float: right;
}

.email-head .icons a {
  display: inline-block;
}

.rtl .email-head .icons {
  float: left;
}

.email-head .icons .icon {
  color: #555555;
  margin-left: 5px;
}

.email-head .icons .icon:hover {
  color: #ef6262;
}

.email-head h4 {
  margin: 12px 0 15px 0;
}

.email-head .date {
  float: right;
}

.rtl .email-head .date {
  float: left;
}

.email-head .date a {
  vertical-align: middle;
  margin-right: 5px;
}

.rtl .email-head .date a {
  display: inline-block;
  vertical-align: baseline;
}

.email-head .date .icon {
  font-size: 1.538rem;
}

.email-body {
  background-color: #FFFFFF;
  border-top: 1px solid #e6e6e6;
  padding: 30px;
  font-size: 1.154rem;
  font-weight: 300;
}

.email-body p {
  line-height: 25px;
  margin-bottom: 20px;
}

.email-subject input {
  font-weight: 300;
  font-size: 1.923rem;
  height: auto;
  padding: 30px;
  border: 0;
  width: 100%;
  color: inherit;
}

.email-recipient {
  background-color: #FFFFFF;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  padding: 30px 30px 10px;
}

.email-recipient .to:first-child .form-group.row {
  padding-top: 0;
}

.email-recipient label {
  padding-top: 15px;
}

.email.editor {
  background-color: #FFFFFF;
  padding: 30px;
}

.email.editor .note-toolbar {
  border: 0;
}

.email.editor .note-resizebar {
  background-color: #FFFFFF;
}

.email.editor .form-group {
  text-align: right;
  margin-top: 15px;
}

.email.editor .form-group .icon {
  font-size: 1.769rem;
}

.rtl .email.editor .form-group .icon {
  display: inline-block;
  vertical-align: text-top;
  line-height: 0.8;
}

/*------------------------------------------------------------------
  [Error pages]
*/
.am-error .error-container {
  text-align: center;
}

.am-error .error-number {
  display: block;
  color: #FFFFFF;
  font-size: 10rem;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  line-height: 120px;
  text-shadow: 6px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .am-error .error-number {
    font-size: 6.923rem;
    margin-bottom: 10px;
    line-height: 100px;
  }
}

.am-error .error-description {
  font-size: 2rem;
  font-weight: 300;
  line-height: 32px;
  color: #cccccc;
}

@media (max-width: 767px) {
  .am-error .error-description {
    font-size: 1.462rem;
  }
}

.am-error .error-goback-text {
  font-size: 1.538rem;
  font-weight: 300;
  color: #a6a6a6;
  margin-bottom: 30px;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .am-error .error-goback-text {
    font-size: 1.154rem;
  }
}

/*------------------------------------------------------------------
  [Page 404]
*/
.am-error-404 .error-container .error-image {
  display: inline-block;
  background: url("../img/404-img.png") no-repeat right center;
  background-size: 387px 292px;
  width: 100%;
  max-width: 587px;
  height: 292px;
}

.am-error-404 .error-container .error-image img {
  max-width: 607px;
  max-height: 459px;
}

@media -webkit-min-device-pixel-ratio {
  .am-error-404 .error-container .error-image {
    background-image: url("../img/404-img-2x.png");
  }
}

@media (max-width: 767px) {
  .am-error-404 .error-container .error-image {
    background-size: 190px 142px;
    max-width: 295px;
    height: 142px;
  }
}

/*------------------------------------------------------------------
  [Forgot password]
*/
.forgot-password {
  max-width: 415px !important;
}

.forgot-password .card-header {
  margin-bottom: 0 !important;
}

.forgot-password .card-body form p {
  color: #979595;
  font-size: 1.077rem;
  margin-bottom: 25px;
}

.forgot-password .card-body form p a {
  color: #d9d9d9 !important;
}

.forgot-password .card-body form p a:hover {
  color: #FFFFFF !important;
}

.forgot-password .card-body form p.contact {
  margin-bottom: 30px;
}

.forgot-password .card-body form .form-group {
  margin-bottom: 30px !important;
}

.forgot-password .out-links {
  margin-top: 40px;
}

@media (max-width: 767px) {
  .forgot-password {
    margin-bottom: 50px;
    margin-top: 30px;
  }
}

/*------------------------------------------------------------------
  [Gallery]
*/
.gallery-container .item {
  width: 25%;
  margin-bottom: 20px;
  padding: 0 10px;
}

.gallery-container .item .photo {
  background: #FFFFFF;
}

.gallery-container .item .photo .img {
  position: relative;
}

.gallery-container .item .photo .img img {
  width: 100%;
}

.gallery-container .item .photo .img .over {
  top: 0;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(239, 98, 98, 0.8);
  transition: opacity 300ms ease;
  -webkit-transition: opacity 300ms ease;
}

.gallery-container .item .photo .img .over .func {
  margin-top: -80px;
  position: relative;
  top: 50%;
  text-align: center;
  transition: margin-top 200ms ease-in-out;
}

.gallery-container .item .photo .img .over .func a {
  display: inline-block;
  height: 50px;
  margin-right: 2px;
  width: 50px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.gallery-container .item .photo .img .over .func a:hover {
  background: rgba(255, 255, 255, 0.3);
}

.gallery-container .item .photo .img .over .func a i {
  font-size: 1.538rem;
  color: #FFFFFF;
  line-height: 2.4;
}

.gallery-container .item .photo .img:hover .over {
  opacity: 1;
}

.gallery-container .item .photo .img:hover .over .func {
  margin-top: -25px;
}

.gallery-container .item .photo .description {
  line-height: 20px;
  display: table;
  width: 100%;
}

.gallery-container .item .photo .description .icon {
  display: table-cell;
  background-color: #e6e6e6;
  padding: 20px;
  vertical-align: middle;
  width: 20px;
}

.gallery-container .item .photo .description .icon a {
  color: #626262;
}

.gallery-container .item .photo .description .icon a i {
  font-size: 2.308rem;
}

@media (max-width: 767px) {
  .gallery-container .item .photo .description .icon a i {
    font-size: 1.538rem;
  }
}

.gallery-container .item .photo .description .icon a:hover {
  color: #ef6262;
}

@media (max-width: 767px) {
  .gallery-container .item .photo .description .icon {
    padding: 15px;
  }
}

.gallery-container .item .photo .description .desc {
  display: table-cell;
  padding: 10px 20px;
  vertical-align: middle;
}

.gallery-container .item .photo .description .desc h4 {
  margin: 0;
  font-size: 1.385rem;
}

@media (max-width: 767px) {
  .gallery-container .item .photo .description .desc h4 {
    font-size: 1.154rem;
  }
}

.gallery-container .item .photo .description .desc span {
  color: #8a8888;
  font-size: .9231rem;
  font-weight: 300;
}

@media (max-width: 767px) {
  .gallery-container .item .photo .description .desc {
    padding: 10px;
  }
}

.gallery-container .item.w2 {
  width: 50%;
}

@media (max-width: 767px) {
  .gallery-container {
    max-width: 767px;
  }
  .gallery-container .item {
    width: 100%;
    padding: 0;
  }
  .gallery-container .item.w2 {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .gallery-container .item {
    width: 50%;
  }
}

/*------------------------------------------------------------------
  [Invoice]
*/
.invoice {
  background-color: #FFFFFF;
  padding: 100px 100px 70px;
  color: #8c8c8c;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice {
    padding: 50px 50px 35px;
  }
}

@media (max-width: 480px) {
  .invoice {
    padding: 35px 25px 15px;
  }
}

.invoice-header {
  margin-bottom: 100px;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-header {
    margin-bottom: 50px;
  }
}

@media (max-width: 480px) {
  .invoice-header > div {
    float: none;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

.invoice-logo {
  line-height: 80px;
  height: 54px;
  min-width: 206px;
  background-image: url("../img/logo-invoice-big.png");
  background-repeat: no-repeat;
  background-position: 0;
}

.rtl .invoice-logo {
  background-position-x: right;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-logo {
    height: 34px;
    min-width: 144px;
    background-image: url("../img/logo-invoice.png");
  }
}

@media (max-width: 480px) {
  .invoice-logo {
    margin: 0 auto 20px;
    float: none;
  }
}

.invoice-order {
  text-align: right;
}

.rtl .invoice-order {
  text-align: left;
}

@media (max-width: 480px) {
  .rtl .invoice-order {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .invoice-order {
    text-align: center;
  }
}

.invoice-id {
  display: block;
  font-size: 30px;
  line-height: 2.30769rem;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-id {
    font-size: 15px;
    line-height: -9.69231rem;
  }
}

.incoice-date {
  display: block;
  font-size: 1.385rem;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .incoice-date {
    font-size: 1rem;
  }
}

.invoice-data {
  margin-bottom: 110px;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-data {
    margin-bottom: 55px;
  }
}

@media (max-width: 480px) {
  .invoice-person {
    text-align: center;
  }
}

.invoice-person span {
  font-size: 1.385rem;
  line-height: 26px;
  display: block;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-person span {
    font-size: 1.0004rem;
    line-height: 20px;
  }
}

.invoice-person .name {
  font-weight: 500;
}

.invoice-person:last-child {
  text-align: right;
}

.rtl .invoice-person:last-child {
  text-align: left;
}

@media (max-width: 480px) {
  .rtl .invoice-person:last-child {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .invoice-person:last-child {
    text-align: center;
  }
}

.invoice-payment-direction {
  text-align: center;
  position: relative;
  padding-top: 20px;
}

@media (max-width: 480px) {
  .invoice-payment-direction {
    padding: 20px;
  }
}

.invoice-payment-direction .icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f7f7f7;
  font-size: 3.846rem;
  color: #c2c2c2;
  line-height: 80px;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-payment-direction .icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 1.923rem;
  }
}

@media (max-width: 480px) {
  .invoice-payment-direction .icon {
    transform: rotate(90deg);
  }
}

.invoice-details {
  width: 100%;
  font-size: 1.231rem;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-details {
    font-size: 0.8464rem;
  }
}

.invoice-details tr > td {
  padding: 20px 0;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-details tr > td {
    padding: 15px 0;
  }
}

.invoice-details th {
  text-align: right;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
}

.rtl .invoice-details th {
  text-align: left;
}

.rtl .invoice-details th:first-child {
  text-align: right;
}

.invoice-details th:first-child {
  text-align: left;
}

.invoice-details td.description, .invoice-details td.hours, .invoice-details td.amount, .invoice-details td.summary {
  border-bottom: 1px solid #f0f0f0;
}

.invoice-details td.hours, .invoice-details td.amount {
  text-align: right;
}

.rtl .invoice-details td.hours, .rtl .invoice-details td.amount {
  text-align: left;
}

.invoice-details td.summary {
  color: #c4c4c4;
}

.invoice-details td.total {
  color: #8c8c8c;
  font-weight: 500;
}

.invoice-details td.total-value {
  font-size: 1.692rem;
  color: #ef6262;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-details td.total-value {
    font-size: 0.8464rem;
  }
}

.invoice-payment-method {
  margin-bottom: 75px;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-payment-method {
    margin-bottom: 37px;
  }
}

.invoice-payment-method span {
  font-size: 1.385rem;
  line-height: 26px;
  display: block;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-payment-method span {
    font-size: 1.0004rem;
    line-height: 20px;
  }
}

.invoice-payment-method .title {
  font-weight: 500;
}

.invoice-message {
  font-size: 1.231rem;
  margin-bottom: 62px;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-message {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .invoice-message {
    margin-bottom: 31px;
  }
}

.invoice-message .title {
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  margin-bottom: 12px;
}

.invoice-message p {
  line-height: 26px;
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .invoice-message p {
    line-height: 20px;
  }
}

.invoice-company-info {
  margin-bottom: 70px;
}

@media (max-width: 767px), (max-width: 480px) {
  .invoice-company-info {
    margin-bottom: 35px;
  }
}

.invoice-company-info > div {
  border-left: 1px solid #f0f0f0;
  padding: 8px 40px;
}

@media (max-width: 767px), (max-width: 480px) {
  .invoice-company-info > div {
    border: none;
    padding: 8px 15px;
  }
}

.invoice-company-info > div:first-child {
  border-left: none;
}

.invoice-company-info .summary {
  padding: 0 40px 0;
  line-height: 16px;
}

@media (max-width: 767px), (max-width: 480px) {
  .invoice-company-info .summary {
    padding: 8px 15px;
  }
}

.invoice-company-info .summary .title {
  color: #8c8c8c;
  font-size: 1.077rem;
  line-height: 21px;
  font-weight: 500;
}

.invoice-company-info .summary p {
  line-height: 16px;
}

.invoice-footer {
  text-align: center;
}

.invoice-footer .btn {
  min-width: 96px;
  font-size: 1.077rem;
  font-weight: 700;
}

/*------------------------------------------------------------------
  [Custom pages]
*/
/*------------------------------------------------------------------
  [Login]
*/
.login-container {
  max-width: 400px;
  margin: 80px auto;
  padding: 10px;
}

.login-container .card {
  background: transparent;
  padding: 0;
  border: 0;
  overflow: hidden;
  margin-bottom: 20px;
}

.login-container .card .card-header {
  padding: 0;
  background: transparent;
  border: 0;
  text-align: center;
  margin-bottom: 20px;
}

.login-container .card .card-header span {
  display: block;
  color: #858383;
  line-height: 20px;
  font-size: 1.154rem;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  margin-top: 10px;
  padding-bottom: 10px;
}

.login-container .card .card-body .form-group {
  margin: 0;
  padding: 0;
}

.login-container .card .card-body .form-group > div {
  padding: 0;
}

.login-container .card .card-body .form-group .input-group {
  border-bottom: 2px solid #4a4949;
  padding: 10px 0;
}

.login-container .card .card-body .form-group .input-group .input-group-prepend .input-group-text, .login-container .card .card-body .form-group .input-group .input-group-append .input-group-text {
  border: 0;
  background-color: transparent;
}

.login-container .card .card-body .form-group .input-group .input-group-prepend .input-group-text .icon, .login-container .card .card-body .form-group .input-group .input-group-append .input-group-text .icon {
  font-size: 1.538rem;
  color: #FFFFFF;
}

.login-container .card .card-body .form-group .input-group .form-control {
  border: 0;
  background-color: transparent;
  box-shadow: none;
  color: #a3a2a2;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-size: 1.077rem;
  font-weight: 500;
  height: 36px;
}

.login-container .card .card-body .form-group .input-group .form-control:-webkit-autofill, .login-container .card .card-body .form-group .input-group .form-control:-webkit-autofill:hover, .login-container .card .card-body .form-group .input-group .form-control:-webkit-autofill:focus, .login-container .card .card-body .form-group .input-group .form-control:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-clip: content-box;
  -webkit-box-shadow: 0 0 0px 1000px #383737 inset;
  -webkit-text-fill-color: #a3a2a2 !important;
  caret-color: #a3a2a2;
}

.login-container .card .card-body .form-group .input-group.parsley-error {
  border-color: #f49090;
}

.login-container .card .card-body .form-group.login-submit {
  padding-top: 40px;
  padding-bottom: 15px;
}

.login-container .card .card-body .form-group.footer {
  margin-top: 3px;
  line-height: 35px;
  font-size: 1.077rem;
}

.login-container .card .card-body .form-group .remember {
  text-align: right;
  color: #a3a2a2;
}

.rtl .login-container .card .card-body .form-group .remember {
  text-align: left;
}

.login-container .card .card-body .form-group .remember label {
  margin: 0;
  cursor: pointer;
}

.rtl .login-container .card .card-body .form-group .remember .custom-checkbox {
  margin-left: 0;
  margin-right: 7px;
}

.login-container .card .card-body .form-group .remember .custom-checkbox .custom-control-label:before {
  border-color: #4a4949;
  background-color: transparent;
}

.login-container .card .card-body .form-group .remember .custom-checkbox .custom-control-label:hover:before {
  border-color: #575555;
}

@media (max-width: 767px) {
  .login-container {
    margin-top: 50px;
  }
}

/*------------------------------------------------------------------
  [Profile page]
*/
.user-profile .user-display {
  margin-bottom: 30px;
}

.user-profile .user-display .photo {
  max-height: 300px;
  overflow: hidden;
}

.user-profile .user-display .photo img {
  width: 100%;
}

@media (max-width: 767px) {
  .user-profile .user-display .photo img {
    min-height: 130px;
  }
}

.user-profile .user-display .bottom {
  background-color: #FFFFFF;
  position: relative;
  padding: 25px 30px 25px 205px;
}

.rtl .user-profile .user-display .bottom {
  padding: 25px 205px 25px 30px;
}

.user-profile .user-display .bottom > div {
  display: inline-block;
}

.user-profile .user-display .bottom .user-avatar {
  position: absolute;
  left: 46px;
  top: -50px;
}

.rtl .user-profile .user-display .bottom .user-avatar {
  left: auto;
  right: 46px;
}

.user-profile .user-display .bottom .user-avatar .status {
  background-color: #00b777;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  left: 105px;
  position: absolute;
  top: 105px;
}

@media (max-width: 767px) {
  .user-profile .user-display .bottom .user-avatar .status {
    height: 13px;
    width: 13px;
    left: 53px;
    top: 51px;
  }
}

.user-profile .user-display .bottom .user-avatar img {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  border: 3px solid #FFFFFF;
}

@media (max-width: 767px) {
  .user-profile .user-display .bottom .user-avatar img {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 767px) {
  .user-profile .user-display .bottom .user-avatar {
    left: 50%;
    top: -46px;
    margin-left: -35px;
  }
}

.user-profile .user-display .bottom .user-info {
  line-height: 20px;
}

.user-profile .user-display .bottom .user-info h4 {
  margin: 0;
  font-size: 1.923rem;
  margin-bottom: 5px;
}

.user-profile .user-display .bottom .user-info span {
  font-size: 1.077rem;
  font-weight: 300;
  color: #7b7b7b;
}

@media (max-width: 767px) {
  .user-profile .user-display .bottom .user-info {
    line-height: 22px;
  }
}

@media (max-width: 767px) {
  .user-profile .user-display .bottom {
    padding: 30px 30px 25px 25px;
  }
}

.user-profile .info-block .description {
  padding-bottom: 15px;
  border-bottom: 1px dashed #e6e6e6;
  display: block;
  color: #7b7b7b;
}

.user-profile .info-block table {
  width: 100%;
}

.user-profile .info-block table tr {
  line-height: 23px;
  border-bottom: 1px solid #e6e6e6;
}

.user-profile .info-block table tr td {
  padding: 15px 0;
}

.user-profile .info-block table tr td.item {
  width: 45%;
  color: #7b7b7b;
}

.user-profile .info-block table tr td.item .icon {
  float: left;
  margin-right: 5px;
  font-size: 1.538rem;
  color: #ef6262;
}

.rtl .user-profile .info-block table tr td.item .icon {
  float: right;
  margin-left: 5px;
  margin-right: 0;
}

.user-profile .info-block table tr:last-child {
  border-bottom: 0;
}

.user-profile .info-block table tr:last-child td {
  padding-bottom: 0;
}

/*------------------------------------------------------------------
  [Sign up]
*/
.sign-up {
  margin-top: 20px;
  max-width: 440px;
  margin-bottom: 20px;
  padding: 0;
}

.sign-up .card-header {
  margin-bottom: 15px !important;
}

.sign-up .card-body form .title {
  font-size: 1.154rem;
  color: #d9d9d9;
  text-align: center;
}

.sign-up .card-body form .sign-up-form {
  margin-bottom: 25px;
}

.sign-up .card-body form .sign-up-form .social-signup {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sign-up .card-body form .sign-up-form .social-signup > div:first-child {
  padding-right: 8px;
}

.rtl .sign-up .card-body form .sign-up-form .social-signup > div:first-child {
  padding-right: 0;
  padding-left: 8px;
}

.sign-up .card-body form .sign-up-form .social-signup > div:last-child {
  padding-right: 8px;
  padding-left: 0;
}

.sign-up .card-body form .conditions {
  color: #979595;
  margin-bottom: 25px;
}

.sign-up .card-body form .conditions a {
  color: #d9d9d9;
}

.sign-up .card-body form .conditions a:hover {
  color: #FFFFFF;
}

.bootstrap-touchspin input {
  border-width: 1px;
}

.rtl .bootstrap-touchspin .input-group-btn-vertical {
  left: 0;
}

.rtl .bootstrap-touchspin .input-group-btn-vertical > .btn {
  left: 0;
  right: unset;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  padding: 1px 3px 0 2px;
  border-bottom-width: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .am-icon-plus {
  font-size: 1.077rem;
  padding: 0 4px;
  border-bottom-width: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .am-icon-times {
  font-size: 1.077rem;
  padding: 0 4px;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-top-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-bottom-right-radius: 0;
}

.bootstrap-touchspin .input-group-addon {
  margin: 0;
}

.bootstrap-touchspin .input-group-addon.btn .input-group-text {
  color: inherit;
  background-color: transparent;
  padding: 0;
  border: 0;
  height: auto;
}

/*------------------------------------------------------------------
  [dataTables]
*/
table.dataTable {
  margin: 0 !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after {
  font-size: 1.538rem;
  bottom: .5rem;
}

div.dataTables_wrapper {
  flex-flow: column;
  align-items: stretch;
  padding-left: 0;
  padding-right: 0;
}

div.dataTables_wrapper div.dataTables_info {
  color: #767676;
  padding-top: 9px;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 0;
  justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_length label, div.dataTables_wrapper div.dataTables_filter label {
  margin-bottom: 0;
  font-size: 13px;
  color: #767676;
}

div.dataTables_wrapper div.dataTables_length label {
  justify-content: flex-start;
}

div.dataTables_wrapper div.dataTables_length label select {
  padding: 2px;
  margin: 0 5px;
}

div.dataTables_wrapper div.dataTables_filter label {
  justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_filter label select {
  padding: 2px;
}

.am-datatable-header {
  border-bottom: 1px solid #eaeaea;
  margin: 2px 0 0;
  padding: 15px 10px 22px;
}

.am-datatable-header .dt-buttons .btn embed {
  outline: none;
}

.am-datatable-footer {
  margin: 0;
  padding: 15px 3px 13px;
  background-color: #f7f7f7;
  border-top: 1px solid #eaeaea;
}

/*------------------------------------------------------------------
  [Bootstrap dateTime Picker]
*/
.datetimepicker.input-group {
  padding: 4px 0;
}

.datetimepicker .input-group-append {
  margin-left: 0;
}

.datetimepicker .input-group-append > button {
  padding: 0 9px;
  font-size: 1.846rem;
  line-height: 1;
  text-align: center;
}

.datetimepicker .input-group-append > button:not(:hover) {
  color: #777777;
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
}

.datetimepicker.dropdown-menu {
  border-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.rtl .datetimepicker.dropdown-menu {
  right: auto;
}

.datetimepicker.dropdown-menu:before {
  border-bottom-color: rgba(0, 0, 0, 0.08);
}

.datetimepicker.dropdown-menu table {
  border-collapse: separate;
  border-spacing: 7px 2px;
}

.datetimepicker.dropdown-menu table thead tr th {
  padding: 10px 4px 8px;
}

.datetimepicker.dropdown-menu table thead tr th.prev, .datetimepicker.dropdown-menu table thead tr th.next {
  padding: 0;
}

.datetimepicker.dropdown-menu table thead tr th.prev .s7-angle-right, .datetimepicker.dropdown-menu table thead tr th.prev .s7-angle-left, .datetimepicker.dropdown-menu table thead tr th.next .s7-angle-right, .datetimepicker.dropdown-menu table thead tr th.next .s7-angle-left {
  font-size: 28px;
}

.datetimepicker.dropdown-menu table thead tr th.prev:hover, .datetimepicker.dropdown-menu table thead tr th.next:hover {
  background-color: transparent;
  color: #ef6262;
}

.datetimepicker.dropdown-menu table thead tr th.switch {
  font-weight: 600;
  font-size: 14px;
}

.datetimepicker.dropdown-menu table thead tr th.switch:hover {
  background-color: transparent;
  color: #6f6f6f;
}

.datetimepicker.dropdown-menu table thead tr th.dow {
  font-weight: 400;
  font-size: 14px;
  padding-top: 10px;
}

.datetimepicker.dropdown-menu table tbody tr td {
  line-height: 31px;
  padding: 0 8px;
}

.datetimepicker.dropdown-menu table tbody tr td.day {
  border-radius: 50%;
  color: #888888;
}

.datetimepicker.dropdown-menu table tbody tr td.day.old, .datetimepicker.dropdown-menu table tbody tr td.day.new {
  color: #c8c8c8;
}

.datetimepicker.dropdown-menu table tbody tr td.day.active {
  background: #ef6262;
  color: #FFFFFF;
  text-shadow: none;
}

.datetimepicker.dropdown-menu table tbody tr td .year, .datetimepicker.dropdown-menu table tbody tr td .month, .datetimepicker.dropdown-menu table tbody tr td .hour, .datetimepicker.dropdown-menu table tbody tr td .minute {
  color: #888888;
}

.datetimepicker.dropdown-menu table tbody tr td .year.old, .datetimepicker.dropdown-menu table tbody tr td .year.new, .datetimepicker.dropdown-menu table tbody tr td .month.old, .datetimepicker.dropdown-menu table tbody tr td .month.new, .datetimepicker.dropdown-menu table tbody tr td .hour.old, .datetimepicker.dropdown-menu table tbody tr td .hour.new, .datetimepicker.dropdown-menu table tbody tr td .minute.old, .datetimepicker.dropdown-menu table tbody tr td .minute.new {
  color: #c8c8c8;
}

.datetimepicker.dropdown-menu table tbody tr td .year.active, .datetimepicker.dropdown-menu table tbody tr td .month.active, .datetimepicker.dropdown-menu table tbody tr td .hour.active, .datetimepicker.dropdown-menu table tbody tr td .minute.active {
  background: #ef6262;
  color: #FFFFFF;
  text-shadow: none;
}

.datetimepicker.dropdown-menu table tbody tr td fieldset legend {
  font-size: 17px;
  font-weight: 400;
  color: #6f6f6f;
  margin-bottom: 5px;
}

form.dropzone {
  position: relative;
  border: 2px dashed #d4d4d4;
  padding: 70px 60px 80px;
  background: transparent;
}

form.dropzone .icon {
  background-color: #e6e6e6;
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  width: 100px;
  margin-bottom: 15px;
  padding: 20px;
  text-align: center;
}

form.dropzone .icon span {
  font-size: 60px;
  color: #696767;
}

form.dropzone h2 {
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 300;
}

form.dropzone .note {
  font-size: 16px;
}

form.dropzone .dz-preview.dz-file-preview .dz-image, form.dropzone .dz-preview.dz-image-preview .dz-image {
  border: 3px solid #ef6262;
  border-radius: 3px;
  background: #ededed;
}

form.dropzone .dz-preview.dz-file-preview.dz-error .dz-image, form.dropzone .dz-preview.dz-image-preview.dz-error .dz-image {
  border-color: #EF6262;
}

form.dropzone .dz-preview.dz-file-preview.dz-success .dz-image, form.dropzone .dz-preview.dz-image-preview.dz-success .dz-image {
  border-color: #ef6262;
}

form.dropzone .dz-preview .dz-details .dz-filename span, form.dropzone .dz-preview .dz-details .dz-size span {
  background: transparent;
}

form.dropzone .dz-preview .dz-error-message {
  word-break: break-word;
}

.dropzone-mobile-trigger {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.tooltip-chart {
  display: flex;
}

.tooltip-chart .content-chart {
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFF;
  border-radius: 3px;
  padding: 5px 12px 5px;
  position: relative;
}

.tooltip-chart .content-chart:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: -5px;
  margin-left: 50%;
  left: -5px;
}

.tooltip-chart .content-chart.arrow-none:before {
  display: none;
}

.tooltip-chart .content-chart span {
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: -2px;
  text-align: center;
  color: #fbd6d6;
}

.tooltip-chart .content-chart .label {
  font-size: 11px;
  text-align: center;
}

.tooltip-chart .content-chart .label .label-x, .tooltip-chart .content-chart .label .label-y {
  display: inline-block;
}

/*------------------------------------------------------------------
  [Full calendar]
*/
.full-calendar .fc-toolbar {
  padding-top: 20px;
  margin-bottom: 20px;
}

.full-calendar .fc-toolbar .fc-left {
  padding: 0 10px;
}

@media (max-width: 767px) {
  .full-calendar .fc-toolbar > * > * {
    float: none;
  }
}

.full-calendar .fc-view-container .fc-event-container .fc-event {
  background: #ef6262;
  border-color: #eb3d3d;
  color: #FFFFFF;
  border-radius: 0;
}

.full-calendar .fc-view-container .fc-border-separate .fc-today {
  background-color: #f7f7f7;
}

.full-calendar .fc-view-container .fc-cell-overlay {
  background-color: fade(#ef6262, 50%);
}

.full-calendar .fc .fc-button-group > * {
  margin: 0;
}

@media (max-width: 767px) {
  .full-calendar .fc .fc-button-group > * {
    float: none;
  }
}

@media (max-width: 480px) {
  .full-calendar .fc .fc-button-group > * {
    margin-bottom: 10px;
  }
}

.full-calendar .fc-button {
  color: #555555;
  background-image: none;
  border-width: 0 1px 0 0;
  height: auto;
  padding: 10px;
  box-shadow: none;
}

@media (max-width: 767px) {
  .full-calendar .fc-button {
    padding: 5px 8px;
  }
}

.full-calendar .fc-button.fc-corner-right {
  border: 0;
}

.full-calendar .fc-button:focus, .full-calendar .fc-button.focus {
  color: #FFFFFF;
  background: #f96c6c;
  text-shadow: none;
}

.full-calendar .fc-button:hover, .full-calendar .fc-button.hover {
  color: #FFFFFF;
  background: #f96c6c;
  text-shadow: none;
}

.full-calendar .fc-button:active, .full-calendar .fc-button.fc-state-active, .full-calendar .fc-button.active,
.open > .dropdown-toggle .full-calendar .fc-button {
  color: #FFFFFF;
  background: #f96c6c;
  box-shadow: none;
  text-shadow: none;
}

.full-calendar .fc-button:active:hover, .full-calendar .fc-button:active:focus, .full-calendar .fc-button:active.focus, .full-calendar .fc-button.fc-state-active:hover, .full-calendar .fc-button.fc-state-active:focus, .full-calendar .fc-button.fc-state-active.focus, .full-calendar .fc-button.active:hover, .full-calendar .fc-button.active:focus, .full-calendar .fc-button.active.focus,
.open > .dropdown-toggle .full-calendar .fc-button:hover,
.open > .dropdown-toggle .full-calendar .fc-button:focus,
.open > .dropdown-toggle .full-calendar .fc-button.focus {
  color: #FFFFFF;
  background-color: #ef6262;
}

.full-calendar .fc-button:active, .full-calendar .fc-button.active,
.open > .dropdown-toggle .full-calendar .fc-button {
  background-image: none;
}

.full-calendar .fc-button.fc-prev-button, .full-calendar .fc-button.fc-next-button {
  padding-left: 7px;
  padding-right: 7px;
}

.full-calendar .fc-button.fc-state-disabled:hover {
  background-color: #e6e6e6;
  color: #545454;
}

.full-calendar .fc-button .fc-icon:after {
  position: static;
  font-weight: 400;
  font-size: 1.615rem;
}

.full-calendar .fc-state-default {
  color: #555555;
  border: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.full-calendar .fc-state-default.fc-corner-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.full-calendar .fc-state-default.fc-corner-right {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.full-calendar .fc-unthemed .fc-today {
  background-color: #f7f7f7;
}

@media (max-width: 767px) {
  .full-calendar {
    max-width: 767px;
  }
  .full-calendar .fc-toolbar .fc-left, .full-calendar .fc-toolbar .fc-center, .full-calendar .fc-toolbar .fc-right {
    text-align: center;
    display: block;
    width: 100%;
  }
  .full-calendar .fc-toolbar .fc-left {
    padding-bottom: 20px;
  }
  .full-calendar .fc-toolbar .fc-center {
    padding: 0;
  }
}

.fullcalendar-external-events .fc-event {
  margin: 2px 0;
  padding: 5px;
  background: #ef6262;
  color: #FFFFFF;
  font-size: .85em;
  cursor: pointer;
  display: inline-block;
  border-width: 0;
  border-radius: 0;
}

.fullcalendar-external-events p input {
  margin-top: 15px;
}

.fullcalendar-external-events p label {
  font-weight: 300;
  vertical-align: middle;
}

/*------------------------------------------------------------------
  [Notifications]
*/
#gritter-notice-wrapper {
  width: 27.69rem;
  top: 6.308rem;
}

.gritter-item-wrapper {
  background: url("../lib/jquery.gritter/images/gritter-bg.png");
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
}

.gritter-item-wrapper .gritter-item {
  font-family: 'Open Sans',sans-serif;
  background: transparent;
  color: #FFFFFF;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.gritter-item-wrapper .gritter-item .gritter-img-container {
  display: table-cell;
  width: 26%;
  vertical-align: middle;
  text-align: center;
  background-color: #302f2f;
}

.gritter-item-wrapper .gritter-item .gritter-img-container .gritter-image {
  float: none;
}

.gritter-item-wrapper .gritter-item .gritter-content {
  width: auto;
}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image {
  padding: 15px 35px 17px 15px;
  color: #d9d9d9;
  width: 267px;
  display: table-cell;
}

.rtl .gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image, .rtl .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image {
  padding: 15px 15px 17px 35px;
}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image .gritter-close, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image .gritter-close {
  display: block !important;
  top: 0;
  right: 0;
  left: auto;
  height: 30px;
  width: 35px;
  font-size: 0;
  background: transparent;
  text-indent: inherit;
  opacity: 0.4;
}

.rtl .gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image .gritter-close, .rtl .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image .gritter-close {
  left: 0;
  right: auto;
}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image .gritter-close:hover, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image .gritter-close:hover {
  opacity: 0.8;
}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image .gritter-close:after, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image .gritter-close:after {
  content: '\e680';
  font-family: "Stroke 7";
  position: absolute;
  color: #FFFFFF;
  font-size: 1.846rem;
  left: 5px;
  text-shadow: none;
}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image .gritter-title, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image .gritter-title {
  text-shadow: none;
  font-weight: 300;
  font-size: 1.308rem;
}

.gritter-item-wrapper .gritter-item .gritter-content.gritter-with-image p, .gritter-item-wrapper .gritter-item .gritter-content.gritter-without-image p {
  font-size: 0.9231rem;
  line-height: 19px;
}

.gritter-item-wrapper .gritter-item .gritter-content .gritter-without-image {
  border-left: 0;
}

.gritter-item-wrapper.img-rounded {
  border-radius: 0;
}

.gritter-item-wrapper.img-rounded .gritter-img-container .gritter-image {
  border-radius: 50%;
}

.gritter-item-wrapper.clean {
  background: #FFFFFF;
}

.gritter-item-wrapper.clean .gritter-item {
  color: #555555;
}

.gritter-item-wrapper.clean .gritter-item .gritter-img-container {
  background-color: #fafafa;
}

.gritter-item-wrapper.clean .gritter-item .gritter-content {
  color: #555555;
}

.gritter-item-wrapper.clean .gritter-item .gritter-content .gritter-close {
  opacity: 0.5;
}

.gritter-item-wrapper.clean .gritter-item .gritter-content .gritter-close:after {
  color: #555555;
}

.gritter-item-wrapper.color .gritter-item .gritter-img-container {
  background-color: transparent;
}

.gritter-item-wrapper.color .gritter-item .gritter-content {
  color: #FFFFFF;
}

.gritter-item-wrapper.color .gritter-item .gritter-content .gritter-close {
  opacity: 0.7;
}

.gritter-item-wrapper.color .gritter-item .gritter-content .gritter-close:hover {
  opacity: 1;
}

.gritter-item-wrapper.primary {
  background: #ef6262;
}

.gritter-item-wrapper.success {
  background: #7ACCBE;
}

.gritter-item-wrapper.info {
  background: #8DCADF;
}

.gritter-item-wrapper.warning {
  background: #FFC870;
}

.gritter-item-wrapper.danger {
  background: #EF6262;
}

.gritter-item-wrapper.alt1 {
  background: #95d9f0;
}

.gritter-item-wrapper.alt2 {
  background: #ffdc7a;
}

.gritter-item-wrapper.alt3 {
  background: #7a98bf;
}

.gritter-item-wrapper.alt4 {
  background: #cccccc;
}

.gritter-item-wrapper.dark {
  background: #3d3c3c;
}

.gritter-item-wrapper.facebook {
  background: #5d82d1;
}

.gritter-item-wrapper.facebook .gritter-item .gritter-img-container {
  background-color: #5179ce;
}

.gritter-item-wrapper.twitter {
  background: #50bff5;
}

.gritter-item-wrapper.twitter .gritter-item .gritter-img-container {
  background-color: #42baf4;
}

.gritter-item-wrapper.google-plus {
  background: #eb5e4c;
}

.gritter-item-wrapper.google-plus .gritter-item .gritter-img-container {
  background-color: #e9523e;
}

.gritter-item-wrapper.dribbble {
  background: #f7659c;
}

.gritter-item-wrapper.dribbble .gritter-item .gritter-img-container {
  background-color: #f65693;
}

.gritter-item-wrapper.flickr {
  background: #0063dc;
}

.gritter-item-wrapper.flickr .gritter-item .gritter-img-container {
  background-color: #005ccd;
}

.gritter-item-wrapper.linkedin {
  background: #238cc8;
}

.gritter-item-wrapper.linkedin .gritter-item .gritter-img-container {
  background-color: #2183bb;
}

.gritter-item-wrapper.youtube {
  background: #ef4e41;
}

.gritter-item-wrapper.youtube .gritter-item .gritter-img-container {
  background-color: #ee4133;
}

.gritter-item-wrapper.pinterest {
  background: #e13138;
}

.gritter-item-wrapper.pinterest .gritter-item .gritter-img-container {
  background-color: #df242b;
}

.gritter-item-wrapper.github {
  background: #333;
}

.gritter-item-wrapper.github .gritter-item .gritter-img-container {
  background-color: #2b2b2b;
}

.gritter-item-wrapper.tumblr {
  background: #426d9b;
}

.gritter-item-wrapper.tumblr .gritter-item .gritter-img-container {
  background-color: #3d6590;
}

.rtl .jvectormap-zoomin,
.rtl .jvectormap-zoomout {
  left: auto;
  right: 10px;
}

@media (max-width: 575.98px) {
  .ms-container {
    width: auto;
  }
}

@media (max-width: 767.98px) {
  .ms-container {
    margin: 0 auto;
  }
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
  padding: 6px 10px;
}

.ms-container .ms-selectable, .ms-container .ms-selection {
  background-color: transparent;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  background-color: #ef6262;
}

.ms-container .ms-list {
  border: 2px solid #eaeaea;
  background-color: #FFFFFF;
  border-radius: 0;
  box-shadow: none;
}

.ms-container .ms-list.ms-focus {
  box-shadow: none;
  border-color: #ef6262;
}

.ms-container .search-input {
  height: 30px;
  margin-bottom: 10px;
}

/*------------------------------------------------------------------
  [Nestable list]
*/
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1rem;
  line-height: 1.538rem;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-item .dd-handle, .dd-list .dd3-item .dd-handle {
  cursor: default;
  display: block;
  margin: 5px 0;
  padding: 7px 10px;
  text-decoration: none;
  border: 1px solid #dedede;
  background: #FFFFFF;
}

.dd-list .dd-item .dd-handle:hover, .dd-list .dd3-item .dd-handle:hover {
  color: #FFFFFF;
  background: #ef6262;
  border-color: #ef6262;
}

.dd-list .dd-item > button, .dd-list .dd3-item > button {
  position: relative;
  font-family: FontAwesome;
  display: block;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 8px 2px;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 0.7692rem;
  line-height: 1;
  text-align: center;
}

.dd-list .dd-item > button:before, .dd-list .dd3-item > button:before {
  content: '\f067';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-list .dd-item > button[data-action="collapse"]:before, .dd-list .dd3-item > button[data-action="collapse"]:before {
  content: '\f068';
}

.dd-list .dd-item .dd3-content, .dd-list .dd3-item .dd3-content {
  display: block;
  margin: 5px 0;
  padding: 7px 10px 7px 40px;
  text-decoration: none;
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #dedede;
}

.dd-list .dd-item .dd3-content:hover, .dd-list .dd3-item .dd3-content:hover {
  background: #FFFFFF;
}

.dd-list .dd-item .dd3-handle, .dd-list .dd3-item .dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: all-scroll;
  width: 34px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #ef6262;
  background: #ef6262;
  height: 36px;
}

.dd-list .dd-item .dd3-handle:before, .dd-list .dd3-item .dd3-handle:before {
  content: '=';
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: normal;
}

.dd-list .dd-item .dd3-handle:hover, .dd-list .dd3-item .dd3-handle:hover {
  background: #f17979;
  border-color: #f17979;
}

.dd-list .dd3-item > button {
  margin-left: 35px;
}

.dd-list .dd-item, .dd-list .dd-empty, .dd-list .dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-list .dd-placeholder, .dd-list .dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #FFF;
  border: 1px dashed #b3b3b3;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-list .dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), -webkit-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
  background-image: -moz-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), -moz-linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
  background-image: linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF), linear-gradient(45deg, #FFFFFF 25%, transparent 25%, transparent 75%, #FFFFFF 75%, #FFFFFF);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-list .dd-collapsed .dd-list {
  display: none;
}

.dd-list.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

.dd-list.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-list.dd-dragel .dd-handle, .dd-list.dd-dragel .dd3-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

.dd-list .dd-list {
  padding-left: 30px;
}

/*------------------------------------------------------------------
  [Parsley]
*/
.parsley-errors-list.filled {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 15px 20px;
  position: relative;
  background-color: #EF6262;
  color: #FFFFFF;
}

.parsley-errors-list.filled:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: -6px;
  left: 20px;
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-bottom: 7px solid #EF6262;
  -moz-transform: scale(0.9999);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.parsley-errors-list.filled > li {
  list-style: none;
}

/*------------------------------------------------------------------
  [Perfect Scrollbar]
*/
/*Perfect Scrollbar*/
.ps:hover > .ps__rail-y, .ps.ps--scrolling-y > .ps__rail-y {
  background-color: transparent;
}

.ps:hover > .ps__rail-y:hover, .ps.ps--scrolling-y > .ps__rail-y:hover {
  width: 6px;
  background-color: rgba(0, 0, 0, 0.15);
}

.ps:hover > .ps__rail-y > .ps__thumb-y, .ps.ps--scrolling-y > .ps__rail-y > .ps__thumb-y {
  right: 0;
  width: 6px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
}

.ps > .ps__rail-y {
  width: 6px;
  background-color: rgba(0, 0, 0, 0.1);
}

.ps > .ps__rail-y > .ps__thumb-y {
  right: 0;
  width: 6px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
}

/*------------------------------------------------------------------
  [Select2]
*/
.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
  border: 0.153846rem solid #eaeaea;
  border-radius: 0;
}

.select2-container--default .select2-selection--single {
  height: 44px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0 12px;
  height: 3.077308rem;
  line-height: 3.077308rem;
  font-size: 1.077rem;
  color: #777777;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 3.231154rem;
  width: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:after {
  content: "";
  font-family: "Stroke 7";
  font-size: 28px;
  font-weight: normal;
  line-height: 3.231154rem;
  color: #777777;
}

.select2-container--default .select2-selection--multiple {
  min-height: 42px;
  line-height: 1;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 4px 12px;
  min-height: 3.385rem;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 0;
  background-color: #f2f2f2;
  color: #6f6f6f;
  border-width: 0;
  padding: 4px 6px;
  line-height: 18px;
}

.rtl .select2-container--default .select2-selection--multiple .select2-selection__choice {
  float: right;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #7b7b7b;
  margin-right: 3px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #626262;
}

.select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field {
  line-height: 2;
}

.select2-container--default.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 0.153846rem solid #eaeaea;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b:after {
  content: "";
}

.select2-container--default .select2-results__group {
  font-size: 12px;
  color: #6f6f6f;
}

.rtl .select2-container--default .select2-results__options {
  text-align: right;
}

.select2-container--default .select2-results__option {
  padding: 10px 6px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #f7f7f7;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #ef6262;
}

.select2-container--default .select2-dropdown {
  border-width: 0.153846rem;
  border-color: #eaeaea;
}

.select2-container--default .select2-dropdown--above {
  border-radius: 0;
  box-shadow: none;
}

.select2-container--default .select2-dropdown--below {
  border-radius: 0;
  box-shadow: none;
}

.select2-container--default .select2-search--dropdown {
  background-color: white;
  border-bottom: 0.153846rem solid #eaeaea;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: transparent;
  border-width: 0;
  outline: none;
}

.rtl .select2-container .select2-search--inline {
  float: right;
}

/*------------------------------------------------------------------
  [Slider]
*/
/*Slider*/
.slider.slider-horizontal {
  top: 7px;
}

.slider.slider-horizontal .slider-track {
  margin-top: -3px;
}

.slider.slider-vertical {
  margin-right: 13px;
}

.slider.slider-vertical .slider-handle {
  margin-left: -1px;
}

.rtl .slider.slider-vertical .slider-handle {
  margin-right: -2px;
  margin-left: 0;
}

.slider-selection {
  background: #ef6262;
  border: 1px solid #ef6262;
  box-shadow: none;
}

.slider-track {
  background: #f2f2f2;
  box-shadow: none;
}

.slider-handle {
  opacity: 1;
  background: #FFFFFF;
  border: 3px solid #ef6262;
  background-clip: padding-box;
  box-shadow: none;
  height: 23px;
  width: 23px;
  transition: box-shadow 200ms ease-in-out;
}

/*------------------------------------------------------------------
  [Summernote]
*/
.note-editor.note-frame {
  border: 0;
  margin-bottom: 0;
}

.note-editor.note-frame .note-toolbar {
  margin: 0;
  padding-bottom: 5px;
}

.note-editor.note-frame .note-toolbar .btn .fa {
  font-size: 1.077rem;
}

.note-editor.note-frame .note-toolbar > .btn-group {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 5px;
}

.rtl .note-editor.note-frame .note-toolbar > .btn-group .dropdown-menu {
  left: auto !important;
}

.note-editor.note-frame .note-toolbar .note-color .dropdown-toggle {
  padding-left: 5px;
  width: 20px;
}

.note-editor.note-frame .note-statusbar {
  border-top-width: 0;
  background-color: #f6f6f6;
}

.note-editor.note-frame .note-statusbar .note-resizebar {
  height: auto;
  padding-top: 3px;
  padding-bottom: 4px;
}

.note-editor.note-frame .note-editable {
  border: 2px solid #eaeaea;
  padding: 10px 12px;
  margin-top: 12px;
}

.note-editor.note-frame .note-editing-area .note-editable {
  color: #555555;
}

.note-editor.note-frame .note-btn:after {
  margin-left: 3px;
}

/*------------------------------------------------------------------
  [Wizard]
*/
.fuelux .wizard {
  *zoom: 1;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  background-color: #f9f9f9;
  position: relative;
  min-height: 48px;
  overflow: hidden;
}

.fuelux .wizard:before, .fuelux .wizard:after {
  display: table;
  content: "";
  line-height: 0;
}

.fuelux .wizard:after {
  clear: both;
}

.fuelux .wizard > .steps {
  list-style: none outside none;
  padding: 0;
  margin: 0;
}

.fuelux .wizard > .steps.previous-disabled li.complete {
  cursor: default;
}

.fuelux .wizard > .steps.previous-disabled li.complete:hover {
  background: #f3f4f5;
  color: #468847;
  cursor: default;
}

.fuelux .wizard > .steps.previous-disabled li.complete:hover .chevron:before {
  border-left-color: #f3f4f5;
}

.fuelux .wizard > .steps li {
  float: left;
  margin: 0;
  padding: 0 20px 0 30px;
  height: 46px;
  line-height: 46px;
  position: relative;
  background: #ededed;
  color: #999999;
  font-size: 1.231rem;
  cursor: not-allowed;
}

.fuelux .wizard > .steps li .chevron {
  border: 24px solid transparent;
  border-left: 14px solid #d4d4d4;
  border-right: 0;
  display: block;
  position: absolute;
  right: -14px;
  top: 0;
  z-index: 1;
}

.fuelux .wizard > .steps li .chevron:before {
  border: 24px solid transparent;
  border-left: 14px solid #ededed;
  border-right: 0;
  content: "";
  display: block;
  position: absolute;
  right: 1px;
  top: -24px;
}

.fuelux .wizard > .steps li.complete {
  background: #f3f4f5;
  color: #468847;
}

.fuelux .wizard > .steps li.complete:hover {
  background: #e7eff8;
  cursor: pointer;
}

.fuelux .wizard > .steps li.complete:hover .chevron:before {
  border-left: 14px solid #e7eff8;
}

.fuelux .wizard > .steps li.complete .chevron:before {
  border-left: 14px solid #f3f4f5;
}

.fuelux .wizard > .steps li.active {
  background: #f1f6fc;
  color: #3a87ad;
  cursor: default;
}

.fuelux .wizard > .steps li.active .chevron:before {
  border-left: 14px solid #f1f6fc;
}

.fuelux .wizard > .steps li.active .badge {
  background-color: #3a87ad;
}

.fuelux .wizard > .steps li .badge {
  margin-right: 8px;
}

.fuelux .wizard > .steps li .badge-success {
  background-color: #468847;
}

.fuelux .wizard > .steps li:first-child {
  border-radius: 4px 0 0 4px;
  padding-left: 20px;
}

.fuelux .wizard > .actions {
  z-index: 1000;
  position: absolute;
  right: 0;
  line-height: 46px;
  float: right;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  background-color: #e5e5e5;
  border-left: 1px solid #d4d4d4;
}

.fuelux .wizard > .actions a {
  line-height: 45px;
  font-size: .9231rem;
  margin-right: 8px;
}

.fuelux .wizard > .actions .btn-prev[disabled] {
  cursor: not-allowed;
}

.fuelux .wizard > .actions .btn-prev span {
  margin-right: 5px;
}

.fuelux .wizard > .actions .btn-next[disabled] {
  cursor: not-allowed;
}

.fuelux .wizard > .actions .btn-prnextev span {
  margin-left: 5px;
}

.fuelux .wizard .step-content {
  border-top: 1px solid #D4D4D4;
  padding: 10px;
  float: left;
  width: 100%;
}

.fuelux .wizard .step-content .step-pane {
  display: none;
}

.fuelux .wizard .step-content > .active {
  display: block;
}

.fuelux .wizard .step-content > .active .btn-group .active {
  display: inline-block;
}

.fuelux .wizard.complete > .actions .glyphicon-arrow-right {
  margin-left: 0;
}

.fuelux .wizard.complete > .actions .glyphicon-arrow-right:before {
  display: none;
}

.block-wizard {
  padding: 0;
}

.fuelux .wizard {
  box-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  border: 0;
  background: #FFFFFF;
}

.fuelux .wizard label.control-label {
  font-weight: 600;
}

.fuelux .wizard label.control-label:not(.col-form-label) {
  margin-bottom: 0;
  padding-top: 9px;
}

.fuelux .wizard .wizard-title {
  margin: 10px 0 0;
}

.fuelux .wizard > .actions {
  line-height: 48px;
  background: #f7f7f7;
}

.fuelux .wizard > .actions .btn .icon {
  font-size: 1.692rem;
}

.fuelux .wizard > .steps > li {
  height: 48px;
  background-color: #f7f7f7;
}

.fuelux .wizard > .steps > li .chevron {
  border-left-color: #e0e0e0;
}

.fuelux .wizard > .steps > li .chevron:before {
  border-left: 14px solid #f7f7f7;
}

.fuelux .wizard > .steps li {
  font-size: 1.154rem;
}

.fuelux .wizard > .steps li:first-child {
  border-radius: 0;
}

.fuelux .wizard > .steps li.active {
  background: #FFFFFF;
  color: #ef6262;
}

.fuelux .wizard > .steps li.active .chevron:before {
  -moz-transform: scale(0.9999);
  border-left: 14px solid #FFFFFF;
}

.fuelux .wizard > .steps li.complete {
  color: #FFFFFF;
  background: #ef6262;
}

.fuelux .wizard > .steps li.complete .chevron {
  border-left-color: #eb3d3d;
}

.fuelux .wizard > .steps li.complete .chevron:before {
  border-left: 14px solid #ef6262;
}

.fuelux .wizard > .steps li.complete:hover {
  background: #f07070;
}

.fuelux .wizard > .steps li.complete:hover .chevron {
  border-left-color: #ec4747;
}

.fuelux .wizard > .steps li.complete:hover .chevron:before {
  border-left-color: #f07070;
}

.fuelux .wizard > .steps li .chevron {
  -moz-transform: scale(0.9999);
}

.fuelux .wizard > .actions button {
  font-size: .9231rem;
}

.fuelux .wizard > .actions button i {
  font-size: .8462rem;
  line-height: 18px;
}

.fuelux .wizard > .actions button.btn-prev i {
  margin-right: 3px;
}

.fuelux .wizard > .actions button.btn-next i {
  margin-left: 3px;
}

.fuelux .wizard .step-content {
  padding: 20px 30px;
  clear: both;
  border-top: 1px solid #E2E2E2;
}

@media (max-width: 767px) {
  .wizard-row .fuelux {
    position: static;
  }
}

.editable-checklist label {
  align-items: center;
  justify-content: flex-start;
}

.editable-checklist label input[type="checkbox"] {
  margin-right: 5px;
}

.editableform .form-group {
  flex-flow: column;
  align-items: flex-start;
}

.widget.bar-chart .chart-container {
  padding: 0 5px 5px 5px;
}

@media (max-width: 767px) {
  .widget.bar-chart .chart-container > div {
    padding: 0 0 5px;
    min-height: 160px;
  }
}

.widget.bar-chart .chart-table {
  padding-top: 20px;
}

.widget.bar-chart .chart-table table {
  margin: 0;
}

.widget.bar-chart .chart-table table .number {
  text-align: right;
  width: 20%;
}

@media (max-width: 767px) {
  .widget.bar-chart .chart-table {
    padding: 10px 0 0;
  }
}

.widget-calendar {
  padding: 0;
}

.widget-calendar .cal-container {
  width: 100%;
  display: table;
}

.widget-calendar .cal-container .cal-notes {
  display: table-cell;
  width: 40%;
  background-color: #ef6262;
  padding: 25px;
}

.widget-calendar .cal-container .cal-notes span {
  display: block;
  color: #FFFFFF;
}

.widget-calendar .cal-container .cal-notes span.day {
  font-size: 1.923rem;
  font-weight: 300;
}

.widget-calendar .cal-container .cal-notes span.date {
  font-size: 1.154rem;
  font-weight: 300;
  color: #fbd6d6;
}

.widget-calendar .cal-container .cal-notes span.title {
  font-size: 1.077rem;
  border-bottom: 1px solid #FFFFFF;
  margin-top: 35px;
  padding-bottom: 3px;
}

.widget-calendar .cal-container .cal-notes span.title .icon {
  float: right;
  font-size: 1.462rem;
  line-height: 22px;
  cursor: pointer;
}

.rtl .widget-calendar .cal-container .cal-notes span.title .icon {
  float: left;
}

.widget-calendar .cal-container .cal-notes ul {
  padding: 0;
  list-style: none;
  padding-top: 20px;
}

.widget-calendar .cal-container .cal-notes ul li {
  padding-bottom: 10px;
  font-size: .9231rem;
}

.widget-calendar .cal-container .cal-notes ul li span {
  display: inline-block;
}

.widget-calendar .cal-container .cal-notes ul li span.hour {
  float: right;
}

.rtl .widget-calendar .cal-container .cal-notes ul li span.hour {
  float: left;
}

.widget-calendar .cal-container .cal-notes ul li:last-child {
  padding-bottom: 0;
}

.widget-calendar .cal-container .cal-calendar {
  display: table-cell;
  padding: 25px;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker {
  position: relative;
  margin: 20px 0;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  cursor: default;
  line-height: 1.8em;
  margin: 0 2.3em;
  text-align: center;
  font-weight: 600;
  font-size: 1.077rem;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev, .widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  height: 1.8em;
  position: absolute;
  top: -5px;
  width: 1.8em;
  color: light;
  font-size: 2.154rem;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span, .widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span {
  overflow: hidden;
  display: block;
  text-indent: -99999px;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span:after, .widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span:after {
  cursor: pointer;
  content: "\e686";
  font-family: "Stroke 7";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  text-indent: 0;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  left: 2px;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  right: 2px;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next span:after {
  content: "\e684";
  right: 14px;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker table.ui-datepicker-calendar {
  width: 100%;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker table.ui-datepicker-calendar th {
  border: 0;
  padding: 3px;
  text-align: center;
  font-weight: 400;
  color: #555555;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker table.ui-datepicker-calendar th span {
  font-size: 1.077rem;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td {
  border: 0;
  padding: 3px;
  text-align: center;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td a {
  display: inline-block;
  padding: 0.2em;
  text-align: center;
  text-decoration: none;
  color: #888888;
  width: 25px;
  height: 25px;
  line-height: 20px;
  font-size: .9231rem;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td a.ui-state-active, .widget-calendar .cal-container .cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td a.ui-state-highlight {
  background-color: #e6e6e6;
  border-radius: 50%;
}

.widget-calendar .cal-container .cal-calendar .ui-datepicker table.ui-datepicker-calendar tr td a.ui-state-highlight {
  background-color: #ef6262;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  .widget-calendar .cal-container {
    display: block;
  }
  .widget-calendar .cal-container .cal-notes, .widget-calendar .cal-container .cal-calendar {
    display: block;
  }
  .widget-calendar .cal-container .cal-notes {
    width: 100%;
  }
  .widget-calendar .cal-container .cal-calendar .ui-datepicker {
    margin: 10px 0;
  }
}

.widget-download-list {
  padding: 0;
}

.widget-download-list > .widget-head {
  background-color: #ff7373;
  color: #FFFFFF;
  line-height: 33px;
  overflow: hidden;
  padding: 19px 20px;
  margin-bottom: 0;
}

.widget-download-list > .widget-head .icon {
  float: right;
  background-color: #ef6262;
  border-radius: 50%;
  font-size: 1.538rem;
  line-height: 19px;
  padding: 8px;
  height: 35px;
  width: 35px;
}

.rtl .widget-download-list > .widget-head .icon {
  float: left;
}

.widget-download-list .widget-list {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

.widget-download-list .widget-list li {
  border-bottom: 1px solid #e6e6e6;
}

.widget-download-list .widget-list li a {
  padding: 21px 20px;
  color: #555555;
  display: block;
  font-weight: 300;
  font-size: .9231rem;
}

.widget-download-list .widget-list li a .icon {
  float: right;
  color: #ef6262;
  font-size: 1.538rem;
}

.rtl .widget-download-list .widget-list li a .icon {
  float: left;
}

.widget-download-list .widget-list li a:hover {
  background-color: #fafafa;
}

.widget-download-list .widget-list li:last-child {
  border: 0;
}

.widget.line-chart .chart-container {
  position: relative;
  padding: 0 5px 5px 5px;
}

.widget.line-chart .chart-container > div {
  z-index: 1;
}

@media (max-width: 767px) {
  .widget.line-chart .chart-container > div {
    padding: 0 0 5px;
    min-height: 160px;
  }
}

.widget.line-chart .chart-container > .counter {
  min-height: auto;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}

.widget.line-chart .chart-container > .counter .value {
  font-size: 1.846rem;
}

.widget.line-chart .chart-container > .counter .desc {
  text-transform: uppercase;
  font-size: .8462rem;
  color: #b2b2b2;
}

.widget.line-chart .chart-table {
  padding-top: 20px;
}

.widget.line-chart .chart-table table {
  margin: 0;
}

.widget.line-chart .chart-table table .number {
  text-align: right;
  width: 20%;
}

@media (max-width: 767px) {
  .widget.line-chart .chart-table {
    padding: 10px 0 0;
  }
}

.widget.line-chart.widget-fullwidth .chart-container {
  padding: 0;
}

.widget.line-chart.widget-fullwidth .chart-container > div .tickLabel {
  font-size: 0;
}

.widget.line-chart.widget-fullwidth .chart-container > .counter {
  min-height: auto;
  left: 20px;
}

.widget.widget-map .map-container > div {
  height: 229px;
}

.widget.widget-radar > .widget-head {
  margin-bottom: 39px;
}

.widget.widget-radar .chart-container {
  margin-bottom: 20px;
}

.widget.widget-tile {
  padding: 24px 20px;
  margin-bottom: 20px;
}

.widget.widget-tile .data-info {
  display: inline-block;
}

.widget.widget-tile .data-info .value {
  font-size: 1.769rem;
  font-weight: 300;
}

.widget.widget-tile .data-info .desc {
  font-size: 1.077rem;
  line-height: 18px;
}

.widget.widget-tile .icon {
  display: inline-block;
  color: #ef6262;
  float: right;
  font-size: 3.462rem;
  line-height: 54px;
}

.rtl .widget.widget-tile .icon {
  float: left;
}

.widget.widget-tile.widget-tile-wide {
  display: table;
  width: 100%;
}

.widget.widget-tile.widget-tile-wide > div {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
}

.widget.widget-tile.widget-tile-wide .data-info {
  vertical-align: top;
}

.widget.widget-tile.widget-tile-wide .data-info .title {
  font-size: 1.231rem;
  line-height: 22px;
  margin-bottom: 2px;
}

.widget.widget-tile.widget-tile-wide .data-info .desc {
  color: gray;
  font-size: .9231rem;
}

.widget.widget-tile.widget-tile-wide .tile-value {
  text-align: right;
}

.rtl .widget.widget-tile.widget-tile-wide .tile-value {
  text-align: left;
}

.widget.widget-tile.widget-tile-wide .tile-value > span {
  display: block;
  padding-right: 10px;
  font-weight: 300;
  font-size: 2.385rem;
}

.widget.widget-tile.widget-tile-wide .icon {
  float: none;
  line-height: 43px;
  vertical-align: top;
  margin-right: 5px;
}

.rtl .widget.widget-tile.widget-tile-wide .icon {
  margin-right: 0;
  margin-left: 5px;
}

.widget-pie .chart-container {
  align-items: center;
  width: 100%;
  margin: 0;
}

@media (max-width: 767px) {
  .widget-pie .chart-container > div {
    display: block;
  }
  .widget-pie .chart-container > div:first-child {
    margin-bottom: 25px;
  }
}

.widget-pie .chart-container .chart {
  min-height: 125px;
}

.widget-pie .chart-container .legend > div {
  background-color: transparent !important;
}

.widget-pie .chart-container .legend table {
  width: 100%;
}

.widget-pie .chart-container .legend table tr td {
  padding-bottom: 7px;
  font-size: .9231rem;
}

.widget-pie .chart-container .legend table tr .legendColorBox {
  width: 1%;
}

.widget-pie .chart-container .legend table tr .legendColorBox > div {
  border: 0 !important;
}

.widget-pie .chart-container .legend table tr .legendLabel {
  padding-left: 8px;
  font-size: .9231rem;
}

.rtl .widget-pie .chart-container .legend table tr .legendLabel {
  padding-left: 0;
  padding-right: 8px;
}

.widget-pie .chart-container .legend table tr .legendData {
  text-align: right;
}

.rtl .widget-pie .chart-container .legend table tr .legendData {
  text-align: left;
}

.widget-pie .chart-info {
  border-top: 1px solid #e0e0e0;
  margin: 0;
  margin-top: 30px;
  text-align: center;
}

.widget-pie .chart-info span {
  display: block;
}

.widget-pie .chart-info span.title {
  margin-bottom: 3px;
  text-transform: uppercase;
  color: #b2b2b2;
  font-size: .9231rem;
}

.widget-pie .chart-info span.number {
  font-size: 1.385rem;
  font-weight: 300;
}

.widget-pie .chart-info > div {
  padding-top: 20px;
}

.widget-pie .chart-info > div:first-child {
  text-align: left;
  padding-left: 0;
}

.rtl .widget-pie .chart-info > div:first-child {
  text-align: right;
  padding-left: 15px;
  padding-right: 0;
}

.widget-pie .chart-info > div:last-child {
  text-align: right;
  padding-right: 0;
}

.rtl .widget-pie .chart-info > div:last-child {
  text-align: left;
  padding-right: 15px;
  padding-left: 0;
}

@media (max-width: 480px) {
  .widget-pie .chart-info {
    padding-top: 15px;
  }
  .widget-pie .chart-info > div {
    float: none;
    text-align: left !important;
    width: 100%;
    line-height: 40px;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
  }
  .widget-pie .chart-info > div span {
    display: inline-block;
  }
  .widget-pie .chart-info > div span.title {
    margin: 0;
  }
  .widget-pie .chart-info > div span.number {
    float: right;
  }
}

.widget-pie.widget-pie-stats .chart-info > div:last-child {
  text-align: left;
}

.rtl .widget-pie.widget-pie-stats .chart-info > div:last-child {
  text-align: right;
}

.widget-pie.widget-pie-stats .chart-info .sparkline {
  float: right;
  margin-top: 11px;
}

.rtl .widget-pie.widget-pie-stats .chart-info .sparkline {
  float: left;
}

@media (max-width: 480px) {
  .widget-pie.widget-pie-stats .chart-info > div {
    line-height: 25px;
  }
  .widget-pie.widget-pie-stats .chart-info > div span {
    display: block;
  }
  .widget-pie.widget-pie-stats .chart-info > div span.number {
    float: none;
  }
  .widget-pie.widget-pie-stats .chart-info > div:first-child {
    margin-bottom: 10px;
  }
}

.widget-weather {
  padding: 0;
}

.widget-weather .wt-container .wt-hour {
  background-color: #ff7373;
  color: #FFFFFF;
  font-size: 2.692rem;
  font-weight: 300;
  padding: 20px;
  text-align: center;
}

.widget-weather .wt-container .wt-hour .meridian {
  font-size: 1.077rem;
  vertical-align: middle;
  margin-left: 8px;
  vertical-align: top;
  line-height: 38px;
}

.widget-weather .wt-container .wt-info {
  display: table;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
}

.widget-weather .wt-container .wt-info .wt-day {
  display: table-cell;
  text-align: center;
  padding: 30px 0;
  border-right: 1px solid #e6e6e6;
}

.widget-weather .wt-container .wt-info .wt-day span {
  display: block;
  font-weight: 300;
  font-size: 1.154rem;
}

.widget-weather .wt-container .wt-info .wt-day span.day {
  margin-bottom: 10px;
  color: #ef6262;
}

.widget-weather .wt-container .wt-info .wt-day span.date {
  margin-top: 10px;
}

@media (max-width: 767px) {
  .widget-weather .wt-container .wt-info .wt-day canvas {
    height: auto !important;
    width: auto !important;
  }
}

.widget-weather .wt-container .wt-info .wt-day:last-child {
  border-right: 0;
}

.widget-weather .wt-container .wt-location {
  padding: 19px;
  font-size: 1.154rem;
  font-weight: 300;
}

.widget-weather .wt-container .wt-location .icon {
  float: right;
  cursor: pointer;
  font-size: 1.538rem;
}

.rtl .widget-weather .wt-container .wt-location .icon {
  float: left;
}

.widget-weather .wt-container .wt-location .icon:hover {
  color: #ef6262;
}
